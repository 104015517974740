import React, { useEffect, useState } from "react";
import api, { API_URL } from "../../../../api";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import NamesInitials from "../../../../components/general/namesInitials";
import FormatDate from "../../../../components/general/formatDateTime";
import ReviewFrom from "../../../../components/incidents/forms/reviewFrom";

const EmployeeReviews = ({ incidentId }) => {
  const [reviews, setReviews] = useState([]);

  const [gettingReviews, setGettingReviews] = useState(true);
  const [showReviewForm, setShowReviewForm] = useState(false);
  const toggleReviewForm = () => {
    setShowReviewForm(!showReviewForm);
  };
  useEffect(() => {
    const getIncidentReviews = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/employee_incident/${incidentId}/reviews/`
        );
        if (response.status === 200) {
          setReviews(response.data);
          console.log(response.data);
          localStorage.setItem("incidentReviewsCount", response.data.length);
          setGettingReviews(false);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          toast.error("Authentication error");
          window.location.reload();
        } else {
          toast.error("Failed to fetch incident reviews");
          console.error(error);
        }
        setGettingReviews(false);
      }
    };
    getIncidentReviews();

    const intervalId = setInterval(getIncidentReviews, 3000);
    return () => clearInterval(intervalId);
  }, [incidentId]);
  return gettingReviews ? (
    <p>...getting reviews</p>
  ) : (
    <div className="incident-comments">
      {reviews &&
        reviews.map((review, index) => (
          <div key={index} className="review">
            <div className="profile">
              {review.created_by.profile_img &&
              review.created_by.profile_img ? (
                <img
                  className="profile-pic"
                  src={`${API_URL}${review.created_by.profile_img}`}
                  alt="Profile Pic"
                />
              ) : (
                <div className="profile-place-holder">
                  {/* we will find a way to change color according to the user who is logged in */}
                  <NamesInitials
                    fullName={`${review.created_by.first_name || "None"} ${
                      review.created_by.last_name || "None"
                    }`}
                  />
                </div>
              )}
            </div>
            <div className="content">
              <div className="names-title">
                <h4>
                  {review.created_by.first_name} {review.created_by.last_name}
                </h4>
                <div className="title">
                  <small>{review.created_by.position}</small>
                </div>
              </div>

              <div
                className="text"
                dangerouslySetInnerHTML={{ __html: review.content } || "None"}
              >
                {/* <p>{review.content}</p> */}
              </div>
              {review.files &&
                review.files.map((file, index) => (
                  <a
                    href={`${API_URL}${file.file}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {file.file_name}
                  </a>
                ))}
              <small className="date-time">
                <FormatDate date={review.created_at} />
              </small>
            </div>
          </div>
        ))}

      {showReviewForm ? (
        <div className="review-form-popup">
          <ReviewFrom
            incidentId={incidentId}
            toggleReviewForm={toggleReviewForm}
            incidentName={"employee_incident"}
          />
        </div>
      ) : (
        <button
          onClick={toggleReviewForm}
          className="primary-button show-review-button"
        >
          Add review
        </button>
      )}
    </div>
  );
};

export default EmployeeReviews;
