import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import DashBoardContainer from '../../../dashboardContainer'
import api from '../../../../../api'
import BackToPage from '../../../../../components/incidents/backToPage'
import ModifyMedicalErrorForm from '../../../../../components/incidents/forms/modiy/modifyMedicalErrorForm'
import { ArrowRight01Icon } from "hugeicons-react";
import { Link } from "react-router-dom";


const ModifyMedicalErrorIncidentPageContent = () => {
  const [error, setError] = useState()
  const [incident, setIncident] = useState({})
  const { incidentId } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchIncidentData = async () => {
      try {
        const response = await api.get(`/incidents/medication_error/${incidentId}/`)

        if (response.status === 200) {
          console.log(response.data.data)
          setIncident(response.data.data)
          setIsLoading(false)
        }
      } catch (error) {
        setError("Error getting incident data")
        console.log(error)
      }
    }
    fetchIncidentData()
  }, [])
  return (
    <div>
      {
        isLoading ? 'Loading'
          : incident && <ModifyMedicalErrorForm data={incident} incidentId={incidentId} />
      }
    </div>
  )
}

const BreadCrumbs = () => {
  const { incidentId } = useParams()
  return (
      <div className="breadcrumbs">
          <Link to={'/'}>Overview</Link> <ArrowRight01Icon /> 
          <Link to={'/incidents/'}>Incidents</Link> <ArrowRight01Icon /> 
          <Link to={'/incident/medication_error/'}>Medication Error List</Link>  <ArrowRight01Icon /> 
          <Link to={`/incident/medication_error/${incidentId}/`}>#{incidentId}</Link> <ArrowRight01Icon />
          <Link className='current-page'> Modify</Link>
      </div>
  )
};

const ModifyMedicalErrorIncidentPage = () => {
  return (
    <DashBoardContainer content={<ModifyMedicalErrorIncidentPageContent />} breadCrumbs={<BreadCrumbs />}/>
  )
}

export default ModifyMedicalErrorIncidentPage
