import React from "react";

const LostFoundOtherInformation = ({ data, incidentStatuses }) => {
  const { data: incidentData } = data;
  return (
    <div className="incident-type-data">
      <div className="general-col">
        {/* <div className="general-sub-col">
          <h3>Contributing Factors</h3>
          <small>{incidentData.contributing_factors || "Not provided"}</small>
        </div> */}

        {/* <div className="general-sub-col">
          <h3>Severity of error</h3>

          <small>{incidentData.error_category || "Not provided"}</small>
        </div> */}
        {/* <div className="general-sub-col">
          <h3>Your comments:</h3>
          <small>{incidentData.comments || "Not provided"}</small>
        </div> */}
        {/* <div className="general-sub-col">
          <h3>Actions/Outcomes:</h3>
          <small>{incidentData.action_taken || "Not provided"}</small>
        </div> */}
      </div>
    </div>
  );
};

export default LostFoundOtherInformation;
