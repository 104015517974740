import React, { useEffect, useState } from "react";
import "../../assets/css/main/main.css";
import api, { API_URL } from "../../api";

import TableActionsPopup from "../general/popups/tableActionPopup";
import { usePermission } from "../../contexts/permissionsContext";
import NoAccessPage from "../../pages/errorPages/401";
import {
  MoreHorizontalSquare01Icon,
  FilterHorizontalIcon,
  CancelSquareIcon,
  PencilEdit01Icon,
  PencilEdit02Icon,
  EyeIcon,
  Note01Icon,
  NoteEditIcon,
  Cancel01Icon,
  PrinterIcon,
  ArrowDown01Icon,
} from "hugeicons-react";
import { useNavigate } from "react-router-dom";
import { ComplainDetails } from "../profile/profileComplaints";
import DateFormatter from "../../pages/dashboard/incidents/dateFormatter";
import ModifyPageLoader from "../../pages/dashboard/incidents/generalIncident/modify/loader";

const handleSearch = (items, searchString) => {
  if (searchString.length > 2) {
    const results = items.filter((item) => {
      return (
        item.patient_name.toLowerCase().includes(searchString.toLowerCase()) ||
        item.form_initiated_by.toLowerCase()
        //   .includes(searchString.toLowerCase()) ||
        // item.follow_up.toLowerCase().includes(searchString.toLowerCase())
      );
    });
    return results;
  }

  return [];
};
function formatDate(dateString) {
  const date = new Date(dateString);
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = String(date.getFullYear()).slice(0);
  return `${month}-${day}-${year}`;
}

const GrievanceTab = () => {
  const permission = usePermission();
  const [errorFetching, setErrorFetching] = useState("");
  const [isFetching, setIsFetching] = useState(true);
  const [grievanceData, setGrievanceData] = useState([]);

  const [searchResults, setSearchResults] = useState("");
  const [resultsFound, setResultsFound] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [filterByDate, setFilterByDate] = useState(false);
  const [activeTab, setActiveTab] = useState("all");

  const [openAction, setOpenAction] = useState(false);
  const [openActionIndex, setOpenActionIndex] = useState("");
  const navigate = useNavigate();

  const handleRowClick = (incidentId) => {
    navigate(`/incident/grievance/${incidentId}`);
  };
  const navigateToModify = (incidentId) => {
    navigate(`/incident/grievance/${incidentId}/modify/`);
  };

  const handleNonClickableColumnClick = (event) => {
    event.stopPropagation();
  };

  const toggleAction = (index) => {
    setOpenActionIndex(index);
    setOpenAction(!openAction);
  };
  const toggleFilterByDate = () => {
    setFilterByDate(!filterByDate);
  };

  const search = (string) => {
    setIsSearching(true);
    if (Object.keys(string).length < 2) {
      setIsSearching(false);
    }
    if (Object.keys(string).length > 2) {
      const results = handleSearch(grievanceData, string);
      setSearchResults(results);
    }
  };

  useEffect(() => {
    const fetchGrievanceData = async () => {
      try {
        const response = await api.get(`${API_URL}/incidents/grievance/`);
        if (response.status === 200) {
          setGrievanceData(response.data.grievances);
          setIsFetching(false);
          console.log(response.data);
        }
      } catch (error) {
        if (error.response.data.error) {
          setErrorFetching(error.response.data.error);
          setIsFetching(false);
        } else {
          setErrorFetching("An error occurred while fetching incident data.");
          setIsFetching(false);
        }
      }
    };
    fetchGrievanceData();
    setIsFetching(false);
  }, []);

  return isFetching ? <ModifyPageLoader /> : (
    <>
      {permission.includes("Quality - Risk Manager") ||
        permission.includes("managers") ? (
        <div>
          {errorFetching ? (
            <div className="error-message">
              <p>{errorFetching}</p>
            </div>
          ) : (
            <div className="tab-container incidents-tab tracking-headers grievance-tracking-headers">
              <div className="grievance-tab-headers">
                <div className="tab-header">
                  <div className="title-container-action">
                    <div className="title-container">
                      <h2 className="title">Grievance List</h2>
                      <p>{grievanceData.length} incidents available</p>
                    </div>
                  </div>

                  {/* <div className="filters">
                  <div className="search-input">
                    <i className="fa-solid fa-magnifying-glass"></i>
                    <input
                      onChange={(e) => {
                        search(e.target.value);
                      }}
                      // value={searchString}
                      type="search"
                      name="systemSearch"
                      id="systemSearch"
                      placeholder="Search the system"
                    />
                  </div>

                  <div className="filter-buttons">
                    <select name="employee" id="status">
                      <option value="employee">Employee</option>
                      <option value="patient">Patient</option>
                      <option value="general">General</option>
                    </select>

                    <select name="status" id="status">
                      <option value="resolved">Resolved</option>
                      <option value="unresolved">Unresolved</option>
                    </select>

                    <div className="date-filter">
                      <button
                        onClick={toggleFilterByDate}
                        className="date-filter-button"
                      >
                        <i className="fa-solid fa-calendar-days"></i>
                        <p> Date </p>
                      </button>

                      {filterByDate ? (
                        <div className="calendars-container">
                          <h3>Filter by dates</h3>

                          <div className="calendars">
                            <div className="calendar">
                              <p>Since</p>
                              <input type="date" />
                            </div>

                            <div className="calendar">
                              <p>Until</p>
                              <input type="date" />
                            </div>
                          </div>

                          <div className="buttons">
                            <button
                              onClick={toggleFilterByDate}
                              className="secondary-button"
                            >
                              Cancel
                            </button>
                            <button className="primary-button">
                              <i className="fa-solid fa-filter"></i>
                              Filter
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div> */}
                </div>
                <div className="tabs">
                  <div
                    onClick={() => setActiveTab("all")}
                    className={`reports-tab tracking-tab ${activeTab === "all" ? "active" : ""
                      }`}
                  >
                    <Note01Icon />
                    <span>Grievance reports</span>
                  </div>

                  <div
                    onClick={() => setActiveTab("drafts")}
                    className={`drafts tracking-tab  ${activeTab === "drafts" ? "active" : ""
                      }`}
                  >
                    <NoteEditIcon />
                    <span>Complaints</span>
                  </div>
                </div>
              </div>

              <div className="incident-content">
                {activeTab === "all" && (
                  <div className="incident-list">
                    <table>
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>ID</th>
                          <th>Facility</th>
                          <th>MRN</th>
                          <th>Name</th>

                          {/* <th>Title</th> */}
                          {/* <th>Patient Name</th> */}

                          {/* <th>Date of Complaint-Grievance</th> */}
                          <th>Date</th>
                          {/* <th>Nature of complaint-Grievance</th> */}
                          {/* <th>Nature</th> */}

                          {/* <th>Follow up</th> */}
                          {/* <th>Complaint resolved by staff present</th> */}

                          {/* <th>Investigation start date</th> */}
                          {/* <th>Investigation end date</th> */}
                          <th>Status</th>

                          <th>Action</th>
                        </tr>
                      </thead>
                      {isSearching ? (
                        <tbody>
                          {searchResults.length > 0 ? (
                            searchResults.map((grievance, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{grievance.id}</td>
                                <td>
                                  {grievance.report_facility?.name ||
                                    "No provided"}
                                </td>
                                <td>
                                  {grievance.medical_record_number ||
                                    "Not specified"}
                                </td>
                                <td>{grievance.patient_name || "-"}</td>
                                {/* <td>{grievance.form_initiated_by || "-"}</td> */}

                                <td>
                                  {(
                                    <DateFormatter
                                      dateString={grievance.date}
                                    />
                                  ) || "-"}
                                </td>
                                <td>{grievance.status || "Not specified"}</td>

                                <td
                                  onClick={(event) =>
                                    handleNonClickableColumnClick(event)
                                  }
                                  className="action-col"
                                >
                                  <div className="table-actions">
                                    {
                                      !grievance.is_resolved &&
                                      <PencilEdit02Icon
                                        size={20}
                                        onClick={() =>
                                          navigateToModify(grievance.id)
                                        }
                                      />
                                    }
                                    <EyeIcon
                                      size={20}
                                      onClick={() =>
                                        handleRowClick(grievance.id)
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <p>Nothing found</p>
                          )}
                        </tbody>
                      ) : (
                        <tbody>
                          {grievanceData.map((grievance, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{grievance.id}</td>
                              <td>
                                {grievance.report_facility?.name ||
                                  "No provided"}
                              </td>
                              <td>
                                {grievance.medical_record_number ||
                                  "Not specified"}
                              </td>
                              <td>{grievance.patient_name || "-"}</td>
                              {/* <td>{grievance.form_initiated_by || "-"}</td> */}

                              <td>
                                {(
                                  <DateFormatter dateString={grievance.date} />
                                ) || "-"}
                              </td>
                              <td>{grievance.status || "Not specified"}</td>

                              <td
                                onClick={(event) =>
                                  handleNonClickableColumnClick(event)
                                }
                                className="action-col"
                              >
                                <div className="table-actions">
                                  {
                                    !grievance.is_resolved &&
                                    <PencilEdit02Icon
                                      size={20}
                                      onClick={() =>
                                        navigateToModify(grievance.id)
                                      }
                                    />}
                                  <EyeIcon
                                    size={20}
                                    onClick={() => handleRowClick(grievance.id)}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </table>
                  </div>
                )}
                {activeTab === "drafts" && <ComplaintsTab />}
              </div>
            </div>
          )}
        </div>
      ) : (
        <NoAccessPage />
      )}
    </>
  );
};

const ComplaintsTab = () => {
  const [loadingComplaints, setLoadingComplaints] = useState(true);
  const [grievanceComplaints, setGrievanceComplaints] = useState([]);

  const [showComplaintDetails, setShowComplainDetails] = useState(false);
  const [selectedComplain, setSelectedComplain] = useState({});

  const handleShowComplainDetails = () => {
    setShowComplainDetails(!showComplaintDetails);
  };

  const handleSelectedComplaint = (complaint) => {
    setSelectedComplain(complaint);
    handleShowComplainDetails();
  };

  useEffect(() => {
    const fetchGrievanceComplaints = async () => {
      try {
        const response = await api.get(`${API_URL}/complaints/`);
        if (response.status === 200) {
          console.log(response.data.complaints);
          setGrievanceComplaints(response.data.complaints);
          setLoadingComplaints(false);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchGrievanceComplaints();
  }, []);
  return loadingComplaints ? (
    <span style={{ paddingLeft: "20px" }}>Loading...</span>
  ) : grievanceComplaints.length > 0 ? (
    <div className="incident-list">
      <table>
        <thead>
          <tr>
            <th>No</th>
            <th>Patient Name</th>
            <th>Phone Number</th>
            <th>MRN</th>
            <th>Complaint Nature</th>

            {/* <th>Title</th> */}
            {/* <th>Patient Name</th> */}

            {/* <th>Date of Complaint-Grievance</th> */}
            <th>Complaint Type</th>
            {/* <th>Nature of complaint-Grievance</th> */}
            <th>Date of Complaint</th>

            {/* <th>Follow up</th> */}
            {/* <th>Complaint resolved by staff present</th> */}
            <th>Resolved</th>
            <th>How complaint was taken</th>
          </tr>
        </thead>
        {/* {isSearching ? (
      <tbody>
        {searchResults.length > 0 ? (
          searchResults.map((grievance, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{grievance.patient_name}</td>
              <td>
                {grievance.phone_number ||
                  "Not specified"}
              </td>
              <td>{grievance.medical_record_number || "-"}</td>
              <td>{grievance.complaint_nature || "-"}</td>
              <td>{grievance.complaint_type || "Not specified"}</td>
              <td>{grievance.date_of_complaint || "-"} </td>
              <td>
                {grievance.resolved_by_staff || "Not specified"}
              </td>
              <td>{grievance.how_complaint_was_taken || "-"}</td>
              
            </tr>
          ))
        ) : (
          <p>Nothing found</p>
        )}
      </tbody>
    ) : ( */}
        <tbody>
          {showComplaintDetails && (
            <ComplainDetails
              handleShowComplainDetails={handleShowComplainDetails}
              complaint={selectedComplain}
            />
          )}
          {grievanceComplaints.map((complaint, index) => (
            <tr key={index} onClick={() => handleSelectedComplaint(complaint)}>
              <td>{index + 1}</td>
              <td>{complaint.patient_name}</td>
              <td>{complaint.phone_number || "Not specified"}</td>
              <td>{complaint.medical_record_number || "-"}</td>
              <td>{complaint.complaint_nature || "-"}</td>
              <td>{complaint.complaint_type || "Not specified"}</td>
              <td>{complaint.date_of_complaint || "-"} </td>
              <td>
                {(complaint.resolved_by_staff ? "Yes" : "No") ||
                  "Not specified"}
              </td>
              <td>{complaint.how_complaint_was_taken || "-"}</td>
            </tr>
          ))}
        </tbody>
        {/* )} */}
      </table>
    </div>
  ) : (
    <div>No Complaint Available</div>
  );
};

{
  /* <div className="tabs">
              <div onClick={() => setActiveTab('all')} className={`reports-tab tracking-tab ${activeTab === 'all' ? 'active' : ''}`}>
                <Note01Icon />
                <span>All reports</span>
              </div>

              <div onClick={() => setActiveTab('drafts')} className={`drafts tracking-tab  ${activeTab === 'drafts' ? 'active' : ''}`}>
                <NoteEditIcon />
                <span>Drafts</span>
              </div>
            </div> */
}

export default GrievanceTab;
