import React, { useEffect, useState } from "react";
import api, { API_URL } from "../../../../api";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import DateFormatter from "../dateFormatter";
const GeneralIncidentDocumentHistory = ({ incidentId }) => {
  const [documentHistory, setDocumentHistory] = useState([]);
  const [gettingDocumentHistory, setGettingDocumentHistory] = useState(true);

  useEffect(() => {
    const getDocumentHistory = async () => {
      try {
        const response = await api.get(
          `${API_URL}/activities/list/${incidentId}/`
        );
        if (response.status === 200) {
          setDocumentHistory(response.data);
          console.log(response.data);
          localStorage.setItem("documentHistoryCount", response.data.length);
          setGettingDocumentHistory(false);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          toast.error("Authentication error");
          window.location.reload();
        } else {
          toast.error("Failed to fetch document History");
          console.error(error);
        }
        setGettingDocumentHistory(false);
      }
    };
    getDocumentHistory();
  }, []);
  return gettingDocumentHistory ? (
    <p>...getting document history</p>
  ) : documentHistory && documentHistory.length > 0 ? (
    <div className="document-history-container">
      {documentHistory.map((el, index) => (
        <div key={index} className="document-history">
          <div className="document-row">
            <div className="small-circle"></div>
            <div className="document-col">
              <p>
                {el.user.email ? el.user.email : "Anonymous "} &nbsp;
                {el.description}
              </p>
              <p>
                <span>
                  On <DateFormatter dateString={el.timestamp} />
                </span>
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <p>No document history</p>
  );
};

export default GeneralIncidentDocumentHistory;
