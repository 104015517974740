import React from 'react'
import DashBoardContainer from '../dashboardContainer'
import { Link } from 'react-router-dom'
import { ArrowRight01Icon } from 'hugeicons-react'

const DepartmentsPageContent = () => {
    return (
        <div>
            <h2>Departments</h2>
        </div>
    )

}
const BreadCrumbs = () => {
    return (
        <div className="breadcrumbs">
            <Link to={'/'}>Overview</Link>
            <ArrowRight01Icon />
            <Link className='current-page'>Facilities</Link>
        </div>
    )
}

const DepartmentsPage = () => {
    return (
        <DashBoardContainer content={<DepartmentsPageContent />} breadCrumbs={''} />
    )
}

export default DepartmentsPage
