import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import DashBoardContainer from "../../../dashboardContainer";
import api from "../../../../../api";
import ModifyWorkplaceIncident from "../../../../../components/incidents/forms/modiy/workplaceIncident";
import { ArrowLeft01Icon, ArrowTurnBackwardIcon } from "hugeicons-react";
import BackToPage from "../../../../../components/incidents/backToPage";
import ModifyEmployeeIncident from "../../../../../components/incidents/forms/modiy/employeeIncident";
import { ArrowRight01Icon } from "hugeicons-react";

const ModifyEmployeeIncidentPageContent = () => {
  const [error, setError] = useState();
  const [incident, setIncident] = useState({});
  const { incidentId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [investigation, setInvestigation] = useState();
  useEffect(() => {
    const fetchIncidentData = async () => {
      try {
        const response = await api.get(
          `/incidents/employee_incident/${incidentId}/`
        );

        if (response.status === 200) {
          console.log(response.data);
          setIncident(response.data);
          setIsLoading(false);
          setInvestigation(response.data.investigation);
        }
      } catch (error) {
        setError("Error getting incident data");
      }
    };
    fetchIncidentData();
  }, []);

  return (
    <div>
      {isLoading
        ? "Loading"
        : incident && (
          <ModifyEmployeeIncident
            data={incident}
            incidentId={incidentId}
            investigation={investigation}
          />
        )}
    </div>
  );
};

const BreadCrumbs = () => {
  const { incidentId } = useParams();
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />
      <Link to={"/incidents/"}>Incidents</Link> <ArrowRight01Icon />
      <Link to={"/incident/employee/"}>Employee Incident Report</Link>{" "}
      <ArrowRight01Icon />
      <Link to={`/incident/employee_incident/${incidentId}/`}>
        #{incidentId}
      </Link>{" "}
      <ArrowRight01Icon />
      <Link className="current-page"> Modify</Link>

    </div>
  );
};

const ModifyEmployeeIncidentPage = () => {
  return (
    <DashBoardContainer
      content={<ModifyEmployeeIncidentPageContent />}
      breadCrumbs={<BreadCrumbs />}
    />
  );
};

export default ModifyEmployeeIncidentPage;
