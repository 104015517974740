import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import DashBoardContainer from '../../../dashboardContainer';
import api from '../../../../../api';
import ModifyLostFound from '../../../../../components/incidents/forms/modiy/ModifyLostFound';
import { ArrowLeft01Icon, ArrowTurnBackwardIcon } from 'hugeicons-react';
import BackToPage from '../../../../../components/incidents/backToPage';
import { ArrowRight01Icon } from "hugeicons-react";

const ModifyLostFoundPageContent = () => {
    const [error, setError] = useState()
    const [incident, setIncident] = useState({})
    const { incidentId } = useParams()
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        const fetchIncidentData = async () => {
            try {
                const response = await api.get(`/incidents/lost_and_found/${incidentId}/`)
                if (response.status === 200) {
                    console.log(response.data.lost_and_found)
                    setIncident(response.data.lost_and_found)
                    setIsLoading(false)
                };
            } catch (error) {
                setError("Error getting incident data")
            };
        }
        fetchIncidentData();
    }, []);


    return (
        <div>
            <ModifyLostFound data={incident} incidentId={incidentId} />
        </div>
    )
}

const BreadCrumbs = () => {
    const { incidentId } = useParams();
    return (
        <div className="breadcrumbs">
            <Link to={'/'}>Overview</Link> <ArrowRight01Icon />
            <Link to={'/incidents/'}>Incidents</Link> <ArrowRight01Icon />
            <Link to={'/incident/lost-and-found/'}>Lost & Found Property report</Link>  <ArrowRight01Icon />
            <Link to={`/incident/lost_and_found/${incidentId}/`}>#{incidentId}</Link> <ArrowRight01Icon />
            <Link className='current-page'> Modify</Link>
        </div>
    )
};

const ModifyLostFoundPage = () => {
    return (
        <DashBoardContainer content={<ModifyLostFoundPageContent />} breadCrumbs={<BreadCrumbs />} />
    );
};

export default ModifyLostFoundPage;