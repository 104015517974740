import React from "react";
import { Helmet } from "react-helmet";
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';

import '../../assets/css/pages/splashScreen/splash_screen.css';
import { Link } from "react-router-dom";

const SplashScreen = () => {
    const { login, register } = useKindeAuth();

    return (
        <div className="page dark light splash-screen">
            <Helmet>
                <title>Login | Quality Control</title>
            </Helmet>
            <div className="container">
                <div className="splash-img">
                    <div className="img"></div>
                </div>

                <div className="splash-content">
                    <h1 className="title">Cohesive Quality Control <br />Software</h1>
                    <div className="text">
                        This is a <span className="company-name">Cohesive Healthcare Management And Consulting</span> product. To use it, you need to be from one of our managed entities or have provided rights. Click Let’s get started... We’ll verify some stuff and let you in....
                    </div>
                    <Link onClick={login} className="button primary-button">Get started</Link>
                </div>

            </div>
        </div>
    );
};

export default SplashScreen;
