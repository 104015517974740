import React, { useEffect, useState } from "react";
import "../../assets/css/main/main.css";
import api, { API_URL } from "../../api";

import TableActionsPopup from "../general/popups/tableActionPopup";
import { usePermission } from "../../contexts/permissionsContext";
import NoAccessPage from "../../pages/errorPages/401";
import {
  MoreHorizontalSquare01Icon,
  FilterHorizontalIcon,
  CancelSquareIcon,
  PencilEdit01Icon,
  PencilEdit02Icon,
  EyeIcon,
} from "hugeicons-react";
import { useNavigate } from "react-router-dom";
import SliceText from "../general/sliceText";
import DateFormatter from "../../pages/dashboard/incidents/dateFormatter";
import ModifyPageLoader from "../../pages/dashboard/incidents/generalIncident/modify/loader";

const handleSearch = (items, searchString) => {
  if (searchString.length > 2) {
    const results = items.filter((item) => {
      return (
        item.patient.toLowerCase().includes(searchString.toLowerCase()) ||
        item.incident_type.toLowerCase().includes(searchString.toLowerCase()) ||
        item.follow_up.toLowerCase().includes(searchString.toLowerCase())
      );
    });
    return results;
  }
  return [];
};

function formatDate(dateString) {
  const date = new Date(dateString);
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = String(date.getFullYear()).slice(0);
  return `${month}-${day}-${year}`;
}

const IncidentsTab = () => {
  const permission = usePermission();
  const [errorFetching, setErrorFetching] = useState("");
  const [isFetching, setIsFetching] = useState(true);
  const [incidentData, setIncidentData] = useState([]);
  const [searchResults, setSearchResults] = useState("");
  const [resultsFound, setResultsFound] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [filterByDate, setFilterByDate] = useState(false);

  const [openAction, setOpenAction] = useState(false);
  const [openActionIndex, setOpenActionIndex] = useState("");
  const navigate = useNavigate();

  const toggleAction = (index) => {
    setOpenActionIndex(index);
    setOpenAction(!openAction);
  };

  const handleRowClick = (incidentId) => {
    navigate(`/incident/employee_incident/${incidentId}`);
  };
  const navigateToModify = (incidentId) => {
    navigate(`/incident/employee_incident/${incidentId}/modify/`);
  };

  const handleNonClickableColumnClick = (event) => {
    event.stopPropagation();
  };

  const toggleFilterByDate = () => {
    setFilterByDate(!filterByDate);
  };

  const search = (string) => {
    setIsSearching(true);
    if (Object.keys(string).length < 2) {
      setIsSearching(false);
    }
    if (Object.keys(string).length > 2) {
      const results = handleSearch(incidentData, string);
      setSearchResults(results);
    }
  };

  useEffect(() => {
    const fetchIncidentData = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/employee_incident/`
        );
        if (response.status === 200) {
          // Format the dates before setting the state
          const formattedData = response.data.map((item) => ({
            ...item,
            date_of_injury_or_near_miss: formatDate(
              item.date_of_injury_or_near_miss
            ),
          }));
          setIncidentData(formattedData);
          setIsFetching(false);
          console.log("Staff incidents", response.data);
        }
      } catch (error) {
        if (error.response.data.error) {
          setErrorFetching(error.response.data.error);
        } else {
          setErrorFetching("An error occurred while fetching incident data.");
        }
        setIsFetching(false);
      }
    };
    fetchIncidentData();
  }, []);

  return isFetching ? <ModifyPageLoader /> : (
    <>
      {permission.includes("Quality - Risk Manager") ||
        permission.includes("managers") ? (
        <div>
          {errorFetching ? (
            <div className="error-message">
              <p>{errorFetching}</p>
            </div>
          ) : (
            <div className="tab-container incidents-tab">
              <div className="tab-header">
                <div className="title-container-action">
                  <div className="title-container">
                    <h2 className="title">Staff Incident Report</h2>
                    <p>{incidentData.length} incidents available</p>
                  </div>
                </div>

                {/* <div className="filters">
                  <div className="search-input">
                    <i className="fa-solid fa-magnifying-glass"></i>
                    <input
                      onChange={(e) => {
                        search(e.target.value);
                      }}
                      type="search"
                      name="systemSearch"
                      id="systemSearch"
                      placeholder="Search the system"
                    />
                  </div>

                  <div className="filter-buttons">
                    <select name="employee" id="status">
                      <option value="employee">Employee</option>
                      <option value="patient">Patient</option>
                      <option value="general">General</option>
                    </select>

                    <select name="status" id="status">
                      <option value="resolved">Resolved</option>
                      <option value="unresolved">Unresolved</option>
                    </select>

                    <div className="date-filter">
                      <button
                        onClick={toggleFilterByDate}
                        className="date-filter-button"
                      >
                        <i className="fa-solid fa-calendar-days"></i>
                        <p> Date </p>
                      </button>

                      {filterByDate ? (
                        <div className="calendars-container">
                          <h3>Filter by dates</h3>

                          <div className="calendars">
                            <div className="calendar">
                              <p>Since</p>
                              <input type="date" />
                            </div>

                            <div className="calendar">
                              <p>Until</p>
                              <input type="date" />
                            </div>
                          </div>

                          <div className="buttons">
                            <button
                              onClick={toggleFilterByDate}
                              className="secondary-button"
                            >
                              Cancel
                            </button>
                            <button className="primary-button">
                              <i className="fa-solid fa-filter"></i>
                              Filter
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="incident-list">
                <table>
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>ID</th>
                      <th>Facility</th>
                      <th>Staff name </th>
                      <th>Injury Date & Time</th>
                      {/* <th>Brief description of incident & Type of injury</th> */}
                      <th>Claims Notified</th>
                      {/* <th>Claim </th> */}
                      <th>Claim contact & PH </th>
                      <th>Follow up</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {isSearching ? (
                    <tbody>
                      {searchResults.length > 0 ? (
                        searchResults.map((employee, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <th>{employee.id}</th>
                            <td>{employee.facility || "Not provided"}</td>
                            <td>{employee.name || "-"}</td>
                            <td>
                              {(
                                <div>
                                  <DateFormatter
                                    dateString={
                                      employee.date_of_injury_or_near_miss
                                    }
                                  />
                                  ,&nbsp;{employee.time_of_injury_or_near_miss}
                                </div>
                              ) || "-"}
                            </td>

                            <td>
                              {SliceText(employee.incident_description, 50) ||
                                "-"}
                            </td>
                            <td>{employee.claim || "Not Specified"}</td>
                            <td>{employee.claim || "Not Specified"}</td>
                            {/* <td>{employee.claim || "Not Specified"}</td> */}
                            <td>{employee.follow_up || "-"}</td>
                            <td
                              onClick={(event) =>
                                handleNonClickableColumnClick(event)
                              }
                              className="action-col"
                            >
                              <div className="table-actions">
                                {
                                  !employee.is_resolved &&
                                  <PencilEdit02Icon
                                    size={20}
                                    onClick={() => navigateToModify(employee.id)}
                                  />
                                }
                                <EyeIcon
                                  size={20}
                                  onClick={() => handleRowClick(employee.id)}
                                />
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <p>Nothing found</p>
                      )}
                    </tbody>
                  ) : (
                    <tbody>
                      {incidentData.map((employee, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{employee.id}</td>
                          <td>{employee.report_facility || "Not provided"}</td>
                          <td>{employee.name || "-"}</td>
                          <td>
                            {(
                              <div>
                                <DateFormatter
                                  dateString={
                                    employee.date_of_injury_or_near_miss
                                  }
                                />
                                ,&nbsp;{employee.time_of_injury_or_near_miss}
                              </div>
                            ) || "-"}
                          </td>
                          {/* <td>
                            {(
                              <SliceText
                                text={employee.incident_description}
                                maxLength={25}
                              />
                            ) || "-"}
                          </td> */}
                          <td>{employee.claim || "Not Specified"}</td>
                          <td>{employee.claim || "Not Specified"}</td>
                          {/* <td>{employee.claim || "Not Specified"}</td> */}
                          {/* header add - claims */}
                          <td>{employee.follow_up || "-"}</td>
                          {/* <td
                            onClick={() => toggleAction(index)}
                            className="action-col"
                          >
                            <MoreHorizontalSquare01Icon
                              size={24}
                              variant={"stroke"}
                            />
                            {openAction && openActionIndex === index ? (
                              <TableActionsPopup
                                incidentId={employee.id}
                                detailPageLink={"/incident/employee_incident"}
                                deleteAPI={""}
                                editPageLink={""}
                              />
                            ) : (
                              ""
                            )}
                          </td> */}
                          <td
                            onClick={(event) =>
                              handleNonClickableColumnClick(event)
                            }
                            className="action-col"
                          >
                            <div className="table-actions">
                              {
                                !employee.is_resolved &&
                                <PencilEdit02Icon
                                  size={20}
                                  onClick={() => navigateToModify(employee.id)}
                                />
                              }
                              <EyeIcon
                                size={20}
                                onClick={() => handleRowClick(employee.id)}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          )}
        </div>
      ) : (
        <NoAccessPage />
      )}
    </>
  );
};

export default IncidentsTab;
