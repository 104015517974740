import React, { useEffect, useState } from "react";
import { usePermission } from "../../contexts/permissionsContext.jsx";
import SideBar from "../../components/dashaboard/sideBar";
import GrievanceTab from "../../components/incidents/grievanceTab.jsx";
import PopUp from "../../components/general/popup";

import "../../assets/css/pages/incidents/incidents_page.css";
import EmployeeTab from "../../components/incidents/employeeTab";
import MedicalTab from "../../components/incidents/mediacalTab";
import InvestigationTab from "../../components/incidents/investigationTab";
import WorkPlaceTab from "../../components/incidents/workplaceTab";
import IncidentsTab from "../../components/incidents/incidentaTab";
import GrievanceInvestigationTab from "../../components/incidents/grivanceInvestigationTab.jsx";
import {ArrowRight01Icon} from "hugeicons-react";
import { Link } from "react-router-dom";
// forms
import FormChoicesPopup from "../../components/incidents/forms/incidentFormsChoices.jsx";
import GeneralIncidentForm from "../../components/incidents/forms/generalIncidentForm";
import LostAndFoundForm from "../../components/incidents/forms/lostAndFound";
import EmployeeIncidentForm from "../../components/incidents/forms/employeeIncidentForm.jsx";
import MedicationErrorForm from "../../components/incidents/forms/medicationError.jsx";
import GrievanceForm from "../../components/incidents/forms/grievanceForm.jsx";
import DrugReactionForm from "../../components/incidents/forms/drugReactionForm.jsx";
import WorkplaceViolenceIncidentForm from "../../components/incidents/forms/workPlaceViolenceForm.jsx";
import HealthIncidentInvestigationForm from "../../components/incidents/forms/healthIncidentForm.jsx";
import VerbalComplaintForm from "../../components/incidents/forms/verbalComplaintForm.jsx";
import NewContentFullContainer from "../../components/general/newContentFullContainer.jsx";
import GrievanceInvestigationForm from "../../components/incidents/forms/grivanceInvestigationForm.jsx";
import MedicationTab from "../../components/incidents/medicationTab.jsx";
import DashBoardContainer from "./dashboardContainer.jsx";

const BreadCrumbs = () => {
  return (
      <div className="breadcrumbs">
          <Link to={'/'}>Overview</Link> <ArrowRight01Icon /> <Link to={'/incidents/'}>Incidents</Link> <ArrowRight01Icon /> <Link className='current-page'>Medication Error List</Link>
      </div>
  )
}

const MedicationPage = ({ content }) => {
  const permissions = usePermission();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isFormChoicesOpen, setIsFormCHoicesOpen] = useState(false);
  const [SelectedForm, setSelectedForm] = useState(false);

  const toggleFormChoicesOpen = () => {
    setIsFormCHoicesOpen(!isFormChoicesOpen);
  };

  const tootlePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  return (
    <div className="dashboard-container">
      <DashBoardContainer content={<MedicationTab />}  breadCrumbs={<BreadCrumbs />} />
    </div>
  );
};

export default MedicationPage;
