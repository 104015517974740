import React from "react";
import FormatDateTime from '../../../../components/general/formatDateTime'
import NamesInitials from "../../../../components/general/namesInitials";
import DateFormatter from "../dateFormatter";

const IncidentDetails = ({ data, fullName, sex, IncidentDate, incidentTime, incidentCategory, incidentDetails }) => {
    // console.log(data.incident_date)
    return (



        <div className="patient-name-type">
            <div className="date">Incident Date <DateFormatter dateString={data.incident_date || data.date_of_injury_or_near_miss || data.date || data.date_reported || data.date_of_report || data.date_of_incident} /></div>
            {/* <div className="date">Incident Date <DateFormatter dateString={data.incident_date} />{data.incident_time}</div> */}
            <div className="name-profile">
                <div className="profile">
                    <div className="profile-pic">
                        <NamesInitials fullName={fullName || 'Not provided'} />
                    </div>
                    <div className="name-sex">
                        <h3 className="name">{fullName || 'Not provided'}</h3>
                        <small className="sex">{sex || 'Sex: Not provided'}</small>
                    </div>
                </div>
                <div className="patient-type">
                    <p>{incidentCategory || 'Not provided'}</p>
                </div>
            </div>
            {incidentDetails}
        </div>
    )
}

export default IncidentDetails