import React from "react";

const PopUp = ({ tootlePopup, isPopupOpen, popupContent: PopupContent, spacialClass }) => {
  return (
    <div className="pop-up incident-form-popup">
      <div className={`popup-content`}>
        <div onClick={tootlePopup} className="close-popup">
          <i className="fa-regular fa-circle-xmark"></i>
        </div>
        {isPopupOpen && PopupContent}
        {console.log(isPopupOpen)}
      </div>
    </div>
  );
};
export default PopUp;
