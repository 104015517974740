import React from "react";
import FormatDateTime from "../../../../components/general/formatDateTime";

const GeneralIncidentOtherInformation = ({ data }) => {
  return (
    <div className="incident-general-info">
      <div className="notification">
        <h3>Notification</h3>
        <div className="physician-notified">
          <div className="name">
            <small>Name of physician notified</small>
            <h4>{data.name_of_physician_notified || "Not provided"}</h4>
          </div>
          <div className="date">
            <small>Date/Time notified</small>
            {data.date_physician_notified && data.time_physician_notified ? (
              <h4>
                <FormatDateTime
                  date={data.date_physician_notified}
                  time={data.time_physician_notified}
                />{" "}
                {data.time_physician_notified}{" "}
              </h4>
            ) : (
              <h4>Not provided</h4>
            )}
          </div>
        </div>
        <div className="family-notified">
          <div className="name">
            <small>Name of family member notified</small>
            <h4>{data.name_of_family_notified || "Not provided"}</h4>
          </div>
          <div className="date">
            <small>Date/Time notified</small>
            {data.date_family_notified && data.time_family_notified ? (
              <h4>
                <FormatDateTime
                  date={data.date_family_notified}
                  time={data.time_family_notified}
                />{" "}
                {data.time_family_notified}
              </h4>
            ) : (
              <h4>Not provided</h4>
            )}
          </div>
        </div>
      </div>
      <div className="brief-description">
        <h3>Brief summary of incident</h3>
        <p>{data.brief_summary_of_incident}</p>
      </div>
    </div>
  );
};

export default GeneralIncidentOtherInformation;
