import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import api from "../../../../api";
import toast from "react-hot-toast";
import {
  CheckmarkSquare01Icon,
  FloppyDiskIcon,
  Loading03Icon,
  Square01Icon,
  SquareIcon,
  Cancel01Icon,
} from "hugeicons-react";
import { sourcesOfInformation } from "../../../../constants";
import RichTexField from "../inputs/richTexField";
import BackToPage from "../../backToPage";
import "../../../../assets/css/pages/modifyIncident/modifyIncident.css";
import postDocumentHistory from "../documentHistory/postDocumentHistory";
import CustomTimeInput from "../inputs/customTimeInput";

const ModifyEmployeeInvestigation = ({ incidentId }) => {
  const [incident, setIncident] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const currentStepRef = useRef(currentStep);

  const [nameInjured, setNameInjured] = useState("");
  const [dateHired, setDateHired] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [bodyPartInjured, setBodyPartInjured] = useState("");
  const [injuryNature, setInjuryNature] = useState("");
  const [priorActivity, setPriorActivity] = useState("");
  const [accidentDetails, setAccidentDetails] = useState("");
  const [equipment, setEquipment] = useState("");
  const [dateEvent, setDateEvent] = useState("");
  const [timeEvent, setTimeEvent] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [causeEvent, setCauseEvent] = useState("");
  const [safetyRegulations, setSafetyRegulations] = useState("");
  const [claim, setClaim] = useState("");
  const [dateClaimNotified, setDateClaimNotified] = useState("");
  const [wentToHospital, setWentToHospital] = useState(false);
  const [doctorName, setDoctorName] = useState("");
  const [hospitalName, setHospitalName] = useState("");
  const [recommendations, setRecommendations] = useState("");
  const [witnesses, setWitnesses] = useState([]);
  const [newWitness, setNewWitness] = useState("");

  const handleIfWentHospital = () => {
    setWentToHospital((prev) => !prev);
  };

  useEffect(() => {
    const fetchIncidentData = async () => {
      try {
        const response = await api.get(
          `/incidents/employee_health_investigation/${incidentId}/`
        );
        const data = response.data.data;
        setIncident(data);

        const witnessesArray = Array.isArray(data.witnesses)
          ? data.witnesses
          : typeof data.witnesses === "string"
            ? data.witnesses.split(",").map((w) => w.trim())
            : [];

        setWitnesses(witnessesArray);
        setNameInjured(data.name_of_injured_staff || "");
        setDateHired(data.date_of_hire || "");
        setBirthDate(data.date_of_birth || "");
        setMaritalStatus(data.marital_status || "");
        setAddress(data.address || "");
        setCity(data.city || "");
        setState(data.state || "");
        setZipCode(data.zip_code || "");
        setBodyPartInjured(data.part_of_body_injured || "");
        setInjuryNature(data.nature_of_injury || "");
        setPriorActivity(data.employee_prior_activity || "");
        setAccidentDetails(data.accident_details || "");
        setEquipment(data.equipment_or_tools || "");
        setDateEvent(data.date_of_event || "");
        setTimeEvent(data.time_of_event || "");
        setEventLocation(data.event_location || "");
        setCauseEvent(data.cause_of_event || "");
        setSafetyRegulations(data.safety_regulations || "");
        setClaim(data.claim || "");
        setDateClaimNotified(data.date_claim_notified || "");
        setWentToHospital(data.went_to_doctor_or_hospital || false);
        setDoctorName(data.doctor_name || "");
        setHospitalName(data.hospital_name || "");
        setRecommendations(data.recommendations || "");
      } catch (error) {
        console.error("Error fetching incident data:", error);
      }
    };
    fetchIncidentData();
  }, [incidentId]);

  const handleAddWitness = () => {
    if (newWitness.trim()) {
      setWitnesses((prev) =>
        Array.isArray(prev) ? [...prev, newWitness.trim()] : [newWitness.trim()]
      );
      setNewWitness("");
    } else {
      toast.error("Witness name cannot be empty");
    }
  };

  const handleRemoveWitness = (index) => {
    setWitnesses((prev) =>
      Array.isArray(prev) ? prev.filter((_, i) => i !== index) : []
    );
  };

  const handleModify = async () => {
    setIsLoading(true);
    const formatDate = (date) => {
      const parsedDate = new Date(date);
      return parsedDate.toString() !== "Invalid Date"
        ? parsedDate.toISOString().split("T")[0]
        : null;
    };

    const formatTime = (time) => {
      const timePattern =
        /^([01]?[0-9]|2[0-3]):([0-5][0-9])(:([0-5][0-9])(\.[0-9]{1,6})?)?$/;
      return timePattern.test(time) ? time : null;
    };

    if (!["married", "single", "divorced"].includes(maritalStatus)) {
      toast.error("Invalid marital status selected");
      return;
    }

    const witnessesString = witnesses.join(",") || "";
    const incidentData = {
      name_of_injured_staff: nameInjured,
      date_of_hire: formatDate(dateHired),
      date_of_birth: formatDate(birthDate),
      marital_status: maritalStatus,
      address: address,
      city: city || "N/A",
      state: state || "N/A",
      zip_code: zipCode || "N/A",
      part_of_body_injured: bodyPartInjured,
      nature_of_injury: injuryNature,
      employee_prior_activity: priorActivity,
      accident_details: accidentDetails,
      equipment_or_tools: equipment,
      witnesses: witnessesString,
      date_of_event: formatDate(dateEvent),
      time_of_event: formatTime(timeEvent),
      event_location: eventLocation,
      cause_of_event: causeEvent,
      safety_regulations: safetyRegulations,
      date_claim_notified: formatDate(dateClaimNotified),
      claim: claim,
      went_to_doctor_or_hospital: wentToHospital,
      doctor_name: doctorName,
      hospital_name: hospitalName,
      recommendations: recommendations,
    };

    try {
      const response = await api.patch(
        `incidents/employee_health_investigation/${incidentId}/modify-incident/`,
        incidentData
      );
      if (response.status === 200) {
        setIsLoading(false);
        toast.success("Incident updated successfully");
        console.log(response.data.incident);
        setIncident(response.data.incident);
        postDocumentHistory(
          incidentId,
          "modified this investigation",
          "modify"
        );
      }
    } catch (error) {
      if (error.response) {
        toast.error(
          error.response.data.message ||
          error.response.data.error ||
          "Error while updating the incident"
        );
      } else {
        toast.error("Unknown error while updating the incident");
      }
    }
  };

  return (
    <div className="modify-page-content">
      <div className="modify-page-header">
        <BackToPage
          link={"/incident/employee/investigation/"}
          pageName={"general incident"}
        />
        <h2 className="title">
          Modifying Employee Health Investigation incindent
        </h2>
        <button className="tertiary-button" onClick={handleModify}>
          {isLoading ? (
            <>
              <Loading03Icon className="loading-icon" size={18} />
              <span>Saving changes</span>
            </>
          ) : (
            <>
              <FloppyDiskIcon size={20} />
              <span>Save changes</span>
            </>
          )}
        </button>
      </div>
      <div className="modify-incident-page">
        <form className="modify-forms">
          <div className="inputs-group modify-inputs">
            <h3 className="full">General info</h3>
            <div className="field small-field">
              <label htmlFor="nameInjured">Name of injured staff</label>
              <input
                type="text"
                name="nameInjured"
                id="nameInjured"
                value={nameInjured}
                onChange={(e) => setNameInjured(e.target.value)}
              />
            </div>
            <div className="field small-field">
              <label htmlFor="dateLetterSent">Date was hired</label>
              <input
                type="date"
                name="dateHired"
                id="dateHired"
                onChange={(e) => setDateHired(e.target.value)}
                value={dateHired}
              />
            </div>
            <div className="field small-field">
              <label htmlFor="birthDate">Date of birth</label>
              <input
                type="date"
                name="birthDate"
                id="birthDate"
                onChange={(e) => setBirthDate(e.target.value)}
                value={birthDate}
              />
            </div>
            <div className="field small-field">
              <label htmlFor="jobTitle">Job title</label>
              <input type="text" name="jobTitle" id="jobTitle" />
            </div>
            <div className="field small-field">
              <label htmlFor="maritalStatus">Marital Status</label>
              <select
                className="custom-select"
                name="maritalStatus"
                id="maritalStatus"
                value={maritalStatus}
                onChange={(e) => setMaritalStatus(e.target.value)}
              >
                <option value="">Select</option>
                <option value="married">Married</option>
                <option value="single">Single</option>
                <option value="divorced">Divorced</option>
              </select>
            </div>
            <div className="field small-field">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                name="address"
                id="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="field small-field">
              <label htmlFor="city">City</label>
              <input
                type="text"
                name="city"
                id="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div className="field small-field">
              <label htmlFor="state">State</label>
              <input
                type="text"
                name="state"
                id="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </div>
            <div className="field small-field">
              <label htmlFor="zipCode">Zip code</label>
              <input
                type="text"
                name="zipCode"
                id="zipCode"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
              />
            </div>
          </div>

          <div className="inputs-group modify-inputs">
            <div className="field small-field">
              <label htmlFor="bodyPartInjured">
                What part of the body was injured?
              </label>
              <RichTexField
                value={bodyPartInjured}
                onEditorChange={setBodyPartInjured}
              />
            </div>
            <div className="field small-field">
              <label htmlFor="injuryNature">
                What was the nature of the injury? Describe in detail:
              </label>
              <RichTexField
                value={injuryNature}
                onEditorChange={setInjuryNature}
              />
            </div>
            <div className="field small-field">
              <label htmlFor="accidentDetails">
                Describe fully how the accident happened?
              </label>
              <RichTexField
                value={accidentDetails}
                onEditorChange={setAccidentDetails}
              />
            </div>
            <div className="field small-field">
              <label htmlFor="equipment">
                What equipment or tools were being used?
              </label>
              <RichTexField value={equipment} onEditorChange={setEquipment} />
            </div>

            {/* WITNESSES */}
            <div className="field full-field witness-fields">
              <div className="witness-container">
                {Array.isArray(witnesses) && witnesses.length > 0 ? (
                  witnesses.map((witness, index) => (
                    <div key={index} className="witness-item">
                      <button
                        type="button"
                        onClick={() => handleRemoveWitness(index)}
                        className="remove-witness-button"
                      >
                        <span className="witness-name">{witness}</span>{" "}
                        <Cancel01Icon size={15} />
                      </button>
                    </div>
                  ))
                ) : (
                  <span className="witness-name">{witnesses}</span>
                )}
              </div>
              <div className="add-witness">
                <input
                  type="text"
                  value={newWitness}
                  onChange={(e) => setNewWitness(e.target.value)}
                  placeholder="Add a new witness"
                />
                <div className="parties">
                  <button
                    className="new-party"
                    type="button"
                    onClick={handleAddWitness}
                  >
                    <i className="fa-solid fa-plus"></i>
                    Add Witnesses
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="inputs-group modify-inputs">
            <div className="field small-field">
              <label htmlFor="dateEvent">Date of event</label>
              <input
                type="date"
                name="dateEvent"
                id="dateEvent"
                onChange={(e) => setDateEvent(e.target.value)}
                value={dateEvent}
              />
            </div>
            <div className="field small-field">
              <label htmlFor="timeEvent">Time of event</label>
              <CustomTimeInput setTime={setTimeEvent} />
            </div>
            <div className="field small-field">
              <label htmlFor="eventLocation">Event location</label>
              <input
                type="text"
                name="eventLocation"
                id="eventLocation"
                value={eventLocation}
                onChange={(e) => setEventLocation(e.target.value)}
              />
            </div>
            <div className="field small-field">
              <label htmlFor="dateClaimNotified">Date Claims notified</label>
              <input
                type="date"
                name="dateClaimNotified"
                id="dateClaimNotified"
                onChange={(e) => setDateClaimNotified(e.target.value)}
                value={dateClaimNotified}
              />
            </div>
            <div className="field small-field">
              <label htmlFor="accidentDetails">What caused the event:</label>
              <RichTexField value={causeEvent} onEditorChange={setCauseEvent} />
            </div>
            <div className="field small-field">
              <label htmlFor="safetyRegulations">
                Were safety regulations in place and used? If not, what was
                wrong?
              </label>
              <RichTexField
                value={safetyRegulations}
                onEditorChange={setSafetyRegulations}
              />
            </div>
            <div className="check-box">
              <input
                onClick={handleIfWentHospital}
                checked={wentToHospital}
                type="checkbox"
                name="wentToHospital"
                id="wentToHospital"
              />
              <label htmlFor="wentToHospital">
                Check if staff went to doctor/hospital?
              </label>
            </div>
            <div className="field small-field">
              <label htmlFor="recommendations">
                Recommended preventative action to take in the future to prevent
                re-occurrence.
              </label>
              <RichTexField
                value={recommendations}
                onEditorChange={setRecommendations}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModifyEmployeeInvestigation;
