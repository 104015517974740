import React from "react";
import "../../../../assets/css/pages/incidents/medication/medication_details.css";

const MedicationGeneralInfo = ({ data, incidentStatuses }) => {
  const renderData = (value) => {
    if (typeof value === "object") {
      return JSON.stringify(value);
    }
    return value || "Not provided";
  };

  return (
    <div className="incident-details">
      <div className="number-mrn">
        <div className="phone-number">
          <small>Incident type</small>
          <h3>{renderData(data.incident_type)}</h3>
          <h4>{renderData(data.physical_injury_description)}</h4>
        </div>
      </div>

      <div className="number-mrn">
        <div className="phone-number">
          <small>Detailed description of the observation, threat, incident, or activity</small>
          <h4>{renderData(data.description)}</h4>
        </div>
      </div>

      <div className="number-mrn">
        <div className="location">
          <small>Person injured</small>
          <h4>{renderData(data.person_injured)}</h4>
        </div>
        <div className="location">
          <small>Incident witness</small>
          <h4>{renderData(data.incident_witness)}</h4>
        </div>
      </div>

      <div className="number-mrn">
        <div className="location">
          <small>Incident witness</small>
          <h4>{renderData(data.incident_witness)}</h4>
        </div> 
        <div className="location">
          <small>Termination of incident</small>
          <h4>{renderData(data.termination_of_incident)}</h4>
        </div> 
      </div>

      <div className="number-mrn">
        <div className="location">
          <small>Type of contact</small>
          <h4>{renderData(data.type_of_contact)}</h4>
        </div>
        <div className="location">
          <small>Was the victim alone?</small>
          <h4>{renderData(data.victim_was_alone)}</h4>
        </div>
      </div>

      <div className="number-mrn">
        <div className="location">
          <small>Location</small>
          <h4>{renderData(data.location)}</h4>
        </div>
        <div className="location">
          <small>Were weapons involved</small>
          <h4>{renderData(data.weapons_were_involved)}</h4>
        </div>
      </div>

      <div className="number-mrn">
        <div className="location">
          <small>Weapon used</small>
          <h4>{renderData(data.weapon_used)}</h4>
        </div>
        <div className="location">
          <small>Any threats before?</small>
          <h4>{renderData(data.there_was_threats_before)}</h4>
        </div>
      </div>

      <div className="number-mrn">
        <div className="location">
          <small>Type of contact</small>
          <h4>{renderData(data.type_of_contact)}</h4>
        </div>
        <div className="location">
          <small>Was the victim alone?</small>
          <h4>{renderData(data.victim_was_alone)}</h4>
        </div>
      </div>

      <div className="number-mrn">
        <div className="location">
          <small>Location</small>
          <h4>{renderData(data.location)}</h4>
        </div>
        <div className="location">
          <small>Are they any injuries?</small>
          <h4>{renderData(data.there_were_injuries)}</h4>
        </div>
      </div>
    </div>
  );
};

export default MedicationGeneralInfo;
