import React, { useEffect, useState } from "react";
import "../../assets/css/main/main.css";
import api, { API_URL } from "../../api";
import { usePermission } from "../../contexts/permissionsContext";
import {
  MoreHorizontalSquare01Icon,
  FilterHorizontalIcon,
  CancelSquareIcon,
  PencilEdit01Icon,
  PencilEdit02Icon,
  EyeIcon,
} from "hugeicons-react";
import TableActionsPopup from "../general/popups/tableActionPopup";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import SliceText from "../general/sliceText";
import DateFormatter from "../../pages/dashboard/incidents/dateFormatter";

const handleSearch = (items, searchString) => {
  if (searchString.length > 2) {
    const results = items.filter((item) => {
      return (
        item.person_taking_report &&
        item.person_taking_report
          .toLowerCase()
          .includes(searchString.toLowerCase())
        // (item.incident_type && item.incident_type.toLowerCase().includes(searchString.toLowerCase())) ||
        // (item.follow_up && item.follow_up.toLowerCase().includes(searchString.toLowerCase()))
      );
    });
    return results;
  }
  return [];
};

const formatTime = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(":");
  return `${hours}:${minutes}:${seconds.split(".")[0]}`; // Exclude milliseconds
};

const handleFilter = (
  items,
  searchType,
  searchStatus,
  searchCareLevel,
  filterByDate
) => {
  const results = items.filter((item) => {
    const matchesStatus =
      !searchStatus ||
      (item.patient_visitor_name &&
        item.patient_visitor_name
          .toLowerCase()
          .includes(searchStatus.toLowerCase()));
    const matchesType =
      !searchType ||
      (item.incident_type &&
        item.incident_type.toLowerCase().includes(searchType.toLowerCase()));
    const matchesCareLevel =
      !searchCareLevel ||
      (item.care_level &&
        item.care_level.toLowerCase().includes(searchCareLevel.toLowerCase()));
    const matchesFilterByDate =
      !filterByDate ||
      (item.incident_date && item.incident_date.includes(filterByDate));

    return (
      matchesStatus && matchesType && matchesCareLevel && matchesFilterByDate
    );
  });

  return results;
};

// const formatTime = (time) => {
//   const [hours, minutes, seconds] = time.split(':');
//   const date = new Date();
//   date.setHours(parseInt(hours, 10));
//   date.setMinutes(parseInt(minutes, 10));
//   date.setSeconds(parseInt(seconds, 10));
//   return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
// };

const LostFOundTab = () => {
  const permission = usePermission();
  const [errorFetching, setErrorFetching] = useState("");
  const [isFetching, setIsFetching] = useState(true);
  const [incidentData, setIncidentData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  // const [filterByDate, setFilterByDate] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [openActionIndex, setOpenActionIndex] = useState("");

  // filters
  const [filterByType, setFilterByType] = useState("");
  const [filterByStatus, setFilterByStatus] = useState("");
  const [filterByCareLevel, setFilterByCareLevel] = useState("");
  const [clearFilters, setClearFilters] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [filterByDate, setFilterByDate] = useState();
  const navigate = useNavigate();

  const handleRowClick = (incidentId) => {
    navigate(`/incident/lost_and_found/${incidentId}`);
  };
  const navigateToModify = (incidentId) => {
    navigate(`/incident/lost_and_found/${incidentId}/modify/`);
  };

  const handleNonClickableColumnClick = (event) => {
    event.stopPropagation();
  };

  const toggleAction = (index) => {
    setOpenActionIndex(index);
    setOpenAction(!openAction);
    console.log("yea");
  };

  const toggleFilterByDate = () => {
    setFilterByDate(!filterByDate);
  };

  const search = (string) => {
    if (string.length <= 2) {
      setIsSearching(false);
    } else {
      setIsSearching(true);
      const results = handleSearch(incidentData, string);
      setSearchResults(results);
    }
  };

  useEffect(() => {
    const fetchIncidentData = async () => {
      try {
        const response = await api.get(`${API_URL}/incidents/lost_and_found`);
        if (response.status === 200) {
          console.log("API Response:", response.data);
          setIncidentData(response.data["lost and found data"]);
        } else {
          setErrorFetching(`Error fetching data: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching incident data:", error);
        if (error.response && error.response.data.error) {
          setErrorFetching(error.response.data.error);
        } else {
          setErrorFetching("An error occurred while fetching incident data.");
        }
      } finally {
        setIsFetching(false);
      }
    };
    fetchIncidentData();
  }, []);

  const formatDate = (date) => {
    return format(new Date(date), "MM-dd-yyyy");
  };

  return isFetching ? (
    <div className="getting-data">
      <p>Getting data...</p>
    </div>
  ) : (
    <div>
      {errorFetching ? (
        <div className="error-message">
          <p>{errorFetching}</p>
        </div>
      ) : (
        <div className="tab-container incidents-tab">
          <div className="tab-header">
            <div className="title-container-action">
              <div className="title-container">
                <h2 className="title">Lost & Found Property report</h2>
                <p>{incidentData.length} incidents available</p>
              </div>
            </div>

            {/* <div className="filters">
              <div className="search-input">
                <i className="fa-solid fa-magnifying-glass"></i>
                <input
                  onChange={(e) => search(e.target.value)}
                  type="search"
                  name="systemSearch"
                  id="systemSearch"
                  placeholder="Search the system"
                />
              </div>

              <div className="filter-buttons">
                <select name="employee" id="status">
                  <option value="employee">Employee</option>
                  <option value="patient">Patient</option>
                  <option value="general">General</option>
                </select>

                <select name="status" id="status">
                  <option value="resolved">Resolved</option>
                  <option value="unresolved">Unresolved</option>
                </select>

                <div className="date-filter">
                  <button
                    onClick={toggleFilterByDate}
                    className="date-filter-button"
                  >
                    <i className="fa-solid fa-calendar-days"></i>
                    <p>Date</p>
                  </button>

                  {filterByDate && (
                    <div className="calendars-container">
                      <h3>Filter by dates</h3>

                      <div className="calendars">
                        <div className="calendar">
                          <p>Since</p>
                          <input type="date" />
                        </div>

                        <div className="calendar">
                          <p>Until</p>
                          <input type="date" />
                        </div>
                      </div>

                      <div className="buttons">
                        <button
                          onClick={toggleFilterByDate}
                          className="secondary-button"
                        >
                          Cancel
                        </button>
                        <button className="primary-button">
                          <i className="fa-solid fa-filter"></i>
                          Filter
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div> */}

            <div className="incident-list">
              <table>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>ID</th>
                    <th>Facility</th>
                    <th>Date & Time Reported</th>
                    <th>Person taking report</th>
                    {/* <th>Description of items</th> */}
                    <th>Person reporting</th>
                    {/* <th>Items released</th> */}
                    <th>Location found</th>
                    <th>Location disposed</th>
                    {/* <th>Date items were disposed</th> */}
                    <th>Status</th>
                    <th className="action-col">Action</th>
                  </tr>
                </thead>
                {isSearching ? (
                  <tbody>
                    {searchResults.length > 0 ? (
                      searchResults.map((incident, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{incident.id}</td>
                          <td>
                            {incident.report_facility?.name || "Not provided"}
                          </td>
                          <td>
                            {(
                              <div>
                                <DateFormatter
                                  dateString={incident.date_reported}
                                />
                                ,&nbsp; {formatTime(incident.time_reported)}
                              </div>
                            ) || "-"}
                          </td>
                          <td>{incident.person_taking_report || "-"}</td>
                          <td>
                            {incident.name_of_person_reporting_loss ||
                              "Not provided"}
                          </td>
                          <td>{incident.location_found || "Not provided"}</td>
                          <td
                            dangerouslySetInnerHTML={{
                              __html: incident.item_description,
                            }}
                          ></td>
                          <td>{incident.severity || "Not provided"}</td>
                          <td>{incident.care_level || "Not provided"}</td>
                          <td className="follow-up">
                            {incident.date_returned
                              ? formatDate(incident.date_returned)
                              : "Not provided"}
                          </td>
                          <td>{incident.status || "Not provided"}</td>
                          <td
                            onClick={(event) =>
                              handleNonClickableColumnClick(event)
                            }
                            className="action-col"
                          >
                            <div className="table-actions">
                              {!incident.is_resolved &&
                                <PencilEdit02Icon
                                  size={20}
                                  onClick={() => navigateToModify(incident.id)}
                                />}
                              <EyeIcon
                                size={20}
                                onClick={() => handleRowClick(incident.id)}
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="10">Nothing found</td>
                      </tr>
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    {incidentData.length > 0 ? (
                      incidentData.map((incident, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{incident.id}</td>
                          <td>
                            {incident.report_facility?.name || "Not provided"}
                          </td>
                          <td>
                            {(
                              <div>
                                <DateFormatter
                                  dateString={incident.date_reported}
                                />
                                ,&nbsp; {formatTime(incident.time_reported)}
                              </div>
                            ) || "-"}
                          </td>

                          <td>{incident.person_taking_report || "-"}</td>
                          <td>
                            {incident.name_of_person_reporting_loss ||
                              "Not provided"}
                          </td>
                          <td>{incident.location_found || "Not provided"}</td>
                          <td>{incident.location_found || "Not provided"}</td>

                          <td>{incident.status || "Not provided"}</td>

                          <td
                            onClick={(event) =>
                              handleNonClickableColumnClick(event)
                            }
                            className="action-col"
                          >
                            <div className="table-actions">
                              {!incident.is_resolved &&
                                <PencilEdit02Icon
                                  size={20}
                                  onClick={() => navigateToModify(incident.id)}
                                />}
                              <EyeIcon
                                size={20}
                                onClick={() => handleRowClick(incident.id)}
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="10">No data available</td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LostFOundTab;
