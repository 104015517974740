import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashBoardContainer from "../dashboardContainer";
import IncidentDetailsHeader from "./incidentDetails/incidentDetailsHeader";
import IncidentDetails from "./incidentDetails/incidentDetails";
import IncidentTabs from "./incidentDetails/incidentDetailsTabs";
// import GeneralIncidentDetailsContentTab from "./generalIncident/incidentDetails";
import api, { API_URL } from "../../../api";

import "../../../assets/css/pages/incidents/general/generalIncidentDetailsPage.css";
// import GeneralIncidentOtherInformation from "./generalIncident/otherInformation";
// import GeneralIncidentGeneralInformation from "./generalIncident/generalInformation";
import MedicationDetailsContentTab from "./medicationIncident/medication";
import MedicationGeneralInfo from "./medicationIncident/medicationGeneralInfo";
import MedicationOtherInformation from "./medicationIncident/medicationOtherInformation";
import MedicationDocumentHistory from "./medicationIncident/medicationDocumentHistory";
import MedicationReviews from "./medicationIncident/medicationReviews";
import toast from "react-hot-toast";
import { ArrowRight01Icon } from "hugeicons-react";
import { Link } from "react-router-dom";
import FilesList from "../../../components/documents/filesList";

const MedicationDetailsContent = () => {
  const { medicationId } = useParams();
  const [isFetching, setIsFetching] = useState(true);
  const [incidentDetails, setIncidentDetails] = useState({});
  const [incidentStatus, setIncidentStatus] = useState({});

  useEffect(() => {
    const fetchIncidentDetails = async () => {
      setIsFetching(true);
      try {
        const response = await api.get(
          `${API_URL}/incidents/medication_error/${medicationId}/`
        );
        if (response.status === 200) {
          //   setIncidentStatus(response.data.statuses);
          setIncidentDetails(response.data.data);
          console.log(response.data.data);
          setIsFetching(false);
        }
        console.log(incidentDetails);
      } catch (error) {
        console.log(error);
        setIsFetching(false);
      }
    };

    fetchIncidentDetails();
  }, []);
  useEffect(() => {
    const getIncidentReviews = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/medication_error/${medicationId}/reviews/`
        );
        if (response.status === 200) {
          localStorage.setItem("incidentReviewsCount", response.data.length);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          toast.error("Authentication error");
          window.location.reload();
        } else {
          toast.error("Failed to fetch incident reviews");
          console.error(error);
        }
      }
    };
    getIncidentReviews();
  }, []);
  useEffect(() => {
    const getDocumentHistory = async () => {
      try {
        const response = await api.get(
          `${API_URL}/activities/list/${medicationId}/`
        );
        if (response.status === 200) {
          localStorage.setItem("documentHistoryCount", response.data.length);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          toast.error("Authentication error");
          window.location.reload();
        } else {
          toast.error("Failed to fetch document History");
          console.error(error);
        }
      }
    };
    getDocumentHistory();
  }, []);
  return (
    <div className="incident-details-page">
      {isFetching ? (
        <div className="fetching-data">Loading data</div>
      ) : (
        <div className="incident-details">
          <IncidentDetailsHeader
            data={incidentDetails}
            incidentId={medicationId}
            apiLink={"medication_error"}
            sendTo={"send-to-department"}
          />
          <div className="details">
            <IncidentDetails
              data={incidentDetails}
              fullName={incidentDetails.patient_name}
              sex={incidentDetails.sex}
              IncidentDate={incidentDetails.date_of_error}
              incidentTime={incidentDetails.time_of_error}
              incidentCategory={incidentDetails.category}
              incidentDetails={
                <MedicationDetailsContentTab data={incidentDetails} />
              }
            />
            <IncidentTabs
              data={incidentDetails}
              statuses={incidentStatus}
              generalInformation={
                <MedicationGeneralInfo
                  data={incidentDetails}
                  incidentStatuses={incidentStatus}
                />
              }
              otherInformation={
                <MedicationOtherInformation data={incidentDetails} />
              }
              documentHistory={
                <MedicationDocumentHistory incidentId={medicationId} />
              }
              reviews={<MedicationReviews incidentId={medicationId} />}
              documents={<IncidentDocuments incidentId={medicationId} />}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const IncidentDocuments = ({ incidentId, apiLink }) => {
  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await api.get(
          `/incidents/medication_error/${incidentId}/documents/`
        );
        if (response.status === 200) {
          setDocuments(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchDocuments();
  }, []);
  return <FilesList documents={documents} showDownload={true} />;
};
const BreadCrumbs = () => {
  const { medicationId } = useParams();
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />
      <Link to={"/incidents/"}>Incidents</Link> <ArrowRight01Icon />
      <Link to={"/incident/medication_error/"}>Medication Error List</Link>{" "}
      <ArrowRight01Icon />
      <Link className="current-page">#{medicationId}</Link>
    </div>
  );
};
const MedicationDetails = () => {
  return (
    <div>
      <DashBoardContainer
        content={<MedicationDetailsContent />}
        breadCrumbs={<BreadCrumbs />}
      />
    </div>
  );
};

export default MedicationDetails;
