import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";

export const useAuthentication = () => {
    const [isAuth, setIsAuth] = useState(false); // Initially assume not authenticated
    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        const token = localStorage.getItem("access");

        if (token !== null) {
            const tokenExpired = isTokenExpired(token);
            if (tokenExpired) {
                setIsAuth(false);
            } else {
                setIsAuth(true);
            }
        }
        setLoading(false);
    }, []);

    const logout = () => {
        localStorage.clear();
        window.location.href = "/";
    };

    return { isAuth, loading, logout };
};

function isTokenExpired(token) {
    if (!token) return true;

    const decodeToken = jwtDecode(token);
    const expirationTime = decodeToken.exp * 1000;
    const currentTime = Date.now();

    return expirationTime < currentTime;
}