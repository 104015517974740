import React from "react";
import "../../../../assets/css/pages/incidents/lostpage/lost_found_details.css";
const LostFoundGeneralInfo = ({ data, incidentStatuses }) => {
  const { data: incidentData } = data;
  return (
    <div className="incident-details">

      <div className="location-contribution-diagnosis">
        <div className="location">
          <h3>Full description of the missing, lost, or misplaced property (including money):</h3>
          {/* <small>{incidentData.item_description || "Not provided"}</small> */}
          <small dangerouslySetInnerHTML={{ __html: incidentData.item_description }} ></small>
        </div>  
      </div>
      <div className="location-contribution-diagnosis">
        <div className="location">
          <h3>Actions taken to locate the  missing, lost, or misplaced property:</h3>
          {/* <small>{incidentData.action_taken || "Not provided"}</small> */}
          <small dangerouslySetInnerHTML={{ __html: incidentData.action_taken }} ></small>
        </div>
      </div>
      <div className="location-contribution-diagnosis">
        <div className="location">
          <h3>Disposal of Unclaimed Property</h3>
          {/* <small >{incidentData.disposal_of_unclaimed_property || "Not provided"}</small> */}
          <small dangerouslySetInnerHTML={{ __html: incidentData.disposal_of_unclaimed_property }} ></small>
        </div>
      </div>
    </div>
  );
};

export default LostFoundGeneralInfo ;
