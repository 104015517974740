import React from 'react'
import login from "../../assets/img/pages/overview/login.png";
import logo from "../../assets/img/branding/logo.png";
import "../../assets/css/pages/auth/restpass.css"

const restPassword = () => {
  return (
    <div className="restPassword">
        <div className="img">
            <img src= {login} alt=''></img>
        </div>
        <div className="container">
            <form >
            <div className="logo">
                <img src= {logo} alt=''></img>
            </div>
                <div class="title">
                    <h2>Reset Password</h2>
                    <p>Your new password has been sent to  <b>michealedwards@outlook.com</b>. Please check your inbox and follow instruction to confirm your password</p>
                </div>
                <a href="#">Open email</a>
                <button type="submit">Send again</button>

            </form>
        </div>
        
    </div>
  )
}

export default restPassword