import React, { useState } from "react";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { API_URL } from "../../api";

import "../../assets/css/pages/auth/login.css";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import EmailPasswordLoginForm from "../../components/auth/EmailPasswordLoginForm";
const LoginPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [hasAccess, setHasAccess] = useState(null);
  const { instance } = useMsal();
  const [loginWitAndEmailPassword, setLoginWithEmailAndPassword] = useState(true);

  const toggleLoginWithEmailAndPassword = () => {
    setLoginWithEmailAndPassword(!loginWitAndEmailPassword)
  }
  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const loginRequest = {
        scopes: ["openid", "profile", "email", "User.Read"],
        prompt: "select_account",
      };
      const loginResponse = await instance.loginPopup(loginRequest);
      const accessToken = loginResponse.accessToken;
      await fetchUserDetails(accessToken);
    } catch (error) {
      setIsLoading(false);
      toast.error("Error during login");
    }
  };

  const fetchUserDetails = async (accessToken) => {
    try {
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${accessToken}`);

      const options = {
        method: "GET",
        headers: headers,
      };

      const response = await fetch(
        "https://graph.microsoft.com/v1.0/me",
        options
      );
      const userData = await response.json();

      const postData = {
        email: userData.mail || userData.userPrincipalName,
      };

      try {
        const response = await axios.post(
          `${API_URL}/accounts/token/`,
          postData
        );
        if (response.status === 200) {
          const accessToken = response.data.access;
          localStorage.setItem("access", accessToken);
          setHasAccess(true);
          toast.success("Login successful!");
          window.location.href = "/";
        }
      } catch (error) {
        setIsLoading(false);
        if (error.response.status === 403 || error.response.status === 401) {
          toast.error("We could not authenticate you!")
        }

        else if (error.response) {
          if (error.response.status) {
            setHasAccess(false);
            toast.error(
              error.response.data.message || "Authentication failed"
            );
          }
        } else {
          setHasAccess(false);
          toast.error("Error connecting to the backend");
        }
      }
    } catch (error) {
      setIsLoading(true);
      toast.error("Error getting your details");
    }
  };

  return (
    <div className="login-page">
      <img src={"/images/login/login-image.png"} alt="" className="login-img" />
      <div className="login-form">
        <div className="branding">
          <img src={"/images/branding/logo.png"} alt="" className="logo" />
        </div>
        <h1>Login into your account</h1>
        <p>
          Please enter your credentials to access cohesive quality control
          platform
        </p>
        <EmailPasswordLoginForm />
        {/* {
          loginWitAndEmailPassword ? (
            <EmailPasswordLoginForm toggleLoginWithEmailAndPassword={toggleLoginWithEmailAndPassword} />
          ) : <>
            <div className="entities">
              <div
                onClick={(e) => setSelectedEntity("pawhuska")}
                className={`entity ${selectedEntity === "pawhuska" ? "selected" : ""
                  }`}
              >
                <p>Pawhuska</p>
              </div>
              <div
                onClick={(e) => setSelectedEntity("prague")}
                className={`entity ${selectedEntity === "prague" ? "selected" : ""
                  }`}
              >
                <p>Prague</p>
              </div>
              <div
                onClick={(e) => setSelectedEntity("carnegie")}
                className={`entity ${selectedEntity === "carnegie" ? "selected" : ""
                  }`}
              >
                <p>Carnegie</p>
              </div>
              <div
                onClick={(e) => setSelectedEntity("mangum")}
                className={`entity ${selectedEntity === "mangum" ? "selected" : ""
                  }`}
              >
                <p>Mangum</p>
              </div>
              <div
                onClick={(e) => setSelectedEntity("ceiling")}
                className={`entity ${selectedEntity === "ceiling" ? "selected" : ""
                  }`}
              >
                <p>Ceiling</p>
              </div>
              <div
                onClick={(e) => setSelectedEntity("firstMed")}
                className={`entity ${selectedEntity === "firstMed" ? "selected" : ""
                  }`}
              >
                <p>FirstMed</p>
              </div>
            </div>
            <button
              disabled={isLoading}
              className="primary-button"
              onClick={handleLogin}
            >
              {isLoading ? (
                <i className="fa-solid fa-circle-notch loading-icon"></i>
              ) : (
                <span>Login with your company email</span>
              )}
            </button>
          </>
        } */}

        {/* {
          !loginWitAndEmailPassword
            ?
            <>
              <p>Or</p>
              <Link className="button primary-button" to={'/auth/login/'} onClick={toggleLoginWithEmailAndPassword}>
                Login with email and password
              </Link></>
            : ''
        } */}
      </div>
    </div>
  );
};

export default LoginPage;
