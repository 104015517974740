import React from 'react'
import DashBoardContainer from '../dashboard/dashboardContainer'
import { Link } from 'react-router-dom'
import { ArrowRight01Icon, UserListIcon } from 'hugeicons-react'

const AdminPageContent = () => {
    return (
        <div className="overview-content">
            <div className="numbers">
                <Link to={'/admin/users/'} className="number users">
                    <div className="icon">
                        <UserListIcon />
                    </div>
                    <div className="content">
                        <h3 className="number-title">
                            Users
                        </h3>
                        <div className="count"> 3</div>
                    </div>
                </Link>
                <div className="number departments">
                    <div className="icon">
                        <UserListIcon />
                    </div>
                    <div className="content">
                        <h3 className="number-title">
                            Departments
                        </h3>
                        <div className="count"> 3</div>
                    </div>
                </div>
                <div className="number position">
                    <div className="icon">
                        <UserListIcon />
                    </div>
                    <div className="content">
                        <h3 className="number-title">
                            Positions
                        </h3>
                        <div className="count"> 3</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const BreadCrumbs = () => {
    return (
        <div className="breadcrumbs">
            <Link to={'/'}>Overview</Link> <ArrowRight01Icon /> <Link className='current-page'>Admin</Link>
        </div>
    )
}
const AdminPage = () => {
    return (
        <DashBoardContainer breadCrumbs={<BreadCrumbs />} content={<AdminPageContent />} />
    )
}

export default AdminPage
