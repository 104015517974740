import React, { useEffect, useState } from 'react'
import DashBoardContainer from './dashboardContainer'
import { Link } from 'react-router-dom'
import { ArrowRight01Icon, Cancel01Icon, CheckmarkSquare02Icon, GoogleDocIcon, InformationCircleIcon, LayersLogoIcon, ListViewIcon, Loading02Icon, Loading03Icon, LockIcon, NoteDoneIcon, NoteEditIcon, PencilEdit02Icon, Sad01Icon, SquareIcon, SquareLock02Icon } from 'hugeicons-react'
import api, { calculateAge } from '../../api'
import '../../assets/css/pages/profile/profile.css'
import DateFormatter from './incidents/dateFormatter'
import toast, { CheckmarkIcon } from 'react-hot-toast'
import CustomDatePicker from '../../components/incidents/forms/inputs/datePicker'
import { howComplaintIsReceived } from '../../constants'
import RichTexField from '../../components/incidents/forms/inputs/richTexField'
import ProfileReports from '../../components/profile/profileReports'
import UserComplaints from '../../components/profile/profileComplaints'
import ProfileDocuments from '../../components/profile/profileDocuments'
import { DraftsTab } from './incidentTrackingPage'
import ChangePassword from '../../components/profile/froms/changePassword'
import EditProfileForm from '../../components/profile/froms/editProfleForm'

const ProfilePageContent = () => {
    const [profile, setProfile] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState("")
    const [showChangePasswordForm, setShowChangePasswordForm] = useState(false)
    const [showUpdateUserForm, setShowUpdateUserForm] = useState(false)
    const [age, setAge] = useState(null);

    useEffect(() => {
        const fetchProfile = async () => {
            setError("")
            try {
                const response = await api.get('accounts/profile/')
                setProfile(response.data)
                console.log(response.data)
                setIsLoading(false)
                if (response.data.profile.date_of_birth) {
                    setAge(calculateAge(response.data.profile.date_of_birth));
                }
            } catch (error) {
                if (error.response) {
                    setError(error.response.data.message || error.response.data.error || 'Error fetching profile data')
                }
                console.error(error)
                setIsLoading(false)
            }
        }

        fetchProfile()
    }, []);


    return isLoading ? '...' : (
        <div className="dashboard-page-content profile-page">
            {
                showUpdateUserForm
                    ? <EditProfileForm setShowUpdateUserForm={setShowUpdateUserForm} />
                    : ''
            }
            {
                showChangePasswordForm
                    ? <ChangePassword setShowChangePasswordForm={setShowChangePasswordForm} />
                    : ''
            }
            {error && <div className="error-message">{error}</div>}
            <div className="profile-information">

                <div className="basic-info">
                    <div className='profile-info'>
                        <img src={profile.profile_url ? profile.profile_url : '/images/branding/logo.png'} alt="" className="profile-image" />
                        <h3 className="full-name">{profile.first_name} {profile.last_name}</h3>
                        <small className="email">{profile.email}</small>
                        <div className="positions">

                            {
                                profile.permissions.length > 3 ?
                                    <>
                                        {profile.permissions.slice(0, 3).map((permission, index) => (
                                            <div key={index} className="position">
                                                <div className="dot"></div>
                                                <small>{permission}</small>
                                            </div>
                                        ))}
                                    </> : profile.permissions.slice(0, 3).map((permission, index) => (
                                        <div key={index} className="position">
                                            <div className="dot"></div>
                                            <small>{permission}</small>
                                        </div>
                                    ))
                            }
                        </div>
                        <div className="profile-actions">
                            <Link className="button primary-button" onClick={(e) => setShowUpdateUserForm(true)}>
                                <span>Edit profile</span>
                                <PencilEdit02Icon />
                            </Link>
                            <button onClick={(e) => setShowChangePasswordForm(true)} type='button' className="tertiary-button">
                                <span>Change password</span>
                                <SquareLock02Icon />
                            </button>
                        </div>
                    </div>
                    <div className="personal-info">
                        <h2>Person information</h2>
                        <div className="profile-items">
                            <div className="item">
                                <small>First name</small>
                                <p>{profile.first_name || 'Not provided'}</p>
                            </div>
                            <div className="item">
                                <small>Last name</small>
                                <p>{profile.last_name || 'Not provided'}</p>
                            </div>
                            <div className="item">
                                <small>Date of birth</small>
                                <p>{profile.profile.date_of_birth || 'Not provided'}</p>
                            </div>
                            <div className="item">
                                <small>Gender</small>
                                <p>{profile.profile.gender || 'Not provided'}</p>
                            </div>
                            <div className="item">
                                <small>Age</small>
                                <p>{age !== null ? age : 'Not provided'}</p>
                            </div>
                            <div className="item">
                                <small>Birth country</small>
                                <p>{profile.profile.birth_country || 'Not provided'}</p>
                            </div>
                            <div className="item">
                                <small>Address</small>
                                <p>{profile.profile.address || 'Not provided'}</p>
                            </div>
                            <div className="item">
                                <small>Phone number</small>
                                <p>{profile.profile.phone_number || 'Not provided'}</p>
                            </div>
                            <div className="full">
                                <small>Permissions</small>
                                <div className="permissions">
                                    {
                                        profile.permissions.map((permission, index) => (
                                            <p className="permission">{permission}</p>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ProfileTabs />
            </div>

        </div>
    )
}



const ProfileTabs = () => {
    const [activeTab, setActiveTab] = useState('reports')
    return (
        <div className="profile-data">
            <div className="tabs-list">
                <div onClick={() => setActiveTab('reports')} className={`tab ${activeTab === 'reports' ? 'active' : ''}`}><ListViewIcon size={20} /><p> Submitted reports</p></div>
                <div onClick={() => setActiveTab('drafts')} className={`tab ${activeTab === 'drafts' ? 'active' : ''}`}><LayersLogoIcon size={20} /><p> Drafts reports</p></div>
                <div onClick={() => setActiveTab('complaints')} className={`tab ${activeTab === 'complaints' ? 'active' : ''}`}> <Sad01Icon size={20} /> <p>Complaints</p></div>
                <div onClick={() => setActiveTab('documents')} className={`tab ${activeTab === 'documents' ? 'active' : ''}`}><GoogleDocIcon /><p>Documents</p></div>
            </div>

            {
                activeTab === 'reports' && <div className="tabs-content">
                    <h3>Your reports</h3>
                    <ProfileReports />
                </div>
            }
            {
                activeTab === 'drafts' && <div className="tabs-content">
                    <h3>Your drafts</h3>
                    <DraftsTab />
                </div>
            }
            {
                activeTab === 'complaints' && <div className="tabs-content">
                    <h3>Your complaints</h3>
                    <UserComplaints />
                </div>
            }
            {
                activeTab === 'documents' && <div className="tabs-content">
                    <h3>Your documents</h3>
                    <ProfileDocuments />
                </div>
            }

        </div>
    )
}
const BreadCrumbs = () => {
    return (
        <div className="breadcrumbs">
            <Link to={'/'}>Overview</Link> <ArrowRight01Icon /> <Link className='current-page'>Profile</Link>

        </div>
    )
}
const ProfilePage = () => {
    return (
        <DashBoardContainer content={<ProfilePageContent />} breadCrumbs={<BreadCrumbs />} />
    )
}

export default ProfilePage

