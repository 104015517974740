import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import api from "../../../../api";
import toast from "react-hot-toast";
import {
  CheckmarkSquare01Icon,
  FloppyDiskIcon,
  Loading03Icon,
  Square01Icon,
  SquareIcon,
} from "hugeicons-react";
import { injuresTypes, sourcesOfInformation } from "../../../../constants";
import RichTexField from "../inputs/richTexField";

import "../../../../assets/css/forms/forms.css";

import CustomSelectInput from "../inputs/customSelect";
import CustomDatePicker from "../inputs/datePicker";
import mediaAPI from "../../../../mediaApi";
import { AddCircleIcon, RemoveCircleIcon } from "hugeicons-react";
import BackToPage from "../../backToPage";
import postDocumentHistory from "../documentHistory/postDocumentHistory";
import FilesList from "../../../documents/filesList";
import CustomTimeInput from "../inputs/customTimeInput";

const ModifyWorkplaceIncident = ({ data, incidentId }) => {
  const [incident, setIncident] = useState(data);

  const [isLoading, setIsLoading] = useState(false);
  const [savingDraft, setSavingDraft] = useState(false);
  const [victimAlone, setVictimAlone] = useState(data.victim_was_alone);

  // forms
  const [status, setStatus] = useState(incident.status);
  const [selfInjury, setSelfInjury] = useState(false);
  const [otherType, setOtherType] = useState(false);
  const [otherExplain, setOtherExplain] = useState(false);
  const [otherAssailant, setOtherAssailant] = useState(false);
  const [showWitnesses, setShowWitnesses] = useState(false);
  const [isOtherTerminationOfContract, setIsOtherTerminationOfContract] =
    useState("");
  const [departmentManagerNotified, setDepartmentManagerNotified] = useState(
    data.immediate_supervisor
  );
  const [date, setDate] = useState(data.date_of_incident);
  const [detail, setDetail] = useState(data.description);
  const [time, setTime] = useState(data.time_of_incident);
  const [notificationTime, setNotificationTime] = useState(data.notification_time)
  const [name, setName] = useState(data.name_of_supervisor);
  const [title, setTitle] = useState(data.title_of_supervisor);
  const [typeOfContact, setTypeOfContact] = useState(data.type_of_contact);
  const [location, setLocation] = useState(data.location);
  const [threats, setThreats] = useState(data.there_was_threats_before);
  const [violence, setViolence] = useState(data.staff_member_reported);
  const [address, setAddress] = useState("");
  const [termination, setTermination] = useState("");
  const [action, setAction] = useState(data.action_taken);
  const [suggestions, setSuggestions] = useState(data.prevention_suggestion);
  const [explainselfinjury, setExplainSelfInjury] = useState("");
  const [otherinjury, setOtherInjury] = useState("");
  const [background, setBackground] = useState("");
  const [assailant, setAssailant] = useState("");
  const [selectedType, setSelectedType] = useState(data.incident_type);
  const [selectedInjuries, setSelectedInjuries] = useState(
    data.physical_injury_description.split(", ") || []
  );
  const [selectedRelationship, setSelectedRelationship] = useState("");
  const [selectedBackground, setSelectedBackground] = useState([]);
  const [weapons, setWeapons] = useState(data.weapons);
  const [reportedBy, setReportedBy] = useState(data.reported_by);
  const [reportedTitle, setreportedTitle] = useState(data.reported_by_title);
  const [dateReported, setdateReported] = useState(data.date_reported);
  const [timeReported, setTimeReported] = useState(data.time_reported);

  const [securityalert, setSecurityAlert] = useState(data.notification);
  const [injuryDetails, setInjuryDetails] = useState("");
  const [personInjured, setPersonInjured] = useState(data.person_injured);
  const [weaponField, setWeaponField] = useState(data.weapon_used);
  const [selectedIncidents, setSelectedIncidents] = useState([]);
  const [otherExplanation, setOtherExplanation] = useState("");
  const [terminationIncidents, setTerminationIncidents] = useState([]);
  const [currentInjury, setCurrentInjury] = useState({
    personInjured: "",
    injuryDetails: "",
  });
  const [injuryCheck, setInjuryCheck] = useState(data.there_were_injuries);
  const [injuries, setInjuries] = useState([]);
  const [success, setSuccess] = useState(false);
  const [newInjury, setNewInjury] = useState({
    personInjured: "",
    injuryDetails: "",
  });
  const [currentWitness, setCurrentWitness] = useState({
    name: "",
    phonenumber: "",
    address: "",
  });
  const [currentParty, setCurrentParty] = useState({
    name: "",
    title: "",
    phoneNumber: "",
    emailAddress: "",
  });

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadingDocuments, setUploadingDocuments] = useState(false);

  const [parties, setParties] = useState({
    Assailant: [
      ...data.initiated_by.filter((el) => el.party_type === "Assailant"),
    ],
    Victim: [...data.initiated_by.filter((el) => el.party_type === "Victim")],
    Witness: [...data.initiated_by.filter((el) => el.party_type === "Witness")],
  });

  const [witnesses, setWitnesses] = useState([]);
  const [currentType, setCurrentType] = useState("Assailant");

  const handleInjuryCheckChange = (value) => {
    if (value === "yes") {
      setInjuryCheck(true);
    } else if (value === "no" || value === "na") {
      setInjuryCheck(false);
    }
  };

  useEffect(() => {
    // get documents
    const fetchIncidentDocuments = async () => {
      try {
        const response = await api.get(
          `/incidents/workplace_violence/${incidentId}/documents/`
        );
        if (response.status === 200) {
          setUploadedFiles(response.data);
          console.log("documents updated successfully");
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchIncidentDocuments();
  }, []);

  const handleFileChange = async (event) => {
    const formData = new FormData();
    const files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    try {
      setUploadingDocuments(true);
      console.log([...formData]);

      const response = await mediaAPI.post(
        `/incidents/workplace_violence/${incidentId}/documents/new/`,
        formData
      );

      if (response.status === 200 || response.status === 201) {
        console.log(response.data.files);
        setUploadingDocuments(false);
        toast.success("Files uploaded successfully");
        setUploadedFiles(response.data.files);
      }
    } catch (error) {
      toast.error("Error uploading documents");
      setUploadingDocuments(false);
      console.log(error);
    }
  };

  const handleThreatsChange = (value) => {
    if (value === "yes") {
      setThreats(true);
    } else if (value === "no" || value === "unknown") {
      setThreats(false);
    }
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const list = [...injuries];
    list[index][name] = value;
    setNewInjury({
      ...newInjury,
      [name]: value,
    });
    setInjuries(list);
  };

  const handleAddInjury = () => {
    if (currentInjury.personInjured && currentInjury.injuryDetails) {
      setInjuries([...injuries, currentInjury]);
      setCurrentInjury({ personInjured: "", injuryDetails: "" });
    } else {
      toast.error("Please fill in both person injured and injury details");
    }
  };

  const handleRemoveInjury = (index) => {
    setInjuries(injuries.filter((_, i) => i !== index));
  };

  const handleWitnessChange = (index, event) => {
    const { name, value } = event.target;
    const newWitnesses = [...witnesses];
    newWitnesses[index][name] = value;
    setWitnesses(newWitnesses);
  };

  const handleAddWitness = () => {
    if (currentWitness.name.trim() !== "") {
      setWitnesses([...witnesses, currentWitness]);
      setCurrentWitness({ name: "", phonenumber: "", address: "" });
    }
  };

  const handleRemoveWitness = (index) => {
    setWitnesses(witnesses.filter((_, i) => i !== index));
  };

  const addPerson = () => {
    if (
      currentParty.name.trim() !== "" &&
      currentParty.title.trim() !== "" &&
      currentParty.phoneNumber.trim() !== "" &&
      currentParty.emailAddress.trim() !== ""
    ) {
      setParties((prevParties) => ({
        ...prevParties,
        [currentType]: [...prevParties[currentType], { ...currentParty }],
      }));
      setCurrentParty({
        name: "",
        title: "",
        phoneNumber: "",
        emailAddress: "",
      });
    } else {
      toast.error("Please fill all fields before adding a person.");
    }
  };

  const handleChange = (type, index, field, value) => {
    setParties((prev) => ({
      ...prev,
      [type]: prev[type].map((party, i) =>
        i === index ? { ...party, [field]: value } : party
      ),
    }));
  };

  const setPartiesType = (type) => {
    setCurrentType(type);
  };

  const removePerson = (type, index) => {
    setParties((prevParties) => ({
      ...prevParties,
      [type]: prevParties[type].filter((_, i) => i !== index),
    }));
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedIncidents((prevSelected) => [...prevSelected, value]);
    } else {
      setSelectedIncidents((prevSelected) =>
        prevSelected.filter((item) => item !== value)
      );
    }
  };

  const handleTerminationChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setTerminationIncidents((prevSelected) => [...prevSelected, value]);
    } else {
      setTerminationIncidents((prevSelected) =>
        prevSelected.filter((item) => item !== value)
      );
    }
  };

  const handleWeaponChange = () => {
    setWeapons((prevWeapons) => !prevWeapons);
    if (weapons) {
      setWeaponField("");
    }
  };
  const handleTerminationOfContract = () => {
    setIsOtherTerminationOfContract(!isOtherTerminationOfContract);
  };
  const handleShowWitnesses = () => {
    setShowWitnesses(!showWitnesses);
  };

  const handleSelfInjury = () => {
    setSelfInjury(!selfInjury);
  };

  const handleOtherExplain = () => [setOtherExplain(!otherExplain)];

  const handleOtherAssailant = () => [setOtherAssailant(!otherAssailant)];

  const handleTypeSelection = (injury) => {
    if (injury === "Other") {
      // Toggle otherType and set selectedInjuries to otherExplanation
      setOtherType(!otherType);
      setSelectedInjuries([otherExplanation]);
    } else {
      // Check if the injury is already in the array
      if (!selectedInjuries.includes(injury)) {
        // Add the injury to the array
        setSelectedInjuries((prevInjuries) => [...prevInjuries, injury]);
      } else {
        // If the injury is already in the array, remove it
        setSelectedInjuries((prevInjuries) =>
          prevInjuries.filter((item) => item !== injury)
        );
      }
    }
  };

  const showOtherInjuryType = () => {
    setOtherType(!otherType);
  };

  const handleOtherTypeOfInjury = () => {
    if (!selectedInjuries.includes(otherExplanation)) {
      // Add the injury to the array
      setSelectedInjuries((prevInjuries) => [
        ...prevInjuries,
        otherExplanation,
      ]);
      injuresTypes.push(otherExplanation);
      setOtherExplanation("");
    } else {
      // If the injury is already in the array, remove it
      setSelectedInjuries((prevInjuries) =>
        prevInjuries.filter((item) => item !== otherExplanation)
      );
      setOtherExplanation("");
    }
  };

  const handleSelection = (type) => {
    setSelectedType(type === selectedType ? null : type);
  };
  const validateInjuries = (injuries) => {
    if (!injuryCheck) return true;
    return (
      injuries.length > 0 &&
      injuries.every((injury) => injury.personInjured && injury.injuryDetails)
    );
  };

  const handleRelationshipVictim = (relationship) => {
    setSelectedRelationship(relationship);
    if (relationship === "Other (explain)") {
      setOtherAssailant(true);
    } else {
      setOtherAssailant(false);
    }
  };

  const handleBackground = (type) => {
    const index = selectedBackground.indexOf(type);
    if (index === -1) {
      setSelectedBackground([...selectedBackground, type]);
    } else {
      setSelectedBackground(selectedBackground.filter((item) => item !== type));
    }
  };

  const handleCheckboxAndSelfInjury = (event) => {
    const { value, checked } = event.target;
    handleCheckboxChange(event);
    if (value === "selfInjury" || value === "otherTypes") {
      handleSelfInjury();
    }
    if (!checked && (value === "selfInjury" || value === "otherTypes")) {
      setSelfInjury(false);
      setExplainSelfInjury("");
    }
  };
  const handleViolenceChange = (value) => {
    setViolence(value);
  };
  const handleVictimChange = (e) => {
    setVictimAlone(e.target.checked);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleSaveDraft = () => {
    setStatus("Draft");
    setSavingDraft(true);
    handleModify("Draft");
  };
  const handleSaveAndSubmit = () => {
    setStatus("Completed");
    setIsLoading(true);
    handleModify("Completed");
    setIsLoading(true);
  };
  const handleModify = async (incidentStatus) => {
    const backgroundData = selectedBackground.includes("Other (explain)")
      ? [
        ...selectedBackground.filter((item) => item !== "Other (explain)"),
        background,
      ]
      : selectedBackground;

    const relationshipData =
      selectedRelationship === "Other (explain)"
        ? assailant
        : selectedRelationship;

    let dataToStringify;

    if (
      selectedIncidents.some((el) => el === "selfInjury") ||
      selectedIncidents.some((el) => el === "otherTypes")
    ) {
      dataToStringify = {
        type: "selfInjuryOrOther",
        explanation: explainselfinjury,
      };
    } else {
      dataToStringify = {
        type: "selectedIncidents",
        incidents: selectedIncidents,
      };
    }

    const jsonData = JSON.stringify(dataToStringify);
    const incidentData = {
      type_of_incident: jsonData,
      physical_injury_description: selectedInjuries.join(", "),
      incident_type: selectedType,
      date_of_incident: date,
      time_of_incident: time,
      description: detail,
      initiated_by: [
        ...parties["Assailant"].map((party) => ({
          party_type: "Assailant",
          name: party.name,
          title: party.title,
          phone_number: party.phoneNumber,
          email: party.emailAddress,
          assailant_relationship_to_patient: relationshipData,
          assailant_background: backgroundData.join(", "),
        })),
        ...parties["Victim"].map((party) => ({
          party_type: "Victim",
          name: party.name,
          title: party.title,
          phone_number: party.phoneNumber,
          email: party.emailAddress,
          assailant_relationship_to_patient: "N/A",
          assailant_background: "N/A",
        })),
        ...parties["Witness"].map((party) => ({
          party_type: "Witness",
          name: party.name,
          title: party.title,
          phone_number: party.phoneNumber,
          email: party.emailAddress,
          assailant_relationship_to_patient: "N/A",
          assailant_background: "N/A",
        })),
      ],
      type_of_contact: typeOfContact,
      victim_was_alone: victimAlone,
      location: location,
      there_was_threats_before: threats,
      staff_member_reported: violence === "yes",
      weapons: weapons,
      weapon_used: weaponField,
      there_were_injuries: injuryCheck ? true : false,
      person_injured: injuryCheck
        ? injuries.map((injury) => ({
          name: injury.personInjured,
          injury_description: injury.injuryDetails,
        }))
        : [{ name: "N/A", injury_description: "N/A" }],
      notification: securityalert,
      incident_witness: witnesses.map((witness) => ({
        name: witness.name,
        phone_number: witness.phonenumber,
        address: witness.address,
      })),
      termination_incident: [
        { description: JSON.stringify(terminationIncidents) },
      ],
      immediate_supervisor: departmentManagerNotified,
      name_of_supervisor: name,
      title_of_supervisor: title,
      date_notified: date,
      notification_time: notificationTime,
      action_taken: action,
      prevention_suggestion: suggestions,
      reported_by: reportedBy,
      reported_by_title: reportedTitle,
      date_reported: dateReported,
      time_reported: timeReported,
      status: incidentStatus,
    };
    try {
      const response = await api.patch(
        `incidents/workplace_violence/${incidentId}/modify/`,
        incidentData
      );
      console.log("response: " + response);
      if (response.status === 200) {
        setIsLoading(false);
        setSavingDraft(false);
        toast.success("Incident updated successfully");
        setIncident(response.data);

        postDocumentHistory(incidentId, "modified this incident", "modify");
      }
    } catch (error) {
      setIsLoading(false);
      setSavingDraft(false);
      console.log(error);
      if (error.response) {
        toast.error(
          error.response.data.message ||
          error.response.data.error ||
          "Error while updating the incident"
        );
      } else {
        toast.error("Unknown error while updating the incident");
      }
    }
  };
  return (
    <div className="modify-page-content">
      <div className="modify-page-header">
        <BackToPage
          link={"/incident/workplace-violence/"}
          pageName={"Workplace violence incident"}
        />
        <h2 className="title">Modifying Workplace Violence Incident</h2>
        <div className="buttons">
          <button className="tertiary-button" onClick={handleSaveDraft}>
            {savingDraft ? (
              <>
                <Loading03Icon className="loading-icon" size={18} />
                <span>Saving draft</span>
              </>
            ) : (
              <>
                <FloppyDiskIcon size={20} />
                <span>Save draft</span>
              </>
            )}
          </button>
          <button className="primary-button" onClick={handleSaveAndSubmit}>
            {isLoading ? (
              <>
                <Loading03Icon className="loading-icon" size={18} />
                <span>Saving changes</span>
              </>
            ) : (
              <>
                <FloppyDiskIcon size={20} />
                <span>Save and submit</span>
              </>
            )}
          </button>
        </div>
      </div>
      {
        <form>
          <div className="incident-status">
            <p>
              Status : <span>{status}</span>
            </p>
          </div>
          <div className="step inputs-group">
            <h4>
              Type of incident{" "}
              <span>
                <small>(check all that apply)</small>
              </span>
            </h4>
            <div className="straight-checkings">
              <div className="check-box">
                <input
                  type="checkbox"
                  name="incidentType"
                  value="Verbal"
                  id="verbal"
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="verbal">
                  Verbal harassment/manipulation/intimidation
                </label>
              </div>
              <div className="check-box">
                <input
                  type="checkbox"
                  name="incidentType"
                  value="Verbal Threat"
                  id="verbalThreat"
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="verbalThreat">
                  Verbal threat of physical assault
                </label>
              </div>
              <div className="check-box">
                <input
                  type="checkbox"
                  name="incidentType"
                  value="Physical Assault"
                  id="physicalAssault"
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="physicalAssault">Physical assault</label>
              </div>
              <div className="check-box">
                <input
                  type="checkbox"
                  name="incidentType"
                  value="Self Injury"
                  id="selfInjury"
                  onChange={handleCheckboxAndSelfInjury}
                />
                <label htmlFor="selfInjury">Self-injury</label>
              </div>
              <div className="check-box">
                <input
                  type="checkbox"
                  name="incidentType"
                  value="Property Damage"
                  id="propertyDamage"
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="propertyDamage">Damage to property</label>
              </div>
              <div className="check-box">
                <input
                  type="checkbox"
                  name="incidentType"
                  value="Other Types"
                  id="otherTypes"
                  onChange={handleCheckboxAndSelfInjury}
                />
                <label htmlFor="otherTypes">Other(Describe)</label>
              </div>
            </div>

            {selfInjury && (
              <>
                <input
                  type="text"
                  name="selfInjuryExplanation"
                  id="selfInjuryExplanation"
                  placeholder="Please explain"
                  value={explainselfinjury}
                  onChange={(e) => setExplainSelfInjury(e.target.value)}
                />
                <button type="button">Add new</button>
              </>
            )}

            <h4>Describe the physical injury</h4>
            <div className="types">
              {injuresTypes.map((injury) => (
                <div
                  key={injury}
                  className={`type ${selectedInjuries.includes(injury) ? "selected" : ""
                    }`}
                  onClick={() => handleTypeSelection(injury)}
                >
                  <p>{injury}</p>
                </div>
              ))}
              <p onClick={showOtherInjuryType} className="type">
                Other
              </p>
            </div>

            {otherType && (
              <>
                <input
                  type="text"
                  name="otherTypesInput"
                  id="otherTypesInput"
                  placeholder="Please explain"
                  value={otherExplanation}
                  onChange={(e) => setOtherExplanation(e.target.value)}
                />
                <button onClick={handleOtherTypeOfInjury} type="button">
                  Add new
                </button>
              </>
            )}
          </div>
          <div className="step inputs-group">
            <h4>Select Incident type</h4>
            <div className="types checkbox-grid">
              <div
                className={`type full-width-type ${selectedType === "Type 1" ? "selected" : ""
                  }`}
                onClick={() => handleSelection("Type 1")}
              >
                <h5>Type 1 (Criminal Intent/External)</h5>
                Violence by strangers/individuals who have no other connection
                with the workplace.
              </div>

              <div
                className={`type full-width-type ${selectedType === "Type 2" ? "selected" : ""
                  }`}
                onClick={() => handleSelection("Type 2")}
              >
                <h5>Type 2 (Patient/Family/Guest)</h5>
                Violence against staff by patients, customers, or others with a
                business relationship.
              </div>

              <div
                className={`type full-width-type ${selectedType === "Type 3" ? "selected" : ""
                  }`}
                onClick={() => handleSelection("Type 3")}
              >
                <h5>Type 3 (Worker on Worker)</h5>
                Violence against staff members, managers or supervisors by a
                current or former staff member.
              </div>

              <div
                className={`type full-width-type ${selectedType === "Type 4" ? "selected" : ""
                  }`}
                onClick={() => handleSelection("Type 4")}
              >
                <h5>Type 4 (Domestic/Intimate Parter)</h5>
                Violence in the workplace by an individual who does not work
                there but has a personal relationship with the worker /such as
                an abusive spouse or domestic partner.
              </div>

              <div
                className={`type full-width-type ${selectedType === "Type 5" ? "selected" : ""
                  }`}
                onClick={() => handleSelection("Type 5")}
              >
                <h5>Type 5 (ideological)</h5>
                Violence in the workplace that is directed at an organization, a
                group of people, and/or its property for ideological, religious,
                or political reasons.
              </div>
            </div>
          </div>
          <div className="step inputs-group">
            <div className="half">
              <div className="field flex-column">
                <label htmlFor="dateOfIncident">Date of incident</label>
                <CustomDatePicker
                  selectedDate={date}
                  setSelectedDate={setDate}
                />
              </div>
              <div className="field flex-column">
                <label htmlFor="dateOfIncident">Time of incident</label>
                <CustomTimeInput setTime={setTime} />

              </div>
            </div>
            <div className="field flex-column">
              <label htmlFor="incidentDescription">
                Detailed description of the observation, threat, incident, or
                activity
              </label>
              <RichTexField value={detail} onEditorChange={setDetail} />
            </div>
          </div>
          <div className="step inputs-group">
            <h4>Part 2: Incident Directed at and Initiated/Committed By</h4>

            <div className="tabs-content">
              <div className="types">
                {["Assailant", "Victim", "Witness"].map((type) => (
                  <div
                    key={type}
                    onClick={() => setPartiesType(type)}
                    className={`type ${currentType === type ? "selected" : ""}`}
                  >
                    {type}
                  </div>
                ))}
              </div>
              <div className="field name flex-column">
                <div
                  className="parties"
                  style={{ flexDirection: "row", flexWrap: "wrap" }}
                >
                  {parties[currentType]
                    .filter((party) => !party.isInitial)
                    .map((party, index) => (
                      <button
                        key={index}
                        className="new-party"
                        onClick={() => removePerson(currentType, index)}
                      >
                        {party.name}
                        <RemoveCircleIcon />
                      </button>
                    ))}
                </div>
                <div className="field flex-column">
                  <label htmlFor={`${currentType.toLowerCase()}Name`}>
                    {currentType} Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id={`${currentType.toLowerCase()}Name`}
                    placeholder={`Enter ${currentType.toLowerCase()} name`}
                    value={currentParty.name}
                    onChange={(e) =>
                      setCurrentParty({
                        ...currentParty,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="half ">
                  <div className="field flex-column">
                    <label htmlFor={`${currentType.toLowerCase()}Title`}>
                      Title
                    </label>
                    <input
                      type="text"
                      name="title"
                      id={`${currentType.toLowerCase()}Title`}
                      placeholder="Enter title"
                      value={currentParty.title}
                      onChange={(e) =>
                        setCurrentParty({
                          ...currentParty,
                          title: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="field flex-column">
                    <label htmlFor={`${currentType.toLowerCase()}Phone`}>
                      Phone number
                    </label>
                    <input
                      type="text"
                      name="phoneNumber"
                      id={`${currentType.toLowerCase()}Phone`}
                      placeholder="Enter phone number"
                      value={currentParty.phoneNumber}
                      onChange={(e) =>
                        setCurrentParty({
                          ...currentParty,
                          phoneNumber: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="field flex-column">
                  <label htmlFor={`${currentType.toLowerCase()}Email`}>
                    Email
                  </label>
                  <input
                    type="email"
                    name="emailAddress"
                    id={`${currentType.toLowerCase()}Email`}
                    placeholder="Enter email address"
                    value={currentParty.emailAddress}
                    onChange={(e) =>
                      setCurrentParty({
                        ...currentParty,
                        emailAddress: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="parties">
                  <button
                    type="button"
                    className="new-party"
                    onClick={addPerson}
                  >
                    <AddCircleIcon />
                    Add {currentType}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="step inputs-group">
            <h4>Assailant Relationship to Victim(s):</h4>
            <div className="types">
              <div
                className={`type ${selectedRelationship.includes(
                  "Staff member/ current employee"
                )
                  ? "selected"
                  : ""
                  }`}
                onClick={() =>
                  handleRelationshipVictim("Staff member/ current employee")
                }
              >
                <p>Staff member/ current employee</p>
              </div>
              <div
                className={`type ${selectedRelationship.includes("Patient") ? "selected" : ""
                  }`}
                onClick={() => handleRelationshipVictim("Patient")}
              >
                <p>Patient</p>
              </div>
              <div
                className={`type ${selectedRelationship.includes("Stranger") ? "selected" : ""
                  }`}
                onClick={() => handleRelationshipVictim("Stranger")}
              >
                <p>Stranger</p>
              </div>
              <div
                className={`type full-width-type ${selectedRelationship.includes("Former staff member")
                  ? "selected"
                  : ""
                  }`}
                onClick={() => handleRelationshipVictim("Former staff member")}
              >
                <p>Former staff member</p>
              </div>
              <div
                className={`type full-width-type ${selectedRelationship.includes("Patient’s family")
                  ? "selected"
                  : ""
                  }`}
                onClick={() => handleRelationshipVictim("Patient’s family")}
              >
                <p>Patient’s family</p>
              </div>
              <div
                className={`type full-width-type ${selectedRelationship.includes("Spouse/Significant other")
                  ? "selected"
                  : ""
                  }`}
                onClick={() =>
                  handleRelationshipVictim("Spouse/Significant other")
                }
              >
                <p>Spouse/Significant other</p>
              </div>
              <div
                className={`type full-width-type ${selectedRelationship.includes("Supervisor/Manager")
                  ? "selected"
                  : ""
                  }`}
                onClick={() => handleRelationshipVictim("Supervisor/Manager")}
              >
                <p>Supervisor/Manager</p>
              </div>
              <div
                className={`type full-width-type ${selectedRelationship.includes("Acquaintance")
                  ? "selected"
                  : ""
                  }`}
                onClick={() => handleRelationshipVictim("Acquaintance")}
              >
                <p>Acquaintance</p>
              </div>
              <div
                className={`type full-width-type ${selectedRelationship.includes("Contractor/Vendor")
                  ? "selected"
                  : ""
                  }`}
                onClick={() => handleRelationshipVictim("Contractor/Vendor")}
              >
                <p>Contractor/Vendor</p>
              </div>
              <div
                className={`type full-width-type ${selectedRelationship.includes("Other (explain)")
                  ? "selected"
                  : ""
                  }`}
                onClick={() => {
                  handleRelationshipVictim("Other (explain)");
                  handleOtherAssailant();
                }}
              >
                <p>Other (explain)</p>
              </div>
              {otherAssailant ? (
                <input
                  type="text"
                  name="otherTypesInput"
                  id="otherTypesInput"
                  placeholder="Explain"
                  value={assailant}
                  onChange={(e) => setAssailant(e.target.value)}
                />
              ) : (
                ""
              )}
            </div>
            <h4>
              Background (
              <span>
                <small>if known, check all that apply</small>
              </span>
              )
            </h4>
            <div className="types">
              <div
                className={`type ${selectedBackground.includes(
                  "Consequences of patient condition/disability"
                )
                  ? "selected"
                  : ""
                  }`}
                onClick={() =>
                  handleBackground(
                    "Consequences of patient condition/disability"
                  )
                }
              >
                <p>
                  Consequences of patient <br /> condition/disability
                </p>
              </div>
              <div
                className={`type ${selectedBackground.includes("Grief") ? "selected" : ""
                  }`}
                onClick={() => handleBackground("Grief")}
              >
                <p>Grief</p>
              </div>
              <div
                className={`type full-width-type ${selectedBackground.includes(
                  "Occured while processing patient information"
                )
                  ? "selected"
                  : ""
                  }`}
                onClick={() =>
                  handleBackground(
                    "Occured while processing patient information"
                  )
                }
              >
                <p>
                  Occured while <br />
                  processing patient information
                </p>
              </div>
              <div
                className={`type full-width-type ${selectedBackground.includes("Dissatisfied with care/service")
                  ? "selected"
                  : ""
                  }`}
                onClick={() =>
                  handleBackground("Dissatisfied with care/service")
                }
              >
                <p>
                  Dissatisfied with care/ <br /> service
                </p>
              </div>
              <div
                className={`type full-width-type ${selectedBackground.includes("Suspected substance abuse")
                  ? "selected"
                  : ""
                  }`}
                onClick={() => handleBackground("Suspected substance abuse")}
              >
                <p>Suspected substance abuse</p>
              </div>
              <div
                className={`type full-width-type ${selectedBackground.includes(
                  "Occured while providing patient care"
                )
                  ? "selected"
                  : ""
                  }`}
                onClick={() =>
                  handleBackground("Occured while providing patient care")
                }
              >
                <p>
                  Occured while <br /> providing patient care
                </p>
              </div>
              <div
                className={`type full-width-type ${selectedBackground.includes("Employment related")
                  ? "selected"
                  : ""
                  }`}
                onClick={() => handleBackground("Employment related")}
              >
                <p>Employment related</p>
              </div>
              <div
                className={`type full-width-type ${selectedBackground.includes("Other (explain)")
                  ? "selected"
                  : ""
                  }`}
                onClick={() => {
                  handleBackground("Other (explain)");
                  handleOtherExplain();
                }}
              >
                <p>Other (explain)</p>
              </div>
              <div
                className={`type full-width-type ${selectedBackground.includes("Interpersonal conflic")
                  ? "selected"
                  : ""
                  }`}
                onClick={() => handleBackground("Interpersonal conflic")}
              >
                <p>Interpersonal conflict</p>
              </div>
              <div
                className={`type full-width-type ${selectedBackground.includes("Prior history of violence")
                  ? "selected"
                  : ""
                  }`}
                onClick={() => handleBackground("Prior history of violence")}
              >
                <p>Prior history of violence</p>
              </div>
            </div>

            {otherExplain ? (
              <input
                type="text"
                name="otherTypesInput"
                id="otherTypesInput"
                placeholder="Enter Background"
                value={background}
                onChange={(e) => setBackground(e.target.value)}
              />
            ) : (
              ""
            )}
          </div>
          <div className="step inputs-group">
            <div className="field flex-column">
              <label htmlFor="typeIfContact">Type of contact</label>
              <CustomSelectInput
                options={[
                  "In person",
                  "Telephone",
                  "Email",
                  "Social Media",
                  "Fax",
                ]}
                placeholder={"Type of contact"}
                selected={typeOfContact}
                setSelected={setTypeOfContact}
              />
            </div>
            <div className="field flex-column">
              <div className="check-box">
                <input
                  type="checkbox"
                  name="victimWasAlone"
                  id="victimWasAlone"
                  checked={victimAlone}
                  onChange={handleVictimChange}
                />
                <label htmlFor="victimWasAlone">
                  Check if the victim was alone at the time of the incident.
                </label>
              </div>
            </div>

            <div className="field flex-column">
              <label htmlFor="incidentLocation">Location</label>
              <input
                type="text"
                name="incidentLocation"
                id="incidentLocation"
                placeholder="Dept.Room Number"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>
            <div className="field flex-column">
              <label htmlFor="incidentThreats">
                Were any threats made before the incident occurred?
              </label>
              <div className="check-boxes">
                <div className="check-box">
                  <input
                    type="radio"
                    name="incidentThreats"
                    id="yesThreats"
                    value="yes"
                    required
                    onChange={(e) => handleThreatsChange(e.target.value)}
                  />
                  <label htmlFor="yesThreats">Yes</label>
                </div>
                <div className="check-box">
                  <input
                    type="radio"
                    name="incidentThreats"
                    id="noThreats"
                    value="no"
                    required
                    onChange={(e) => handleThreatsChange(e.target.value)}
                  />
                  <label htmlFor="noThreats">No</label>
                </div>
                <div className="check-box">
                  <input
                    type="radio"
                    name="incidentThreats"
                    id="unknownThreats"
                    value="unknown"
                    onChange={(e) => handleThreatsChange(e.target.value)}
                    required
                  />
                  <label htmlFor="unknownThreats">Unknown</label>
                </div>
              </div>
            </div>

            <div className="field flex-column">
              <label htmlFor="incidentViolent">
                Did the staff member ever report they were threatened, harassed,
                or suspicious that the assailant may become violent?
              </label>
              <div className="check-boxes">
                <div className="check-box">
                  <input
                    type="radio"
                    name="violent"
                    id="yesViolent"
                    value="yes"
                    onChange={(e) => handleViolenceChange(e.target.value)}
                  />
                  <label htmlFor="yesViolent">Yes</label>
                </div>
                <div className="check-box">
                  <input
                    type="radio"
                    name="violent"
                    id="noViolent"
                    value="no"
                    onChange={(e) => handleViolenceChange(e.target.value)}
                  />
                  <label htmlFor="noViolent">No</label>
                </div>
                <div className="check-box">
                  <input
                    type="radio"
                    name="violent"
                    id="unknownViolent"
                    value="unknown"
                    onChange={(e) => handleViolenceChange(e.target.value)}
                  />
                  <label htmlFor="unknownViolent">Unknown</label>
                </div>
                <div className="check-box">
                  <input
                    type="radio"
                    name="violent"
                    id="unknownNA"
                    value="n/a"
                    onChange={(e) => handleViolenceChange(e.target.value)}
                  />
                  <label htmlFor="unknownNA">N/A</label>
                </div>
              </div>
            </div>

            <div className="field flex-column">
              <div className="check-boxes">
                <div className="check-box">
                  <input
                    type="checkbox"
                    name="weaponsInvolved"
                    id="weaponsInvolved"
                    checked={weapons}
                    onChange={handleWeaponChange}
                  />
                  <label htmlFor="weaponsInvolved">
                    Check if any weapons were involved in this incident.
                  </label>
                </div>

                {weapons && (
                  <div className="field flex-column">
                    <label htmlFor="nameOfWeaponInvolved">Weapon used</label>
                    <input
                      type="text"
                      name="nameOfWeaponInvolved"
                      id="nameOfWeaponInvolved"
                      placeholder="Enter description"
                      value={weaponField}
                      onChange={(e) => setWeaponField(e.target.value)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="step inputs-group">
            <label htmlFor="forInjuries">Were there injuries?</label>
            <div className="check-boxes">
              <div className="check-box">
                <label htmlFor="yesInjury">Yes</label>
                <input
                  type="radio"
                  name="injury"
                  id="yesInjury"
                  value="yes"
                  onChange={(e) => handleInjuryCheckChange(e.target.value)}
                />
              </div>
              <div className="check-box">
                <label htmlFor="noInjury">No</label>
                <input
                  type="radio"
                  name="injury"
                  id="noInjury"
                  value="no"
                  onChange={(e) => handleInjuryCheckChange(e.target.value)}
                />
              </div>
              <div className="check-box">
                <label htmlFor="NAInjury">N/A</label>
                <input
                  type="radio"
                  name="injury"
                  id="NAInjury"
                  value="na"
                  onChange={(e) => handleInjuryCheckChange(e.target.value)}
                />
              </div>
            </div>

            {injuryCheck && (
              <div className="field name flex-column">
                <div
                  className="parties"
                  style={{ flexDirection: "row", flexWrap: "wrap" }}
                >
                  {injuries.length > 0
                    ? injuries.map((injury, index) => (
                      <button
                        key={index}
                        className="new-party"
                        onClick={() => handleRemoveInjury(index)}
                      >
                        {injury.personInjured}
                        <RemoveCircleIcon />
                      </button>
                    ))
                    : null}
                </div>
                <label htmlFor="personInjured">Who was injured</label>
                <input
                  type="text"
                  name="personInjured"
                  id="personInjured"
                  placeholder="Enter name"
                  value={currentInjury.personInjured}
                  onChange={(e) =>
                    setCurrentInjury({
                      ...currentInjury,
                      personInjured: e.target.value,
                    })
                  }
                />
                <div className="field flex-column">
                  <label htmlFor="injuryDetails">Injury description</label>
                  <input
                    type="text"
                    name="injuryDetails"
                    id="injuryDetails"
                    placeholder="Enter Description"
                    value={currentInjury.injuryDetails}
                    onChange={(e) =>
                      setCurrentInjury({
                        ...currentInjury,
                        injuryDetails: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="parties">
                  <button
                    type="button"
                    className="new-party"
                    onClick={handleAddInjury}
                  >
                    <AddCircleIcon />
                    Add Injury
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="step inputs-group">
            <h4>Witnesses to the incident:</h4>

            <div className="tabs-content">
              <div className="field name flex-column">
                <div
                  className="parties"
                  style={{ flexDirection: "row", flexWrap: "wrap" }}
                >
                  {witnesses.length > 0
                    ? witnesses.map((witness, index) => (
                      <button
                        key={index}
                        className="new-party"
                        onClick={() => handleRemoveWitness(index)}
                      >
                        {witness.name}
                        <RemoveCircleIcon />
                      </button>
                    ))
                    : null}
                </div>
                <label htmlFor="witnessName">Witness Name</label>
                <input
                  type="text"
                  name="name"
                  id="witnessName"
                  placeholder="Enter witness name"
                  value={currentWitness.name}
                  onChange={(e) =>
                    setCurrentWitness({
                      ...currentWitness,
                      name: e.target.value,
                    })
                  }
                />
                <div className="half">
                  <div className="field flex-column">
                    <label htmlFor="witnessPhone">Phone</label>
                    <input
                      type="text"
                      name="phonenumber"
                      id="witnessPhone"
                      placeholder="Phone number"
                      value={currentWitness.phonenumber}
                      onChange={(e) =>
                        setCurrentWitness({
                          ...currentWitness,
                          phonenumber: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="field flex-column">
                    <label htmlFor="witnessAddress">Address</label>
                    <input
                      type="text"
                      name="address"
                      id="witnessAddress"
                      placeholder="Address"
                      value={currentWitness.address}
                      onChange={(e) =>
                        setCurrentWitness({
                          ...currentWitness,
                          address: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="parties">
                  <button
                    type="button"
                    className="new-party"
                    onClick={handleAddWitness}
                  >
                    <AddCircleIcon />
                    Add Witness
                  </button>
                </div>
              </div>
            </div>

            <h4>Notification: </h4>
            <div className="field flex-column">
              <div className="check-boxes">
                <div className="check-box">
                  <input
                    type="radio"
                    name="securityOption"
                    id="securityAlertCalled"
                    checked={securityalert === "securityAlertCalled"}
                    onChange={() => setSecurityAlert("securityAlertCalled")}
                  />
                  <label htmlFor="securityAlertCalled">
                    Security Alert Code called?
                  </label>
                </div>
                <div className="check-box">
                  <input
                    type="radio"
                    name="securityOption"
                    id="lawEnforcementCalled"
                    checked={securityalert === "lawEnforcementCalled"}
                    onChange={() => setSecurityAlert("lawEnforcementCalled")}
                  />
                  <label htmlFor="lawEnforcementCalled">
                    Law Enforcement called to intervene?
                  </label>
                </div>
                <div className="check-box">
                  <input
                    type="radio"
                    name="securityOption"
                    id="administrationCalled"
                    checked={securityalert === "administrationCalled"}
                    onChange={() => setSecurityAlert("administrationCalled")}
                  />
                  <label htmlFor="administrationCalled">
                    Administrator/AOC called?
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            className="step inputs-group"
            value={termination}
            onChange={(e) => setTermination(e.target.value)}
          >
            <h4>
              Termination of incident{" "}
              <span>
                <small>(check all that apply)</small>
              </span>
            </h4>
            <div className="check-boxes">
              <div className="check-box">
                <input
                  type="checkbox"
                  name="incidentDeescalated"
                  id="incidentDeescalated"
                  value="incidentDeescalated"
                  onChange={handleTerminationChange}
                />
                <label htmlFor="incidentDeescalated">
                  Incident de-escalated{" "}
                </label>
              </div>
              <div className="check-box">
                <input
                  type="checkbox"
                  name="assailantEscortedOffPremises"
                  id="assailantEscortedOffPremises"
                  value="assailantEscortedOffPremises"
                  onChange={handleTerminationChange}
                />
                <label htmlFor="assailantEscortedOffPremises">
                  Assailant escorted off premises
                </label>
              </div>
              <div className="check-box">
                <input
                  type="checkbox"
                  name="assailantArrested"
                  id="assailantArrested"
                  value="assailantArrested"
                  onChange={handleTerminationChange}
                />
                <label htmlFor="assailantArrested">Assailant arrested </label>
              </div>
              <div className="check-box">
                <input
                  type="checkbox"
                  name="letOnOwn"
                  id="letOnOwn"
                  value="leftOnOwn"
                  onChange={handleTerminationChange}
                />
                <label htmlFor="letOnOwn">Left on own</label>
              </div>

              <div className="check-box">
                <input
                  type="checkbox"
                  name="stayedOnPremise"
                  id="stayedOnPremise"
                  value="stayedOnPremise"
                  onChange={handleTerminationChange}
                />
                <label htmlFor="stayedOnPremise">Stayed on premises</label>
              </div>

              <div className="check-box">
                <input
                  type="checkbox"
                  name="assailantRestrained"
                  id="assailantRestrained"
                  value="assailantRestrained"
                  onChange={handleTerminationChange}
                />
                <label htmlFor="assailantRestrained">
                  Assailant restrained
                </label>
              </div>

              <div className="check-box">
                <input
                  onClick={() => handleTerminationOfContract("other")}
                  value={isOtherTerminationOfContract}
                  type="checkbox"
                  name="otherTermination"
                  id="otherTermination"
                />
                <label htmlFor="otherTermination">Other</label>
              </div>

              {isOtherTerminationOfContract ? (
                <div className="field">
                  <input
                    type="text"
                    name="otherTerminationOfContract"
                    id="otherTerminationOfContract"
                    placeholder="Please explain"
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="step inputs-group">
            <h4>Was the immediate supervisor/department manager notified? </h4>
            <div className="check-boxes">
              <div className="check-box">
                <input
                  type="radio"
                  name="departmentManagerNotified"
                  id="YesDepartmentManagerNotified"
                  checked={departmentManagerNotified === true}
                  onChange={() => setDepartmentManagerNotified(true)}
                />
                <label htmlFor="YesDepartmentManagerNotified">Yes</label>
              </div>
              <div className="check-box">
                <input
                  type="radio"
                  name="departmentManagerNotified"
                  id="noDepartmentManagerNotified"
                  checked={departmentManagerNotified === false}
                  onChange={() => setDepartmentManagerNotified(false)}
                />
                <label htmlFor="noDepartmentManagerNotified">No</label>
              </div>
            </div>

            {departmentManagerNotified ? (
              <div>
                <div className="half">
                  <div className="field flex-column">
                    <label htmlFor="departmentNotifiedName">Name</label>
                    <input
                      type="text"
                      name="departmentNotifiedName"
                      id="departmentNotifiedName"
                      placeholder="Enter name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="field flex-column">
                    <label htmlFor="departmentNotifiedTitle">
                      Title/Department
                    </label>
                    <input
                      type="text"
                      name="departmentNotifiedTitle"
                      id="departmentNotifiedTitle"
                      placeholder="Enter title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                </div>

                <div className="half">
                  <div className="field flex-column">
                    <label htmlFor="dateOfNotification">Date</label>
                    <CustomDatePicker
                      selectedDate={date}
                      setSelectedDate={setDate}
                    />
                  </div>

                  <div className="field flex-column">
                    <label htmlFor="timeOfNotification">Time</label>
                    <CustomTimeInput setTime={setNotificationTime} />


                  </div>
                </div>

                <div className="field flex-column">
                  <label htmlFor="departmentAction">
                    What action was/has been taken by immediate
                    supervisor/department manager and/or Hospital
                    administration?
                  </label>
                  <input
                    type="text"
                    name="departmentAction"
                    id="departmentAction"
                    placeholder="Enter action(s)"
                    value={action}
                    onChange={(e) => setAction(e.target.value)}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="field flex-column">
              <label htmlFor="suggestionOfPrevention">
                Suggestions for preventing a similar incident in the future?
              </label>
              <input
                type="text"
                name="suggestionOfPrevention"
                id="suggestionOfPrevention"
                placeholder="Enter suggestion(s)"
                value={suggestions}
                onChange={(e) => setSuggestions(e.target.value)}
              />
            </div>
          </div>
          <div className="step inputs-group grid">
            <div className="field flex-column">
              <label htmlFor="departmentAction">Report completed by</label>
              <input
                type="text"
                name="reportedby"
                id="reportedby"
                placeholder="Enter Name"
                value={reportedBy}
                onChange={(e) => setReportedBy(e.target.value)}
              />
            </div>

            <div className="field flex-column">
              <label htmlFor="reprtedtitle">Title</label>
              <input
                type="text"
                name="reprtedtitle"
                id="reprtedtitle"
                placeholder="Enter title/dept"
                value={reportedTitle}
                onChange={(e) => setreportedTitle(e.target.value)}
              />
            </div>

            <div className="field flex-column">
              <label htmlFor="datereported">Date report completed</label>
              <CustomDatePicker
                selectedDate={dateReported}
                setSelectedDate={setdateReported}
              />
            </div>

            <div className="field flex-column">
              <label htmlFor="timeReported">Time report completed</label>
              <CustomTimeInput setTime={setTimeReported} />

            </div>
          </div>
          <div className="field full inputs-group">
            <h3>Supporting documents</h3>
            <FilesList documents={uploadedFiles} />

            {uploadingDocuments ? (
              "Uploading ..."
            ) : (
              <input
                type="file"
                onChange={handleFileChange}
                name="files"
                id="files"
                multiple
              />
            )}
          </div>
        </form>
      }
    </div>
  );
};

export default ModifyWorkplaceIncident;
