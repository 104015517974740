import React, { useState } from "react";
import {
  ArrowRight01Icon,
  ArrowDown01Icon,
  MailSend02Icon,
  PencilEdit02Icon,
  DocumentValidationIcon,
  CommentAdd01Icon,
  CheckListIcon,
  CheckmarkCircle02Icon,
} from "hugeicons-react";
import SendToDepartmentForm from "../../../../components/incidents/forms/sendToDepartmentForm";
import { usePermission } from "../../../../contexts/permissionsContext";
import ReviewFrom from "../../../../components/incidents/forms/reviewFrom";
import MarkResolvedForm from "../../../../components/incidents/forms/markResolvedForm";
import { Link } from "react-router-dom";
import { CheckmarkIcon } from "react-hot-toast";
const IncidentDetailsHeader = ({
  data,
  incidentId,
  apiLink,
  incidentType,
  incidentSteps,
  currentStep,
  sendTo,
}) => {
  const permissions = usePermission();
  const [showActions, setShowActions] = useState(false);
  const [showSendToDepartmentForm, setShowSendToDepartmentForm] =
    useState(false);
  const [showReviewForm, setShowReviewForm] = useState(false);
  const [showMarkResolvedPopup, setShowMarkResolvedPopup] = useState(false);

  const toggleShowMarkResolvedPopup = () => {
    setShowMarkResolvedPopup(!showMarkResolvedPopup);
  };
  const toggleShowReviewForm = () => {
    setShowReviewForm(!showReviewForm);
  };

  const toggleActions = () => {
    setShowActions(!showActions);
  };
  const toggleShowSendToDepartmentForm = () => {
    setShowSendToDepartmentForm(!showSendToDepartmentForm);
  };
  return (
    <div className="incident-details-header">
      <div className="incident-steps-and-type">
        <div className="details">
          <h3>#{data.id}</h3>
          <div className="incident-type">
            <span>Incident details</span>{" "}
            <div className="type">
              {data.incident_type || "Not provided"}
            </div>
          </div>
        </div>

        <span>{data.report_facility?.name}</span>
        {
          data && data.is_resolved
            ? <div className="is-resolved button primary-button ">
              <CheckmarkCircle02Icon />
              <span>Incident is resolved</span>
            </div>
            :

            <div
              onClick={toggleActions}
              className={`actions ${showActions ? "show-actions" : ""}`}
            >

              <button type="button" className="primary-button">
                <span>Actions</span>
                <div className="icon">
                  {showActions ? (
                    <ArrowDown01Icon variant={"stroke"} />
                  ) : (
                    <ArrowRight01Icon variant={"stroke"} />
                  )}
                </div>
              </button>

              <div className="actions-popup">
                {permissions && permissions.includes("managers") ? (
                  <>
                    <div
                      onClick={toggleShowSendToDepartmentForm}
                      className="action"
                    >
                      <div className="icon">
                        <MailSend02Icon size={20} variant={"stroke"} />
                      </div>
                      <span>Send to department</span>
                    </div>
                    <div onClick={toggleShowMarkResolvedPopup} className="action">
                      <div className="icon">
                        <DocumentValidationIcon size={20} variant={"stroke"} />
                      </div>
                      <span>Mark Resolved</span>
                    </div>
                  </>
                ) : (
                  ""
                )}

                <Link to={"modify/"} className="action">
                  <div className="icon">
                    <PencilEdit02Icon size={20} variant={"stroke"} />
                  </div>
                  <span>Modify</span>
                </Link>

                <div onClick={toggleShowReviewForm} className="action">
                  <div className="icon">
                    <CommentAdd01Icon size={20} variant={"stroke"} />
                  </div>
                  <span>Add a comment</span>
                </div>
              </div>
            </div>
        }
      </div>

      <div className="incident-details-and-tabs"></div>
      {showMarkResolvedPopup ? (
        <MarkResolvedForm
          incidentId={incidentId}
          apiLink={apiLink}
          closeForm={toggleShowMarkResolvedPopup}
          isResolved={data.is_resolved}
        />
      ) : (
        ""
      )}
      {showReviewForm ? (
        <div className="incident-popup">
          <div className="popup-content">
            <h3>Add a comment</h3>
            <p>
              Your comments will be visible for anyone who can access this
              incident.
            </p>
            <ReviewFrom
              incidentId={incidentId}
              toggleReviewForm={toggleShowReviewForm}
              incidentName={apiLink}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {showSendToDepartmentForm ? (
        <SendToDepartmentForm
          closeForm={toggleShowSendToDepartmentForm}
          incidentId={incidentId}
          apiLink={apiLink}
          sendTo={sendTo}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default IncidentDetailsHeader;
