import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { API_URL } from "../../api";
import toast from "react-hot-toast";
import { CheckmarkSquare02Icon, CheckmarkSquare03Icon, Loading03Icon, SquareIcon } from 'hugeicons-react';

const EmailPasswordLoginForm = ({ toggleLoginWithEmailAndPassword, refresh }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [hasAccess, setHasAccess] = useState(null);

    const handleSubmit = async (e) => {

        const formData = {
            "username": username,
            "password": password,
        };
        try {
            setIsLoading(true)
            const response = await axios.post(
                `${API_URL}/accounts/token/email-password/`,
                formData,
            );
            const data = response.data;

            if (response.status === 200) {
                setIsLoading(false)
                console.log('Login successful:', data);
                localStorage.setItem('access', data.access);
                localStorage.setItem('refresh', data.refresh);
                localStorage.setItem("userData", JSON.stringify(data.user_info))
                if (refresh) {
                    window.location.reload();
                } else {
                    window.location.href = '/';
                }

            }
        } catch (error) {
            setIsLoading(false)
            console.error('Error:', error);
            toast.error(error.message)

        }

    };


    const handleForgetPassword = (e) => {
        e.preventDefault();
        console.log('Forgot Password clicked');

    };

    const handleRememberMe = () => {
        setRememberMe(!rememberMe);
    }
    return (
        <div className='login-email-password'>
            <form className='login' onSubmit={handleSubmit}>
                <div className="input-with-icon">
                    <FontAwesomeIcon className='icon' icon={faEnvelope} />
                    <input
                        type='text'
                        placeholder='Email'
                        name='email'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div className="input-with-icon">
                    <FontAwesomeIcon className='icon' icon={faLock} />
                    <input
                        name="password"
                        type='password'
                        placeholder='Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div className='remember-forget'>
                    <div onClick={handleRememberMe} className='remember'>
                        {rememberMe ? <CheckmarkSquare02Icon size={22} /> : <SquareIcon size={22} />}
                        <p>Remember me</p>
                    </div>
                    <a href='/forgot-password/'>Forgot Password?</a>
                </div>

                <button onClick={handleSubmit} type='button' className='primary-button'>
                    {
                        isLoading
                            ? <Loading03Icon size={20} className='loading-icon' />
                            :
                            'Login'
                    }
                </button>
            </form>
            {/* <p>Or</p>
            <button onClick={toggleLoginWithEmailAndPassword} className='button tertiary-button'>
                Login with company email
            </button> */}
        </div>
    );
};

export default EmailPasswordLoginForm;
