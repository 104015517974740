import React from "react";
import FormatDateTime from '../../../../components/general/formatDateTime'
import NamesInitials from "../../../../components/general/namesInitials";
import DateFormatter from "../dateFormatter";

const formatTime = (time) => {
  const [hours, minutes, seconds] = time.split(':');
  const date = new Date();
  date.setHours(parseInt(hours, 10));
  date.setMinutes(parseInt(minutes, 10));
  date.setSeconds(parseInt(seconds, 10));
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

const LostDetails = ({ data, incidentDetails }) => {
  console.log(data.incident_date)
  const { data: incidentData } = data;
  return (
    <div className="patient-name-type">
      <div className="date">
        <small>Date & Time reported</small>
        <h4>
          {<DateFormatter dateString={incidentData.date_reported} />}
          {/* {incidentData.time_reported}  */}
          {/* {incidentData.date_reported ? incidentData.date_reported : " "}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          {incidentData.time_reported ? formatTime(incidentData.time_reported) : "Not provided"} */}
        </h4>
      </div>
      {incidentDetails}
    </div>
  )
}

export default LostDetails