import React from "react";
import { useState } from "react";
import SideBar from "./sideBar";
import DashHeader from "./dashHeader";

import '../../assets/css/pages/dashboard/dash_container.css'


const DashboardContainer = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen)
    }
    return (
        <div className="dashboard-container">
            <SideBar isMobileMenuOpen={isMobileMenuOpen} />

            <div className="dashboard-content">
                <div className="dash-header">
                    <div onClick={toggleMobileMenu} className={isMobileMenuOpen ? "mobile-menu-button open" : "mobile-menu-button"}>
                        <div className="bar first"></div>
                        <div className="bar middle"></div>
                        <div className="bar last"></div>
                    </div>
                    <h4 className="page-title">Incident Tracking</h4>
                    <div className="search-input">
                        <i class="fa-solid fa-magnifying-glass"></i>
                        <input type="search" name="systemSearch" id="systemSearch" placeholder="Search the system" />
                    </div>
                    <div className="header-actions">
                        <button className="new-action-button primary-button">
                            <i className="fa-solid fa-plus"></i>
                            <span>Add new</span>
                        </button>

                        <div className="notification">
                            <div className="dot"></div>
                            <i className="fa-regular fa-bell"></i>
                        </div>

                        <div className="profile">
                            <img src={require("../../assets/img/branding/logo.png")} alt="" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default DashboardContainer