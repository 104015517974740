import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "../../assets/css/pages/auth/otp.css";

const OtpPage = () => {
  const [code1, setcode1] = useState("");
  const [code2, setcode2] = useState("");
  const [code3, setcode3] = useState("");
  const [code4, setcode4] = useState("");
  const [code5, setcode5] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [remainingTime, setRemainingTime] = useState(60);
  const [codeInputActive, setCodeInputActive] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (remainingTime <= 60) {
      setCodeInputActive(false);
    }
  }, [remainingTime]);

  const handlecode = () => {
    setIsLoading(true);
    console.log(code1);
    console.log(code2);
    console.log(code3);
    console.log(code4);
    console.log(code5);

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  return (
    <div className="otpPage">
      <Helmet>
        <title> Verification| Quality Control</title>
      </Helmet>
      <div className="container">
        <div class="verification-container">
          <b>Email Verification</b>
          <div class="row">
            <div>
              A 6-digit code has been sent to your email at{" "}
              <span>cy*******2023@outlook.com</span>
            </div>
            <a href="login.html">Change</a>
          </div>
          <form>
            <div class="inputs">
              <input
                onChange={(e) => setcode1(e.target.value)}
                name="code"
                class="code-input"
                value={code1}
                maxLength={1}
                required
              />

              <input
                onChange={(e) => setcode2(e.target.value)}
                name="code"
                class="code-input"
                value={code2}
                maxLength={1}
                required
              />

              <input
                onChange={(e) => setcode3(e.target.value)}
                name="code"
                class="code-input"
                value={code3}
                maxLength={1}
                required
              />
              <input
                onChange={(e) => setcode4(e.target.value)}
                name="code"
                class="code-input"
                value={code4}
                maxLength={1}
                required
              />
              <input
                onChange={(e) => setcode5(e.target.value)}
                name="code"
                class="code-input"
                value={code5}
                maxLength={1}
                required
              />
            </div>
            <button disabled={isLoading} onClick={handlecode} type="button">
              {isLoading ? "Logging in" : "Login"}
            </button>
            <div class="last">
              <div>
                Didn’t receive code,{" "}
                <span id="timer">
                  {remainingTime > 0 ? `0:${remainingTime}` : "Expired"}
                </span>{" "}
                secs remaining
              </div>
              <span
                id="resend"
                style={{ color: codeInputActive ? "#F87C47" : "#F87C4780" }}
              >
                Resend
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OtpPage;
