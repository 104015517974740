import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { stepOne, stepTwo } from "../validators/medicationErrorForm";
import { validateStep } from "../validators/generalIncidentForm";
import Grivance from "../../successMessage/grivance";
import api, { API_URL, cleanedData } from "../../../api";
import { useLocation, useHistory } from "react-router-dom";
import RichTexField from "./inputs/richTexField";
import FormCompleteMessage from "./formCompleteMessage";
import { useRef } from "react";
import postDocumentHistory from "./documentHistory/postDocumentHistory";
import CustomDatePicker from "./inputs/datePicker";
import FilesList from "../../documents/filesList";
import mediaAPI from "../../../mediaApi";
import CustomTimeInput from "./inputs/customTimeInput";

const GrievanceForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const currentStepRef = useRef(currentStep);

  const [isLoading, setIsLoading] = useState(false);
  const [incidentDate, setIncidentDate] = useState("");
  const [patientName, setPatientName] = useState("");
  const [medicalRecord, setMedicalRecord] = useState("");
  const [formInitiatedBy, setFormInitiatedBy] = useState("");
  const [formInitiatedByTitle, setFormInitiatedByTitle] = useState("");
  const [complaintBy, setComplaintBy] = useState("");
  const [patientRelationship, setPatientRelationship] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [complaintOrConcern, setComplaintOrConcern] = useState("");
  const [actionTaken, setActionTaken] = useState("");
  const [dateBirth, setdateBirth] = useState("");
  const [age, setAge] = useState("");
  const [reviewActionTaken, setReviewActionTaken] = useState("");
  const [review, setReview] = useState("");

  const [otherInput, setOtherInput] = useState("");
  const [feedbackDate, setFeedbackDate] = useState("");
  const [extensionLetterDate, setExtensionLetterDate] = useState("");
  const [responseLetterDate, setResponseLetterDate] = useState("");
  const [actionMeeting, setActionMeeting] = useState("");
  const [involvedPerson, setInvolvedPerson] = useState("");
  const [actionTelephone, setActionTelephone] = useState("");
  const [relationshipToPatient, setRelationshipToPatient] = useState("");
  const [dateClosed, setDateClosed] = useState("");
  const [extensionLetterFile, setExtensionLetterFile] = useState("");
  const [responseLetterFile, setResponseLetterFile] = useState("");
  const maxFileSize = 12 * 1024 * 1024;
  const [adversePatientOutcome, setAdversePatientOutcome] = useState(false);
  const [notifiedAdministrator, setNotifiedAdministrator] = useState(false);
  const [outcome, setOutcome] = useState("");
  const [nameOfAdministator, setNameOfAdministartor] = useState("");
  const [grivanceDate, setGrivanceDate] = useState("");
  const [grivanceTime, setGrivanceTime] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadingDocuments, setUploadingDocuments] = useState(false);
  const handleAdversePatientOutcome = () => {
    setAdversePatientOutcome((prev) => !prev);
  };

  const handleNotifiedAdministrator = () => {
    setNotifiedAdministrator((prev) => !prev);
  };

  useEffect(() => {
    currentStepRef.current = currentStep;
  }, [currentStep]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if Ctrl or Alt key is pressed
      if (event.key === "Enter") {
        event.preventDefault();
        if (currentStepRef.current < 3) {
          document.getElementById("continue-button").click();
        } else if (currentStepRef.current === 3) {
          document.getElementById("save-button").click();
        } else {
          return;
        }
      }

      if (event.ctrlKey || event.altKey) {
        switch (event.key) {
          case "s": // Ctrl + S
            event.preventDefault(); // Prevent default browser action
            if (currentStepRef.current < 3) {
              document.getElementById("continue-button").click();
            } else if (currentStepRef.current === 3) {
              document.getElementById("save-button").click();
            } else {
              return;
            }
            break;
          case "b":
            event.preventDefault();
            if (currentStepRef.current > 1 && currentStepRef.current <= 3) {
              document.getElementById("back-button").click();
            }
            console.log(currentStepRef.current);
            break;
          case "f": // Ctrl + F
            event.preventDefault(); // Prevent default browser action
            document.getElementById("name").focus();
            break;
          case "e": // Ctrl + E
            event.preventDefault(); // Prevent default browser action
            document.getElementById("email").focus();
            break;
          default:
            break;
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  async function postStepOne() {
    const data = {
      date: incidentDate,
      patient_name: patientName,
      medical_record_number: medicalRecord,
      form_initiated_by: formInitiatedBy,
      title: formInitiatedByTitle,
      complaint_made_by: complaintBy,
      relationship_to_patient: patientRelationship,
      phone_number: phoneNumber,
      source_of_information:
        selectedOption === "other"
          ? otherInput
          : selectedOption
            ? selectedOption
            : null,

      status: "Draft",
      age: age,
      date_of_birth: dateBirth,
    };

    try {
      const response = await api.post(
        `${API_URL}/incidents/grievance/new/`,
        cleanedData(data)
      );

      if (response.status === 200 || response.status === 201) {
        localStorage.setItem("grievance_id", response.data.grievance.id);
        postDocumentHistory(response.data.grievance.id, "added a new incident");
        toast.success("Data saved successfully");
        setCurrentStep(currentStep + 1);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (error.response) {
        toast.error(
          error.response.data.message ||
          error.response.data.error ||
          "Error while saving incident"
        );
      } else {
        toast.error("Unknown error while saving incident");
      }
    }
  }

  async function patchData(data) {
    try {
      const grievance_id = localStorage.getItem("grievance_id");
      const response = await api.patch(
        `${API_URL}/incidents/grievance/${grievance_id}/update/`,
        data
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Data saved successfully");
        setCurrentStep(currentStep + 1);
        setIsLoading(false);

        if (currentStep === 3) {
          postDocumentHistory(grievance_id, "added a new incident", "create");
        }
      }

      console.log(response.data);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      toast.error("Failed to post data");
      toast.error(error.message);
    }
  }

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    // setInputValue("");
  };
  const handleSaveChange = () => {
    if (currentStep === 3) {
      const isValid = validateStep({
        "Name of Administrator": nameOfAdministator,
        "Grivance Date": grivanceDate,
        "Grivance Time": grivanceTime,
      });

      if (isValid) {
        // postStepThree();
      }
      if (isValid) {
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      } else {
        return;
      }
    }
  };

  const handleNextStep = () => {
    let isValid = true;

    if (currentStep === 1) {
      if (selectedOption === "other") {
        isValid = validateStep({
          "incident date": incidentDate,
          "patient name": patientName,
          "date of birth": dateBirth,
          age: age,
          "medical record": medicalRecord,
          "form initiated by": formInitiatedBy,
          "complaint by": complaintBy,
          "patient relationship": patientRelationship,
          "phone number": phoneNumber,
          "form initiated by title": formInitiatedByTitle,
          "source of information input": otherInput,
        });
      } else {
        isValid = validateStep({
          "incident date": incidentDate,
          "patient name": patientName,
          "medical record": medicalRecord,
          "form initiated by": formInitiatedBy,
          "complaint by": complaintBy,
          "patient relationship": patientRelationship,
          "phone number": phoneNumber,
          "form initiated by title": formInitiatedByTitle,
          "source of information": selectedOption,
        });
      }
      if (isValid) {
        postStepOne();
      }

      // if (!selectedOption) {
      //   toast.error("Please select at least one source of information.");
      //   isValid = false;
      // }
    } else if (currentStep === 2) {
      isValid = validateStep({
        "complaint or concern": complaintOrConcern,
        "action taken": actionTaken,
      });

      if (adversePatientOutcome && !outcome.trim()) {
        toast.error("Please identify outcome for adverse patient outcome.");
        isValid = false;
      }

      if (isValid) {
        patchData({
          complaint_or_concern: complaintOrConcern,
          initial_corrective_actions: actionTaken,
          adverse_patient_outcome: adversePatientOutcome,
          outcome: outcome,
        });
      } else {
        return;
      }
    } else if (currentStep === 3) {
      if (notifiedAdministrator) {
        isValid = validateStep({
          "name of administrator": nameOfAdministator,
          "grivance date": grivanceDate,
          "grivance time": grivanceTime,
        });

        if (isValid) {
          patchData({
            administrator_notified: nameOfAdministator,
            notification_date: grivanceDate,
            notification_time: grivanceTime,
            status: "Completed",
          });
        }
      } else {
        patchData({
          administrator_notified: "N/A",
          notification_date: null,
          notification_time: null,
          status: "Completed",
        });
      }
    } else if (currentStep === 4) {
      isValid = validateStep({
        "feedback date": feedbackDate,
        "Involved Person": involvedPerson,
        "relationship to patient": relationshipToPatient,
      });
      if (!actionMeeting && !actionTelephone) {
        toast.error("Please select at least one option.");
        isValid = false;
      }
    }

    if (!isValid) {
      return;
    }

    setIsLoading(true);
  };

  const handlePreviousStep = () => {
    currentStep > 1 ? setCurrentStep(currentStep - 1) : setCurrentStep(1);
  };

  const handleFileChange = async (event) => {
    const formData = new FormData();
    const files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    try {
      const grievance_id = localStorage.getItem("grievance_id");
      setUploadingDocuments(true);
      console.log([...formData]);

      const response = await mediaAPI.post(
        `/incidents/grievance/${grievance_id}/documents/new/`,
        formData
      );

      if (response.status === 200) {
        console.log(response.data.files);
        setUploadingDocuments(false);
        toast.success("Files uploaded successfully");
        setUploadedFiles(response.data.files);
      }
    } catch (error) {
      toast.error("Error uploading documents");
      setUploadingDocuments(false);
      console.log(error);
    }
  };
  return (
    <div className="forms-container">
      <h2>Patient/Patient Representative Grievance Form</h2>
      {currentStep < 4 ? (
        <div className="form-steps">
          <div className={currentStep === 1 ? "step current-step" : "step"}>
            <div className="icon">
              <i className="fa-solid fa-circle-check"></i>
            </div>
            <div className="name">
              <p className="step-name">Step 1/3</p>
            </div>
          </div>
          <div className="divider"></div>
          <div className={currentStep === 2 ? "step current-step" : "step"}>
            <div className="icon">
              <i className="fa-solid fa-circle-check"></i>
            </div>
            <div className="name">
              <p className="step-name">Step 2/3</p>
            </div>
          </div>
          <div className="divider"></div>
          <div className={currentStep === 3 ? "step current-step" : "step"}>
            <div className="icon">
              <i className="fa-solid fa-circle-check"></i>
            </div>
            <div className="name">
              <p className="step-name">Step 3/3</p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <form className="newIncidentForm">
        {currentStep === 1 ? (
          <div className="step">
            <div className="half">
              <div className="field">
                <label htmlFor="incidentDate">Date</label>
                <input
                  type="date"
                  name="incidentDate"
                  id="incidentDate"
                  onChange={(e) => setIncidentDate(e.target.value)}
                  value={incidentDate}
                />
              </div>
              <div className="field">
                <label htmlFor="patientName">Patient name</label>
                <input
                  onChange={(e) => setPatientName(e.target.value)}
                  value={patientName}
                  type="text"
                  name="patientName"
                  id="patientName"
                  placeholder="Enter patient name"
                />
              </div>
            </div>

            <div className="half">
              <div className="date-of-injury field">
                <label htmlFor="dateBirth">Date of birth</label>
                <CustomDatePicker
                  selectedDate={dateBirth}
                  setSelectedDate={setdateBirth}
                />
              </div>

              <div className="field job-title">
                <label htmlFor="age">Age</label>
                <input
                  onChange={(e) => setAge(e.target.value)}
                  value={age}
                  type="number"
                  name="age"
                  id="age"
                  placeholder="Enter age"
                />
              </div>
            </div>

            <div className="field">
              <label htmlFor="medicalRecord">Medical record</label>
              <input
                onChange={(e) => setMedicalRecord(e.target.value)}
                value={medicalRecord}
                type="text"
                name="medicalRecord"
                id="medicalRecord"
                placeholder="Enter medical name"
              />
            </div>

            <div className="half">
              <div className="field">
                <label htmlFor="formInitiatedBy">Form initiated by</label>
                <input
                  onChange={(e) => setFormInitiatedBy(e.target.value)}
                  value={formInitiatedBy}
                  type="text"
                  name="formInitiatedBy"
                  id="formInitiatedBy"
                  placeholder="Enter name"
                />
              </div>
              <div className="field">
                <label htmlFor="formInitiatedByTitle">Title</label>
                <input
                  onChange={(e) => setFormInitiatedByTitle(e.target.value)}
                  value={formInitiatedByTitle}
                  type="text"
                  name="formInitiatedByTitle"
                  id="formInitiatedByTitle"
                  placeholder="Enter title"
                />
              </div>
            </div>
            <div className="half">
              <div className="field">
                <label htmlFor="complaintBy">Who made complaint?</label>
                <input
                  onChange={(e) => setComplaintBy(e.target.value)}
                  value={complaintBy}
                  type="text"
                  name="complaintBy"
                  id="complaintBy"
                  placeholder="Enter name"
                />
              </div>
              <div className="field">
                <label htmlFor="patientRelationship">
                  Relationship to patient
                </label>
                <input
                  onChange={(e) => setPatientRelationship(e.target.value)}
                  value={patientRelationship}
                  type="text"
                  name="patientRelationship"
                  id="patientRelationship"
                  placeholder="Enter   relationship"
                />
              </div>
            </div>

            <div className="field">
              <label htmlFor="phoneNumber">Phone number</label>
              <input
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={phoneNumber}
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                placeholder=" Enter phone number"
              />
            </div>

            <div className="field">
              <label htmlFor="phoneNumber">Source of information</label>
              <div className="half">
                <div className="check-box">
                  <input
                    type="radio"
                    name="source_of_information"
                    id="survey"
                    value="survey"
                    onChange={handleOptionChange}
                    checked={selectedOption === "survey"}
                  />
                  <label htmlFor="survey">Patient satisfaction survey</label>
                </div>

                <div className="check-box">
                  <input
                    type="radio"
                    name="source_of_information"
                    id="letter"
                    value="letter"
                    onChange={handleOptionChange}
                    checked={selectedOption === "letter"}
                  />
                  <label htmlFor="letter">Letter</label>
                </div>
                <div className="check-box">
                  <input
                    type="radio"
                    name="source_of_information"
                    value="leadershipRounds"
                    id="leadershipRounds"
                    onChange={handleOptionChange}
                    checked={selectedOption === "leadershipRounds"}
                  />
                  <label htmlFor="leadershipRounds">Leadership Rounds</label>
                </div>
                <div className="check-box">
                  <input
                    type="radio"
                    name="source_of_information"
                    id="verbalReport"
                    value="verbalReport"
                    onChange={handleOptionChange}
                    checked={selectedOption === "verbalReport"}
                  />
                  <label htmlFor="verbalReport">
                    Verbal Report form patient and /or visitor
                  </label>
                </div>
                <div className="check-box">
                  <input
                    type="radio"
                    name="source_of_information"
                    id="other"
                    value="other"
                    onChange={handleOptionChange}
                    checked={selectedOption === "other"}
                  />
                  <label htmlFor="other">other</label>
                </div>
                <div>
                  {selectedOption === "other" && (
                    <input
                      type="text"
                      value={otherInput}
                      onChange={(e) => setOtherInput(e.target.value)}
                      placeholder="Enter Source Of Infromation"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : currentStep === 2 ? (
          <div className="step">
            <div className="field">
              <label htmlFor="complaintOrConcern">Complaint or Concern:</label>
              <RichTexField
                value={complaintOrConcern}
                onEditorChange={setComplaintOrConcern}
              />
              {/* <textarea
                onChange={(e) => setComplaintOrConcern(e.target.value)}
                value={complaintOrConcern}
                name="complaintOrConcern"
                id="complaintOrConcern"
                rows="6"
                placeholder="Enter description"
              ></textarea> */}
            </div>
            <div className="field">
              <label htmlFor="actionTaken">
                What were the initial corrective actions taken to resolve the
                complaint?
              </label>
              <RichTexField
                value={actionTaken}
                onEditorChange={setActionTaken}
              />
              {/* <textarea
                onChange={(e) => setActionTaken(e.target.value)}
                value={actionTaken}
                name="actionTaken"
                id="actionTaken"
                rows="6"
                placeholder="Enter description"
              ></textarea> */}
            </div>

            <div className="check-box">
              <input
                onClick={handleAdversePatientOutcome}
                checked={adversePatientOutcome}
                type="checkbox"
                name="adversePatientOutcome"
                id="adversePatientOutcome"
              />
              <label htmlFor="adversePatientOutcome">
                Check if there was an adverse patient outcome related to this
                complaint.
              </label>
            </div>
            {adversePatientOutcome ? (
              <div className="field">
                <label htmlFor="outcome">Identify outcome</label>

                <RichTexField value={outcome} onEditorChange={setOutcome} />
                {/* <textarea
                  name="outcome"
                  id="outcome"
                  rows="6"
                  placeholder="Enter outcome"
                  value={outcome}
                  onChange={(e) => setOutcome(e.target.value)}
                ></textarea> */}
              </div>
            ) : null}
          </div>
        ) : currentStep === 3 ? (
          <div className="step">
            <div className="check-box">
              <input
                onClick={handleNotifiedAdministrator}
                checked={notifiedAdministrator}
                type="checkbox"
                name="notifiedAdministrator"
                id="notifiedAdministrator"
              />
              <label htmlFor="notifiedAdministrator">
                Check if administrator on call or designee was notified
              </label>
            </div>
            {notifiedAdministrator && (
              <>
                <div className="field">
                  <label htmlFor="NameOfAdministartor">
                    Name of administrator on call or designee notified:
                  </label>
                  <input
                    type="text"
                    name="NameOfAdministator"
                    id="NameOfAdministrator"
                    onChange={(e) => setNameOfAdministartor(e.target.value)}
                    value={nameOfAdministator}
                    placeholder="Name of administrator"
                  />
                </div>
                <div className="half">
                  <div className="field">
                    <label htmlFor="grivanceDate">Date</label>
                    <input
                      onChange={(e) => setGrivanceDate(e.target.value)}
                      value={grivanceDate}
                      type="date"
                      name="grivanceDate"
                      id="grivanceDate"
                    />
                  </div>

                  <div className="field">
                    <label htmlFor="grivanceTime">Time</label>
                    <CustomTimeInput setTime={setGrivanceTime} />
                  </div>
                </div>
              </>
            )}

            <div className="field full">
              <h3>Attachments</h3>
              <FilesList documents={uploadedFiles} />

              {uploadingDocuments ? (
                "Uploading ..."
              ) : (
                <input
                  type="file"
                  onChange={handleFileChange}
                  name="files"
                  id="files"
                  multiple
                />
              )}
            </div>
          </div>
        ) : currentStep === 4 ? (
          <FormCompleteMessage />
        ) : (
          ""
        )}
      </form>

      <div className="buttons">
        {currentStep > 1 && currentStep < 4 ? (
          <button onClick={handlePreviousStep} className="secondary-button">
            <i className="fa-solid fa-arrow-left"></i>
            <span>back</span>
          </button>
        ) : (
          ""
        )}

        {currentStep < 3 ? (
          <button
            onClick={handleNextStep}
            id="continue-button"
            className="primary-button"
          >
            <span>{isLoading ? "Processing..." : "Continue"}</span>
            <i className="fa-solid fa-arrow-right"></i>
          </button>
        ) : currentStep === 3 ? (
          <button
            onClick={handleNextStep}
            id="save-button"
            className="primary-button"
          >
            <span>{isLoading ? "Processing..." : "Save"}</span>
            <i className="fa-solid fa-arrow-right"></i>
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default GrievanceForm;
