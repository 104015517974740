import React, { useState, useEffect } from "react";
import { useRef } from "react";
import toast from "react-hot-toast";
import { validateStep } from "../validators/generalIncidentForm";
import api, { API_URL, cleanedData } from "../../../api";
// import employee from "../../successMessage/employeecomplete";
import CustomDatePicker from "./inputs/datePicker";
import CustomSelectInput from "./inputs/customSelect";
import RichTexField from "./inputs/richTexField";
import FormCompleteMessage from "./formCompleteMessage";
import postDocumentHistory from "./documentHistory/postDocumentHistory";
import { Cancel01Icon } from "hugeicons-react";
import CustomTimeInput from "./inputs/customTimeInput";

const EmployeeIncidentForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [statusType, setStatusType] = useState("Select Status");
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [dateOfInjury, setDateOfInjury] = useState("");
  const [dateSeenDoctor, setDateSeenDoctor] = useState("");
  const [timeSeenDoctor, setTimeSeenDoctor] = useState("");
  const [timeOfInjury, setTimeOfInjury] = useState("");
  const [supervisorName, setSupervisorName] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [doctorPhone, setDoctorPhone] = useState("");
  const [whereItHappened, setWhereItHappened] = useState("");
  const [doingWhat, setDoingWhat] = useState("");
  const [whatLedTo, setWhatLedTo] = useState("");
  const [whenInjured, setWhenInjured] = useState("");
  const [DoneToPrevent, setDoneToPrevent] = useState("");
  const [beingInjured, setBeingInjured] = useState("");
  const [seenDoctor, setSeenDoctor] = useState(false);
  const [toldSupervisor, setToldSupervisor] = useState(false);
  const [injuredBody, setInjuredBody] = useState(false);
  const [reportId, setReportID] = useState("");
  const [witnesses, setWitnesses] = useState([]);
  const [newWitness, setNewWitness] = useState("");
  const [success, setSuccess] = useState("false");
  const [incidentDate, setIncidentDate] = useState("");
  const [dateBirth, setdateBirth] = useState("");
  const [age, setAge] = useState("");
  const currentStepRef = useRef(currentStep);

  useEffect(() => {
    currentStepRef.current = currentStep;
  }, [currentStep]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if Ctrl or Alt key is pressed
      if (event.key === "Enter") {
        event.preventDefault();
        if (currentStepRef.current < 5) {
          document.getElementById("continue-button").click();
        } else if (currentStepRef.current === 5) {
          document.getElementById("save-button").click();
        } else {
          return;
        }
      }

      if (event.ctrlKey || event.altKey) {
        switch (event.key) {
          case "s": // Ctrl + S
            event.preventDefault(); // Prevent default browser action
            if (currentStepRef.current < 5) {
              document.getElementById("continue-button").click();
            } else if (currentStepRef.current === 5) {
              document.getElementById("save-button").click();
            } else {
              return;
            }
            break;
          case "b":
            event.preventDefault();
            if (currentStepRef.current > 1 && currentStepRef.current <= 5) {
              document.getElementById("back-button").click();
            }
            console.log(currentStepRef.current);
            break;
          case "f": // Ctrl + F
            event.preventDefault(); // Prevent default browser action
            document.getElementById("name").focus();
            break;
          case "e": // Ctrl + E
            event.preventDefault(); // Prevent default browser action
            document.getElementById("email").focus();
            break;
          default:
            break;
        }
      }
    };

    // Add event listener when component mounts
    document.addEventListener("keydown", handleKeyDown);

    // Clean up event listener when component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const handleAddWitness = () => {
    setWitnesses((prevWitnesses) => {
      if (!prevWitnesses.includes(newWitness)) {
        return [...prevWitnesses, newWitness];
      }
      return prevWitnesses;
    });
    setNewWitness("");
  };

  const handleRemoveWitness = (nameToRemove) => {
    const newWitnesses = witnesses.filter(
      (witness) => witness !== nameToRemove
    );
    setWitnesses(newWitnesses);
  };

  const handleStepOneSubmit = async () => {
    try {
      const witnessesList = witnesses.map((name) => ({ witness_name: name }));

      const res = await api.post(
        `${API_URL}/incidents/employee_incident/new/employee/`,
        {
          incident_status: statusType,
          name: name,
          job_title: jobTitle,
          age: age,
          date_of_birth: dateBirth,
          supervisor: supervisorName,
          date_of_injury_or_near_miss: dateOfInjury,
          time_of_injury_or_near_miss: timeOfInjury,
          witnesses: witnessesList,
          status: "Draft",
        }
      );

      if (res.status === 200 || res.status === 201) {
        setCurrentStep(currentStep + 1);
        toast.success("Data posted successfully");
        localStorage.setItem("employeeId", res.data.id);
        console.log(res.data);
        setReportID(res.data.id);
      }
    } catch (error) {
      console.error("Error submitting step 1: ", error);
      return;
    }
  };

  const handleStepThreeSubmit = async () => {
    try {
      const res = await api.put(
        `${API_URL}/incidents/employee_incident/update/employee/incident_description/`,
        {
          id: reportId,
          location: whereItHappened,
          activity_at_time_of_incident: doingWhat,
          incident_description: whatLedTo,
          preventive_measures: DoneToPrevent,
        }
      );

      if (res.status === 200 || res.status === 201) {
        setCurrentStep(currentStep + 1);
        toast.success("Data posted successfully");
      }
    } catch (error) {
      console.error("Error submitting step 3: ", error);
      return;
    }
  };

  const handleToldSupervisor = () => {
    setToldSupervisor(!toldSupervisor);
  };

  const handleSaveChange = async () => {
    if (currentStep === 4) {
      let isValid = validateStep({
        "Being injured": beingInjured,
      });

      if (
        seenDoctor &&
        !doctorName &&
        !doctorPhone &&
        !dateSeenDoctor &&
        !timeSeenDoctor
      ) {
        toast.error("Please fill all required fields for Doctor information");
        isValid = false;
        return;
      }
      if (injuredBody && !whenInjured) {
        toast.error("Please fill in when the injury occured");
        isValid = false;
        return;
      }

      if (isValid) {
        console.log(whenInjured);
        const data = {
          id: reportId,
          body_parts_injured: beingInjured,
          doctor_consulted: seenDoctor,
          doctor_consulted_dated: seenDoctor ? dateSeenDoctor : null,
          doctor_consulted_time: seenDoctor ? timeSeenDoctor : null,
          doctor_name: doctorName || " ",
          doctor_address: doctorPhone || " ",
          doctor_phone_number: doctorPhone || " ",
          previous_injury: injuredBody,
          previous_injury_date: whenInjured,
          status: "Completed",
        };
        try {
          const res = await api.put(
            `${API_URL}/incidents/employee_incident/update/employee/final_report/`,
            cleanedData(data)
          );
          console.log(dateSeenDoctor);
          console.log(timeSeenDoctor);
          console.log(res.data);
          setIsLoading(true);

          if (res.status === 200) {
            setCurrentStep(currentStep + 1);
            toast.success("Data posted successfully");
            setIsLoading(false);

            postDocumentHistory(reportId, "added a new incident", "create");
          }
        } catch (error) {
          console.log(dateSeenDoctor);
          console.log(timeSeenDoctor);
          console.log(whenInjured);
          console.error("Error submitting step 4: ", error);
          return;
        }
        setSuccess(true);
      } else {
        return;
      }
    }
  };

  const handleSeenDoctor = () => {
    setSeenDoctor(!seenDoctor);
  };

  const handleInjuredBody = () => {
    setInjuredBody(!injuredBody);
  };
  const handleNextStep = () => {
    if (currentStep === 1) {
      setCurrentStep(2);
    } else if (currentStep === 2) {
      let isValid = validateStep({
        "Status Type": statusType,
        Name: name,
        "Job Title": jobTitle,
        "Date of birth": dateBirth,
        Age: age,
        "Date of Injury": dateOfInjury,
        "Time of Injury": timeOfInjury,
      });

      // const areWitnessesValid = witnesses.every(
      //   (witness) => witness.trim() !== ""
      // );
      // if (!areWitnessesValid) {
      //   toast.error("Please provide names for all witnesses.");
      //   isValid = false;
      // }

      if (toldSupervisor && !supervisorName.trim()) {
        toast.error(
          "Please provide the supervisor's name if you have informed them about the injury/near miss."
        );
        isValid = false;
      }

      if (isValid) {
        console.log(witnesses);
        setIsLoading(true);
        try {
          handleStepOneSubmit();
        } catch (err) {
          console.error(err);
        }
      }
    } else if (currentStep === 3) {
      const isValid = validateStep({
        "What led to": whatLedTo,
        "Done to prevent": DoneToPrevent,
        "Where it happened": whereItHappened,
        "What were you doing": doingWhat,
      });
      if (isValid) {
        setIsLoading(true);
        try {
          handleStepThreeSubmit();
        } catch (err) {
          console.error(err);
        }
      }
    }
  };

  const handlePreviousStep = () => {
    currentStep > 1 ? setCurrentStep(currentStep - 1) : setCurrentStep(1);
  };
  return (
    <div className="forms-container">
      <h2>Staff Incident</h2>

      <div className="form-steps">
        <div className={currentStep === 1 ? "step current-step" : "step"}>
          <div className="icon">
            <i className="fa-solid fa-circle-check"></i>
          </div>
          <div className="name">
            <p className="step-name">Step 1/4</p>
            <p className="step-details">Incident Info</p>
          </div>
        </div>
        <div className="divider"></div>
        <div className={currentStep === 2 ? "step current-step" : "step"}>
          <div className="icon">
            <i className="fa-solid fa-circle-check"></i>
          </div>
          <div className="name">
            <p className="step-name">Step 2/4</p>
            <p className="step-details">Location & status</p>
          </div>
        </div>
        <div className="divider"></div>
        <div className={currentStep === 3 ? "step current-step" : "step"}>
          <div className="icon">
            <i className="fa-solid fa-circle-check"></i>
          </div>
          <div className="name">
            <p className="step-name">Step 3/4</p>
            <p className="step-details">Incident type</p>
          </div>
        </div>
        <div className="divider"></div>
        <div className={currentStep === 4 ? "step current-step" : "step"}>
          <div className="icon">
            <i className="fa-solid fa-circle-check"></i>
          </div>
          <div className="name">
            <p className="step-name">Step 4/4</p>
          </div>
        </div>
      </div>

      <form className="newIncidentForm">
        {currentStep === 1 ? (
          <div className="step">
            <div className="form-notification">
              <div className="info-icon-main-container">
                <div className="info-container">
                  <div className="info-icon">
                    <i className="fa-solid fa-info"></i>
                  </div>
                </div>
              </div>
              <p>
                Staff shall use this form to report all work-related injuries,
                illnesses, or “near miss” events (which could have caused an
                injury or illness) – no matter how minor. This helps us to
                identify and correct hazards before they cause serious injuries.
                This form shall be completed by employees as soon as possible
              </p>
            </div>
          </div>
        ) : currentStep === 2 ? (
          <div className="step">
            <h3>I am reporting a work related</h3>
            <div className="field step-2-status">
              <label htmlFor="incidentLocation">Status</label>
              <CustomSelectInput
                options={["Injury", "Illness", "Near miss"]}
                placeholder={"status"}
                selected={statusType}
                setSelected={setStatusType}
              />
            </div>
            <div className="half">
              <div className="field name">
                <label htmlFor="employeeName">Name</label>
                <input
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  type="text"
                  name="employeeName"
                  id="employeeName"
                  placeholder="Enter name"
                />
              </div>

              <div className="field job-title">
                <label htmlFor="jobTitle">Job Title</label>
                <input
                  onChange={(e) => setJobTitle(e.target.value)}
                  value={jobTitle}
                  type="text"
                  name="jobTitle"
                  id="jobTitle"
                  placeholder="Enter job title"
                />
              </div>
            </div>

            <div className="half">
              <div className="date-of-injury field">
                <label htmlFor="dateBirth">Date of birth</label>
                <CustomDatePicker
                  selectedDate={dateBirth}
                  setSelectedDate={setdateBirth}
                />
              </div>

              <div className="field job-title">
                <label htmlFor="age">Age</label>
                <input
                  onChange={(e) => setAge(e.target.value)}
                  value={age}
                  type="text"
                  name="age"
                  id="age"
                  placeholder="Enter age"
                />
              </div>
            </div>

            <div className="check-box">
              <div className="check-box">
                <input
                  onChange={handleToldSupervisor}
                  checked={toldSupervisor}
                  type="checkbox"
                  name="toldSupervisor"
                  id="toldSupervisor"
                />
                <label htmlFor="toldSupervisor">
                  Check if you have told your supervisor about this injury/near
                  miss.
                </label>
              </div>
            </div>
            {toldSupervisor && (
              <div className="supervisor field">
                <label htmlFor="supervisorName">Supervisor</label>
                <input
                  onChange={(e) => setSupervisorName(e.target.value)}
                  value={supervisorName}
                  type="text"
                  name="supervisorName"
                  id="supervisorName"
                  placeholder="Enter supervisor name"
                />
              </div>
            )}

            <div className="half">
              <div className="date-of-injury field">
                <label htmlFor="dateOfInjury">Date Of Injury/Near Miss</label>
                <CustomDatePicker
                  selectedDate={dateOfInjury}
                  setSelectedDate={setDateOfInjury}
                />
              </div>

              <div className="time-of-injury field">
                <label htmlFor="timeOfInjury">Time Of Injury/Near Miss</label>
                <CustomTimeInput setTime={setTimeOfInjury} />

              </div>
            </div>
            <div className="witness-list">
              {witnesses.map((witness, index) => (
                <div className="witness field" key={index}>
                  <span>{witness}</span>
                  <Cancel01Icon size={18} onClick={() => handleRemoveWitness(witness)} />
                </div>
              ))}
            </div>
            <div className="parties">
              Witnesses:
              <br />
              To add a witness, type the name and click add witness button
              <input
                onChange={(e) => setNewWitness(e.target.value)}
                value={newWitness}
                type="text"
                placeholder="Enter witness name"
              />
              <button
                className="new-party"
                type="button"
                onClick={() => handleAddWitness()}
              >
                <i className="fa-solid fa-plus"></i>
                Add Witnesses
              </button>
            </div>
          </div>
        ) : currentStep === 3 ? (
          <div className="step">
            <div className="where-it-happened field">
              <label htmlFor="whereItHappened">
                Where, exactly, did it happen?
              </label>
              <input
                onChange={(e) => setWhereItHappened(e.target.value)}
                value={whereItHappened}
                type="text"
                name="whereItHappened"
                id="whereItHappened"
                placeholder="Enter where did it happen"
              />
            </div>

            <div className="doing-what field">
              <label htmlFor="doingWhat">
                What were you doing at the time?
              </label>
              <RichTexField value={doingWhat} onEditorChange={setDoingWhat} />
            </div>
            <div className="what-led-to field">
              <label htmlFor="whatLedTo">
                Describe step by step what led up to the injury/near miss.
              </label>
              <RichTexField value={whatLedTo} onEditorChange={setWhatLedTo} />
            </div>
            <div className="done-to-prevent field">
              <label htmlFor="doneToPrevent">
                What could have been done to prevent this injury/near miss?
              </label>
              <RichTexField
                value={DoneToPrevent}
                onEditorChange={setDoneToPrevent}
              />
            </div>
          </div>
        ) : currentStep === 4 ? (
          <div className="step">
            <div className="being-injured field">
              <label htmlFor="beingInjured">
                What parts of your body were injured? If a near miss, how could
                you have been hurt?
              </label>
              <RichTexField
                value={beingInjured}
                onEditorChange={setBeingInjured}
              />
            </div>
            <div className="check-box">
              <input
                onChange={handleSeenDoctor}
                checked={seenDoctor}
                type="checkbox"
                name="seenDoctor"
                id="seenDoctor"
              />
              <label htmlFor="seenDoctor">
                Check this if you have seen a doctor about this injury/illness?
              </label>
            </div>

            {seenDoctor && (
              <>
                {" "}
                <div className="half">
                  <div className="field doctor-name">
                    <label htmlFor="doctorName">
                      If yes, whom did you see?
                    </label>
                    <input
                      onChange={(e) => setDoctorName(e.target.value)}
                      value={doctorName}
                      type="text"
                      name="doctorName"
                      id="doctorName"
                      placeholder="Enter doctor's name"
                    />
                  </div>

                  <div className="field doctor-phone">
                    <label htmlFor="doctorPhone">Doctor's phone number:</label>
                    <input
                      onChange={(e) => setDoctorPhone(e.target.value)}
                      value={doctorPhone}
                      type="tel"
                      name="doctorPhone"
                      id="doctorPhone"
                      placeholder="Enter doctor's phone number"
                    />
                  </div>
                </div>
                <div className="half">
                  <div className="time-seen-doctor field">
                    <label htmlFor="dateSeenDoctor">Date</label>
                    <CustomDatePicker
                      selectedDate={dateSeenDoctor}
                      setSelectedDate={setDateSeenDoctor}
                    />
                  </div>

                  <div className="time-seen-doctor field">
                    <label htmlFor="timeSeenDoctor">Time</label>
                    <CustomTimeInput setTime={setTimeSeenDoctor} />
                  </div>
                </div>
              </>
            )}

            <div className="check-box">
              <input
                onChange={handleInjuredBody}
                checked={injuredBody}
                type="checkbox"
                name="injuredBody"
                id="injuredBody"
              />
              <label htmlFor="injuredBody">
                Check if this part of your body has been injured before.
              </label>
            </div>

            {injuredBody && (
              <div className="when-injured field">
                <label htmlFor="whenInjured">When</label>
                <CustomDatePicker
                  selectedDate={whenInjured}
                  setSelectedDate={setWhenInjured}
                />
              </div>
            )}
          </div>
        ) : currentStep === 5 ? (
          <FormCompleteMessage />
        ) : (
          <h1>Something ain't right</h1>
        )}
      </form>

      <div className="buttons">
        {currentStep > 1 && currentStep < 5 ? (
          <button
            onClick={handlePreviousStep}
            id="back-button"
            className="secondary-button"
          >
            <i className="fa-solid fa-arrow-left"></i>
            <span>Back</span>
          </button>
        ) : (
          ""
        )}

        {currentStep > 3 && currentStep < 5 ? (
          <button
            className="primary-button"
            id="save-button"
            onClick={handleSaveChange}
          >
            <span>Save Incident</span>
            <i className="fa-solid fa-arrow-right"></i>
          </button>
        ) : currentStep < 5 ? (
          <button
            onClick={handleNextStep}
            id="continue-button"
            className="primary-button"
          >
            <span>{isLoading ? "Continue..." : "Continue"}</span>
            <i className="fa-solid fa-arrow-right"></i>
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default EmployeeIncidentForm;
