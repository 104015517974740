import React, { useEffect, useState } from "react";
import { usePermission } from "../../contexts/permissionsContext.jsx";
import SideBar from "../../components/dashaboard/sideBar";
import GrievanceTab from "../../components/incidents/incidentaTab";
import PopUp from "../../components/general/popup";

import "../../assets/css/pages/incidents/incidents_page.css";
import EmployeeTab from "../../components/incidents/employeeTab";
import MedicalTab from "../../components/incidents/mediacalTab";
import InvestigationTab from "../../components/incidents/investigationTab";
import WorkPlaceTab from "../../components/incidents/workplaceTab";
import IncidentsTab from "../../components/incidents/incidentaTab";
import GrievanceInvestigationTab from "../../components/incidents/grivanceInvestigationTab.jsx";

// forms
import FormChoicesPopup from "../../components/incidents/forms/incidentFormsChoices.jsx";
import GeneralIncidentForm from "../../components/incidents/forms/generalIncidentForm";
import LostAndFoundForm from "../../components/incidents/forms/lostAndFound";
import EmployeeIncidentForm from "../../components/incidents/forms/employeeIncidentForm.jsx";
import MedicationErrorForm from "../../components/incidents/forms/medicationError.jsx";
import GrievanceForm from "../../components/incidents/forms/grievanceForm.jsx";
import DrugReactionForm from "../../components/incidents/forms/drugReactionForm.jsx";
import WorkplaceViolenceIncidentForm from "../../components/incidents/forms/workPlaceViolenceForm.jsx";
import HealthIncidentInvestigationForm from "../../components/incidents/forms/healthIncidentForm.jsx";
import VerbalComplaintForm from "../../components/incidents/forms/verbalComplaintForm.jsx";
import NewContentFullContainer from "../../components/general/newContentFullContainer.jsx";
import GrievanceInvestigationForm from "../../components/incidents/forms/grivanceInvestigationForm.jsx";
import { Link } from "react-router-dom";
import { DepartementIcon, House01Icon, Logout01Icon, Note01Icon } from "hugeicons-react";
import { useAuthentication } from "../../contexts/authContext.jsx";

const DashBoardContainer = ({ content, breadCrumbs }) => {
  const [path, setPath] = useState()
  const permissions = usePermission();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isFormChoicesOpen, setIsFormCHoicesOpen] = useState(false);
  const [SelectedForm, setSelectedForm] = useState(null);
  const [showProfileCard, setShowProfileCard] = useState(false)

  const handleShowProfileCard = () => {
    setShowProfileCard(!showProfileCard);
  }

  const [currentTab, setCurrentTab] = useState("incidents");

  const toggleTabs = (tab) => { };

  const toggleFormChoicesOpen = () => {
    setIsFormCHoicesOpen(!isFormChoicesOpen);
  };
  const tootlePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    // get current link line Overview/User/Profile
    // remove the last part "Profile", then add it to the currrent tab
    const pathParts = window.location.pathname.split("/");
    const tab = pathParts[pathParts.length - 1];
    setCurrentTab(tab);
    setPath(window.location.pathname);

  })

  return (
    <div className="dashboard-container">
      <SideBar isMobileMenuOpen={isMobileMenuOpen} />

      <div className="dashboard-content">
        <div className="dash-header">
          <div
            onClick={toggleMobileMenu}
            className={
              isMobileMenuOpen
                ? "mobile-menu-button open"
                : "mobile-menu-button"
            }
          >
            <div className="bar first"></div>
            <div className="bar middle"></div>
            <div className="bar last"></div>
          </div>
          <h4 className="page-title">Incident Tracking</h4>
          <div className="search-input">
            <i className="fa-solid fa-magnifying-glass"></i>
            <input
              type="search"
              name="systemSearch"
              id="systemSearch"
              placeholder="Search the system"
            />
          </div>
          <div className="header-actions">
            <button
              onClick={toggleFormChoicesOpen}
              className="new-action-button primary-button"
            >
              <i className="fa-solid fa-plus"></i>
              <span>Add new</span>

              {isFormChoicesOpen ? (
                <FormChoicesPopup
                  tootlePopup={tootlePopup}
                  setSelectedForm={setSelectedForm}
                />
              ) : (
                ""
              )}
            </button>

            {/* <div className="notification">
              <div className="dot"></div>
              <i className="fa-regular fa-bell"></i>
            </div> */}

            <div onClick={handleShowProfileCard} className="profile">
              <img src={require("../../assets/img/branding/logo.png")} alt="" />
            </div>
            {
              showProfileCard &&
              <ProfileCard />
            }

          </div>
        </div>
        <div className="breadcrumbs-container">
          {breadCrumbs}
        </div>
        <div className="page-content">
          {isPopupOpen ? (
            <PopUp
              tootlePopup={tootlePopup}
              isPopupOpen={isPopupOpen}
              popupContent={
                SelectedForm === "general" ? (
                  <GeneralIncidentForm />
                ) : SelectedForm === "lostAndFound" ? (
                  <LostAndFoundForm />
                ) : SelectedForm === "employee" ? (
                  <EmployeeIncidentForm />
                ) : SelectedForm === "medicationError" ? (
                  <MedicationErrorForm />
                ) : SelectedForm === "grievance" ? (
                  <GrievanceForm />
                ) : SelectedForm === "reactionReport" ? (
                  <DrugReactionForm />
                ) : SelectedForm === "workPlaceViolence" ? (
                  <WorkplaceViolenceIncidentForm />
                ) : SelectedForm === "verbalComplaint" ? (
                  <VerbalComplaintForm />
                ) : ""
              }
            />
          ) : (
            ""
          )}

          <div>{content}</div>

        </div>
      </div>
    </div>
  );
};

export default DashBoardContainer;


export const ProfileCard = () => {
  const { logout } = useAuthentication()
  const [user, setUser] = useState({})
  useEffect(() => {
    const userObj = localStorage.getItem('userData');
    if (userObj && userObj !== 'undefined') {
      setUser(JSON.parse(userObj))
      console.log(JSON.parse(userObj))
    }
  }, [])
  return (
    <div className="profile-card">

      <div className="profile-card-container">
        <div className="profile">
          <img src={require("../../assets/img/branding/logo.png")} alt="" />
        </div>
        <div className="name-role">
          <h3 className="name">{user && user.first_name} {user && user.last_name}</h3>
          <small className="role">{user && user.email}</small>
        </div>
      </div>
      <div className="links">
        <Link to={'/users/profile/'} className="link"> <Note01Icon size={20} /> <span>My profile</span></Link>
        <Link className="link"> <House01Icon size={20} /><span>{user && user.facility?.name}</span></Link>
        <Link className="link"><DepartementIcon size={20} /> <span>{user && user.department?.name}</span></Link>
        <div className="divider"></div>
        <div onClick={logout} className="link special"><Logout01Icon size={20} /> <span>Logout</span></div>
      </div>
    </div>
  )
}