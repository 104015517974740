import React from "react";

const VerbalComplaintForm = () => {
    return (
        <div className="forms-container">
            <h2>Patient/Visitor Verbal Complaint</h2>
            <h1>Coming Soon</h1>
            <p>Our team is working on it... when finished, this will update automatically</p>
        </div>
    )
}

export default VerbalComplaintForm