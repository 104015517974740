import React, { useState } from "react";

const MedicationOtherInformation = ({ data, incidentStatuses }) => {
  const [errorCategory, setErrorCategory] = useState({})

  useState(() => {
    if (data.error_category) {
      setErrorCategory(JSON.parse(data.error_category))
      console.log(JSON.parse(data.error_category))
    }
  })
  return (
    <div className="incident-details">
      <div className="number-mrn">
        <div className="phone-number">
          <small>Medication time</small>
          <h4>{data.medication_time || "Not provided"}</h4>
        </div>
        <div className="mrn">
          <small>Expiration</small>
          <h4>
            {data.expiration || "Not provided"}
          </h4>
        </div>
      </div>
      <div className="number-mrn">
        <div className="location">
          <small>Error category</small>
          <h4>{errorCategory.category || "Not provided"}</h4>
        </div>
        <div className="location">
          <small> Comments </small>
          <h4>{data.comments || "Not provided"}</h4>
        </div>
      </div>

      <div className="number-mrn">
        <div className="location">
          <small>Actions taken</small>
          <h4>{data.actions_taken || "Not provided"}</h4>
        </div>
        <div className="location">
          <small>Severity rating </small>
          <h4>{data.severity_rating || "Not provided"}</h4>
        </div>
      </div>
    </div>
  );
};

export default MedicationOtherInformation;
