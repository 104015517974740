import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import api from "../../../../api";
import toast from "react-hot-toast";
import {
  CheckmarkSquare01Icon,
  FloppyDiskIcon,
  Loading03Icon,
  Square01Icon,
  SquareIcon,
} from "hugeicons-react";
import { sourcesOfInformation } from "../../../../constants";
import RichTexField from "../inputs/richTexField";
import BackToPage from "../../backToPage";
import postDocumentHistory from "../documentHistory/postDocumentHistory";
import FilesList from "../../../documents/filesList";
import mediaAPI from "../../../../mediaApi";
import CustomTimeInput from "../inputs/customTimeInput";

const ModifyLostFound = ({ incidentId, data }) => {
  const [incident, setIncident] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [savingDraft, setSavingDraft] = useState(false);

  const [status, setStatus] = useState("");
  const [propertyName, setPropertyName] = useState("");
  const [personReportingLoss, setPersonReportingLoss] = useState("");
  const [personTakingReport, setPersonTakingReport] = useState("");
  const [relationToPatient, setRelationToPatient] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [actionTaken, setActionTaken] = useState("");
  const [isFound, setIsFound] = useState("");
  const [dateFound, setDateFound] = useState("");
  const [timeFound, setTimeFound] = useState("");
  const [locationFound, setLocationFound] = useState("");
  const [foundBy, setFoundBy] = useState("");
  const [disposalUnclaimedProperty, setDisposalUnclaimedProperty] =
    useState("");

  const [returnedTo, setReturnedTo] = useState("");
  const [dateReturned, setDateReturned] = useState("");
  const [timeReturned, setTimeReturned] = useState("");
  const [locationReturned, setLocationReturned] = useState("");
  const [resolved, setResolved] = useState("");
  const [dateLetterSent, setDateLetterSent] = useState("");
  const [letterSentTo, setLetterSentTo] = useState("");
  const [resolveOutcome, setResolveOutcome] = useState("");
  const [dateBirth, setdateBirth] = useState("");
  const [age, setAge] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadingDocuments, setUploadingDocuments] = useState(false);
  const handleResolveOutcome = () => {
    setResolveOutcome((prev) => !prev);
  };

  useEffect(() => {
    const fetchIncidentData = async () => {
      try {
        const response = await api.get(
          `/incidents/lost_and_found/${incidentId}/`
        );
        setStatus(response.data.data.status);
        setIncident(response.data.data);
        setPropertyName(response.data.data.property_name);
        setPersonReportingLoss(
          response.data.data.name_of_person_reporting_loss
        );
        setPersonTakingReport(response.data.data.person_taking_report);
        setRelationToPatient(response.data.data.relation_to_patient);
        setItemDescription(response.data.data.item_description);
        setActionTaken(response.data.data.action_taken);
        setIsFound(response.data.data.is_found);
        setDateFound(response.data.data.date_found);
        setTimeFound(response.data.data.time_found);
        setLocationFound(response.data.data.location_found);
        setFoundBy(response.data.data.found_by);
        setDisposalUnclaimedProperty(
          response.data.data.disposal_of_unclaimed_property
        );
        setDateReturned(response.data.data.returned_to);
        setTimeReturned(response.data.data.date_returned);
        setLocationReturned(response.data.data.location_returned);
        setResolved(response.data.data.resolved);
        setDateLetterSent(response.data.data.date_letter_sent);
        setLetterSentTo(response.data.data.letter_sent_to);
        setdateBirth(response.data.data.date_of_birth);
        setAge(response.data.data.age);
      } catch (error) {
        console.error("Error fetching incident data:", error);
      }
    };
    fetchIncidentData();
  }, [incidentId]);

  const handleSaveDraft = () => {
    setStatus("Draft");
    setSavingDraft(true);
    handleModify("Draft");
  };
  const handleSaveAndSubmit = () => {
    setStatus("Completed");
    setIsLoading(true);
    handleModify("Completed");
    setIsLoading(true);
  };

  useEffect(() => {
    // get documents
    const fetchIncidentDocuments = async () => {
      try {
        const response = await api.get(
          `/incidents/lost_and_found/${incidentId}/documents/`
        );
        if (response.status === 200) {
          setUploadedFiles(response.data);
          console.log("documents updated successfully");
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchIncidentDocuments();
  }, []);

  const handleFileChange = async (event) => {
    const formData = new FormData();
    const files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    try {
      setUploadingDocuments(true);
      console.log([...formData]);

      const response = await mediaAPI.post(
        `/incidents/lost_and_found/${incidentId}/documents/new/`,
        formData
      );

      if (response.status === 200 || response.status === 201) {
        console.log(response.data.files);
        setUploadingDocuments(false);
        toast.success("Files uploaded successfully");
        setUploadedFiles(response.data.files);
      }
    } catch (error) {
      toast.error("Error uploading documents");
      setUploadingDocuments(false);
      console.log(error);
    }
  };
  const handleModify = async (incidentStatus) => {
    const formatDate = (date) => {
      const parsedDate = new Date(date);
      return parsedDate.toString() !== "Invalid Date"
        ? parsedDate.toISOString().split("T")[0]
        : null;
    };

    const formatTime = (time) => {
      if (!time) return null;
      const timeParts = time.split(":");
      if (timeParts.length < 2) return null;
      const hours = timeParts[0].padStart(2, "0");
      const minutes = timeParts[1].padStart(2, "0");
      return `${hours}:${minutes}:00`;
    };
    console.log("dateFound:", dateFound);
    console.log("dateReturned:", dateReturned);
    console.log("dateLetterSent:", dateLetterSent);
    console.log("timeFound:", timeFound);
    console.log("timeReturned:", timeReturned);

    const incidentData = {
      property_name: propertyName,
      name_of_person_reporting_loss: personReportingLoss,
      person_taking_report: personTakingReport,
      relation_to_patient: relationToPatient,
      item_description: itemDescription,
      action_taken: actionTaken,
      is_found: isFound,
      date_found: formatDate(dateFound),
      time_found: formatTime(timeFound),
      location_found: locationFound,
      found_by: foundBy,
      disposal_of_unclaimed_property: disposalUnclaimedProperty,
      returned_to: returnedTo,
      date_returned: formatDate(dateReturned),
      time_returned: formatTime(timeReturned),
      location_returned: locationReturned,
      resolved: resolved,
      date_letter_sent: formatDate(dateLetterSent),
      letter_sent_to: letterSentTo,
      status: incidentStatus,
      age: age,
      date_of_birth: dateBirth,
    };

    try {
      const response = await api.patch(
        `/incidents/lost_and_found/${incidentId}/modify/`,
        incidentData
      );
      if (response.status === 200) {
        setIsLoading(false);
        setSavingDraft(false);
        toast.success("Incident updated successfully");
        setIncident(response.data.incident);
        postDocumentHistory(incidentId, "modified this incident", "modify");
      }
    } catch (error) {
      setIsLoading(false);
      setSavingDraft(false);
      toast.error("Error updating the incident");
      console.error("Error updating incident:", error);
    }
  };

  return (
    <div className="modify-page-content">
      <div className="modify-page-header">
        <BackToPage
          link={"/incident/lost-and-found/"}
          pageName={"general incident"}
        />
        <h2 className="title">Modifying Lost and Found incident</h2>
        <div className="buttons">
          <button className="tertiary-button" onClick={handleSaveDraft}>
            {savingDraft ? (
              <>
                <Loading03Icon className="loading-icon" size={18} />
                <span>Saving draft</span>
              </>
            ) : (
              <>
                <FloppyDiskIcon size={20} />
                <span>Save draft</span>
              </>
            )}
          </button>
          <button className="primary-button" onClick={handleSaveAndSubmit}>
            {isLoading ? (
              <>
                <Loading03Icon className="loading-icon" size={18} />
                <span>Saving changes</span>
              </>
            ) : (
              <>
                <FloppyDiskIcon size={20} />
                <span>Save and submit</span>
              </>
            )}
          </button>
        </div>
      </div>
      <div className="modify-incident-page">
        <div className="incident-status">
          <p>
            Status : <span>{status}</span>
          </p>
        </div>
        <form className="modify-forms">
          <div className="inputs-group modify-inputs">
            <h3 className="full">General info</h3>
            <div className="field">
              <label htmlFor="propertyName">Property Name</label>
              <input
                type="text"
                name="propertyName"
                id="propertyName"
                value={propertyName}
                onChange={(e) => setPropertyName(e.target.value)}
                placeholder="Enter property name"
              />
            </div>
            <div className="field">
              <label htmlFor="personTakingReport">
                Name of person taking report
              </label>
              <input
                type="text"
                name="personTakingReport"
                id="personTakingReport"
                value={personTakingReport}
                onChange={(e) => setPersonTakingReport(e.target.value)}
                placeholder="Enter name"
              />
            </div>
            <div className="field">
              <label htmlFor="age">Age</label>
              <input
                type="text"
                name="age"
                id="age"
                value={age}
                onChange={(e) => setAge(e.target.value)}
                placeholder="Enter age"
              />
            </div>
            <div className="field">
              <label htmlFor="dateBirth">Date of birth</label>
              <input
                type="date"
                name="dateBirth"
                id="dateBirth"
                onChange={(e) => setdateBirth(e.target.value)}
                value={dateBirth}
              />
            </div>
            <div className="field full">
              <label htmlFor="personReportingLoss">
                Name of Patient, Patient Representative,or other individual
                reporting loss:
              </label>
              <input
                type="text"
                name="personReportingLoss"
                id="personReportingLoss"
                value={personReportingLoss}
                onChange={(e) => setPersonReportingLoss(e.target.value)}
                placeholder="Enter Name"
              />
            </div>

            <div className="field">
              <label htmlFor="relationToPatient">
                Relationship to Patient (if applicable):
              </label>
              <input
                onChange={(e) => setRelationToPatient(e.target.value)}
                value={relationToPatient}
                type="text"
                name="relationToPatient"
                id="relationToPatient"
                placeholder="Enter name"
              />
            </div>

            <div className="field full">
              <label htmlFor="complaintOrConcern">
                Full description of the missing, lost, or misplaced property
                (including money):
              </label>
              <RichTexField
                value={itemDescription}
                onEditorChange={setItemDescription}
              />
            </div>

            <div className="field full">
              <label htmlFor="complaintOrConcern">
                Actions taken to locate the missing, lost, or misplaced
                property:
              </label>
              <RichTexField
                value={actionTaken}
                onEditorChange={setActionTaken}
              />
            </div>
            <div className="check-box">
              <input
                onClick={handleResolveOutcome}
                checked={resolveOutcome}
                type="checkbox"
                name="resolveOutcome"
                id="resolveOutcome"
              />
              <label htmlFor="resolveOutcome">
                Check if missing, lost, or misplaced property was found
              </label>
            </div>
            <div className="half"></div>
          </div>
          <div className="inputs-group modify-inputs">
            <div className="field">
              <label htmlFor="returnedTo">Property returned to</label>
              <input
                onChange={(e) => setReturnedTo(e.target.value)}
                value={returnedTo}
                type="text"
                name="returnedTo"
                id="returnedTo"
                placeholder="Enter name"
              />
            </div>
            <div className="field">
              <label htmlFor="dateReturned">Date property was returned</label>
              <input
                type="date"
                name="dateReturned"
                id="dateReturned"
                onChange={(e) => setDateReturned(e.target.value)}
                value={dateReturned}
              />
            </div>

            <div className="field">
              <label htmlFor="timeFound">Time property was returned</label>
              <CustomTimeInput setTime={setTimeFound} />

            </div>
            <div className="field">
              <label htmlFor="locationReturned">
                Location where property was found:
              </label>
              <input
                onChange={(e) => setLocationReturned(e.target.value)}
                value={locationReturned}
                type="text"
                name="locationReturned"
                id="locationReturned"
                placeholder="Enter Name"
              />
            </div>

            <div className="field">
              <label htmlFor="dateFound">Date property found</label>
              <input
                type="date"
                name="dateFound"
                id="dateFound"
                onChange={(e) => setDateFound(e.target.value)}
                value={dateFound}
              />
            </div>
            <div className="field">
              <label htmlFor="timeFound">Time property found</label>
              <CustomTimeInput setTime={setTimeFound} />

            </div>

            <div className="field">
              <label htmlFor="foundBy">
                Name of person who found property:
              </label>
              <input
                onChange={(e) => setFoundBy(e.target.value)}
                value={foundBy}
                type="text"
                name="foundBy"
                id="foundBy"
                placeholder="Enter name"
              />
            </div>
            <div className="field full">
              <label htmlFor="disposalUnclaimedProperty">
                Disposal of Unclaimed Property
              </label>
              <RichTexField
                value={disposalUnclaimedProperty}
                onEditorChange={setDisposalUnclaimedProperty}
              />
            </div>
            <div className="field">
              <label htmlFor="dateLetterSent">Date letter was sent</label>
              <input
                type="date"
                name="dateLetterSent"
                id="dateLetterSent"
                onChange={(e) => setDateLetterSent(e.target.value)}
                value={dateLetterSent}
              />
            </div>

            <div className="field">
              <label htmlFor="letterSentTo">Letter was sent to</label>
              <input
                onChange={(e) => setLetterSentTo(e.target.value)}
                value={letterSentTo}
                type="text"
                name="letterSentTo"
                id="letterSentTo"
                placeholder="Enter name"
              />
            </div>
            <div className="field full">
              <h3>Supporting documents</h3>
              <FilesList documents={uploadedFiles} />

              {uploadingDocuments ? (
                "Uploading ..."
              ) : (
                <input
                  type="file"
                  onChange={handleFileChange}
                  name="files"
                  id="files"
                  multiple
                />
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModifyLostFound;
