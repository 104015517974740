import React from 'react'

const LogoText = ({ text, logoSize, fontSize }) => {
    return (
        <div className="branding">
            <img style={
                { maxWidth: logoSize || 80 }
            } src={'/images/branding/logo.png'} alt="Logo" />
            <h1 className="site-title"></h1>
            <h1 style={{
                fontSize: fontSize || 22
            }} >{text || 'CHMC Quality Control'}</h1>
        </div>
    )
}

export default LogoText
