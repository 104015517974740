import React from "react";

const EmployeeOtherInformation = ({ data, incidentStatuses }) => {
  return (
    <div className="incident-type-data">
      <div className="general-col">
        <div className="flex-row">
          <div className="general-sub-col">
            <h3>Doctor he/she has seen</h3>
            <small>{data.doctor_name || "Doctor’s name"}</small>
          </div>
          <div className="general-sub-col">
            <h3>Doctor’s phone number:</h3>
            <small>{data.doctor_phone_number || "Number"}</small>
          </div>
          <div className="general-sub-col">
            <h3>Supervisor:</h3>
            <small>{data.supervisor || "Number"}</small>
          </div>
        </div>

        <div className="flex-row">
          <div className="general-sub-col">
            <h3>Time</h3>
            <small>{data.doctor_consulted_time || "Not provided"}</small>
          </div>
          <div className="general-sub-col">
            <h3>Date</h3>
            <small>{data.doctor_consulted_dated || "Not provided"}</small>
          </div>
        </div>

        <div className="flex-row">
          <div className="general-sub-col">
            <h3>previous_injury</h3>
            <small>{data.previous_injury || "Not provided"}</small>
          </div>
          <div className="general-sub-col">
            <h3>Date of Previous injury ate</h3>
            <small>{data.previous_injury_date || "Not provided"}</small>
          </div>
          <div className="general-sub-col">
            <h3>Report completed</h3>
            <small>{data.report_completed || "Not provided"}</small>
          </div>
        </div>

      </div>
    </div>
  );
};

export default EmployeeOtherInformation;
