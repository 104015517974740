import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import api from "../../../../api";
import toast from "react-hot-toast";
import {
  Cancel01Icon,
  CheckmarkSquare01Icon,
  EyeIcon,
  FloppyDiskIcon,
  Loading03Icon,
  SearchList02Icon,
  Square01Icon,
  SquareIcon,
} from "hugeicons-react";
import { sourcesOfInformation } from "../../../../constants";
import RichTexField from "../inputs/richTexField";
import BackToPage from "../../backToPage";
import postDocumentHistory from "../documentHistory/postDocumentHistory";
import mediaAPI from "../../../../mediaApi";
import { format } from "date-fns";
import GrievanceInvestigationForm from "../grivanceInvestigationForm";
import FormatDate from "../../../general/formatDateTime";
import FilesList from "../../../documents/filesList";
import CustomTimeInput from "../inputs/customTimeInput";

const ModifyGrievanceIncident = ({ data, incidentId, investigation }) => {
  const [incident, setIncident] = useState(data);
  const [isLoading, setIsLoading] = useState(false);
  const [savingDraft, setSavingDraft] = useState(false);
  const [showInvestigationFrom, setShowInvestigationFrom] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const currentStepRef = useRef(currentStep);

  const [status, setStatus] = useState(incident.status);
  const [incidentDate, setIncidentDate] = useState(incident.date);
  const [patientName, setPatientName] = useState(incident.patient_name);
  const [medicalRecord, setMedicalRecord] = useState(
    incident.medical_record_number
  );
  const [formInitiatedBy, setFormInitiatedBy] = useState(
    incident.form_initiated_by
  );
  const [formInitiatedByTitle, setFormInitiatedByTitle] = useState(
    incident.title
  );
  const [complaintBy, setComplaintBy] = useState(incident.complaint_made_by);
  const [patientRelationship, setPatientRelationship] = useState(
    incident.relationship_to_patient
  );
  const [phoneNumber, setPhoneNumber] = useState(incident.phone_number);
  const [sourceOfInformation, setSourceOfInformation] = useState(
    incident.source_of_information
  );
  const [showOtherSourceOfInfo, setShowOtherSourceOfInfo] = useState(false);
  const [complaintOrConcern, setComplaintOrConcern] = useState(
    incident.complaint_or_concern
  );
  const [actionTaken, setActionTaken] = useState(
    incident.initial_corrective_actions
  );
  const [reviewActionTaken, setReviewActionTaken] = useState("");
  const [review, setReview] = useState("");
  const [dateBirth, setdateBirth] = useState(incident.date_of_birth);
  const [age, setAge] = useState("");

  const [otherInput, setOtherInput] = useState("");
  const [feedbackDate, setFeedbackDate] = useState("");
  const [extensionLetterDate, setExtensionLetterDate] = useState("");
  const [responseLetterDate, setResponseLetterDate] = useState("");
  const [actionMeeting, setActionMeeting] = useState("");
  const [involvedPerson, setInvolvedPerson] = useState("");
  const [actionTelephone, setActionTelephone] = useState("");
  const [relationshipToPatient, setRelationshipToPatient] = useState("");
  const [dateClosed, setDateClosed] = useState("");
  const [extensionLetterFile, setExtensionLetterFile] = useState(null);
  const [responseLetterFile, setResponseLetterFile] = useState(null);
  const maxFileSize = 12 * 1024 * 1024;
  const [adversePatientOutcome, setAdversePatientOutcome] = useState(
    incident.adverse_patient_outcome
  );
  const [outcome, setOutcome] = useState(incident.outcome);
  const [nameOfAdministator, setNameOfAdministartor] = useState(
    incident.administrator_notified
  );
  const [grivanceDate, setGrivanceDate] = useState(incident.notification_date);
  const [grivanceTime, setGrivanceTime] = useState(incident.notification_time);
  const [selectedOption, setSelectedOption] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadingDocuments, setUploadingDocuments] = useState(false);

  const handleShowInvestigationForm = () => {
    setShowInvestigationFrom(!showInvestigationFrom);
  };
  const handleFileChange = async (event) => {
    const formData = new FormData();
    const files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    try {
      setUploadingDocuments(true);
      console.log([...formData]);

      const response = await mediaAPI.post(
        `/incidents/grievance/${data.id}/documents/new/`,
        formData
      );

      if (response.status === 200 || 201) {
        console.log(response.data.files);
        setUploadingDocuments(false);
        toast.success("Files uploaded successfully");
        setUploadedFiles(response.data.files);
      }
    } catch (error) {
      toast.error("Error uploading documents");
      setUploadingDocuments(false);
      console.log(error);
    }
  };

  const handleAdversePatientOutcome = () => {
    setAdversePatientOutcome((prev) => !prev);
  };

  const handleSourceOfInformation = (source) => {
    if (source === "Other") {
      setShowOtherSourceOfInfo(source === "Other");
    } else {
      setSourceOfInformation(source);
    }
    console.log(source);
    // setInputValue("");
  };

  useEffect(() => {
    // get documents
    const fetchIncidentDocuments = async () => {
      try {
        const response = await api.get(
          `/incidents/grievance/${data.id}/documents/`
        );
        if (response.status === 200) {
          setUploadedFiles(response.data);
          console.log("documents updated successfully");
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchIncidentDocuments();
  }, []);

  const handleSaveDraft = () => {
    setStatus("Draft");
    setSavingDraft(true);
    handleModify("Draft");
  };
  const handleSaveAndSubmit = () => {
    setStatus("Completed");
    setIsLoading(true);
    handleModify("Completed");
    setIsLoading(true);
  };
  const handleModify = async (incidentStatus) => {
    const formatDate = (date) => {
      return format(new Date(date), "yyyy-MM-dd");
    };
    const incidentData = {
      date: formatDate(incidentDate),
      patient_name: patientName,
      medical_record_number: medicalRecord,
      form_initiated_by: formInitiatedBy,
      title: formInitiatedByTitle,
      complaint_made_by: complaintBy,
      relationship_to_patient: patientRelationship,
      phone_number: phoneNumber,
      source_of_information: sourceOfInformation,
      complaint_or_concern: complaintOrConcern,
      initial_corrective_actions: actionTaken,
      adverse_patient_outcome: adversePatientOutcome,
      outcome: outcome,
      administrator_notified: nameOfAdministator,
      notification_date: grivanceDate,
      notification_time: grivanceTime,
      status: incidentStatus,
      age: parseInt(age, 10) || 0,
      date_of_birth: formatDate(dateBirth),
    };
    console.log(dateBirth);
    console.log(incidentDate);
    try {
      const response = await api.patch(
        `incidents/grievance/${incidentId}/modify/`,
        incidentData
      );
      if (response.status === 200) {
        setIsLoading(false);
        setSavingDraft(false);
        toast.success("Incident updated successfully");
        setIncident(response.data.incident);
        console.log(response.data.incident);
        postDocumentHistory(incidentId, "modified this incident", "modify");
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        toast.error(
          error.response.data.message ||
          error.response.data.error ||
          "Error while updating the incident"
        );
      } else {
        toast.error("Unknown error while updating the incident");
      }
      setIsLoading(false);
      setSavingDraft(false);
    }
  };
  return (
    <div className="modify-page-content">
      {showInvestigationFrom && (
        <div className="grievance-investigation-form">
          <div className="form-container">
            <Cancel01Icon
              className="close-popup"
              onClick={handleShowInvestigationForm}
            />
            <GrievanceInvestigationForm incidentId={incidentId} />
          </div>
        </div>
      )}
      <div className="modify-page-header">
        <BackToPage
          link={"/incident/grievance/"}
          pageName={"general incident"}
        />
        <h2 className="title">Modifying grievance incident</h2>
        {investigation && investigation.id ? (
          <Link
            to={`/incident/grievance/${incidentId}`}
            onClick={() => {
              localStorage.setItem("activate_investigation_tab", true);
            }}
          >
            <button type="button" className="tertiary-button">
              <span>View investigation</span>
              <EyeIcon size={18} />
            </button>
          </Link>
        ) : (
          <button
            onClick={handleShowInvestigationForm}
            className="tertiary-button"
          >
            <span>Add investigation</span>
            <SearchList02Icon />
          </button>
        )}

        <div className="buttons">
          <button className="tertiary-button" onClick={handleSaveDraft}>
            {savingDraft ? (
              <>
                <Loading03Icon className="loading-icon" size={18} />
                <span>Saving draft</span>
              </>
            ) : (
              <>
                <FloppyDiskIcon size={20} />
                <span>Save draft</span>
              </>
            )}
          </button>
          <button className="primary-button" onClick={handleSaveAndSubmit}>
            {isLoading ? (
              <>
                <Loading03Icon className="loading-icon" size={18} />
                <span>Saving changes</span>
              </>
            ) : (
              <>
                <FloppyDiskIcon size={20} />
                <span>Save and submit</span>
              </>
            )}
          </button>
        </div>
      </div>
      <div className="modify-incident-page">
        <div className="incident-status">
          <p>
            Status : <span>{status}</span>
          </p>
        </div>
        <form className="modify-forms">
          <div className="inputs-group modify-inputs">
            <h3 className="full">General info</h3>
            <div className="field">
              <label htmlFor="incidentDate">Date</label>
              <input
                type="date"
                name="incidentDate"
                id="incidentDate"
                onChange={(e) => setIncidentDate(e.target.value)}
                value={incidentDate}
              />
            </div>
            <div className="field">
              <label htmlFor="patientName">Patient name</label>
              <input
                onChange={(e) => setPatientName(e.target.value)}
                value={patientName}
                type="text"
                name="patientName"
                id="patientName"
                placeholder="Enter patient name"
              />
            </div>

            <div className="field">
              <label htmlFor="age">Age</label>
              <input
                type="text"
                name="age"
                id="age"
                value={age}
                onChange={(e) => setAge(e.target.value)}
                placeholder="Enter age"
              />
            </div>
            <div className="field">
              <label htmlFor="dateBirth">Date of birth</label>
              <input
                type="date"
                name="dateBirth"
                id="dateBirth"
                onChange={(e) => setdateBirth(e.target.value)}
                value={dateBirth}
              />
            </div>

            <div className="field">
              <label htmlFor="medicalRecord">Medical record</label>
              <input
                onChange={(e) => setMedicalRecord(e.target.value)}
                value={medicalRecord}
                type="text"
                name="medicalRecord"
                id="medicalRecord"
                placeholder="Enter medical name"
              />
            </div>

            <div className="field">
              <label htmlFor="formInitiatedBy">Form initiated by</label>
              <input
                onChange={(e) => setFormInitiatedBy(e.target.value)}
                value={formInitiatedBy}
                type="text"
                name="formInitiatedBy"
                id="formInitiatedBy"
                placeholder="Enter name"
              />
            </div>
            <div className="field">
              <label htmlFor="formInitiatedByTitle">Title</label>
              <input
                onChange={(e) => setFormInitiatedByTitle(e.target.value)}
                value={formInitiatedByTitle}
                type="text"
                name="formInitiatedByTitle"
                id="formInitiatedByTitle"
                placeholder="Enter title"
              />
            </div>

            <div className="field">
              <label htmlFor="complaintBy">Who made complaint?</label>
              <input
                onChange={(e) => setComplaintBy(e.target.value)}
                value={complaintBy}
                type="text"
                name="complaintBy"
                id="complaintBy"
                placeholder="Enter name"
              />
            </div>
            <div className="field">
              <label htmlFor="patientRelationship">
                Relationship to patient
              </label>
              <input
                onChange={(e) => setPatientRelationship(e.target.value)}
                value={patientRelationship}
                type="text"
                name="patientRelationship"
                id="patientRelationship"
                placeholder="Enter   relationship"
              />
            </div>

            <div className="field">
              <label htmlFor="phoneNumber">Phone number</label>
              <input
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={phoneNumber}
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                placeholder=" Enter phone number"
              />
            </div>

            <div className="field full">
              <label htmlFor="phoneNumber">Source of information</label>
              <div className="half">
                {sourcesOfInformation.map((source) => (
                  <div
                    onClick={() => handleSourceOfInformation(source.value)}
                    className="check-box"
                    key={source.value}
                  >
                    {sourceOfInformation === source.value ? (
                      <CheckmarkSquare01Icon />
                    ) : (
                      <SquareIcon />
                    )}
                    <p>{source.label}</p>
                  </div>
                ))}

                {showOtherSourceOfInfo && (
                  <div>
                    <input
                      type="text"
                      value={sourceOfInformation}
                      onChange={(e) =>
                        handleSourceOfInformation(e.target.value)
                      }
                      placeholder="Enter Source Of Information"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="inputs-group modify-inputs">
            <div className="field full">
              <label htmlFor="complaintOrConcern">Complaint or Concern:</label>
              <RichTexField
                value={complaintOrConcern}
                onEditorChange={setComplaintOrConcern}
              />
            </div>
            <div className="field full">
              <label htmlFor="actionTaken">
                What were the initial corrective actions taken to resolve the
                complaint?
              </label>
              <RichTexField
                value={actionTaken}
                onEditorChange={setActionTaken}
              />
            </div>

            <div className="check-box">
              <input
                onClick={handleAdversePatientOutcome}
                checked={adversePatientOutcome}
                type="checkbox"
                name="adversePatientOutcome"
                id="adversePatientOutcome"
              />
              <label htmlFor="adversePatientOutcome">
                Check if there was an adverse patient outcome related to this
                complaint.
              </label>
            </div>
            {adversePatientOutcome ? (
              <div className="field full">
                <label htmlFor="outcome">Identify outcome</label>

                <RichTexField value={outcome} onEditorChange={setOutcome} />
              </div>
            ) : null}
          </div>

          <div className="inputs-group modify-inputs">
            <div className="field">
              <label htmlFor="NameOfAdministartor">
                Name of administrator on call or designee notified:
              </label>
              <input
                type="text"
                name="NameOfAdministator"
                id="NameOfAdministrator"
                onChange={(e) => setNameOfAdministartor(e.target.value)}
                value={nameOfAdministator}
                placeholder="Name of administrator"
              />
            </div>
            <div className="field">
              <label htmlFor="grivanceDate">Date</label>
              <input
                onChange={(e) => setGrivanceDate(e.target.value)}
                value={grivanceDate}
                type="date"
                name="grivanceDate"
                id="grivanceDate"
              />
            </div>

            <div className="field">
              <label htmlFor="grivanceTime">Time</label>
              <CustomTimeInput setTime={setGrivanceTime} />

            </div>
            <div className="field full">
              <h3>Supporting documents</h3>
              <FilesList documents={uploadedFiles} showDownload={true} />

              {uploadingDocuments ? (
                "Uploading ..."
              ) : (
                <input
                  type="file"
                  onChange={handleFileChange}
                  name="files"
                  id="files"
                  multiple
                />
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModifyGrievanceIncident;
