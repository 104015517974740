import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import DashBoardContainer from '../../../dashboardContainer';
import api from '../../../../../api';
import ModifyEmployeeInvestigation from '../../../../../components/incidents/forms/modiy/ModifyEmployeeInvestigation';
import { ArrowLeft01Icon, ArrowTurnBackwardIcon } from 'hugeicons-react';
import BackToPage from '../../../../../components/incidents/backToPage';
import { ArrowRight01Icon } from "hugeicons-react";


const ModifyEmployeeInvestigationPageContent = () => {
    const [error, setError] = useState()
    const [incident, setIncident] = useState({})
    const { incidentId } = useParams()
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        console.log(incidentId); 
        const fetchIncidentData = async () => {
            try {
                const response = await api.get(`/incidents/employee_health_investigation/${incidentId}/`);
                if (response.status === 200) {
                    setIncident(response.data.employee_investigation);
                    setIsLoading(false);
                }
            } catch (error) {
                setError("Error getting incident data");
            }
        }
        fetchIncidentData();
    }, [incidentId]);
    
    return (
        <div>
            <ModifyEmployeeInvestigation data={incident} incidentId={incidentId} />
        </div>
    )
};

const BreadCrumbs = () => {
    const { incidentId } = useParams();
    return (
        <div className="breadcrumbs">
            <Link to={'/'}>Overview</Link> <ArrowRight01Icon /> 
            <Link to={'/incidents/'}>Incidents</Link> <ArrowRight01Icon /> 
            <Link to={'/incident/grievance/investigation/'}>Grievance Investigation List</Link>  <ArrowRight01Icon /> 
            <Link to={`/incident/general/${incidentId}/`}>#{incidentId}</Link> <ArrowRight01Icon />
            <Link className='current-page'> Modify</Link>
        </div>
    )
};


const ModifyLostFoundPage = () => {
    return (
        <DashBoardContainer content={<ModifyEmployeeInvestigationPageContent />} breadCrumbs={<BreadCrumbs />}/>
    );
};

export default ModifyLostFoundPage;