import React from "react";

const EmployeeInvestigationOtherInformation = ({ data, incidentStatuses }) => {
  return (
    <div className="incident-type-data">
      <div className="general-col">
        <div className="general-sub-col">
          <h3>Witness</h3>
          <small>{data.witnesses || "Not provided"}</small>
        </div>

        <div className="general-sub-col">
          <h3>Event location</h3>
          <small>{data.event_location || "Not provided"}</small>
        </div>
        <div className="general-sub-col">
          <h3>What caused the event</h3>
          <small>{data.cause_of_event || "Number"}</small>
        </div>
        <div className="general-sub-col">
          <h3>
            Were safety regulations in place and used? If not, what was wrong?{" "}
          </h3>
          <small>{data.safety_regulations || "Not provided"}</small>
        </div>

        <div className="general-sub-col">
          <h3>Date Claims notified</h3>
          <small>{data.claim || "Not provided"}</small>
        </div>
        <div className="general-sub-col">
          <h3>Claim number</h3>
          <small>{data.date_claim_notified || "Not provided"}</small>
        </div>

        <div className="general-sub-col">
          <h3>Doctor's Name</h3>
          <small>{data.doctor_name || "Not provided"}</small>
        </div>
        <div className="general-sub-col">
          <h3>Hospital's Name</h3>
          <small>{data.hospital_name || "Not provided"}</small>
        </div>
        <div className="general-sub-col">
          <h3>
            Recommended preventative action to take in the future to prevent
            re-occurrence.
          </h3>
          <small>{data.recommendations || "Not provided"}</small>
        </div>
      </div>
    </div>
  );
};

export default EmployeeInvestigationOtherInformation;
