import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import api from "../../../../api";
import toast from "react-hot-toast";
import {
  Cancel01Icon,
  CheckmarkSquare01Icon,
  EyeIcon,
  FloppyDiskIcon,
  Loading03Icon,
  SearchList02Icon,
  Square01Icon,
  SquareIcon,
} from "hugeicons-react";
import { Link } from "react-router-dom";
import { sourcesOfInformation } from "../../../../constants";
import RichTexField from "../inputs/richTexField";
import mediaAPI from "../../../../mediaApi";
// import employee from "../../successMessage/employeecomplete";
import CustomDatePicker from "../inputs/datePicker";
import CustomSelectInput from "../inputs/customSelect";

import BackToPage from "../../backToPage";
import postDocumentHistory from "../documentHistory/postDocumentHistory";
import EmployeeIncidentForm from "../employeeIncidentForm";
import HealthIncidentInvestigationForm from "../healthIncidentForm";
import FilesList from "../../../documents/filesList";
import CustomTimeInput from "../inputs/customTimeInput";

const ModifyEmployeeIncident = ({ data, incidentId, investigation }) => {
  const [incident, setIncident] = useState(data);
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [savingDraft, setSavingDraft] = useState(false);

  const [statusType, setStatusType] = useState(data.incident_status);
  const [status, setStatus] = useState(incident.status);
  const [name, setName] = useState(data.name);
  const [description, setDescription] = useState("");
  const [jobTitle, setJobTitle] = useState(data.job_title);
  const [dateOfInjury, setDateOfInjury] = useState(
    data.date_of_injury_or_near_miss
  );
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadingDocuments, setUploadingDocuments] = useState(false);
  const [dateSeenDoctor, setDateSeenDoctor] = useState(
    data.doctor_consulted_dated
  );
  const [timeSeenDoctor, setTimeSeenDoctor] = useState(
    data.doctor_consulted_time
  );
  const [timeOfInjury, setTimeOfInjury] = useState(
    data.time_of_injury_or_near_miss
  );
  const [supervisorName, setSupervisorName] = useState(data.supervisor);
  const [doctorName, setDoctorName] = useState(data.doctor_name);
  const [doctorPhone, setDoctorPhone] = useState(data.doctor_phone_number);
  const [whereItHappened, setWhereItHappened] = useState(data.location);
  const [doingWhat, setDoingWhat] = useState(data.activity_at_time_of_incident);
  const [whatLedTo, setWhatLedTo] = useState(data.incident_description);
  const [whenInjured, setWhenInjured] = useState(data.previous_injury_date);
  const [DoneToPrevent, setDoneToPrevent] = useState(data.preventive_measures);
  const [beingInjured, setBeingInjured] = useState(data.body_parts_injured);
  const [seenDoctor, setSeenDoctor] = useState(data.doctor_consulted);
  const [toldSupervisor, setToldSupervisor] = useState(false);
  const [injuredBody, setInjuredBody] = useState(data.previous_injury);
  const [reportId, setReportID] = useState(null);
  const [witnesses, setWitnesses] = useState(data.witnesses || []);
  const [success, setSuccess] = useState("false");
  const [incidentDate, setIncidentDate] = useState("");
  const currentStepRef = useRef(currentStep);
  const [showInvestigationFrom, setShowInvestigationFrom] = useState(false);

  const [newWitness, setNewWitness] = useState("");
  const handleShowInvestigationForm = () => {
    setShowInvestigationFrom(!showInvestigationFrom);
  };

  const handleAddWitness = () => {
    setWitnesses((prevWitnesses) => {
      if (!prevWitnesses.includes(newWitness)) {
        return [...prevWitnesses, newWitness];
      }
      return prevWitnesses;
    });
    setNewWitness("");
  };

  const handleRemoveWitness = (nameToRemove) => {
    const newWitnesses = witnesses.filter(
      (witness) => witness !== nameToRemove
    );
    setWitnesses(newWitnesses);
  };

  useEffect(() => {
    // get documents
    const fetchIncidentDocuments = async () => {
      try {
        const response = await api.get(
          `/incidents/employee_incident/${incidentId}/documents/`
        );
        if (response.status === 200) {
          setUploadedFiles(response.data);
          console.log("documents updated successfully");
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchIncidentDocuments();
  }, []);
  const handleFileChange = async (event) => {
    const formData = new FormData();
    const files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    try {
      setUploadingDocuments(true);
      console.log([...formData]);

      const response = await mediaAPI.post(
        `/incidents/employee_incident/${incidentId}/documents/new/`,
        formData
      );

      if (response.status === 200 || response.status === 201) {
        console.log(response.data.files);
        setUploadingDocuments(false);
        toast.success("Files uploaded successfully");
        setUploadedFiles(response.data.files);
      }
    } catch (error) {
      toast.error("Error uploading documents");
      setUploadingDocuments(false);
      console.log(error);
    }
  };
  const handleSeenDoctor = () => {
    setSeenDoctor(!seenDoctor);
  };

  const handleWitnessChange = (index, event) => {
    const { name, value } = event.target;
    const newWitnesses = [...witnesses];
    newWitnesses[index][name] = value;
    setWitnesses(newWitnesses);
  };

  const handleInjuredBody = () => {
    setInjuredBody(!injuredBody);
  };

  const handleToldSupervisor = () => {
    setToldSupervisor(!toldSupervisor);
    console.log(!toldSupervisor);
  };

  const handleSaveDraft = () => {
    setStatus("Draft");
    setSavingDraft(true);
    handleModify("Draft");
  };
  const handleSaveAndSubmit = () => {
    setStatus("Completed");
    setIsLoading(true);
    handleModify("Completed");
    setIsLoading(true);
  };
  const handleModify = async (incidentStatus) => {
    const witnessesList = witnesses.map((name) => ({ witness_name: name }));
    const incidentData = {
      incident_status: statusType,
      name: name,
      job_title: jobTitle,
      supervisor: supervisorName,
      date_of_injury_or_near_miss: dateOfInjury,
      time_of_injury_or_near_miss: timeOfInjury,
      // witnesses: witnessesList,

      location: whereItHappened || "N/A",
      activity_at_time_of_incident: doingWhat || "N/A",
      incident_description: whatLedTo || "N/A",
      preventive_measures: DoneToPrevent || "N/A",
      body_parts_injured: beingInjured,

      doctor_consulted: seenDoctor,
      doctor_consulted_dated: dateSeenDoctor || null,
      doctor_consulted_time: timeSeenDoctor || null,
      doctor_name: doctorName || " ",
      doctor_address: doctorPhone || " ",
      doctor_phone_number: doctorPhone || " ",
      previous_injury: injuredBody,
      previous_injury_date: whenInjured,
      status: incidentStatus,
    };
    try {
      const response = await api.patch(
        `incidents/employee_incident/${incidentId}/modify/`,
        incidentData
      );
      if (response.status === 200) {
        setIsLoading(false);
        setSavingDraft(false);
        toast.success("Incident updated successfully");
        setIncident(response.data.incident);
        console.log(response.data.incident);
        postDocumentHistory(incidentId, "modified this incident", "modify");
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);

      if (error.response) {
        toast.error(
          error.response.data.message ||
          error.response.data.error ||
          "Error while updating the incident"
        );
      } else {
        toast.error("Unknown error while updating the incident");
      }
      setSavingDraft(false);
    }
  };
  return (
    <div className="modify-page-content">
      {showInvestigationFrom && (
        <div className="grievance-investigation-form">
          <div className="form-container">
            <Cancel01Icon
              className="close-popup"
              onClick={handleShowInvestigationForm}
            />
            <HealthIncidentInvestigationForm investigationId={incidentId} />
          </div>
        </div>
      )}
      <div className="modify-page-header">
        <BackToPage link={"/incident/employee/"} pageName={"Staff incident"} />
        <h2 className="title">Modifying Staff Incident</h2>
        {investigation && investigation.id ? (
          <Link
            to={`/incident/employee_incident/${incidentId}`}
            onClick={() => {
              localStorage.setItem("activate_investigation_tab", true);
            }}
          >
            <button type="button" className="tertiary-button">
              <span>View investigation</span>
              <EyeIcon size={18} />
            </button>
          </Link>
        ) : (
          <button
            onClick={handleShowInvestigationForm}
            className="tertiary-button"
          >
            <span>Add investigation</span>
            <SearchList02Icon />
          </button>
        )}
        <div className="buttons">
          <button className="tertiary-button" onClick={handleSaveDraft}>
            {savingDraft ? (
              <>
                <Loading03Icon className="loading-icon" size={18} />
                <span>Saving draft</span>
              </>
            ) : (
              <>
                <FloppyDiskIcon size={20} />
                <span>Save draft</span>
              </>
            )}
          </button>
          <button className="primary-button" onClick={handleSaveAndSubmit}>
            {isLoading ? (
              <>
                <Loading03Icon className="loading-icon" size={18} />
                <span>Saving changes</span>
              </>
            ) : (
              <>
                <FloppyDiskIcon size={20} />
                <span>Save and submit</span>
              </>
            )}
          </button>
        </div>
      </div>
      {
        <form>
          <div className="incident-status">
            <p>
              Status : <span>{status}</span>
            </p>
          </div>
          <div className="step inputs-group">
            <h3>I am reporting a work related</h3>
            <div className="field flex-column step-2-status">
              <label htmlFor="incidentLocation">Status</label>
              <CustomSelectInput
                options={["Injury", "Illness", "Near miss"]}
                placeholder={"status"}
                selected={statusType}
                setSelected={setStatusType}
              />
            </div>
            <div className="half">
              <div className="field flex-column name">
                <label htmlFor="employeeName">Name</label>
                <input
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  type="text"
                  name="employeeName"
                  id="employeeName"
                  placeholder="Enter name"
                />
              </div>

              <div className="field flex-column job-title">
                <label htmlFor="jobTitle">Job Title</label>
                <input
                  onChange={(e) => setJobTitle(e.target.value)}
                  value={jobTitle}
                  type="text"
                  name="jobTitle"
                  id="jobTitle"
                  placeholder="Enter job title"
                />
              </div>
            </div>
            <div className="check-box">
              <div className="check-box">
                <input
                  onChange={handleToldSupervisor}
                  checked={toldSupervisor}
                  type="checkbox"
                  name="toldSupervisor"
                  id="toldSupervisor"
                />
                <label htmlFor="toldSupervisor">
                  Check if you have told your supervisor about this injury/near
                  miss.
                </label>
              </div>
            </div>
            {toldSupervisor && (
              <div className="supervisor flex-column field">
                <label htmlFor="supervisorName">Supervisor</label>
                <input
                  onChange={(e) => setSupervisorName(e.target.value)}
                  value={supervisorName}
                  type="text"
                  name="supervisorName"
                  id="supervisorName"
                  placeholder="Enter supervisor name"
                />
              </div>
            )}
            <div className="half">
              <div className="date-of-injury flex-column field">
                <label htmlFor="dateOfInjury">Date Of Injury/Near Miss</label>
                <CustomDatePicker
                  selectedDate={dateOfInjury}
                  setSelectedDate={setDateOfInjury}
                />
              </div>

              <div className="time-of-injury flex-column field">
                <label htmlFor="timeOfInjury">Time Of Injury/Near Miss</label>
                <CustomTimeInput setTime={setTimeOfInjury} />

              </div>
            </div>
            {witnesses &&
              witnesses.map((witness, index) => (
                <div className="witness flex-column field" key={index}>
                  <label htmlFor={`witness-${index}`}>
                    {index === 0
                      ? "Names of Witnesses (If Any):"
                      : "Witness Name:"}
                  </label>
                  <input
                    onChange={(e) => handleWitnessChange(index, e.target.value)}
                    value={witness.witness_name}
                    type="text"
                    name={`witness-${index}`}
                    id={`witness-${index}`}
                    placeholder="Enter witness name"
                  />
                  {index !== 0 && (
                    <button
                      type="button"
                      onClick={() => handleRemoveWitness(index)}
                    >
                      Remove
                    </button>
                  )}
                </div>
              ))}
            <div className="parties">
              Witnesses:
              <br />
              To add a witness, type the name and click add witness button
              <input
                onChange={(e) => setNewWitness(e.target.value)}
                value={newWitness}
                type="text"
                placeholder="Enter witness name"
              />
              <button
                className="new-party"
                type="button"
                onClick={() => handleAddWitness()}
              >
                <i className="fa-solid fa-plus"></i>
                Add Witnesses
              </button>
            </div>{" "}
          </div>
          <div className="step inputs-group">
            <div className="where-it-happened flex-column field">
              <label htmlFor="whereItHappened">
                Where, exactly, did it happen?
              </label>
              <input
                onChange={(e) => setWhereItHappened(e.target.value)}
                value={whereItHappened}
                type="text"
                name="whereItHappened"
                id="whereItHappened"
                placeholder="Enter where did it happen"
              />
            </div>

            <div className="doing-what flex-column field">
              <label htmlFor="doingWhat">
                What were you doing at the time?
              </label>
              <RichTexField value={doingWhat} onEditorChange={setDoingWhat} />
            </div>
            <div className="what-led-to flex-column field">
              <label htmlFor="whatLedTo">
                Describe step by step what led up to the injury/near miss.
              </label>
              <RichTexField value={whatLedTo} onEditorChange={setWhatLedTo} />
            </div>
            <div className="done-to-prevent flex-column field">
              <label htmlFor="doneToPrevent">
                What could have been done to prevent this injury/near miss?
              </label>
              <RichTexField
                value={DoneToPrevent}
                onEditorChange={setDoneToPrevent}
              />
            </div>
          </div>
          <div className="step inputs-group">
            <div className="being-injured flex-column field">
              <label htmlFor="beingInjured">
                What parts of your body were injured? If a near miss, how could
                you have been hurt?
              </label>
              <RichTexField
                value={beingInjured}
                onEditorChange={setBeingInjured}
              />
            </div>
            <div className="check-box">
              <input
                onChange={handleSeenDoctor}
                checked={seenDoctor}
                type="checkbox"
                name="seenDoctor"
                id="seenDoctor"
              />
              <label htmlFor="seenDoctor">
                Check this if you have seen a doctor about this injury/illness?
              </label>
            </div>

            {seenDoctor && (
              <>
                {" "}
                <div className="half">
                  <div className="field flex-column doctor-name">
                    <label htmlFor="doctorName">
                      If yes, whom did you see?
                    </label>
                    <input
                      onChange={(e) => setDoctorName(e.target.value)}
                      value={doctorName}
                      type="text"
                      name="doctorName"
                      id="doctorName"
                      placeholder="Enter doctor's name"
                    />
                  </div>

                  <div className="field flex-column doctor-phone">
                    <label htmlFor="doctorPhone">Doctor's phone number:</label>
                    <input
                      onChange={(e) => setDoctorPhone(e.target.value)}
                      value={doctorPhone}
                      type="tel"
                      name="doctorPhone"
                      id="doctorPhone"
                      placeholder="Enter doctor's phone number"
                    />
                  </div>
                </div>
                <div className="half">
                  <div className="time-seen-doctor flex-column field">
                    <label htmlFor="dateSeenDoctor">Date</label>
                    <CustomDatePicker
                      selectedDate={dateSeenDoctor}
                      setSelectedDate={setDateSeenDoctor}
                    />
                  </div>

                  <div className="time-seen-doctor flex-column field">
                    <label htmlFor="timeSeenDoctor">Time</label>
                    <CustomTimeInput setTime={setTimeSeenDoctor} />

                  </div>
                </div>
              </>
            )}

            <div className="check-box">
              <input
                onChange={handleInjuredBody}
                checked={injuredBody}
                type="checkbox"
                name="injuredBody"
                id="injuredBody"
              />
              <label htmlFor="injuredBody">
                Check this if part of your body has been injured before.
              </label>
            </div>

            {injuredBody && (
              <div className="when-injured flex-column field">
                <label htmlFor="whenInjured">When</label>
                <CustomDatePicker
                  selectedDate={whenInjured}
                  setSelectedDate={setWhenInjured}
                />
              </div>
            )}

            <div className="field full">
              <h3>Supporting documents</h3>
              <FilesList documents={uploadedFiles} />

              {uploadingDocuments ? (
                "Uploading ..."
              ) : (
                <input
                  type="file"
                  onChange={handleFileChange}
                  name="files"
                  id="files"
                  multiple
                />
              )}
            </div>
          </div>
        </form>
      }
    </div>
  );
};

export default ModifyEmployeeIncident;
