import React from "react";
import FormatDateTime from '../../../../components/general/formatDateTime'
import NamesInitials from "../../../../components/general/namesInitials";

const WorkDetails = ({ fullName, sex, IncidentDate, incidentCategory, incidentTime, incidentDetails }) => {
    return (
        <div className="patient-name-type">
            <div className="date">Incident Date & Time  <FormatDateTime date={IncidentDate} /></div>
            <div className="name-profile">
                <div className="profile">
                    <div className="profile-pic">
                        <NamesInitials fullName={fullName || 'Not provided'} />
                    </div>
                    <div className="name-sex">
                        <h3 className="name">{fullName || 'Not provided'}</h3>
{/*                         <small className="sex">{sex || 'Sex: Not provided'}</small> */}
                    </div>
                </div>
                <div className="patient-type">
                    <p>{incidentCategory || 'Not provided'}</p>
                </div>
            </div>
            {incidentDetails}
        </div>
    )
}

export default WorkDetails