import React, { useEffect, useState } from 'react'
import api from '../../../../api'
import CustomSelectInput from '../inputs/customSelect'
import { FloppyDiskIcon, Loading03Icon } from 'hugeicons-react'

const NewUserForm = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [email, setEmail] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [department, setDepartment] = useState("")
    const [selectedDepartment, setSelectedDepartment] = useState("")
    const [userRole, setUserRole] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [gender, setGender] = useState("")
    const [dateOfBirth, setDateOfBirth] = useState("")
    const [address, setAddress] = useState("")
    const [birthCountry, setBirthCountry] = useState("")
    const [profilePicture, setProfilePicture] = useState("")

    const [departments, setDepartments] = useState([])
    const [positions, setPositions] = useState([])
    const [gettingDepartments, setGatheringDepartments] = useState(true)
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState()
    useEffect(() => {
        // Fetch departments
        const fetchDepartments = async () => {
            try {
                const response = await api.get('/departments/')
                setDepartments(response.data.departments)
                setGatheringDepartments(false)
                console.log("departments", response.data.departments)
            } catch (error) {
                console.error(error)
                setErrorMessage("Error getting departments")
            }
        }
        fetchDepartments()

        //fetchPositions
        const fetchPositions = async () => {
            try {
                const response = await api.get('/accounts/permissions/all/')
                setPositions(response.data.permissions)
                console.log("positions", response.data.permissions)
            } catch (error) {
                console.error(error)
                setErrorMessage("Error getting positions")
            }
        }
        fetchPositions()



    }, [])


    const handleNewUser = async () => {
        const departmentId = departments.find(dept => dept.name === department)?.id
        console.log(departmentId, department)

        if (!firstName || !lastName || !email || !userRole || !department) {
            alert("Please fill in all required fields")
            return
        }

        const userData = {
            "first_name": firstName,
            "last_name": lastName,
            "email": email,
            "phone_number": phoneNumber,
            "gender": gender,
            "date_of_birth": dateOfBirth,
            "address": address,
            "birth_country": birthCountry,
            "profile_picture": profilePicture,
            "department_name": department,
            "role": userRole
        }

        try {
            setIsLoading(true)
            const response = await api.post('/accounts/users/new/', userData)
            if (response.status === 201) {
                setSuccessMessage("User is added successfully")
                setIsLoading(false)
                console.log(response.data)

                setTimeout(() => {
                    setSuccessMessage("")
                }, 2000);
            }
        } catch (error) {
            if (error.response) {
                setErrorMessage(error.response.data.message || error.response.data.error || 'Error while creating a new user')
            } else {
                setErrorMessage('Unknown error creating a new user')
            }

            console.error(error)
            setIsLoading(false)
            setTimeout(() => {
                setErrorMessage("")
            }, 5000);

        }
    }
    return (
        <div className="form">
            <form className='form'>
                {
                    errorMessage && <div className="error-message">{errorMessage}</div>
                }
                <div className="half">
                    <div className="field">
                        <label htmlFor="">First name <span>*</span></label>
                        <input type="text" name="firstName" id="firstName" placeholder='Enter user first name' onChange={(e) => setFirstName(e.target.value)} />
                    </div>
                    <div className="field">
                        <label htmlFor="">Last name <span>*</span></label>
                        <input type="text" name="lastName" id="lastName" placeholder='Enter user last name' onChange={(e) => setLastName(e.target.value)} />
                    </div>
                </div>
                <div className="half">
                    <div className="field">
                        <label htmlFor="">Email <span>*</span></label>
                        <input type="email" name="email" id="email" placeholder='Enter user email' onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="field">
                        <label htmlFor="">Phone number</label>
                        <input type="tel" name="phoneNumber" id="phoneNumber" placeholder='Enter user phone number' onChange={(e) => setPhoneNumber(e.target.value)} />
                    </div>
                </div>
                <div className="half">
                    <div className="field">
                        <label htmlFor="">Sex</label>
                        <CustomSelectInput options={["Male", "Female", "Other"]} placeholder={"Select sex"} selected={gender} setSelected={setGender} />
                    </div>
                    <div className="field">
                        <label htmlFor="">Date of birth</label>
                        <input type="date" name="dateOfBirth" id="dateOfBirth" onChange={(e) => setDateOfBirth(e.target.value)} />
                    </div>
                </div>
                <div className="half">
                    <div className="field">
                        <label htmlFor="">Address</label>
                        <input type="text" name="address" id="address" placeholder='Enter user address' onChange={(e) => setAddress(e.target.value)} />
                    </div>
                    <div className="field">
                        <label htmlFor="">Birth country</label>
                        <input type="text" name="birthCountry" id="birthCountry" placeholder='Enter user birth country' onChange={(e) => setBirthCountry(e.target.value)} />
                    </div>
                </div>
                <div className="field">
                    <label htmlFor="">Profile picture</label>
                    <input type="file" name="profilePicture" id="profilePicture" onChange={(e) => setProfilePicture(e.target.files[0])} />
                </div>
                <h4>Permissions</h4>
                <div className="half">
                    <div className="field">
                        <label htmlFor="">Department <span>*</span> </label>
                        <CustomSelectInput selected={department} setSelected={setDepartment} options={departments.map(department => department.name)} />
                    </div>
                    {/* data list input */}
                    <div className="field">
                        <label htmlFor="">Position <span>*</span></label>
                        <input onChange={(e) => setUserRole(e.target.value)} value={userRole} type="text" name="userPosition" id="userPosition" list='positionsSuggestions' placeholder='Start typing or select' />
                        <datalist id="positionsSuggestions">
                            {positions.map(position => <option key={position.id} value={position} />)}
                        </datalist>
                    </div>
                </div>
            </form>
            {
                successMessage && <div className="success-message">{successMessage}</div>
            }
            <button onClick={handleNewUser} type="button" className="primary-button">
                {
                    isLoading ? <><Loading03Icon size={20} className='loading-icon' /> Saving</> : <><FloppyDiskIcon size={20} />Add user</>
                }
            </button>
        </div>
    )
}

export default NewUserForm
