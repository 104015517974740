import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashBoardContainer from "../dashboardContainer";
import IncidentDetailsHeader from "./incidentDetails/incidentDetailsHeader";
import IncidentDetails from "./incidentDetails/incidentDetails";
import WorkDetails from "./workplaceviolence/workDetails";
import IncidentTabs from "./incidentDetails/incidentDetailsTabs";
import GeneralIncidentDetailsContentTab from "./generalIncident/incidentDetails";
import WorkPlaceDetailsContentTab from "./workplaceviolence/workplace";
import WorkPlaceGeneralInfo from "./workplaceviolence/workplaceGeneralInfo";
import WorkPlaceOtherInfo from "./workplaceviolence/workplaceOtherInformation";
import api, { API_URL } from "../../../api";
import toast from "react-hot-toast";
import "../../../assets/css/pages/incidents/general/generalIncidentDetailsPage.css";
import GeneralIncidentOtherInformation from "./generalIncident/otherInformation";
import GeneralIncidentGeneralInformation from "./generalIncident/generalInformation";
import WorkplaceDocumentHistory from "./workplaceviolence/workplaceDocumentHistory";
import WorkplaceReviews from "./workplaceviolence/workplaceReviews";
import { ArrowRight01Icon } from "hugeicons-react";
import { Link } from "react-router-dom";
import FilesList from "../../../components/documents/filesList";

const WorkPlaceDetailsContent = () => {
  const { workplace_violence_Id } = useParams();
  const [isFetching, setIsFetching] = useState(true);
  const [incidentDetails, setIncidentDetails] = useState({});
  const [incidentStatus, setIncidentStatus] = useState({});

  useEffect(() => {
    const fetchIncidentDetails = async () => {
      setIsFetching(true);
      try {
        const response = await api.get(
          `${API_URL}/incidents/workplace_violence/${workplace_violence_Id}/`
        );

        if (response.status === 200) {
          setIncidentStatus(response.data.statuses);
          setIncidentDetails(response.data);
          console.log(response.data);
          setIsFetching(false);
        }
      } catch (error) {
        console.log(error);
        setIsFetching(false);
      }
    };

    fetchIncidentDetails();
    console.log(incidentDetails);
  }, []);
  useEffect(() => {
    const getIncidentReviews = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/workplace_violence/${workplace_violence_Id}/reviews/`
        );
        if (response.status === 200) {
          localStorage.setItem("incidentReviewsCount", response.data.length);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          toast.error("Authentication error");
          window.location.reload();
        } else {
          toast.error("Failed to fetch incident reviews");
          console.error(error);
        }
      }
    };
    getIncidentReviews();
  }, []);
  useEffect(() => {
    const getDocumentHistory = async () => {
      try {
        const response = await api.get(
          `${API_URL}/activities/list/${workplace_violence_Id}/`
        );
        if (response.status === 200) {
          localStorage.setItem("documentHistoryCount", response.data.length);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          toast.error("Authentication error");
          window.location.reload();
        } else {
          toast.error("Failed to fetch document History");
          console.error(error);
        }
      }
    };
    getDocumentHistory();
  }, []);
  return (
    <div className="incident-details-page">
      {isFetching ? (
        <div className="fetching-data">Loading data</div>
      ) : (
        <div className="incident-details">
          <IncidentDetailsHeader
            data={incidentDetails}
            incidentId={workplace_violence_Id}
            apiLink={"workplace_violence"}
            sendTo={"send-to-department"}
          />
          <div className="details">
            <WorkDetails
              data={incidentDetails}
              fullName={incidentDetails.staff_member_reported}
              /* sex={incidentDetails.sex} */
              IncidentDate={incidentDetails.date_of_incident}
              incidentTime={incidentDetails.time_of_incident}
              incidentCategory={incidentDetails.incident_type}
              incidentDetails={
                <WorkPlaceDetailsContentTab data={incidentDetails} />
              }
            />
            <IncidentTabs
              data={incidentDetails}
              statuses={incidentStatus}
              generalInformation={
                <WorkPlaceGeneralInfo
                  data={incidentDetails}
                  incidentStatuses={incidentStatus}
                />
              }
              otherInformation={<WorkPlaceOtherInfo data={incidentDetails} />}
              documentHistory={
                <WorkplaceDocumentHistory incidentId={workplace_violence_Id} />
              }
              reviews={<WorkplaceReviews incidentId={workplace_violence_Id} />}
              documents={
                <IncidentDocuments incidentId={workplace_violence_Id} />
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

const BreadCrumbs = () => {
  const { workplace_violence_Id } = useParams();
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />
      <Link to={"/incidents/"}>Incidents</Link> <ArrowRight01Icon />
      <Link to={"/incident/workplace-violence/"}>
        Work Place Violence List
      </Link>{" "}
      <ArrowRight01Icon />
      <Link className="current-page"> #{workplace_violence_Id}</Link>
    </div>
  );
};

const IncidentDocuments = ({ incidentId, apiLink }) => {
  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await api.get(
          `/incidents/workplace_violence/${incidentId}/documents/`
        );
        if (response.status === 200) {
          setDocuments(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchDocuments();
  }, []);
  return <FilesList documents={documents} showDownload={true} />;
};

const WorkPlaceDetails = () => {
  return (
    <div>
      <DashBoardContainer
        content={<WorkPlaceDetailsContent />}
        breadCrumbs={<BreadCrumbs />}
      />
    </div>
  );
};

export default WorkPlaceDetails;
