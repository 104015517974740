import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuthentication } from "../../contexts/authContext";
import { AccountSetting03Icon, CustomerSupportIcon, GroupItemsIcon, House04Icon, Layers01Icon, LockKeyIcon, UserMultipleIcon } from "hugeicons-react";
const SideBar = ({ isMobileMenuOpen }) => {
    const currentPath = window.location.pathname;
    const { logout } = useAuthentication()

    return (
        <div className={isMobileMenuOpen ? "side-bar is-menu-open" : "side-bar"}>
            <div className="links">
                <div className="branding">
                    <Link
                        className="logo">
                        <img src={require("../../assets/img/branding/logo.png")} alt="logo" />
                        <h1 className="site-title title">Quality Control</h1>
                    </Link>
                </div>

                <div className="menu-links">
                    <Link
                        to={"/"}
                        className={currentPath === "/" ? "link active" : "link"}>
                        <div className="icon"><i className="fa-solid fa-gauge"></i></div> <span className="link-text">Overview</span>
                    </Link>

                    <Link

                        to={"/incidents/"}
                        className={currentPath === "/incidents/" ? "link active" : "link"}>
                        <div className="icon"><i className="fa-solid fa-dumbbell"></i></div> <span className="link-text">Incident Tracking</span>
                    </Link>
                    {/* <Link

                        to={"/departments/"}
                        className={currentPath === "/departments/" ? "link active" : "link"}>
                        <div className=""><Layers01Icon /></div> <span className="link-text">Departments</span>
                    </Link> */}

                    {/* <Link

                        to={"/users/"}
                        className={currentPath === "/users/" ? "link active" : "link"}>
                        <div className=""><UserMultipleIcon /></div> <span className="link-text">Users</span>
                    </Link> */}
                    {/* <Link

                        to={"/permissions/"}
                        className={currentPath === "/users/" ? "link active" : "link"}>
                        <div className=""><LockKeyIcon /></div> <span className="link-text">Permissions</span>
                    </Link> */}

                    <Link

                        to={"/facilities/"}
                        className={currentPath === "/facilities/" ? "link active" : "link"}>
                        <div className=""><House04Icon /></div> <span className="link-text">Facilities</span>
                    </Link>

                    {/* <Link

                        to={"/patients-records/"}
                        className={currentPath === "/patients-records/" ? "link active" : "link"}>
                        <div className="icon"><i className="fa-solid fa-shield-halved"></i></div> <span className="link-text">Patient records</span>
                    </Link>

                    <Link

                        to={"/quality-management/"}
                        className={currentPath === "/quality-management/" ? "link active" : "link"}>
                        <div className="icon"><i className="fa-solid fa-percent"></i></div> <span className="link-text">Quality Management</span>
                    </Link>

                    <Link

                        to={"/patient-relations/"}
                        className={currentPath === "/patient-records/" ? "link active" : "link"}>
                        <div className="icon"><i className="fa-regular fa-circle-user"></i></div> <span className="link-text">Patient Relations</span>
                    </Link>


                    <Link
                        to={"/reports/"}
                        className={currentPath === "/reports/" ? "link active" : "link"}>
                        <div className="icon"><i className="fa-solid fa-chart-simple"></i></div> <span className="link-text">Report and Analytics</span>
                    </Link> */}
                </div>
            </div>

            <div className="menu-actions">
                {/* <Link to={'/admin/'} className={currentPath === "/admin/" ? "link active" : "link"}>
                    <div className="icon"><AccountSetting03Icon /></div> <span className="link-text">Admin</span>
                </Link> */}

                <Link to={'mailto:alphonse@compstaffing.com'} className={currentPath === "/admin/" ? "link active" : "link"}>
                    <div className="icon"><CustomerSupportIcon /></div> <span className="link-text">Support</span>
                </Link>
            </div>

        </div>
    )
}

export default SideBar