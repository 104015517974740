import React from "react";

const GrievanceDetailsContentTab = ({ data }) => {
  return (
    <div className="incident-details">
      <div className="number-mrn">
        <div className="phone-number">
          <small>Phone number</small>
          <h4>{data.phone_number || "Not provided"}</h4>
        </div>
        <div className="mrn">
          <small>MRN</small>
          <h4>{data.medical_record_number || "Not provided"}</h4>
        </div>
      </div>

      <div className="address">
        <div className="street">
          <small>Date of birth</small>
          <h4>{data.date_of_birth || "Not provided"}</h4>
        </div>
        <div className="zip-code">
          <small>Age</small>
          <h4>{data.age || "Not provided"}</h4>
        </div>
      </div>

      <div className="address">
        <div className="street">
          <small>Who made the complaint?</small>
          <h4>{data.complaint_made_by || "Not provided"}</h4>
        </div>
        <div className="state">
          <small>Relationship to patient</small>
          <h4>{data.relationship_to_patient || "Not provided"}</h4>
        </div>
        <div className="zip-code">
          <small>Source of information</small>
          <h4>{data.source_of_information || "Not provided"}</h4>
        </div>
      </div>

      <div className="address">
        <div className="location">
          <small>Administrator Notified</small>
          <h4>{data.administrator_notified || "Not provided"}</h4>
        </div>
        <div className="status">
          <small>Date & Time</small>
          <h4>{data.date || "Not provided"}</h4>
        </div>
      </div>

    </div>
  );
};

export default GrievanceDetailsContentTab;
