import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import api from "../../../../api";
import BackToPage from "../../backToPage";
import {
  AddCircleIcon,
  CheckmarkSquare01Icon,
  FloppyDiskIcon,
  Loading03Icon,
  RemoveCircleIcon,
  SquareIcon,
} from "hugeicons-react";
import CustomSelectInput from "../inputs/customSelect";
import CustomDatePicker from "../inputs/datePicker";
import RichTexField from "../inputs/richTexField";
import {
  drugRoutes,
  incident_agreement,
  outComeData,
} from "../../../../constants";
import postDocumentHistory from "../documentHistory/postDocumentHistory";
import FilesList from "../../../documents/filesList";
import mediaAPI from "../../../../mediaApi";
import CustomTimeInput from "../inputs/customTimeInput";
const ModifyAdverseDruReactionForm = ({ data }) => {
  const { incidentId } = useParams();
  const [incident, setIncident] = useState(data);
  const [currentStep, setCurrentStep] = useState(1);
  const currentStepRef = useRef(currentStep);
  const [isLoading, setIsLoading] = useState(false);

  // form
  const [outComeType, setOutComeType] = useState("mild");
  const [name, setName] = useState(incident.patient_name);
  const [sex, setSex] = useState(incident.sex);
  const [incidentDate, setIncidentDate] = useState(incident.incident_date);
  const [incidentTime, setIncidentTime] = useState(incident.incident_time);
  const [incidentMr, setIncidentMr] = useState(incident.medical_record_number);
  const [address, setAddress] = useState(incident.address);
  const [state, setState] = useState(incident.state);
  const [zipCode, setZipCode] = useState(incident.zip_code);
  const [phoneNumber, setPhoneNumber] = useState(incident.phone_number);
  const [victimType, setVictimType] = useState(incident.patient_type);
  const [otherStatus, setOtherStatus] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [location, setLocation] = useState("");
  const [contributingDiagnosis, setContributingDiagnosis] = useState("");
  const [isIv, setIsIv] = useState(false);
  const [isReactionTreated, setIsReactionTreated] = useState(
    incident.reaction_was_treated
  );
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadingDocuments, setUploadingDocuments] = useState(false);
  const [provider, setProvider] = useState(incident.provider);
  const [observersName, setObserversName] = useState("");
  const [observersNameArray, setObserversNameArray] = useState(
    incident.observers_name || []
  );
  const [timeOfReport, setTimeOfReport] = useState(incident.time_of_report);
  const [dateOfReport, setDateOfReport] = useState(incident.date_of_report);
  const [eventDetails, setEventDetails] = useState(incident.event_detail);
  const [suspectedMedication, setSuspectedMeedication] = useState(
    incident.suspected_medication
  );
  const [dose, setDose] = useState(incident.dose);
  const [frequency, setFrequency] = useState(incident.frequency);
  const [route, setRoute] = useState(incident.route);
  const [rateOfAdministration, setRateOfAdministration] = useState(
    incident.rate_of_administration
  );
  const [dateOfMedicationOrder, setDateOfMedicationOrder] = useState(
    incident.date_of_medication_order
  );
  const [dateInformation, setDateInformation] = useState(
    incident.date_of_information
  );
  const [reaction, setReaction] = useState(incident.information_reaction);
  const [adverseReactionDate, setAdverseReactionDate] = useState(
    incident.date_of_adverse_reaction
  );
  const [reactionSetTime, setReactionSetTime] = useState(
    incident.reaction_on_settime
  );
  const [selectedAgreements, setSelectedAgreements] = useState(
    incident.incident_type_classification || []
  );
  const [physicianNotified, setPhysicianNotified] = useState(
    incident.name_of_physician_notified
  );
  const [physcianDate, setPhyscianDate] = useState(
    incident.date_physician_was_notified
  );
  const [physcianTime, setPhyscianTime] = useState(
    incident.time_physician_was_notified
  );
  const [familyNotified, setFamilyNotified] = useState(
    incident.name_of_family_notified
  );
  const [otherOutcome, setOtherOutcome] = useState("");
  const [familyDate, setFamilyDate] = useState(
    incident.date_family_was_notified
  );
  const [familyTime, setFamilyTime] = useState(
    incident.time_family_was_notified
  );
  const [notifiedBy, setNotifiedBy] = useState(incident.notified_by);
  const [briefSummary, setBriefSummary] = useState(
    incident.brief_summary_incident
  );
  const [immediateActionsTaken, setImmediateActionsTaken] = useState(
    incident.immediate_actions_taken
  );
  const [description, setDescription] = useState(
    incident.other_route_description
  );
  const [nurseNote, setNurseNote] = useState(incident.nurse_note);
  const [progressNote, setProgressNote] = useState(incident.progress_note);
  const [otherNote, setOtherNote] = useState(
    incident.other_information_can_be_found_in
  );
  const [otherNoteDescription, setOtherNoteDescription] = useState(
    incident.other_information_description
  );
  const [treatmentDescription, setTreatmentDescription] = useState(
    incident.treatment_description
  );
  const [agreementDescription, setAgreementDescription] = useState("");
  const [outcomeType, setOutcomeType] = useState(incident.outcome_type);
  const [outcomeDescription, setOutcomeDescription] = useState(
    incident.description
  );
  const [adrOutcome, setAdrOutcome] = useState(incident.anaphylaxis_outcome);
  const [fdaReported, setFdaReported] = useState(
    incident.adverse_event_to_be_reported_to_FDA
  );

  useEffect(() => {
    // get documents
    const fetchIncidentDocuments = async () => {
      try {
        const response = await api.get(
          `/incidents/adverse_drug_reaction/${data.id}/documents/`
        );
        if (response.status === 200) {
          setUploadedFiles(response.data);
          console.log("documents updated successfully");
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchIncidentDocuments();
  }, []);

  const handleFileChange = async (event) => {
    const formData = new FormData();
    const files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    try {
      setUploadingDocuments(true);
      console.log([...formData]);

      const response = await mediaAPI.post(
        `/incidents/adverse_drug_reaction/${data.id}/documents/new/`,
        formData
      );

      if (response.status === 200 || response.status === 201) {
        console.log(response.data.files);
        setUploadingDocuments(false);
        toast.success("Files uploaded successfully");
        setUploadedFiles(response.data.files);
      }
    } catch (error) {
      toast.error("Error uploading documents");
      setUploadingDocuments(false);
      console.log(error);
    }
  };

  const handleObserversNameArray = (value) => {
    setObserversNameArray([...observersNameArray, value]);
    setObserversName("");
  };

  const handleRemoveObserverName = (name) => {
    setObserversNameArray((observersNameArray) =>
      observersNameArray.filter((el) => el !== name)
    );
  };

  const handleReactionTreated = () => {
    setIsReactionTreated(!isReactionTreated);
    setTreatmentDescription("");
  };

  const handleSelection = (agreementName) => {
    setSelectedAgreements((prevSelected) => {
      if (prevSelected.includes(agreementName)) {
        // Remove if already selected
        return prevSelected.filter((name) => name !== agreementName);
      } else {
        // Add if not already selected
        return [...prevSelected, agreementName];
      }
    });
  };

  const handleOutcomeType = (value) => {
    setOutcomeType(value);
    setOutcomeDescription("");
  };

  const handleOutcomeDescription = (value) => {
    setOutcomeDescription(value);
  };

  const handleProgressNote = () => {
    setOtherNote(false);
    setProgressNote(true);
    setNurseNote(false);
    setOtherNoteDescription("");
  };

  const handleModify = async () => {
    setIsLoading(true);
    const incidentData = {
      patient_type: victimType,
      patient_name: name,
      sex: sex,
      incident_date: incidentDate,
      incident_time: incidentTime,
      medical_record_number: incidentMr,
      address: address,
      state: state,
      zip_code: zipCode,
      phone_number: phoneNumber,
      provider: provider,

      observers_name: observersNameArray,
      time_of_report: timeOfReport,
      date_of_report: dateOfReport,
      event_detail: eventDetails,

      suspected_medication: suspectedMedication,
      dose: dose,
      frequency: frequency,
      route: route,
      rate_of_administration: rateOfAdministration,
      date_of_medication_order: dateOfMedicationOrder,
      other_route_description: description,

      date_of_information: dateInformation,
      information_reaction: reaction,
      date_of_adverse_reaction: adverseReactionDate,
      reaction_on_settime: reactionSetTime,
      nurse_note: nurseNote,
      progress_note: progressNote,
      other_information_can_be_found_in: otherNote,
      other_information_description: otherNoteDescription,
      reaction_was_treated: isReactionTreated,
      treatment_description: treatmentDescription,

      incident_type_classification: selectedAgreements,

      outcome_type: outcomeType,
      description: outcomeDescription,
      anaphylaxis_outcome: adrOutcome,
      adverse_event_to_be_reported_to_FDA: fdaReported,
      name_of_physician_notified: physicianNotified,
      date_physician_was_notified: physcianDate,
      time_physician_was_notified: physcianTime,
      name_of_family_notified: familyNotified,
      date_family_was_notified: familyDate,
      time_family_was_notified: familyTime,
      notified_by: notifiedBy,
      brief_summary_incident: briefSummary,
      immediate_actions_taken: immediateActionsTaken,
    };
    try {
      console.log("request data: ", incidentData);
      const response = await api.patch(
        `/incidents/adverse_drug_reaction/${data.id}/modify-incident/`,
        incidentData
      );
      if (response.status === 200) {
        toast.success("Incident modified successfully");
        setIncident(response.data.incident);
        setIsLoading(false);
        console.log("Response: ", response.data.incident);
        postDocumentHistory(data.id, "modified this incident", "modify");
      }
    } catch (error) {
      if (error.response) {
        toast.error(
          error.response.data.error ||
          error.response.data.message ||
          "Error updating incident"
        );
      } else {
        toast.error("Unknown error updating incident");
        console.log(error);
      }

      setIsLoading(false);
    }
  };

  return (
    <div className="modify-page-content">
      <div className="modify-page-header">
        <BackToPage link={"/"} pageName={"ADDR incident"} />
        <h2 className="title">Modifying Adverse drug incident</h2>
        <button className="tertiary-button" onClick={handleModify}>
          {isLoading ? (
            <>
              <Loading03Icon className="loading-icon" size={18} />
              <span>Saving changes</span>
            </>
          ) : (
            <>
              <FloppyDiskIcon size={20} />
              <span>Save changes</span>
            </>
          )}
        </button>
      </div>
      <div className="modify-incident-page">
        <form className="modify-forms">
          <div className="inputs-group modify-inputs">
            <h3 className="full">General Info</h3>
            <div className="field date">
              <label htmlFor="">Select type</label>
              <CustomSelectInput
                options={["Inpatient", "Outpatient", "ER", "Visitor"]}
                selected={victimType}
                setSelected={setVictimType}
                placeholder={"Select incident Category"}
              />
            </div>
            <div className="field">
              <label htmlFor="patientName">Patient/Visitor name</label>
              <input
                onChange={(e) => setName(e.target.value)}
                value={name}
                type="text"
                name="patientName"
                id="patientName"
                placeholder="Patient or visitors name"
              />
            </div>
            <div className="field name">
              <div className="sex field">
                <label htmlFor="sex">Sex</label>
                <CustomSelectInput
                  options={["Male", "Female", "Other"]}
                  selected={sex}
                  setSelected={setSex}
                  placeholder={"sex"}
                />
              </div>
            </div>
            <div className="half">
              <div className="incident-date field">
                <label htmlFor="incidentDate">Incident Date</label>
                <CustomDatePicker
                  selectedDate={incidentDate}
                  setSelectedDate={setIncidentDate}
                />
              </div>

              <div className="incident-time field">
                <label htmlFor="incidentTime">Incident Time</label>
                <CustomTimeInput setTime={setIncidentTime} />

              </div>
            </div>
            <div className="field">
              <label htmlFor="incidentMr">Medical Record Number</label>
              <input
                onChange={(e) => setIncidentMr(e.target.value)}
                value={incidentMr}
                type="text"
                name="incidentMr"
                id="incidentMr"
                placeholder="Enter MR"
              />
            </div>

            <div className="field">
              <label htmlFor="address">Address</label>
              <input
                onChange={(e) => setAddress(e.target.value)}
                value={address}
                type="text"
                name="address"
                placeholder="Enter patient or visitor address"
              />
            </div>

            <div className="field">
              <label htmlFor="state">State</label>
              <input
                onChange={(e) => setState(e.target.value)}
                value={state}
                type="text"
                name="state"
                id="state"
                placeholder="Enter  patient or visitor state"
              />
            </div>
            <div className="field">
              <label htmlFor="zipCode">Zip Code</label>
              <input
                onChange={(e) => setZipCode(e.target.value)}
                value={zipCode}
                type="text"
                name="zipCode"
                id="zipCode"
                placeholder="Zip code"
              />
            </div>

            <div className="field">
              <label htmlFor="phoneNumber">Phone number</label>
              <input
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={phoneNumber}
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                placeholder="Phone number"
              />
            </div>
          </div>

          <div className="inputs-group modify-inputs">
            <div className="field">
              <label htmlFor="provider">Provider</label>
              <input
                onChange={(e) => setProvider(e.target.value)}
                value={provider}
                type="text"
                name="provider"
                id="provider"
                placeholder="Enter Provider"
              />
            </div>
            <div className="half">
              <div className="field">
                <label htmlFor="dateOfReport">Date date of report</label>
                <CustomDatePicker
                  selectedDate={dateOfReport}
                  setSelectedDate={setDateOfReport}
                />
              </div>
              <div className="field">
                <label htmlFor="timeOfReport">Time of report</label>
                <CustomTimeInput setTime={setTimeOfReport} />

              </div>
            </div>
            <div className="field name">
              <label htmlFor="eventDetails">Event Detail</label>
              <input
                onChange={(e) => setEventDetails(e.target.value)}
                value={eventDetails}
                type="text"
                name="eventDetails"
                id="eventDetails"
                placeholder="Enter Event Detail"
              />
            </div>
            <div className="field full">
              <label htmlFor="observersName">Observers' Names</label>
              <p>Enter a name and click add button</p>
              <div
                className="parties"
                style={{ flexDirection: "row", flexWrap: "wrap" }}
              >
                {observersNameArray.length > 0 &&
                  observersNameArray.map((name, index) => (
                    <button
                      type="button"
                      key={index}
                      className="new-party"
                      onClick={() => handleRemoveObserverName(name)}
                    >
                      {name}
                      <RemoveCircleIcon />
                    </button>
                  ))}
              </div>
              <input
                value={observersName}
                onChange={(e) => setObserversName(e.target.value)}
                type="text"
                name="observersName"
                id="observersName"
                placeholder="Enter observers name"
              />
              <div className="parties">
                <button
                  type="button"
                  className="new-party"
                  onClick={() => handleObserversNameArray(observersName)}
                >
                  <AddCircleIcon />
                  Add Observer
                </button>
              </div>
            </div>

            {selectedStatus === "others" ? (
              <div className="other-field">
                <div className="field name">
                  <input
                    onChange={(e) => setOtherStatus(e.target.value)}
                    value={otherStatus}
                    type="text"
                    name="otherStatus"
                    id="otherStatus"
                    placeholder="Enter other status"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="inputs-group modify-inputs">
            <div className="field">
              <label htmlFor="suspectedMedication">Suspected medication</label>
              <input
                type="text"
                name="suspectedMedication"
                id="suspectedMedication"
                placeholder="Enter suspected medication"
                value={suspectedMedication}
                onChange={(e) => setSuspectedMeedication(e.target.value)}
              />
            </div>
            <div className="field">
              <label htmlFor="dose">Dose</label>
              <input
                type="text"
                name="dose"
                id="dose"
                placeholder="Enter dose"
                value={dose}
                onChange={(e) => setDose(e.target.value)}
              />
            </div>

            <div className="field name">
              <label htmlFor="incidentLocation">Route</label>
              <CustomSelectInput
                options={drugRoutes.map((route) => route.label)}
                selected={route}
                setSelected={setRoute}
                placeholder={"route"}
              />
            </div>
            <div className="field">
              <label htmlFor="frequency">Frequency</label>
              <input
                type="text"
                name="frequency"
                id="frequency"
                placeholder="Enter frequency"
                value={frequency}
                onChange={(e) => setFrequency(e.target.value)}
              />
            </div>
            {route &&
              (route === "Other" ||
                route === "IV Push" ||
                route === "IV Drip") && (
                <div className="field full">
                  <label htmlFor="">Description</label>
                  <input
                    type="text"
                    name="description"
                    id="description"
                    placeholder="Enter description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              )}

            <div className="field">
              <label htmlFor="rateOfAdministration">
                Rate of administration (if IV)
              </label>
              <input
                type="text"
                name="rateOfAdministration"
                id="rateOfAdministration"
                placeholder="Enter rate"
                value={rateOfAdministration}
                onChange={(e) => setRateOfAdministration(e.target.value)}
              />
            </div>

            <div className="field">
              <label htmlFor="dateOfMedicationOrder">
                Date of medication order
              </label>
              <CustomDatePicker
                selectedDate={dateOfMedicationOrder}
                setSelectedDate={setDateOfMedicationOrder}
              />
            </div>
          </div>
          <div className="inputs-group modify-inputs">
            <div className="field">
              <label htmlFor="dateInformation">
                Information on this reaction can be found on
              </label>
              <CustomDatePicker
                selectedDate={dateInformation}
                setSelectedDate={setDateInformation}
              />
            </div>

            <div className="field">
              <label htmlFor="informationIn">
                Information on this reaction can be found in
              </label>
              <CustomSelectInput
                options={[
                  "Nurse note",
                  "Progress note",
                  "imaging reports",
                  "Other",
                ]}
                placeholder={"source"}
                selected={progressNote}
                setSelected={handleProgressNote}
              />
            </div>

            {otherNote ? (
              <>
                <RichTexField
                  value={otherNoteDescription}
                  onEditorChange={setOtherNoteDescription}
                />
              </>
            ) : (
              ""
            )}

            <div className="field">
              <label htmlFor="reaction">Reaction</label>
              <input
                type="text"
                name="reaction"
                id="reaction"
                placeholder="Describe Reaction"
                value={reaction}
                onChange={(e) => setReaction(e.target.value)}
              />
            </div>
            <div className="field">
              <label htmlFor="adverseReactionDate">
                Date of adverse reaction
              </label>
              <CustomDatePicker
                selectedDate={adverseReactionDate}
                setSelectedDate={setAdverseReactionDate}
              />
            </div>
            <div className="field">
              <label htmlFor="isTreated">Reaction on-set time</label>
              <CustomTimeInput setTime={setReactionSetTime} />

            </div>
            <div className="field full">
              <div className="check-boxes">
                <div className="check-box">
                  <input
                    type="checkbox"
                    name="reactionTreated"
                    id="reactionTreated"
                    onChange={handleReactionTreated}
                    checked={isReactionTreated}
                  />
                  <label htmlFor="reactionTreated">
                    Check if reaction was treated
                  </label>
                </div>
              </div>
            </div>

            {isReactionTreated ? (
              <div className="field">
                <label htmlFor="treatmentDescription">Describe treatment</label>
                <RichTexField
                  value={treatmentDescription}
                  onEditorChange={setTreatmentDescription}
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="inputs-group modify-inputs">
            <h3 className="full">Incident type agreement</h3>
            <div className="grid-container full">
              {incident_agreement.map((agreement, index) => (
                <div
                  onClick={() => handleSelection(agreement.name)}
                  key={index}
                  className={`type grid-item`}
                >
                  {selectedAgreements.includes(agreement.name) ? (
                    <CheckmarkSquare01Icon />
                  ) : (
                    <SquareIcon />
                  )}
                  <span>{agreement.name}</span>
                </div>
              ))}
            </div>
            {selectedAgreements.includes("other (describe)") ? (
              <div className="field full">
                <RichTexField
                  value={agreementDescription}
                  onEditorChange={setAgreementDescription}
                />
              </div>
            ) : null}
          </div>
          <div className="inputs-group modify-inputs">
            <h2 className="full">Outcome</h2>
            <div className="check-boxes-container">
              <div className="check-box">
                <input
                  type="radio"
                  name="mildAdmission"
                  id="mildAdmission"
                  checked={outcomeType === "mild"}
                  onChange={() => handleOutcomeType("mild")}
                />
                <label htmlFor="mildAdmission">
                  Mild: required no intervention; no apparent harm to patient
                </label>
              </div>

              <div className="input-container check-box">
                <input
                  type="radio"
                  name="moderateAdmission"
                  id="moderateAdmission"
                  checked={outcomeType === "moderate"}
                  onChange={() => handleOutcomeType("moderate")}
                />
                <label htmlFor="moderateAdmission">Moderate</label>
              </div>
              <div>
                {outcomeType === "moderate"
                  ? outComeData.moderate.map((el, i) => (
                    <div key={i} className="outcome-data check-box">
                      <input
                        type="radio"
                        name="moderateOutcome"
                        id={el.name}
                        checked={outcomeDescription === el.name}
                        onChange={() => handleOutcomeDescription(el.name)}
                      />
                      <label htmlFor={el.name}>{el.name}</label>
                    </div>
                  ))
                  : null}
              </div>

              <div className="input-container check-box">
                <input
                  type="radio"
                  name="severeAdmission"
                  id="severeAdmission"
                  checked={outcomeType === "severe"}
                  onChange={() => handleOutcomeType("severe")}
                />
                <label htmlFor="severeAdmission">Severe</label>
              </div>
              <div>
                {outcomeType === "severe"
                  ? outComeData.severe.map((el, i) => (
                    <div key={i} className="outcome-data check-box">
                      <input
                        type="radio"
                        name="severeOutcome"
                        id={el.name}
                        checked={outcomeDescription === el.name}
                        onChange={() => handleOutcomeDescription(el.name)}
                      />
                      <label htmlFor={el.name}>{el.name}</label>
                    </div>
                  ))
                  : null}
              </div>

              <div className="half">
                <div className="check-boxes-container">
                  <h2>Anaphylaxis /ADR Outcome</h2>
                  <div className="grid-container">
                    {adrOutcome &&
                      adrOutcome.map((el, i) => (
                        <div className="check-box separator" key={i}>
                          <input
                            type="checkbox"
                            name={el.name}
                            id={el.name}
                            checked={adrOutcome === el.name}
                            onChange={() => setAdrOutcome(el.name)}
                          />
                          <label htmlFor={el.name}>{el.name}</label>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="check-box separator">
                <input
                  type="checkbox"
                  name="reportedToFDA"
                  id="reportedToFDA"
                  onChange={() => setFdaReported(!fdaReported)}
                />
                <label htmlFor="reportedToFDA">
                  Adverse event to be reported to fda
                </label>
              </div>
            </div>
          </div>
          <div className="inputs-group modify-inputs">
            <h1 className="full">Notification</h1>

            <div className="field">
              <label htmlFor="physicianNotified">
                Name of physician notified
              </label>
              <input
                onChange={(e) => setPhysicianNotified(e.target.value)}
                type="text"
                name="physicianNotified"
                id="physicianNotified"
                placeholder="Enter  name"
                value={physicianNotified}
              />
            </div>

            <div className="field">
              <label htmlFor="physcianDate">Date</label>
              <CustomDatePicker
                selectedDate={physcianDate}
                setSelectedDate={setPhyscianDate}
              />
            </div>
            <div className="field">
              <label htmlFor="physcianTime">Time</label>
              <CustomTimeInput setTime={setPhyscianTime} />

            </div>

            <div className="field">
              <label htmlFor="familyNotified">Name of family notified</label>
              <input
                onChange={(e) => setFamilyNotified(e.target.value)}
                type="text"
                name="familyNotified"
                id="familyNotified"
                value={familyNotified}
                placeholder="Enter the name"
              />
            </div>
            <div className="field">
              <label htmlFor="familyDate">Date</label>
              <CustomDatePicker
                selectedDate={familyDate}
                setSelectedDate={setFamilyDate}
              />
            </div>
            <div className="field">
              <label htmlFor="familyTime">Time</label>
              <CustomTimeInput setTime={setFamilyTime} />

            </div>
            <div className="field">
              <label htmlFor="physicianNotified">Notified by</label>
              <input
                onChange={(e) => setNotifiedBy(e.target.value)}
                type="text"
                name="notifiedBy"
                id="notifiedBy"
                placeholder="Enter  name"
                value={notifiedBy}
              />
            </div>
          </div>
          <div className="inputs-group modify-inputs">
            <h2 className="full">Other info</h2>
            <div className="half full">
              <div className="field">
                <label htmlFor="Brief Summary of incident">
                  Brief summary of incident
                </label>
                <RichTexField
                  value={briefSummary}
                  onEditorChange={setBriefSummary}
                />
              </div>
              <div className="field">
                <label htmlFor="Immediate actions taken">
                  Immediate actions taken,
                </label>
                <RichTexField
                  value={immediateActionsTaken}
                  onEditorChange={setImmediateActionsTaken}
                />
              </div>
            </div>
          </div>
          <div className="field full inputs-group">
            <h3>Supporting documents</h3>
            <FilesList documents={uploadedFiles} />

            {uploadingDocuments ? (
              "Uploading ..."
            ) : (
              <input
                type="file"
                onChange={handleFileChange}
                name="files"
                id="files"
                multiple
              />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModifyAdverseDruReactionForm;
