function formatTimeWithAMPM(timeString) {
  // Parse the time string into hours, minutes, and seconds
  const [hoursStr, minutesStr, secondsStr] = timeString.split(":");
  const hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);

  // Calculate AM/PM
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Format the time string
  return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${ampm}`;
}

function DrugReactionOtherInformation({ data }) {
  const incidentType = data?.incident_type_classification?.[0] || "Not Provided";
  const sanitizedIncidentType = incidentType.replace(/<\/?[^>]+(>|$)/g, "");
  const outcome = data?.outcome_type?.[0] || {};

  return (
    <div className="flex-column">
      <div className="flex-column">
        <h2>General Classification of Reaction</h2>
        <small>{sanitizedIncidentType}</small>
      </div>
      <div className="flex-row">
        <div className="flex-column">
          <h3>Outcome</h3>
          <small>{outcome.outcome_type || "Not Provided"}</small>
        </div>
        <div className="flex-column">
          <h3>Description</h3>
          <small>{outcome.description || "Not Provided"}</small>
        </div>
        <div className="flex-column">
          <h3>Anaphylaxis /ADR Outcome</h3>
          <small>{outcome.anaphylaxis_outcome || "Not Provided"}</small>
        </div>
        <div className="flex-column">
          <h3>Adverse event to be reported to the FDA</h3>
          <small>{outcome.adverse_event_to_be_reported_to_FDA || "Not Provided"}</small>
        </div>
      </div>
      <h3>Notification</h3>
      <div className="flex-row">
        <div className="flex-column">
          <h3>Name of physician notified</h3>
          <small>{outcome.name_of_physician_notified || "Not Provided"}</small>
        </div>
        <div className="flex-column">
          <h3>Date/Time notified</h3>
          <small>
            {outcome.date_physician_was_notified && outcome.time_physician_was_notified
              ? `${outcome.date_physician_was_notified} ${formatTimeWithAMPM(outcome.time_physician_was_notified)}`
              : "Not Provided"}
          </small>
        </div>
      </div>
      <div className="flex-row">
        <div className="flex-column">
          <h3>Name of family notified</h3>
          <small>{outcome.name_of_family_notified || "Not Provided"}</small>
        </div>
        <div className="flex-column">
          <h3>Date/Time notified</h3>
          <small>
            {outcome.date_family_was_notified && outcome.time_family_was_notified
              ? `${outcome.date_family_was_notified} ${formatTimeWithAMPM(outcome.time_family_was_notified)}`
              : "Not Provided"}
          </small>
        </div>
      </div>

      <div className="flex-row">
        <div className="flex-column">
          <h3>Notified by</h3>
          <small>{outcome.notified_by || "Not Provided"}</small>
        </div>
        <div className="flex-column">
          <h3>Brief summary of incident</h3>
          <small
            dangerouslySetInnerHTML={{
              __html: data?.brief_summary_incident || "Not Provided",
            }}
          ></small>
        </div>
      </div>

      <div className="flex-row">
        <div className="flex-column">
          <h3>Immediate action taken</h3>
          <small
            dangerouslySetInnerHTML={{
              __html: data?.immediate_actions_taken || "Not Provided",
            }}
          ></small>
        </div>
        <div className="flex-column">
          <h3>Severity rating</h3>
          <small
            dangerouslySetInnerHTML={{
              __html: data?.severity_rating || "Not Provided",
            }}
          ></small>
        </div>
      </div>

    </div>
  );
}

export default DrugReactionOtherInformation;
