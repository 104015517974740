import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { validateStep } from "../validators/generalIncidentForm";
import api, { API_URL } from "../../../api";
import toast from "react-hot-toast";
import "./../../../assets/css/drug_reaction_form/drug_reaction.css";
import { AddCircleIcon, Cancel01Icon, CheckmarkSquare02Icon, PlusSignIcon, RemoveCircleIcon, SquareIcon } from "hugeicons-react";
import CustomSelectInput from "./inputs/customSelect";
import CustomDatePicker from "./inputs/datePicker";
import RichTexField from "./inputs/richTexField";
import FormCompleteMessage from "./formCompleteMessage";
import postDocumentHistory from "./documentHistory/postDocumentHistory";
import {
  drugRoutes,
  incident_agreement,
  outComeData,
} from "../../../constants";
import CustomTimeInput from "./inputs/customTimeInput";

const DrugReactionForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const currentStepRef = useRef(currentStep);
  const [isLoading, setIsLoading] = useState(false);

  // form
  const [outComeType, setOutComeType] = useState("mild");
  const [name, setName] = useState("");
  const [sex, setSex] = useState("");
  const [incidentDate, setIncidentDate] = useState("");
  const [incidentTime, setIncidentTime] = useState("");
  const [incidentMr, setIncidentMr] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [victimType, setVictimType] = useState("");
  const [otherStatus, setOtherStatus] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [location, setLocation] = useState("");
  const [contributingDiagnosis, setContributingDiagnosis] = useState("");
  const [isIv, setIsIv] = useState(false);
  const [isReactionTreated, setIsReactionTreated] = useState(false);
  const [provider, setProvider] = useState("");
  const [observersName, setObserversName] = useState("");
  const [observersNameArray, setObserversNameArray] = useState([]);
  const [timeOfReport, setTimeOfReport] = useState("");
  const [dateOfReport, setDateOfReport] = useState("");
  const [eventDetails, setEventDetails] = useState("");
  const [suspectedMedication, setSuspectedMeedication] = useState("");
  const [dose, setDose] = useState("");
  const [frequency, setFrequency] = useState("");
  const [route, setRoute] = useState("");
  const [rateOfAdministration, setRateOfAdministration] = useState("");
  const [dateOfMedicationOrder, setDateOfMedicationOrder] = useState("");
  const [dateInformation, setDateInformation] = useState("");
  const [reaction, setReaction] = useState("");
  const [adverseReactionDate, setAdverseReactionDate] = useState("");
  const [reactionSetTime, setReactionSetTime] = useState("");
  const [selectedAgreements, setSelectedAgreements] = useState([]);
  const [physicianNotified, setPhysicianNotified] = useState("");
  const [physcianDate, setPhyscianDate] = useState("");
  const [physcianTime, setPhyscianTime] = useState("");
  const [familyNotified, setFamilyNotified] = useState("");
  const [otherOutcome, setOtherOutcome] = useState("");
  const [familyDate, setFamilyDate] = useState("");
  const [familyTime, setFamilyTime] = useState("");
  const [notifiedBy, setNotifiedBy] = useState("");
  const [briefSummary, setBriefSummary] = useState("");
  const [immediateActionsTaken, setImmediateActionsTaken] = useState("");
  const [description, setDescription] = useState("");
  const [nurseNote, setNurseNote] = useState(false);
  const [progressNote, setProgressNote] = useState(false);
  const [otherNote, setOtherNote] = useState(false);
  const [otherNoteDescription, setOtherNoteDescription] = useState("");
  const [treatmentDescription, setTreatmentDescription] = useState("");
  const [agreementDescription, setAgreementDescription] = useState("");
  const [outcomeType, setOutcomeType] = useState("");
  const [outcomeDescription, setOutcomeDescription] = useState("");
  const [adrOutcome, setAdrOutcome] = useState("");
  const [fdaReported, setFdaReported] = useState(false);

  useEffect(() => {
    currentStepRef.current = currentStep;
  }, [currentStep]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if Ctrl or Alt key is pressed
      if (event.key === "Enter") {
        event.preventDefault();
        if (currentStepRef.current < 7) {
          document.getElementById("continue-button").click();
        } else if (currentStepRef.current === 7) {
          document.getElementById("save-button").click();
        } else {
          return;
        }
      }

      if (event.ctrlKey || event.altKey) {
        switch (event.key) {
          case "s": // Ctrl + S
            event.preventDefault(); // Prevent default browser action
            if (currentStepRef.current < 7) {
              document.getElementById("continue-button").click();
            } else if (currentStepRef.current === 7) {
              document.getElementById("save-button").click();
            } else {
              return;
            }
            break;
          case "b":
            event.preventDefault();
            if (currentStepRef.current > 1 && currentStepRef.current <= 7) {
              document.getElementById("back-button").click();
            }
            console.log(currentStepRef.current);
            break;
          case "f": // Ctrl + F
            event.preventDefault(); // Prevent default browser action
            document.getElementById("name").focus();
            break;
          case "e": // Ctrl + E
            event.preventDefault(); // Prevent default browser action
            document.getElementById("email").focus();
            break;
          default:
            break;
        }
      }
    };

    // Add event listener when component mounts
    document.addEventListener("keydown", handleKeyDown);

    // Clean up event listener when component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const handleVictimType = (value) => {
    setVictimType(value);
  };

  const handleNurseNote = () => {
    setOtherNote(false);
    setProgressNote(false);
    setNurseNote(true);
    setOtherNoteDescription("");
  };
  const handleProgressNote = () => {
    setOtherNote(false);
    setProgressNote(true);
    setNurseNote(false);
    setOtherNoteDescription("");
  };

  const handleOtherNote = () => {
    setOtherNote(true);
    setProgressNote(false);
    setNurseNote(false);
  };

  const handleReactionTreated = () => {
    setIsReactionTreated(!isReactionTreated);
    setTreatmentDescription("");
  };

  const handleObserversNameArray = (e, value) => {
    e.preventDefault();
    if (!value.trim().length) return;
    setObserversNameArray((observersNameArray) => [
      ...observersNameArray,
      value,
    ]);
    setObserversName("");
  };

  const handleRemoveObserverName = (e, name) => {
    e.preventDefault();
    setObserversNameArray((observersNameArray) =>
      observersNameArray.filter((el) => el !== name)
    );
  };

  const handleOutcomeType = (value) => {
    setOutcomeType(value);
    setOutcomeDescription("");
  };

  const handleOutcomeDescription = (value) => {
    setOutcomeDescription(value);
  };

  const handleRouteChange = (e) => {
    setRoute(e.target.value);
  };
  const handleAgreementChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedAgreements([...selectedAgreements, value]);
    } else {
      setSelectedAgreements(
        selectedAgreements.filter((agreement) => agreement !== value)
      );
    }
  };

  const handleSelection = (agreementName) => {
    setSelectedAgreements((prevSelected) => {
      if (prevSelected.includes(agreementName)) {
        // Remove if already selected
        return prevSelected.filter((name) => name !== agreementName);
      } else {
        // Add if not already selected
        return [...prevSelected, agreementName];
      }
    });
  };

  async function handleNewDrugAdverseReaction(drugReactionData) {
    try {
      setIsLoading(true);
      const response = await api.post(
        `${API_URL}/incidents/adverse_drug_reaction/new/`,
        drugReactionData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 201) {
        const id = response.data.id;
        localStorage.setItem("drugReactionId", id.toString());
        toast.success("Successfully posted data");
        setCurrentStep(currentStep + 1);
        console.log(response.data);
        postDocumentHistory(id, "added a new incident");
      }
    } catch (error) {
      if (error.response.data) {
        toast.error(
          error.response.data.message ||
          "Error while creating new incident, please try again"
        );
      } else {
        toast.error("Something went wrong");
      }
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
    }
  }

  async function updateDrugAdverseReaction(drugReactionData) {
    try {
      setIsLoading(true);
      const id = localStorage.getItem("drugReactionId");
      console.log(id);
      const response = await api.patch(
        `${API_URL}/incidents/adverse_drug_reaction/${id}/update/`,
        drugReactionData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        if (currentStep === 8) {
          toast.success("Incident Saved Successfully");
          setCurrentStep(currentStep + 1);

          postDocumentHistory(id, "added a new incident", "create");
        } else {
          setCurrentStep(currentStep + 1);
          toast.success("Data posted Successfully");
        }
        console.log(response.data);
      }
    } catch (error) {
      if (error.response.data) {
        toast.error(
          error.response.data.message ||
          "Failed to update the data. Please try again."
        );
      } else {
        toast.error("Something went wrong");
      }
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
    }
  }

  const handleNextStep = () => {
    let isValid = true;
    let drugReactionData;

    if (currentStep === 1) {
      isValid = validateStep({
        "Incident Type": victimType,
        Name: name,
        "Incident Date": incidentDate,
        "Incident Time": incidentTime,
        Sex: sex,
        "Incident MR": incidentMr,
        Address: address,
        State: state,
        "Zip Code": zipCode,
        "Phone Number": phoneNumber,
      });

      if (isValid) {
        drugReactionData = JSON.stringify({
          patient_type: victimType,
          patient_name: name,
          sex: sex,
          incident_date: incidentDate,
          incident_time: incidentTime,
          medical_record_number: incidentMr,
          address: address,
          state: state,
          zip_code: zipCode,
          phone_number: phoneNumber,
          status: "Draft",
        });
        handleNewDrugAdverseReaction(drugReactionData);
      }
    }
    if (currentStep === 2) {
      isValid = validateStep({
        provider: provider,
        "observers name": observersNameArray.length,
        "time of report": timeOfReport,
        "date of report ": dateOfReport,
        "event details": eventDetails,
      });

      if (isValid) {
        drugReactionData = JSON.stringify({
          provider: provider,
          observers_name: observersNameArray,
          time_of_report: timeOfReport,
          date_of_report: dateOfReport,
          event_detail: eventDetails,
        });

        updateDrugAdverseReaction(drugReactionData);
      }
    }
    if (currentStep === 3) {
      if (route === "others" || route === "ivpush" || route === "ivdrip") {
        isValid = validateStep({
          "suspected medication": suspectedMedication,
          dose: dose,
          frequency: frequency,
          route: route,
          "rate of administration": rateOfAdministration,
          "date of medication order": dateOfMedicationOrder,
          description: description,
        });
      } else {
        isValid = validateStep({
          "suspected medication": suspectedMedication,
          dose: dose,
          frequency: frequency,
          route: route,
          "rate of admninistration": rateOfAdministration,
          "date of medication order": dateOfMedicationOrder,
        });
      }

      if (isValid) {
        drugReactionData = JSON.stringify({
          suspected_medication: suspectedMedication,
          dose: dose,
          frequency: frequency,
          route: route,
          rate_of_administration: rateOfAdministration,
          date_of_medication_order: dateOfMedicationOrder,
          other_route_description: description,
        });

        updateDrugAdverseReaction(drugReactionData);
      }
    }
    if (currentStep === 4) {
      if (otherNote && isReactionTreated) {
        isValid = validateStep({
          "Date information": dateInformation,
          reaction: reaction,
          "adverse reaction date": adverseReactionDate,
          "reaction set time": reactionSetTime,
          note: otherNote,
          "other note description": otherNoteDescription,
          "reaction was treated description": treatmentDescription,
        });
      } else if (otherNote) {
        isValid = validateStep({
          "Date information": dateInformation,
          reaction: reaction,
          "adverse reaction date": adverseReactionDate,
          "reaction set time": reactionSetTime,
          note: otherNote,
          "other note description": otherNoteDescription,
        });
      } else if (isReactionTreated) {
        isValid = validateStep({
          "Date information": dateInformation,
          reaction: reaction,
          "adverse reaction date": adverseReactionDate,
          "reaction set time": reactionSetTime,
          note: nurseNote || progressNote,
          "reaction was treated description": treatmentDescription,
        });
      } else {
        isValid = validateStep({
          "Date information": dateInformation,
          reaction: reaction,
          "adverse reaction date": adverseReactionDate,
          "reaction set time": reactionSetTime,
          note: nurseNote || progressNote,
        });
      }

      if (isValid) {
        drugReactionData = JSON.stringify({
          date_of_information: dateInformation,
          information_reaction: reaction,
          date_of_adverse_reaction: adverseReactionDate,
          reaction_on_settime: reactionSetTime,
          nurse_note: nurseNote,
          progress_note: progressNote,
          other_information_can_be_found_in: otherNote,
          other_information_description: otherNoteDescription,
          reaction_was_treated: isReactionTreated,
          treatment_description: treatmentDescription,
        });

        updateDrugAdverseReaction(drugReactionData);
      }
    }
    if (currentStep === 5) {
      const isAnySelected = selectedAgreements.length > 0;
      if (selectedAgreements.includes("other (describe)")) {
        isValid = validateStep({
          description: agreementDescription,
        });
      } else {
        setAgreementDescription("");
      }

      if (!isAnySelected) {
        toast.error("Please select at least one option");
        isValid = false;
      }

      if (isValid) {
        drugReactionData = JSON.stringify({
          incident_type_classification: selectedAgreements.includes(
            "other (describe)"
          )
            ? {
              description:
                selectedAgreements
                  .filter((el) => el !== "other (describe)")
                  .join(", ") +
                ", " +
                agreementDescription,
            }
            : {
              description: selectedAgreements.join(", "),
            },
        });
        updateDrugAdverseReaction(drugReactionData);
      }
    }
    if (currentStep === 6) {
      if (outcomeType !== "mild") {
        isValid = validateStep({
          "outcome type": outcomeType,
          "outcome description": outcomeDescription,
          // "ADR Outcome": adrOutcome,
        });
      } else {
        isValid = validateStep({
          "outcome type": outcomeType,
          // "ADR Outcome": adrOutcome,
        });
      }
      if (isValid) {
        if (outcomeType !== "mild") {
          drugReactionData = {
            outcome_type: outcomeType,
          };
          updateDrugAdverseReaction(drugReactionData);
        }
      }
    }

    if (currentStep === 7) {
      isValid = validateStep({
        "physician notified": physicianNotified,
        "family notified": familyNotified,
        "physcian date ": physcianDate,
        "physcian time": physcianTime,
        "family date": familyDate,
        "family time": familyTime,
        "notified by": notifiedBy,
      });

      if (isValid) {
        drugReactionData = {
          incident_type_outcome: {
            outcome_type: outcomeType,
            description: outcomeDescription,
            anaphylaxis_outcome: adrOutcome,
            adverse_event_to_be_reported_to_FDA: fdaReported,
            name_of_physician_notified: physicianNotified,
            date_physician_was_notified: physcianDate,
            time_physician_was_notified: physcianTime,
            name_of_family_notified: familyNotified,
            date_family_was_notified: familyDate,
            time_family_was_notified: familyTime,
            notified_by: notifiedBy,
          },
        };

        updateDrugAdverseReaction(drugReactionData);
      }
    }
    if (currentStep === 8) {
      isValid = validateStep({
        "brief summary": briefSummary,
        "immediate actions taken": immediateActionsTaken,
      });

      if (isValid) {
        drugReactionData = JSON.stringify({
          brief_summary_incident: briefSummary,
          immediate_actions_taken: immediateActionsTaken,
          status: "Completed",
        });

        updateDrugAdverseReaction(drugReactionData);
      }
    }
  };
  const handlePreviousStep = () => {
    currentStep > 1 ? setCurrentStep(currentStep - 1) : setCurrentStep(1);
  };

  return (
    <div className="forms-container">
      <h2>Anaphylaxis/Adverse Drug Reaction Report</h2>
      {currentStep < 5 ? (
        <div className="form-steps">
          <div className={currentStep === 1 ? "step current-step" : "step"}>
            <div className="icon">
              <i className="fa-solid fa-circle-check"></i>
            </div>
            <div className="name">
              <p className="step-name">Step 1/8</p>
              <p className="step-details">Incident Info</p>
            </div>
          </div>
          <div className="divider"></div>
          <div className={currentStep === 2 ? "step current-step" : "step"}>
            <div className="icon">
              <i className="fa-solid fa-circle-check"></i>
            </div>
            <div className="name">
              <p className="step-name">Step 2/8</p>
              <p className="step-details">Event Details</p>
            </div>
          </div>
          <div className="divider"></div>
          <div className={currentStep === 3 ? "step current-step" : "step"}>
            <div className="icon">
              <i className="fa-solid fa-circle-check"></i>
            </div>
            <div className="name">
              <p className="step-name">Step 3/8</p>
              <p className="step-details">Medication and dose</p>
            </div>
          </div>
          <div className="divider"></div>
          <div className={currentStep === 4 ? "step current-step" : "step"}>
            <div className="icon">
              <i className="fa-solid fa-circle-check"></i>
            </div>
            <div className="name">
              <p className="step-name">Step 4/8</p>
              <p className="step-details">Incident type</p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {currentStep >= 5 ? (
        <div className="form-steps">
          <div className={currentStep === 5 ? "step current-step" : "step"}>
            <div className="icon">
              <i className="fa-solid fa-circle-check"></i>
            </div>
            <div className="name">
              <p className="step-name">Step 5/8</p>
              <p className="step-details">Incident type</p>
            </div>
          </div>
          <div className="divider"></div>

          <div className={currentStep === 6 ? "step current-step" : "step"}>
            <div className="icon">
              <i className="fa-solid fa-circle-check"></i>
            </div>
            <div className="name">
              <p className="step-name">Step 6/8</p>
              <p className="step-details">Incident type</p>
            </div>
          </div>
          <div className="divider"></div>

          <div className={currentStep === 7 ? "step current-step" : "step"}>
            <div className="icon">
              <i className="fa-solid fa-circle-check"></i>
            </div>
            <div className="name">
              <p className="step-name">Step 7/8</p>
              <p className="step-details">Incident type</p>
            </div>
          </div>
          <div className="divider"></div>

          <div className={currentStep === 8 ? "step current-step" : "step"}>
            <div className="icon">
              <i className="fa-solid fa-circle-check"></i>
            </div>
            <div className="name">
              <p className="step-name">Step 8/8</p>
              <p className="step-details">Summary</p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <form className="newIncidentForm">
        {currentStep === 1 ? (
          <div className="step incident-info">
            <h4>Select type</h4>
            <div className="types ">
              <div className="type">
                <input
                  onChange={(e) => handleVictimType("In Patient")}
                  type="radio"
                  name="victimType"
                  id="inPatient"
                />
                <label htmlFor="inPatient">Inpatient</label>
              </div>

              <div className="type">
                <input
                  onChange={(e) => handleVictimType("Out Patient")}
                  type="radio"
                  name="victimType"
                  id="outPatient"
                  value={"Out Patient"}
                />
                <label htmlFor="outPatient">Outpatient</label>
              </div>

              <div className="type">
                <input
                  onChange={(e) => handleVictimType("Er")}
                  type="radio"
                  name="victimType"
                  id="eR"
                  value={"Er"}
                />
                <label htmlFor="eR">ER</label>
              </div>

              <div className="type">
                <input
                  onChange={(e) => handleVictimType("Visitor")}
                  type="radio"
                  name="victimType"
                  id="visitor"
                  value={"Visitor"}
                />
                <label htmlFor="visitor">Visitor</label>
              </div>
            </div>

            <div className="half">
              <div className="field name">
                <label htmlFor="patientName">Patient/Visitor name</label>
                <input
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  type="text"
                  name="patientName"
                  id="patientName"
                  placeholder="Patient or visitors name"
                />
              </div>

              <div className="sex field">
                <label htmlFor="sex">Sex</label>
                <CustomSelectInput
                  options={["Male", "Female", "Other"]}
                  selected={sex}
                  setSelected={setSex}
                  placeholder={"sex"}
                />
              </div>
            </div>

            <div className="half">
              <div className="incident-date field">
                <label htmlFor="incidentDate">Incident Date</label>
                <CustomDatePicker
                  selectedDate={incidentDate}
                  setSelectedDate={setIncidentDate}
                />
              </div>

              <div className="incident-time field">
                <label htmlFor="incidentTime">Incident Time</label>
                <CustomTimeInput setTime={setIncidentTime} />

              </div>
            </div>

            <div className="mr field">
              <label htmlFor="incidentMr">Medical Record Number</label>
              <input
                onChange={(e) => setIncidentMr(e.target.value)}
                value={incidentMr}
                type="text"
                name="incidentMr"
                id="incidentMr"
                placeholder="Enter MR"
              />
            </div>

            <div className="half spacer">
              <div className="field">
                <label htmlFor="address">Address</label>
                <input
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                  type="text"
                  name="address"
                  placeholder="Enter patient or visitor address"
                />
              </div>

              <div className="field">
                <label htmlFor="state">State</label>
                <input
                  onChange={(e) => setState(e.target.value)}
                  value={state}
                  type="text"
                  name="state"
                  id="state"
                  placeholder="Enter  patient or visitor state"
                />
              </div>
              <div className="field">
                <label htmlFor="zipCode">Zip Code</label>
                <input
                  onChange={(e) => setZipCode(e.target.value)}
                  value={zipCode}
                  type="text"
                  name="zipCode"
                  id="zipCode"
                  placeholder="Zip code"
                />
              </div>

              <div className="field">
                <label htmlFor="phoneNumber">Phone number</label>
                <input
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  value={phoneNumber}
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  placeholder="Phone number"
                />
              </div>
            </div>
          </div>
        ) : currentStep === 2 ? (
          <div className="step location-status">
            <div className="field name">
              <label htmlFor="provider">Provider</label>
              <input
                onChange={(e) => setProvider(e.target.value)}
                value={provider}
                type="text"
                name="provider"
                id="provider"
                placeholder="Enter Provider"
              />
            </div>
            <div className="field name">
              <label htmlFor="observersName">Observers Name</label>
              <div className="witness-list" >
                {observersNameArray.length
                  ? observersNameArray.map((el, i) => (
                    <div className="witness"
                      key={i}
                      onClick={(e) => handleRemoveObserverName(e, el)}
                    >
                      {el}
                      <Cancel01Icon size={20} />
                    </div>
                  ))
                  : null}
              </div>
              <input
                value={observersName}
                onChange={(e) => setObserversName(e.target.value)}
                type="text"
                name="observersName"
                id="observersName"
                placeholder="Enter observers name"
              />
              <div className="parties">
                <button
                  className="new-party"
                  onClick={(e) => handleObserversNameArray(e, observersName)}
                >
                  <PlusSignIcon size={20} />
                  Add Observer
                </button>
              </div>
            </div>

            <div className="half">
              <div className="field">
                <label htmlFor="dateOfReport">Date</label>
                <CustomDatePicker
                  selectedDate={dateOfReport}
                  setSelectedDate={setDateOfReport}
                />
              </div>
              <div className="field">
                <label htmlFor="timeOfReport">Time</label>
                <CustomTimeInput setTime={setTimeOfReport} />

              </div>
            </div>
            <div className="field name">
              <label htmlFor="eventDetails">Event Detail</label>
              <input
                onChange={(e) => setEventDetails(e.target.value)}
                value={eventDetails}
                type="text"
                name="eventDetails"
                id="eventDetails"
                placeholder="Enter Event Detail"
              />
            </div>

            {selectedStatus === "others" ? (
              <div className="other-field">
                <div className="field name">
                  <input
                    onChange={(e) => setOtherStatus(e.target.value)}
                    value={otherStatus}
                    type="text"
                    name="otherStatus"
                    id="otherStatus"
                    placeholder="Enter other status"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : currentStep === 3 ? (
          <div className="step">
            <div className="half">
              <div className="field">
                <label htmlFor="suspectedMedication">
                  Suspected medication
                </label>
                <input
                  type="text"
                  name="suspectedMedication"
                  id="suspectedMedication"
                  placeholder="Enter suspected medication"
                  value={suspectedMedication}
                  onChange={(e) => setSuspectedMeedication(e.target.value)}
                />
              </div>
              <div className="field">
                <label htmlFor="dose">Dose</label>
                <input
                  type="text"
                  name="dose"
                  id="dose"
                  placeholder="Enter dose"
                  value={dose}
                  onChange={(e) => setDose(e.target.value)}
                />
              </div>
            </div>

            <div className="half spacer">
              <div className="field name">
                <label htmlFor="incidentLocation">Route</label>
                <CustomSelectInput
                  options={drugRoutes.map((route) => route.label)}
                  selected={route}
                  setSelected={setRoute}
                  placeholder={"route"}
                />
              </div>

              <div className="field">
                <label htmlFor="frequency">Frequency</label>
                <input
                  type="text"
                  name="frequency"
                  id="frequency"
                  placeholder="Enter frequency"
                  value={frequency}
                  onChange={(e) => setFrequency(e.target.value)}
                />
              </div>

              {route &&
                (route === "others" ||
                  route === "ivpush" ||
                  route === "ivdrip") && (
                  <div className="field">
                    <input
                      type="text"
                      name="description"
                      id="description"
                      placeholder="Enter description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                )}
            </div>

            <div className="half">
              <div className="field">
                <label htmlFor="rateOfAdministration">
                  Rate of administration (if IV)
                </label>
                <input
                  type="text"
                  name="rateOfAdministration"
                  id="rateOfAdministration"
                  placeholder="Enter rate"
                  value={rateOfAdministration}
                  onChange={(e) => setRateOfAdministration(e.target.value)}
                />
              </div>

              <div className="field">
                <label htmlFor="dateOfMedicationOrder">
                  Date of medication order
                </label>
                <CustomDatePicker
                  selectedDate={dateOfMedicationOrder}
                  setSelectedDate={setDateOfMedicationOrder}
                />
              </div>
            </div>
          </div>
        ) : currentStep === 4 ? (
          <div className="step">
            <div className="field">
              <label htmlFor="dateInformation">
                Information on this reaction can be found on
              </label>
              <CustomDatePicker
                selectedDate={dateInformation}
                setSelectedDate={setDateInformation}
              />
            </div>

            <div className="field">
              <label htmlFor="informationIn">
                Information on this reaction can be found in
              </label>
              <div className="check-boxes">
                <div className="check-box">
                  <input
                    type="radio"
                    name="informationIn"
                    id="nurseNote"
                    onChange={handleNurseNote}
                    checked={nurseNote}
                  />
                  <label htmlFor="nurseNote">Nurse note</label>
                </div>
                <div className="check-box">
                  <input
                    type="radio"
                    name="informationIn"
                    id="progressNote"
                    onChange={handleProgressNote}
                    checked={progressNote}
                  />
                  <label htmlFor="progressNote">Progress note</label>
                </div>
                <div className="check-box">
                  <input
                    type="radio"
                    name="informationIn"
                    id="otherLocationIn"
                    onChange={handleOtherNote}
                    checked={otherNote}
                  />
                  <label htmlFor="otherLocationIn">Other</label>
                </div>
              </div>
            </div>

            {otherNote ? (
              <>
                <RichTexField
                  value={otherNoteDescription}
                  onEditorChange={setOtherNoteDescription}
                />
                {/* <textarea
                  placeholder="Enter note"
                  rows={3}
                  value={otherNoteDescription}
                  onChange={(e) => setOtherNoteDescription(e.target.value)}
                ></textarea> */}
              </>
            ) : (
              ""
            )}

            <div className="field">
              <label htmlFor="reaction">Reaction</label>
              <input
                type="text"
                name="reaction"
                id="reaction"
                placeholder="Describe Reaction"
                value={reaction}
                onChange={(e) => setReaction(e.target.value)}
              />

              <div className="half">
                <div className="field">
                  <label htmlFor="adverseReactionDate">
                    Date of adverse reaction
                  </label>
                  <CustomDatePicker
                    selectedDate={adverseReactionDate}
                    setSelectedDate={setAdverseReactionDate}
                  />
                </div>
                <div className="field">
                  <label htmlFor="isTreated">Reaction on-set time</label>
                  <CustomTimeInput setTime={setReactionSetTime} />

                </div>
              </div>
              <div className="field">
                <div className="check-boxes">
                  <div className="check-box">
                    <input
                      type="checkbox"
                      name="reactionTreated"
                      id="reactionTreated"
                      onChange={handleReactionTreated}
                      checked={isReactionTreated}
                    />
                    <label htmlFor="reactionTreated">
                      Check if reaction was treated
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {isReactionTreated ? (
              <div className="field">
                <label htmlFor="treatmentDescription">Describe treatment</label>
                <RichTexField
                  value={treatmentDescription}
                  onEditorChange={setTreatmentDescription}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        ) : currentStep === 5 ? (
          <div className="step">
            <div className="grid-container">
              {incident_agreement.map((agreement, index) => (
                <div key={index} className={`type grid-item`}>
                  <div onClick={() => handleSelection(agreement.name)} className="field checkbox">
                    {
                      selectedAgreements.includes(agreement.name) ? <CheckmarkSquare02Icon size={20} /> : <SquareIcon size={20} />
                    }
                    <p>{agreement.name}</p>

                  </div>
                </div>
              ))}
            </div>
            {selectedAgreements.includes("other (describe)") ? (
              <>
                <RichTexField
                  value={agreementDescription}
                  onEditorChange={setAgreementDescription}
                />
              </>
            ) : null}
          </div>
        ) : currentStep === 6 ? (
          <div className="other-info">
            <h2>Outcome</h2>
            <div className="check-boxes-container">
              <div className="check-box">
                <input
                  type="radio"
                  name="mildAdmission"
                  id="mildAdmission"
                  checked={outcomeType === "mild"}
                  onChange={() => handleOutcomeType("mild")}
                />
                <label htmlFor="mildAdmission">
                  Mild: required no intervention; no apparent harm to patient
                </label>
              </div>

              <div className="input-container check-box">
                <input
                  type="radio"
                  name="moderateAdmission"
                  id="moderateAdmission"
                  checked={outcomeType === "moderate"}
                  onChange={() => handleOutcomeType("moderate")}
                />
                <label htmlFor="moderateAdmission">Moderate</label>
              </div>
              <div>
                {outcomeType === "moderate"
                  ? outComeData.moderate.map((el, i) => (
                    <div key={i} className="outcome-data check-box">
                      <input
                        type="radio"
                        name="moderateOutcome"
                        id={el.name}
                        checked={outcomeDescription === el.name}
                        onChange={() => handleOutcomeDescription(el.name)}
                      />
                      <label htmlFor={el.name}>{el.name}</label>
                    </div>
                  ))
                  : null}
              </div>

              <div className="input-container check-box">
                <input
                  type="radio"
                  name="severeAdmission"
                  id="severeAdmission"
                  checked={outcomeType === "severe"}
                  onChange={() => handleOutcomeType("severe")}
                />
                <label htmlFor="severeAdmission">Severe</label>
              </div>
              <div>
                {outcomeType === "severe"
                  ? outComeData.severe.map((el, i) => (
                    <div key={i} className="outcome-data check-box">
                      <input
                        type="radio"
                        name="severeOutcome"
                        id={el.name}
                        checked={outcomeDescription === el.name}
                        onChange={() => handleOutcomeDescription(el.name)}
                      />
                      <label htmlFor={el.name}>{el.name}</label>
                    </div>
                  ))
                  : null}
              </div>

              <div className="half">
                <div className="check-boxes-container">
                  <h2>Anaphylaxis /ADR Outcome</h2>
                  <div className="grid-container">
                    {adrOutcome && adrOutcome.map((el, i) => (
                      <div className="check-box separator" key={i}>
                        <input
                          type="checkbox"
                          name={el.name}
                          id={el.name}
                          checked={adrOutcome === el.name}
                          onChange={() => setAdrOutcome(el.name)}
                        />
                        <label htmlFor={el.name}>{el.name}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="check-box separator">
                <input
                  type="checkbox"
                  name="reportedToFDA"
                  id="reportedToFDA"
                  onChange={() => setFdaReported(!fdaReported)}
                />
                <label htmlFor="reportedToFDA">
                  Adverse event to be reported to fda
                </label>
              </div>
            </div>
          </div>
        ) : currentStep === 7 ? (
          <div className="other-info">
            <h1>Notification</h1>

            <div className="field">
              <label htmlFor="physicianNotified">
                Name of physician notified
              </label>
              <input
                onChange={(e) => setPhysicianNotified(e.target.value)}
                type="text"
                name="physicianNotified"
                id="physicianNotified"
                placeholder="Enter  name"
                value={physicianNotified}
              />
            </div>

            <div className="half">
              <div className="field">
                <label htmlFor="physcianDate">Date</label>
                <CustomDatePicker
                  selectedDate={physcianDate}
                  setSelectedDate={setPhyscianDate}
                />
              </div>
              <div className="field">
                <label htmlFor="physcianTime">Time</label>
                <CustomTimeInput setTime={setPhyscianTime} />

              </div>
            </div>

            <div className="field">
              <label htmlFor="familyNotified">Name of family notified</label>
              <input
                onChange={(e) => setFamilyNotified(e.target.value)}
                type="text"
                name="familyNotified"
                id="familyNotified"
                value={familyNotified}
                placeholder="Enter the name"
              />
              <div className="half">
                <div className="field">
                  <label htmlFor="familyDate">Date</label>
                  <CustomDatePicker
                    selectedDate={familyDate}
                    setSelectedDate={setFamilyDate}
                  />
                </div>
                <div className="field">
                  <label htmlFor="familyTime">Time</label>
                  <CustomTimeInput setTime={setFamilyTime} />
                </div>
              </div>
            </div>
            <div className="field">
              <label htmlFor="physicianNotified">Notified by</label>
              <input
                onChange={(e) => setNotifiedBy(e.target.value)}
                type="text"
                name="notifiedBy"
                id="notifiedBy"
                placeholder="Enter  name"
                value={notifiedBy}
              />
            </div>
          </div>
        ) : currentStep === 8 ? (
          <div className="step">
            <div className="field">
              <label htmlFor="Brief Summary of incident">
                Brief summary of incident
              </label>
              <RichTexField
                value={briefSummary}
                onEditorChange={setBriefSummary}
              />
            </div>
            <div className="field">
              <label htmlFor="Immediate actions taken">
                Immediate actions taken,
              </label>
              <RichTexField
                value={immediateActionsTaken}
                onEditorChange={setImmediateActionsTaken}
              />
            </div>
          </div>
        ) : (
          <FormCompleteMessage
            title={"Anaphylaxis/Adverse drug reaction Form"}
            message={"Excellent Job on the Form! Your Input is Highly Regarded"}
          />
        )}
      </form>

      <div className="buttons">
        {currentStep > 1 && currentStep < 8 ? (
          <button
            onClick={handlePreviousStep}
            id="back-button"
            className="secondary-button"
          >
            <i className="fa-solid fa-arrow-left"></i>
            <span>back</span>
          </button>
        ) : (
          ""
        )}

        {currentStep === 8 ? (
          <button
            className="primary-button"
            id="save-button"
            onClick={handleNextStep}
          >
            <span>Save Incident</span>
            <i className="fa-solid fa-arrow-right"></i>
          </button>
        ) : currentStep < 8 ? (
          <button
            onClick={handleNextStep}
            id="continue-button"
            className="primary-button"
          >
            <span>{isLoading ? "Processing..." : "Continue"}</span>
            <i className="fa-solid fa-arrow-right"></i>
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default DrugReactionForm;
