import React, { useEffect, useState } from "react";
import SideBar from "../../components/dashaboard/sideBar";
import DashboardContainer from "./dashboardContainer";
import { usePermission } from "../../contexts/permissionsContext";
import {
  GoogleDocIcon,
  MedicineSyrupIcon,
  UserAccountIcon,
  DocumentAttachmentIcon,
  JobLinkIcon,
  Settings05Icon,
  Note01Icon,
  NoteEditIcon,
  ArrowRight01Icon,
} from "hugeicons-react";

import "../../assets/css/pages/incidentTrackingPage/incidentTrackingPage.css";
import { Link } from "react-router-dom";
import api from "../../api";
import DateFormatter from "./incidents/dateFormatter";
const IncidentTrackingPageContent = () => {
  const permission = usePermission();
  const [activeTab, setActiveTab] = useState("all");
  return (
    <>
      <div className="dashboard-page-content">
        <div className="tracking-headers">
          <div className="title">
            <h3>Incident reports</h3>
            <small>Detailed logs of recent incidents</small>
          </div>

          <div className="tabs">
            <div
              onClick={() => setActiveTab("all")}
              className={`reports-tab tracking-tab ${activeTab === "all" ? "active" : ""
                }`}
            >
              <Note01Icon />
              <span>All reports</span>
            </div>

            <div
              onClick={() => setActiveTab("drafts")}
              className={`drafts tracking-tab  ${activeTab === "drafts" ? "active" : ""
                }`}
            >
              <NoteEditIcon />
              <span>Drafts</span>
            </div>
          </div>
        </div>
        <div className="tabs-content">
          {activeTab === "all" && (
            <div className="incidents-reports">
              <Link to={"/incident/general/"} className="incident-report">
                <div className="list-icon">
                  <i className="fa-solid fa-ellipsis-vertical"></i>
                  <i className="fa-solid fa-ellipsis-vertical"></i>
                </div>
                <div className="icon">
                  <GoogleDocIcon size={24} variant={"stroke"} />
                </div>
                <div className="text">
                  <h3>General incidents</h3>
                  {/* <small>Last updated on 24 June, 2023</small> */}
                </div>
              </Link>
              <Link to={"/incident/drug-reaction/"} className="incident-report">
                <div className="list-icon">
                  <i className="fa-solid fa-ellipsis-vertical"></i>
                  <i className="fa-solid fa-ellipsis-vertical"></i>
                </div>
                <div className="icon">
                  <MedicineSyrupIcon size={24} variant={"stroke"} />
                </div>
                <div className="text">
                  <h3>Anaphylaxis/Adverse Drug Reaction </h3>
                  {/* <small>Last updated on 24 June, 2023</small> */}
                </div>
              </Link>

              <Link to={"/incident/employee/"} className="incident-report">
                <div className="list-icon">
                  <i className="fa-solid fa-ellipsis-vertical"></i>
                  <i className="fa-solid fa-ellipsis-vertical"></i>
                </div>
                <div className="icon">
                  <GoogleDocIcon size={24} variant={"stroke"} />
                </div>
                <div className="text">
                  <h3>Staff Incident Report</h3>
                  {/* <small>Last updated on 24 June, 2023</small> */}
                </div>
              </Link>

              <Link to={"/incident/grievance/"} className="incident-report">
                <div className="list-icon">
                  <i className="fa-solid fa-ellipsis-vertical"></i>
                  <i className="fa-solid fa-ellipsis-vertical"></i>
                </div>
                <div className="icon">
                  <DocumentAttachmentIcon size={24} variant={"stroke"} />
                </div>
                <div className="text">
                  <h3>Complaint & Grievance</h3>
                  {/* <small>Last updated on 24 June, 2023</small> */}
                </div>
              </Link>

              <Link
                to={"/incident/lost-and-found/"}
                className="incident-report"
              >
                <div className="list-icon">
                  <i className="fa-solid fa-ellipsis-vertical"></i>
                  <i className="fa-solid fa-ellipsis-vertical"></i>
                </div>
                <div className="icon">
                  <Settings05Icon size={24} variant={"stroke"} />
                </div>
                <div className="text">
                  <h3>Lost & Found Property report</h3>
                  {/* <small>Last updated on 24 June, 2023</small> */}
                </div>
              </Link>
              <Link
                to={"/incident/workplace-violence/"}
                className="incident-report"
              >
                <div className="list-icon">
                  <i className="fa-solid fa-ellipsis-vertical"></i>
                  <i className="fa-solid fa-ellipsis-vertical"></i>
                </div>
                <div className="icon">
                  <JobLinkIcon size={24} variant={"stroke"} />
                </div>
                <div className="text">
                  <h3>Workplace Violence Incidence Report</h3>
                  {/* <small>Last updated on 24 June, 2023</small> */}
                </div>
              </Link>
              <Link
                to={"/incident/medication_error/"}
                className="incident-report"
              >
                <div className="list-icon">
                  <i className="fa-solid fa-ellipsis-vertical"></i>
                  <i className="fa-solid fa-ellipsis-vertical"></i>
                </div>
                <div className="icon">
                  <JobLinkIcon size={24} variant={"stroke"} />
                </div>
                <div className="text">
                  <h3>Medication Error Report</h3>
                  {/* <small>Last updated on 24 June, 2023</small> */}
                </div>
              </Link>
            </div>
          )}
          {activeTab === "drafts" && (
            <div className="drafts">
              <div className="drafts">
                <DraftsTab />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
const BreadCrumbs = () => {
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />{" "}
      <Link className="current-page">Incidents</Link>
    </div>
  );
};
const IncidentTrackingPage = () => {
  return (
    <DashboardContainer
      content={<IncidentTrackingPageContent />}
      breadCrumbs={<BreadCrumbs />}
    />
  );
};

export default IncidentTrackingPage;

export const DraftsTab = () => {
  const [drafts, setDrafts] = useState({});
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [generalIncidents, setGeneralIncidents] = useState(
    drafts.general_incident
  );
  const [grievances, setGrievances] = useState(drafts.grievances);
  const [grievanceInvestigations, setGrievancesInvestigation] = useState([]);
  const [lostAndFoundIncidents, setLostAndFoundIncidents] = useState([]);
  const [employeeIncidents, setEmployeeIncidents] = useState([]);
  const [employeeHealthInvestigations, setEmployeeHealthInvestigations] =
    useState([]);
  const [workplaceViolenceIncidents, setWorkplaceViolenceIncidents] = useState(
    []
  );
  const [adverseDrugReaction, setAdverseDrugReaction] = useState([]);
  const [medicationError, setMedicationError] = useState([]);
  useEffect(() => {
    // Fetch drafts data

    const fetchDrafts = async () => {
      // API call to fetch drafts data
      try {
        const response = await api.get(`incidents/overview/draft/user/`);
        if (response.status === 200) {
          setDrafts(response.data);
          setGrievancesInvestigation(response.data.grievance_investigation);
          setGrievances(response.data.grievance_incident);
          setGeneralIncidents(response.data.general_incident);
          setLostAndFoundIncidents(response.data.lost_and_found);
          setEmployeeIncidents(response.data.employee_incident);
          setEmployeeHealthInvestigations(response.data.health_investigation);
          setWorkplaceViolenceIncidents(response.data.workplace_violence);
          setAdverseDrugReaction(response.data.adverse_drug_reaction);
          setMedicationError(response.data.medical_error);
          console.log(response.data);
          setLoading(false);
        }
      } catch (error) {
        if (error.response) {
          setError(
            error.response.data.message ||
            error.response.error ||
            "Error fetching drafts data, try again later"
          );
        } else {
          setError("Unknown fetching drafts data, try again later");
        }
        setLoading(false);
        console.error(error);
      }
    };

    fetchDrafts();
  }, []);

  return loading ? (
    "Loading"
  ) : (
    <div className="draft-list">
      {error && <div className="error-message">{error}</div>}
      {drafts && drafts.length > 0 ? (
        "No drafts found"
      ) : (
        <div className="drafts-categories">
          Drafts ({Object.keys(drafts).length})
          {generalIncidents && generalIncidents.length > 0 ? (
            <DraftCategory
              incident={generalIncidents}
              title={"General incident reports"}
              apiLink={"incident/general"}
            />
          ) : (
            ""
          )}
          {grievances && grievances.length > 0 ? (
            <DraftCategory
              incident={grievances}
              title={"Grievance reports"}
              apiLink={"incident/grievance"}
            />
          ) : (
            ""
          )}
          {adverseDrugReaction && adverseDrugReaction.length > 0 ? (
            <DraftCategory
              incident={adverseDrugReaction}
              title={"Adverse drug reaction reports"}
              apiLink={"incident/drug-reaction"}
            />
          ) : (
            ""
          )}
          {lostAndFoundIncidents && lostAndFoundIncidents.length > 0 ? (
            <DraftCategory
              incident={lostAndFoundIncidents}
              title={"Lost & Found property report reports"}
              apiLink={"incident/lost_and_found"}
            />
          ) : (
            ""
          )}
          {workplaceViolenceIncidents &&
            workplaceViolenceIncidents.length > 0 ? (
            <DraftCategory
              incident={workplaceViolenceIncidents}
              title={"Workplace violence reports"}
              apiLink={"incident/workplace_violence"}
            />
          ) : (
            ""
          )}
          {medicationError && medicationError.length > 0 ? (
            <DraftCategory
              incident={medicationError}
              title={"Medication error reports"}
              apiLink={"incident/medication_error"}
            />
          ) : (
            ""
          )}
          {employeeIncidents && employeeIncidents.length > 0 ? (
            <DraftCategory
              incident={employeeIncidents}
              title={"Staff Incident reports"}
              apiLink={"incident/employee_incident"}
            />
          ) : (
            ""
          )}
          ,
        </div>
      )}
    </div>
  );
};

export const DraftCategory = ({ incident, title, apiLink }) => {
  return (
    <div className="categories">
      <div className="categories-title">
        <NoteEditIcon />
        <h3>
          {title} ({incident.length})
        </h3>
      </div>
      <div className="cards">
        {incident &&
          incident.map((draft, index) => (
            <div className="category" key={index}>
              <Link to={`/${apiLink}/${draft.id}/modify/`}>
                <div className="draft-item">
                  <p>
                    Incident created on{" "}
                    <DateFormatter dateString={draft.created_at} />
                  </p>
                  <h3>ID: {draft.id}</h3>
                  <small>Current step {draft.current_step}</small>
                </div>
              </Link>
            </div>
          ))}
      </div>
    </div>
  );
};
