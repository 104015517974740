import React from "react"

const NewContentFullContainer = ({ action }) => {
    return (
        <div className="new-item-full-container">
            <button onClick={action} type="button" className="primary-button">Add new Incident</button>
        </div>
    )
}
export default NewContentFullContainer
