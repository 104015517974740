import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import DashBoardContainer from "../dashboardContainer";
import IncidentDetailsHeader from "./incidentDetails/incidentDetailsHeader";
import IncidentDetails from "./incidentDetails/incidentDetails";
import IncidentTabs from "./incidentDetails/incidentDetailsTabs";
import GeneralIncidentDetailsContentTab from "./generalIncident/incidentDetails";
import api, { API_URL } from "../../../api";

import "../../../assets/css/pages/incidents/general/generalIncidentDetailsPage.css";
import GeneralIncidentOtherInformation from "./generalIncident/otherInformation";
import GeneralIncidentGeneralInformation from "./generalIncident/generalInformation";
import GeneralIncidentReviews from "./generalIncident/generalIncidentReviews";
import GeneralIncidentDocumentHistory from "./generalIncident/generalIncidentDocumentHistory";
import toast from "react-hot-toast";
import { ArrowRight01Icon, Download01Icon, Download02Icon, Download03Icon, Download04Icon, FileAttachmentIcon } from "hugeicons-react";
import FilesList from "../../../components/documents/filesList";


const GeneralIncidentDetailsContent = () => {
  const { incidentId } = useParams();
  const [isFetching, setIsFetching] = useState(true);
  const [incidentDetails, setIncidentDetails] = useState({});
  const [incidentStatus, setIncidentStatus] = useState({});

  useEffect(() => {
    const fetchIncidentDetails = async () => {
      setIsFetching(true);
      try {
        const response = await api.get(
          `${API_URL}/incidents/general/${incidentId}/`
        );
        if (response.status === 200) {
          setIncidentStatus(response.data.statuses);
          setIncidentDetails(response.data.incident);
          console.log(response.data.incident)
          setIsFetching(false);
        }
      } catch (error) {
        console.log(error);
        setIsFetching(false);
      }
    };

    fetchIncidentDetails();
  }, []);
  useEffect(() => {
    const getIncidentReviews = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/general/${incidentId}/reviews/`
        );
        if (response.status === 200) {
          localStorage.setItem("incidentReviewsCount", response.data.length);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          toast.error("Authentication error");
          window.location.reload();
        } else {
          toast.error("Failed to fetch incident reviews");
          console.error(error);
        }
      }
    };
    getIncidentReviews();
  }, []);
  useEffect(() => {
    const getDocumentHistory = async () => {
      try {
        const response = await api.get(
          `${API_URL}/activities/list/${incidentId}/`
        );
        if (response.status === 200) {
          localStorage.setItem("documentHistoryCount", response.data.length);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          toast.error("Authentication error");
          window.location.reload();
        } else {
          toast.error("Failed to fetch document History");
          console.error(error);
        }
      }
    };
    getDocumentHistory();
  }, []);
  return (
    <div className="incident-details-page">
      {isFetching ? (
        <div className="fetching-data">Loading data</div>
      ) : (
        <div className="incident-details">
          <IncidentDetailsHeader
            data={incidentDetails}
            incidentId={incidentId}
            apiLink={"general"}
            sendTo={"send-to-department"}
          />
          <div className="details">
            <IncidentDetails
              data={incidentDetails}
              fullName={incidentDetails.patient_visitor_name}
              sex={incidentDetails.sex}
              IncidentDate={incidentDetails.incident_date}
              IncidentDOB={incidentDetails.date_of_birth}
              IncidentPatientAge={incidentDetails.age}
              incidentTime={incidentDetails.incident_time}
              incidentCategory={incidentDetails.category}
              incidentDetails={
                <GeneralIncidentDetailsContentTab data={incidentDetails} />
              }
            />
            <IncidentTabs
              data={incidentDetails}
              statuses={incidentStatus}
              generalInformation={
                <GeneralIncidentGeneralInformation
                  data={incidentDetails}
                  incidentStatuses={incidentStatus}
                />
              }
              otherInformation={
                <GeneralIncidentOtherInformation data={incidentDetails} />
              }
              reviews={<GeneralIncidentReviews />}
              documentHistory={
                <GeneralIncidentDocumentHistory incidentId={incidentId} />
              }
              documents={<IncidentDocuments incidentId={incidentId} />}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const IncidentDocuments = ({ incidentId, apiLink }) => {
  const [documents, setDocuments] = useState([])
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await api.get(`/incidents/general/${incidentId}/documents/`)
        if (response.status === 200) {
          setDocuments(response.data)
          console.log(response.data)
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchDocuments()
  }, [])
  return (
    <FilesList documents={documents} showDownload={true} />
  )
}

const BreadCrumbs = () => {
  const { incidentId } = useParams();
  return (
      <div className="breadcrumbs">
          <Link to={'/'}>Overview</Link> <ArrowRight01Icon /> 
          <Link to={'/incidents/'}>Incidents</Link> <ArrowRight01Icon /> 
          <Link to={'/incident/general/'}>General Incidents List</Link>  <ArrowRight01Icon /> 
          <Link className='current-page'> #{incidentId}</Link>
      </div>
  )
};

const GeneralIncidentDetails = () => {
  return (
    <div>
      <DashBoardContainer content={<GeneralIncidentDetailsContent />} breadCrumbs={<BreadCrumbs />}/>
    </div>
  );
};

export default GeneralIncidentDetails;
