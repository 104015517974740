import React, { useEffect, useState } from "react";
import DashboardContainer from "./dashboardContainer";
import { Link, useParams } from "react-router-dom";
import {
  ArrowLeft01Icon,
  ArrowRight01Icon,
  DocumentAttachmentIcon,
  GoogleDocIcon,
  JobLinkIcon,
  MedicineSyrupIcon,
  Note02Icon,
  Settings05Icon,
} from "hugeicons-react";
import api from "../../api";
import ModifyPageLoader from "./incidents/generalIncident/modify/loader";
import DateFormatter from "./incidents/dateFormatter";
import NamesInitials from "../../components/general/namesInitials";
import { ComplainDetails } from "../../components/profile/profileComplaints";
import { ListItemIcon } from "@mui/material";
const DepartmentDetailsPageContent = () => {
  const { departmentId } = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [activeTab, setActiveTab] = useState("reports");
  const [generalIncidents, setGeneralIncidents] = useState([]);
  const { facilityId } = useParams();
  const [facility, setFacility] = useState({});
  const [departments, setDepartments] = useState(true);
  const [complaints, setComplaints] = useState([]);
  const [staff, setStaff] = useState([]);
  const [grievances, setGrievances] = useState([]);
  const [grievanceInvestigations, setGrievancesInvestigation] = useState([]);
  const [lostAndFoundIncidents, setLostAndFoundIncidents] = useState([]);
  const [employeeIncidents, setEmployeeIncidents] = useState([]);
  const [activeIncidentReport, setActiveIncidentReport] = useState("main-page");
  const [employeeHealthInvestigations, setEmployeeHealthInvestigations] =
    useState([]);
  const [workplaceViolenceIncidents, setWorkplaceViolenceIncidents] = useState(
    []
  );
  const [adverseDrugReaction, setAdverseDrugReaction] = useState([]);
  const [medicationError, setMedicationError] = useState([]);

  const [department, setDepartment] = useState({});
  const [incidents, setIncidents] = useState([]);
  useEffect(() => {
    localStorage.setItem("departmentId", departmentId);
    const getDepartment = async () => {
      try {
        const response = await api.get(`/departments/${departmentId}/`);
        if (response.status === 200) {
          setDepartment(response.data);
          console.log(response.data);
          setIsLoading(false);
          localStorage.setItem("departmentName", response.data.name);
        }
      } catch (error) {
        if (error.response) {
          setErrorMessage(
            error.response.data.message ||
            error.response.data.error ||
            "Error fetching department"
          );
        } else {
          setErrorMessage("Unknown error fetching department");
        }
        setIsLoading(false);
        console.log(error);
      }
    };

    getDepartment();

    const getIncidents = async () => {
      setIsLoading(true);
      try {
        const response = await api.get(
          `/departments/${departmentId}/incidents/`
        );
        if (response.status === 200) {
          setIncidents(response.data);
          setGeneralIncidents(response.data.general);
          setLostAndFoundIncidents(response.data.lost_and_founds);
          setAdverseDrugReaction(response.data.adverse_drug_reaction);
          setEmployeeIncidents(response.data.employee_incidents);
          setEmployeeHealthInvestigations(
            response.data.employee_health_investigation
          );
          setGrievances(response.data.grievances);
          setGrievancesInvestigation(response.data.grievance_investigation);
          setMedicationError(response.data.medication_error);
          setWorkplaceViolenceIncidents(response.data.workplace_violence);
          console.log("Incidents ", response.data);
          setIsLoading(false);
        }
      } catch (error) {
        if (error.response) {
          setErrorMessage(
            error.response.data.message ||
            error.response.data.error ||
            "Error fetching incidents"
          );
        } else {
          setErrorMessage("Unknown error fetching incidents");
        }
        setIsLoading(false);
        console.log(error);
      }
    };
    getIncidents();

    const getStaff = async () => {
      setIsLoading(true);
      try {
        const response = await api.get(`/departments/${departmentId}/members/`);
        if (response.status === 200) {
          console.log("staff", response.data);
          setIsLoading(false);
          setStaff(response.data.members);
        }
      } catch (error) {
        if (error.response) {
          setErrorMessage(
            error.response.data.message ||
            error.response.data.error ||
            "Error fetching incidents"
          );
        } else {
          setErrorMessage("Unknown error fetching incidents");
        }
        setIsLoading(false);
        console.log(error);
      }
    };
    getStaff();

    const getComplaints = async () => {
      setIsLoading(true);
      try {
        const response = await api.get(
          `/departments/${departmentId}/complaints/`
        );
        if (response.status === 200) {
          console.log("Complaints", response.data);
          setIsLoading(false);
          setComplaints(response.data.complaints);
        }
      } catch (error) {
        if (error.response) {
          setErrorMessage(
            error.response.data.message ||
            error.response.data.error ||
            "Error fetching incidents"
          );
        } else {
          setErrorMessage("Unknown error fetching incidents");
        }
        setIsLoading(false);
        console.log(error);
      }
    };
    getComplaints();
  }, []);
  return isLoading ? (
    <ModifyPageLoader />
  ) : (
    <div>
      <div className="dashboard-page-content">
        <h2>{facility.name}</h2>
        <div className="tabs-list">
          <div
            onClick={() => setActiveTab("reports")}
            className={`tab ${activeTab === "reports" ? "active" : ""}`}
          >
            Incidents reports
          </div>
          <div
            onClick={() => setActiveTab("complaints")}
            className={`tab ${activeTab === "complaints" ? "active" : ""}`}
          >
            Complaints
          </div>
          {/* <div onClick={() => setActiveTab('documents')} className={`tab ${activeTab === 'documents' ? 'active' : ''}`}>Documents</div> */}
          <div
            onClick={() => setActiveTab("staff")}
            className={`tab ${activeTab === "staff" ? "active" : ""}`}
          >
            Staff
          </div>
        </div>
        {errorMessage && <div className="error-message">{errorMessage}</div>}

        {activeTab === "reports" &&
          (activeIncidentReport === "main-page" ? (
            <div className="dashboard-page-content">
              <div className="incidents-reports">
                <div
                  className="incident-report"
                  onClick={() => {
                    setActiveIncidentReport("general");
                  }}
                >
                  <div className="list-icon">
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                  <div className="icon">
                    <GoogleDocIcon size={24} variant={"stroke"} />
                  </div>
                  <div className="text">
                    <h3>General incidents</h3>
                    <small>Last updated on 24 June, 2023</small>
                  </div>
                </div>
                <div
                  className="incident-report"
                  onClick={() => {
                    setActiveIncidentReport("drug-reaction");
                  }}
                >
                  <div className="list-icon">
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                  <div className="icon">
                    <MedicineSyrupIcon size={24} variant={"stroke"} />
                  </div>
                  <div className="text">
                    <h3>Anaphylaxis/Adverse Drug Reaction </h3>
                    <small>Last updated on 24 June, 2023</small>
                  </div>
                </div>

                <div
                  className="incident-report"
                  onClick={() => {
                    setActiveIncidentReport("employee-incident");
                  }}
                >
                  <div className="list-icon">
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                  <div className="icon">
                    <GoogleDocIcon size={24} variant={"stroke"} />
                  </div>
                  <div className="text">
                    <h3>Staff Incident Report</h3>
                    <small>Last updated on 24 June, 2023</small>
                  </div>
                </div>

                <div
                  className="incident-report"
                  onClick={() => {
                    setActiveIncidentReport("grievance");
                  }}
                >
                  <div className="list-icon">
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                  <div className="icon">
                    <DocumentAttachmentIcon size={24} variant={"stroke"} />
                  </div>
                  <div className="text">
                    <h3>Complaint & Grievance</h3>
                    <small>Last updated on 24 June, 2023</small>
                  </div>
                </div>

                <div
                  className="incident-report"
                  onClick={() => {
                    setActiveIncidentReport("lost-found");
                  }}
                >
                  <div className="list-icon">
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                  <div className="icon">
                    <Settings05Icon size={24} variant={"stroke"} />
                  </div>
                  <div className="text">
                    <h3>Lost & Found Property report</h3>
                    <small>Last updated on 24 June, 2023</small>
                  </div>
                </div>
                <div
                  className="incident-report"
                  onClick={() => {
                    setActiveIncidentReport("workplace-violence");
                  }}
                >
                  <div className="list-icon">
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                  <div className="icon">
                    <JobLinkIcon size={24} variant={"stroke"} />
                  </div>
                  <div className="text">
                    <h3>Workplace Violence Incidence Report</h3>
                    <small>Last updated on 24 June, 2023</small>
                  </div>
                </div>
                <div
                  className="incident-report"
                  onClick={() => {
                    setActiveIncidentReport("medication-error");
                  }}
                >
                  <div className="list-icon">
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                  <div className="icon">
                    <JobLinkIcon size={24} variant={"stroke"} />
                  </div>
                  <div className="text">
                    <h3>Medication Error Report</h3>
                    <small>Last updated on 24 June, 2023</small>
                  </div>
                </div>
              </div>
            </div>
          ) : activeIncidentReport === "general" ? (
            <DepartmentReports generalIncidents={generalIncidents} />
          ) : activeIncidentReport === "drug-reaction" ? (
            <DepartmentReports adverseDrugReaction={adverseDrugReaction} />
          ) : activeIncidentReport === "employee-incident" ? (
            <DepartmentReports employeeIncidents={employeeIncidents} />
          ) : activeIncidentReport === "grievance" ? (
            <DepartmentReports grievances={grievances} />
          ) : activeIncidentReport === "lost-found" ? (
            <DepartmentReports lostAndFoundIncidents={lostAndFoundIncidents} />
          ) : activeIncidentReport === "workplace-violence" ? (
            <DepartmentReports
              workplaceViolenceIncidents={workplaceViolenceIncidents}
            />
          ) : activeIncidentReport === "medication-error" ? (
            <DepartmentReports medicationError={medicationError} />
          ) : (
            <div className="dashboard-page-content">
              <div className="incidents-reports">
                <div className="incident-report">
                  <div className="list-icon">
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                  <div className="icon">
                    <GoogleDocIcon size={24} variant={"stroke"} />
                  </div>
                  <div className="text">
                    <h3>General incidents</h3>
                    <small>Last updated on 24 June, 2023</small>
                  </div>
                </div>
                <div className="incident-report">
                  <div className="list-icon">
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                  <div className="icon">
                    <MedicineSyrupIcon size={24} variant={"stroke"} />
                  </div>
                  <div className="text">
                    <h3>Anaphylaxis/Adverse Drug Reaction </h3>
                    <small>Last updated on 24 June, 2023</small>
                  </div>
                </div>

                <div className="incident-report">
                  <div className="list-icon">
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                  <div className="icon">
                    <GoogleDocIcon size={24} variant={"stroke"} />
                  </div>
                  <div className="text">
                    <h3>Staff Incident Report</h3>
                    <small>Last updated on 24 June, 2023</small>
                  </div>
                </div>

                <div className="incident-report">
                  <div className="list-icon">
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                  <div className="icon">
                    <DocumentAttachmentIcon size={24} variant={"stroke"} />
                  </div>
                  <div className="text">
                    <h3>Complaint & Grievance</h3>
                    <small>Last updated on 24 June, 2023</small>
                  </div>
                </div>

                <div className="incident-report">
                  <div className="list-icon">
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                  <div className="icon">
                    <Settings05Icon size={24} variant={"stroke"} />
                  </div>
                  <div className="text">
                    <h3>Lost & Found Property report</h3>
                    <small>Last updated on 24 June, 2023</small>
                  </div>
                </div>
                <div className="incident-report">
                  <div className="list-icon">
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                  <div className="icon">
                    <JobLinkIcon size={24} variant={"stroke"} />
                  </div>
                  <div className="text">
                    <h3>Workplace Violence Incidence Report</h3>
                    <small>Last updated on 24 June, 2023</small>
                  </div>
                </div>
                <div className="incident-report">
                  <div className="list-icon">
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                  <div className="icon">
                    <JobLinkIcon size={24} variant={"stroke"} />
                  </div>
                  <div className="text">
                    <h3>Medication Error Report</h3>
                    <small>Last updated on 24 June, 2023</small>
                  </div>
                </div>
              </div>
            </div>
          ))}
        {activeTab === "complaints" && (
          <div>
            <DepartmentComplaints complaints={complaints} />
          </div>
        )}
        {/* {activeTab === "documents" && (
          <div>
            <h3>Documents</h3>
          </div>
        )} */}
        {activeTab === "staff" && (
          <div>
            <DepartmentStaff staff={staff} department={department} />
          </div>
        )}
      </div>
      <div className="department-page dashboard-page-content">
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}
      </div>
    </div>
  );
};

const Assignee = ({ assignee }) => {
  return (
    <div className="assignee">
      <h3>Assignee info</h3>
      <div className="name-profile">
        <div className="name-initials">
          <NamesInitials
            fullName={`${assignee.first_name} ${assignee.last_name}`}
          />
        </div>
        <h3>
          {assignee.first_name} {assignee.last_name}
        </h3>
      </div>
      <p>Email: {assignee.email}</p>
    </div>
  );
};
const BreadCrumbs = () => {
  const { departmentId, facilityId } = useParams();

  const [departmentName, setDepartmentName] = useState();
  const facilityName = localStorage.getItem("facilityName");
  useEffect(() => {
    setDepartmentName(localStorage.getItem("departmentName"));
    setDepartmentName(localStorage.getItem("departmentName"));
  });
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />
      <Link to={`/facilities/`}>Facilities</Link>
      <ArrowRight01Icon />
      <Link to={`/facilities/${facilityId}/`}>{facilityName}</Link>
      <ArrowRight01Icon />
      <Link className="current-page">{departmentName || departmentId}</Link>
    </div>
  );
};
const DepartmentDetailsPage = () => {
  return (
    <DashboardContainer
      content={<DepartmentDetailsPageContent />}
      breadCrumbs={<BreadCrumbs />}
    />
  );
};

const DepartmentStaff = ({ staff, department }) => {
  return (
    <div className="staff-list">
      <div className="content-card">
        <div className="card-header">
          <h3>Staff</h3>
        </div>

        <div className="table">
          <table>
            <thead>
              <tr>
                <th>No</th>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Departments</th>
              </tr>
            </thead>
            <tbody>
              {staff &&
                staff.map((staff, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{staff.id}</td>
                    <td>
                      {staff.first_name} {staff.last_name}
                    </td>
                    <td>{staff.email}</td>
                    <td>{department.name}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const DepartmentComplaints = ({ complaints }) => {
  const [showComplaintDetails, setShowComplaintDetailsWithComplaints] =
    useState(false);
  const [selectedComplaint, setSelectedComplaint] = useState();
  const handleShowComplainDetails = (complaint) => {
    setSelectedComplaint(complaint);
    setShowComplaintDetailsWithComplaints(!showComplaintDetails);
  };
  return (
    <div className="complaints-lists">
      {showComplaintDetails && (
        <ComplainDetails
          complaint={selectedComplaint}
          handleShowComplainDetails={handleShowComplainDetails}
        />
      )}
      <div className="content-card">
        <div className="card-header">
          <h3>Complaints</h3>
        </div>
      </div>

      <div className="table">
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Claim ID</th>
              <th>Patient name</th>
              <th>MRN</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {complaints && complaints.length > 0
              ? complaints.map((complaint, index) => (
                <tr
                  onClick={() => handleShowComplainDetails(complaint)}
                  key={index}
                >
                  <td>{index + 1}</td>
                  <td>{complaint.id}</td>
                  <td>{complaint.patient_name}</td>
                  <td>{complaint.medical_record_number}</td>
                  <td>
                    {<DateFormatter dateString={complaint.created_at} />}
                  </td>
                </tr>
              ))
              : "No complaints found"}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const DepartmentReports = ({
  generalIncidents,
  grievances,
  adverseDrugReaction,
  lostAndFoundIncidents,
  workplaceViolenceIncidents,
  medicationError,
  employeeIncidents,
}) => {
  return (
    <div className="reports-list">
      <div
        className="back-link"
        onClick={(e) => {
          e.preventDefault();
          window.location.reload();
        }}
      >
        <div className="icon">
          <ArrowLeft01Icon />
        </div>
        <p>Back to Incident Report Tab</p>
      </div>
      {
        <div className="reports-categories">
          {generalIncidents && generalIncidents.length > 0 ? (
            <ContentCard
              incident={generalIncidents}
              title={"General incident reports"}
              apiLink={"incident/general"}
            />
          ) : (
            ""
          )}
          {grievances && grievances.length > 0 ? (
            <ContentCard
              incident={grievances}
              title={"Grievance reports"}
              apiLink={"incident/grievance"}
            />
          ) : (
            ""
          )}
          {adverseDrugReaction && adverseDrugReaction.length > 0 ? (
            <ContentCard
              incident={adverseDrugReaction}
              title={"Adverse drug reaction reports"}
              apiLink={"incident/drug-reaction"}
            />
          ) : (
            ""
          )}
          {lostAndFoundIncidents && lostAndFoundIncidents.length > 0 ? (
            <ContentCard
              incident={lostAndFoundIncidents}
              title={"Lost & Found property report reports"}
              apiLink={"incident/lost_and_found"}
            />
          ) : (
            ""
          )}
          {workplaceViolenceIncidents &&
            workplaceViolenceIncidents.length > 0 ? (
            <ContentCard
              incident={workplaceViolenceIncidents}
              title={"Workplace violence reports"}
              apiLink={"incident/workplace_violence"}
            />
          ) : (
            ""
          )}
          {medicationError && medicationError.length > 0 ? (
            <ContentCard
              incident={medicationError}
              title={"Medication error reports"}
              apiLink={"incident/medication_error"}
            />
          ) : (
            ""
          )}
          {employeeIncidents && employeeIncidents.length > 0 ? (
            <ContentCard
              incident={employeeIncidents}
              title={"Staff Incident reports"}
              apiLink={"incident/employee_incident"}
            />
          ) : (
            ""
          )}
          ,
        </div>
      }
    </div>
  );
};

const ContentCard = ({ incident, title }) => {
  console.log("yayyyy", incident);
  return (
    <div className="reports-card">
      <div className="card-header">
        <h3>{title}</h3>
      </div>
      <div className="table">
        <table>
          {incident.length > 0 ? (
            <div className="general-incidents">
              <table>
                <thead>
                  <tr>
                    <th>Submitted by</th>
                    <th>Incident ID</th>
                    <th>Date</th>
                    <th>Assignees</th>
                  </tr>
                </thead>

                <tbody>
                  {incident.map((incident, index) => (
                    <tr key={index}>
                      <td>
                        {incident.created_by.first_name}{" "}
                        {incident.created_by.last_name}
                      </td>
                      <td>
                        <Link to={`/incident/general/${incident.id}/`}>
                          #{incident.id}
                        </Link>
                      </td>
                      <td>
                        {<DateFormatter dateString={incident.created_at} />}
                      </td>
                      <td>
                        <div className="authors-list">
                          {incident.assignees
                            .slice(0, 4)
                            .map((assignee, index) => (
                              <div className="author">
                                <NamesInitials
                                  fullName={`${assignee.first_name} ${assignee.last_name}`}
                                />
                                <div className="details">
                                  <Assignee assignee={assignee} />
                                </div>
                              </div>
                            ))}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div>No incident available</div>
          )}
        </table>
      </div>
    </div>
  );
};

export default DepartmentDetailsPage;
