import React from "react";

const MedicationDetailsContentTab = ({ data }) => {
  return (
    <div className="incident-details">
      <div className="number-mrn">
        <div className="phone-number">
          <small>Patient Name</small>
          <h4>{data.patient_name || "Not provided"}</h4>
        </div>
        <div className="mrn">
          <small>MRN</small>
          <h4>{data.mrn || "Not provided"}</h4>
        </div>
      </div>

      <div className="number-mrn">
        <div className="phone-number">
          <small>Date of birth</small>
          <h4>{data.date_of_birth || "Not provided"}</h4>
        </div>
        <div className="mrn">
          <small>Age</small>
          <h4>{data.age || "Not provided"}</h4>
        </div>
      </div>

      <div className="address">
        <div className="street">
          <small>Location</small>
          <h4>{data.location || "Not provided"}</h4>
        </div>
        <div className="state">
          <small>Date & Time</small>
          <h4>
            {data.date_of_error || "-"}
            <br />
            {data.time_of_error || "Not provided"}
          </h4>
        </div>
      </div>
      <div className="address">
        <div className="zip-code">
          <small>Day of the week</small>
          <h4>{data.day_of_the_week || "Not provided"}</h4>
        </div>
      </div>

      <div className="location-contribution-diagnosis">
        <h3>Provider/Staff Involved</h3>
        <div className="location">
          <small>Classification</small>
          <h4>{data.provider_classification || "Not provided"}</h4>
        </div>
        <div className="contribution">
          <small>Status</small>
          <h4>{data.status || "Not provided"}</h4>
        </div>
      </div>

      <div className="patient-status">
        <div className="status">
          <small>Provider Notified</small>
          <h4>
            {data.provider_title || "Not provided"}
            {"  "}
            {data.provider_name || "Not provided"}
          </h4>
        </div>
        <div className="status">
          <small>Duration of error</small>
          <h4>
            {data.days || "Not provided"} day(s), {data.hours || "Not provided"}{" "}
            hour(s){" "}
          </h4>
        </div>
      </div>

      <div className="patient-status">
        <div className="status">
          <small>Provider Title</small>
          <h4> {data.provider_title || "Not provided"}</h4>
        </div>
      </div>
    </div>
  );
};

export default MedicationDetailsContentTab;
