import React, { useEffect, useState } from 'react'
import DashBoardContainer from '../dashboard/dashboardContainer'
import { Link } from 'react-router-dom'
import { ArrowRight01Icon, Cancel01Icon, PlusSignIcon, UserAdd02Icon } from 'hugeicons-react'
import api from '../../api'

import '../../assets/css/pages/users/users.css'
import NewUserForm from '../../components/incidents/forms/admin/newUserForm'
const UsersListPageContent = () => {
    const [users, setUsers] = useState([])
    const [errorMessage, setErrorMessage] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [showNewUserForm, setShowNewUserForm] = useState(false)

    const handleShowNewUserForm = () => {
        setShowNewUserForm(!showNewUserForm)
    }
    useEffect(() => {
        // get users from api
        const fetchUsers = async () => {
            try {
                const response = await api.get(`accounts/users/list/`)
                if (response.status === 200) {
                    setUsers(response.data)
                    console.log(response.data)
                    setIsLoading(false)
                }
            } catch (error) {
                if (error.response) {
                    setErrorMessage(error.response.data.message || error.response.data.error || 'Error setting a list of users')
                } else {
                    setErrorMessage('Unknown error fetching users')
                }
                console.log(error)
                setIsLoading(false)
            }
        }
        fetchUsers()
    }, [])
    return isLoading ? <div className="dashboard-page-content">
        <p>Loading...</p>
    </div> : (
        <div className='dashboard-page-content'>
            {
                showNewUserForm &&
                <div className="new-user-form-popup">
                    <div className="popup-content">
                        <Cancel01Icon onClick={handleShowNewUserForm} className='close-icon' />
                        <NewUserForm />
                    </div>
                </div>
            }
            <div className="actions">
                <div className="title">
                    <h1>All users</h1>
                    <small>{users && users.length} users available</small>
                </div>
                <div className="filters">
                    <input type="search" name="" id="" placeholder='Search user by name or email' />
                </div>
                <button type='button' onClick={handleShowNewUserForm} className="button tertiary-button new-user-button"><UserAdd02Icon size={20} /><span>New user</span></button>
            </div>
            {/* users table */}
            <table className='users-table'>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>ID</th>
                        <th>Email</th>
                        <th>Phone number</th>
                        <th>Department</th>
                        <th>Date Added</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        users && users.map((user) => (
                            <tr>
                                <td>{user.first_name} {user.last_name}</td>
                                <td>{user.id}</td>
                                <td>{user.email}</td>
                                <td>{user.phone_number}</td>
                                <td>{user.department?.slice(0, 2).map((dep, index) => (dep.name))}</td>
                                <td>{user.date_added}</td>
                            </tr>
                        ))
                    }
                    <tr>

                    </tr>
                </tbody>
            </table>

            <div className="mobile-users">
                {
                    users && users.map((user, index) => (
                        <div className="user-card">
                            <p>{user.first_name} {user.last_name}</p>
                            <small>{user.email}</small>
                        </div>
                    ))
                }
            </div>
        </div>
    )

}

const BreadCrumbs = () => {
    return (
        <div className="breadcrumbs">
            <Link to={'/'}>Overview</Link>
            <ArrowRight01Icon />
            <Link to={'/admin/'}>Admin</Link>
            <ArrowRight01Icon />
            <Link className='current-page'>Users</Link>
        </div>
    )
}
const UsersListPage = () => {
    return (
        <DashBoardContainer breadCrumbs={<BreadCrumbs />} content={<UsersListPageContent />} />
    )
}

export default UsersListPage
