import { Download04Icon, File02Icon, FileAttachmentIcon } from 'hugeicons-react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SliceText from '../general/sliceText';

const FilesList = ({ documents, showDownload }) => {
    const [selectedFile, setSelectedFile] = useState({})
    const [showFilePreview, setShowFilePreview] = useState(false)

    const handleSelectFile = (file) => {
        setSelectedFile(file)
        setShowFilePreview(true)
    }
    return (
        <div className="documents-list">
            {
                documents && documents.length > 0
                    ?
                    documents.map((doc, index) => (
                        <div onClick={() => handleSelectFile(doc)} className="document">
                            <div className="document-container">
                                <div className="icon">
                                    <img src={doc.file_type === '.pdf'
                                        ? '/images/file_types/pdf2-svgrepo-com 1.svg' : doc.file_type === '.xlsx'
                                            ? '/images/file_types/excel2-svgrepo-com 1.svg' : doc.file_type === '.doc'
                                                ? '/images/file_types/word2-svgrepo-com 1.svg' : "/images/file_types/file-link-stroke-rounded.svg"} alt="" />
                                </div>
                                <div className="text-content">
                                    <h4 className="file-name"><SliceText text={doc.name} maxLength={20} /></h4>
                                </div>
                            </div>
                            {
                                showDownload
                                    ? <Link to={doc.url} target="_black" className="download-icon">
                                        <Download04Icon size={20} />
                                    </Link>
                                    : ''
                            }

                        </div>
                    ))
                    : 'No documents found'
            }
            <div className="files-list">
            </div>
        </div>
    )
}

export default FilesList;