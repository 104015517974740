import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Toaster } from "react-hot-toast";
import SwitchTheme from "./components/general/switchTheme";
import NotFoundPage from "./pages/errorPages/404";
import RestPassword from "./pages/auth/restPassword";
import OtpPage from "./pages/auth/otpPage";
import OverViewPage from "./pages/dashboard/overviewPage";
import ProtectedRoute from "./contexts/protectedRoute";
import { AuthClientId, AuthDomain, AuthRedirectUrl, AuthLogout } from "./api";
import SplashScreen from "./pages/auth/splashScreen";
import IncidentsPage from "./pages/dashboard/incidentTracking";
// styles
import "./assets/css/main/main.css";
import "./assets/css/forms/forms.css";
import ForbiddenPage from "./pages/errorPages/403";
import ServerErrorPage from "./pages/errorPages/500";
import ReportsPage from "./pages/dashboard/reports";
import ComingSoonPage from "./pages/errorPages/comingSoon";
import MsLoginPage from "./pages/auth/ms/msLoginPage";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./contexts/authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import LoginPage from "./pages/auth/loginPage";
import { PermissionsProvider, usePermission } from "./contexts/permissionsContext";
import IncidentTrackingPage from "./pages/dashboard/incidentTrackingPage";
import GeneralIncidentDetails from "./pages/dashboard/incidents/generalIncidentDetails";
import EmployeeTab from "./components/incidents/employeeTab";
import EmployeePage from "./pages/dashboard/employeePage";
import GrievancePage from "./pages/dashboard/grieviencePage";
import GrievanceDetails from "./pages/dashboard/incidents/grievanceDetails";
import MedicationPage from "./pages/dashboard/medicationPage";
import WorkPlacePage from "./pages/dashboard/workplaceTracking";
import IncidentInvestigationDetails from "./pages/dashboard/incidents/healthIncidentInvestigation/incidentInvestigationDetails";
import EmployeeInvestigation from "./pages/dashboard/incidents/employeeInvestigation";
import MedicationDetails from "./pages/dashboard/incidents/medicationDetails";

import EmployeeDetailsContent from "./pages/dashboard/incidents/employeeDetails";
import EmployeeIncidentDetails from "./pages/dashboard/incidents/employeeDetails";
import WorkPlaceDetails from "./pages/dashboard/incidents/workplaceDetails";
import GrivanceInvestigationdetails from "./pages/dashboard/incidents/grivanceinvestigation/grivanceivestigationdetails";
import LostFoundPage from "./pages/dashboard/lostFoundTracking";
import LostFoundDetails from "./pages/dashboard/incidents/lostFoundDetails";
import DrugReactionPage from "./pages/dashboard/DrugReactionPage";
import DrugReactionDetails from "./pages/dashboard/incidents/DrugReactionDetails";
import ModifyGeneralIncident from "./pages/dashboard/incidents/generalIncident/modify/modifyGeneralIncidentPage";
import GrievanceInvestigationPage from "./pages/dashboard/grievanceInvestigationPage";
import GrievanceInvestigationDetails from "./pages/dashboard/incidents/grivanceinvestigation/grivanceivestigationdetails";
import ModifyGrievanceIncident from "./pages/dashboard/incidents/generalIncident/modify/modifyGrievanceIncident";
import ModifyGrievanceIncidentPage from "./pages/dashboard/incidents/generalIncident/modify/modifyGrievanceIncident";
import ModifyMedicalErrorIncidentPage from "./pages/dashboard/incidents/generalIncident/modify/modifyMedicalErrorIncidentPage";
import ModifyMedicalAdverseDrugReactionIncidentPage from "./pages/dashboard/incidents/drugReactionIncident/modifyMedicalAdverseDrugReactionIncidentPage";
import ModifyLostFoundPage from "./pages/dashboard/incidents/generalIncident/modify/ModifyLostFoundPage";
import ModifyEmployeeInvestigationPage from "./pages/dashboard/incidents/generalIncident/modify/ModifyEmployeeInvestigationPage";
import ModifyGrievanceInvestigationFoundPage from "./pages/dashboard/incidents/generalIncident/modify/modifyGrievanceInvestigationFoundPage";
import ModifyEmployeeIncidentPage from "./pages/dashboard/incidents/generalIncident/modify/modifyEmployeeIncident";
import ModifyWorkplaceIncidentPage from "./pages/dashboard/incidents/generalIncident/modify/modifyWorkplaceIncidentPage";
import ForgotPassword from "./pages/auth/forgotPassword";
import VerifyCode from "./pages/auth/veirfyCode";
import ResetPassword from "./pages/auth/resetPassword";
import ProfilePage from "./pages/dashboard/profilePage";
import AdminPage from "./pages/admin/adminPage";
import UsersListPage from "./pages/admin/usersList";
import DepartmentDetailsPage from "./pages/dashboard/departmentDetails";
import FacilitiesPage from "./pages/dashboard/facilities/facilitiesPage";
import DepartmentsPage from "./pages/dashboard/facilities/departmentsPage";
import FacilityDetailsPage from "./pages/dashboard/facilities/facilityDetailsPage";
import DashBoardContainer from "./pages/dashboard/dashboardContainer";
import { Loading03Icon } from "hugeicons-react";


// main app
const RedirectUser = () => {
  const permissions = usePermission()
  const [userId, setUserId] = useState("")
  const [facility, setFacility] = useState("")
  const [department, setDepartment] = useState({})

  // users can land on the overview page
  // if logged user is staff, take them to the overview page
  // if logged in user is admin, take them to the admin page
  // if logged in user is head of department, take them to the department page
  // if user is staff, take them to the profile page.


  useEffect(() => {
    const checkUserPermissions = () => {
      // check user permissions and return permissions, user id, facility is, department id
      const userDataJson = localStorage.getItem('userData')
      if (userDataJson && userDataJson !== undefined) {
        const userData = JSON.parse(userDataJson)
        setDepartment(userData.department)
        setFacility(userData.facility)
      }
    }
    checkUserPermissions()

    if (permissions && permissions.includes('Admin')) {
      window.location.href = '/admin/'
    }
    else if (permissions && permissions.includes('Corporate')) {
      window.location.href = '/overview/'
    } else if (permissions && permissions.includes('Facility')) {
      window.location.href = `/facilities/${facility.id}`
    } else if (permissions && permissions.includes("Department Head")) {
      window.location.href = `/facilities/${facility.id}/departments/${department.id}`
    } else {
      window.location.href = '/users/profile/'
    }

  })
  return (
    <DashBoardContainer content={
      <div className="checking-permissions">
        <Loading03Icon className="loading-icon" />
        <h3>Checking permissions</h3>
      </div>
    }
    />
  )
}
function App() {
  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <MsalProvider instance={msalInstance}>
      <PermissionsProvider>
        <div>
          <Router>
            <Routes>
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <OverViewPage />
                    {/* We need to change this to redirection after test group 1 */}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/overview/"
                element={
                  <ProtectedRoute>
                    <OverViewPage />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/login/"
                element={<LoginPage instance={msalInstance} />}
              />
              {/* auth */}
              <Route
                path="/login/"
                element={<LoginPage instance={msalInstance} />}
              />
              <Route
                path="/forgot-password/"
                element={<ForgotPassword instance={msalInstance} />}
              />
              <Route
                path="/forgot-password/verify-code/"
                element={<VerifyCode instance={msalInstance} />}
              />
              <Route
                path="/forgot-password/new-password/"
                element={<ResetPassword instance={msalInstance} />}
              />
              {/* end of auth */}
              <Route
                path="/users/profile/"
                element={
                  <ProtectedRoute>
                    <ProfilePage />
                  </ProtectedRoute>
                }
              />

              {/* profile */}

              {/* end of profile */}
              <Route path="/auth/login/" element={<LoginPage />} />
              {/* incident */}
              <Route
                path="/incidents/"
                element={
                  <ProtectedRoute>
                    <IncidentTrackingPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/incident/general/"
                element={
                  <ProtectedRoute>
                    <IncidentsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/incident/medication_error/"
                element={
                  <ProtectedRoute>
                    <MedicationPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/incident/general/:incidentId/"
                element={
                  <ProtectedRoute>
                    <GeneralIncidentDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/incident/general/:incidentId/modify/"
                element={
                  <ProtectedRoute>
                    <ModifyGeneralIncident />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/incident/medication_error/:medicationId/"
                element={
                  <ProtectedRoute>
                    <MedicationDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/incident/lost_and_found/:lostAndFoundId/"
                element={
                  <ProtectedRoute>
                    <LostFoundDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/incident/grievance/:grievanceId/"
                element={
                  <ProtectedRoute>
                    <GrievanceDetails />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/incident/workplace_violence/:workplace_violence_Id/"
                element={
                  <ProtectedRoute>
                    <WorkPlaceDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/incident/drug-reaction/"
                element={
                  <ProtectedRoute>
                    <DrugReactionPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/incident/drug-reaction/:drugReactionId/"
                element={
                  <ProtectedRoute>
                    <DrugReactionDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/incident/employee/"
                element={
                  <ProtectedRoute>
                    <EmployeePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/incident/employee_incident/:incidentId/"
                element={
                  <ProtectedRoute>
                    <EmployeeIncidentDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/incident/employee/investigation/:investigationId/"
                element={
                  <ProtectedRoute>
                    <EmployeeInvestigation />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/incident/employee/investigation/"
                element={
                  <ProtectedRoute>
                    <IncidentInvestigationDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/incident/grievance/"
                element={
                  <ProtectedRoute>
                    <GrievancePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/incident/grievance/investigation/"
                element={
                  <ProtectedRoute>
                    <GrievanceInvestigationPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/incident/grievance-investigation/:grievanceInvestigationId"
                element={
                  <ProtectedRoute>
                    <GrievanceInvestigationDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/incident/lost-and-found/"
                element={
                  <ProtectedRoute>
                    <LostFoundPage />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/incident/workplace-violence/"
                element={
                  <ProtectedRoute>
                    <WorkPlacePage />
                  </ProtectedRoute>
                }
              />

              {/* end of incident */}

              <Route
                path="/patients-records/"
                element={
                  <ProtectedRoute>
                    <ComingSoonPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/quality-management/"
                element={
                  <ProtectedRoute>
                    <ComingSoonPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/patient-relations/"
                element={
                  <ProtectedRoute>
                    <ComingSoonPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/reports/"
                element={
                  <ProtectedRoute>
                    <ReportsPage />
                  </ProtectedRoute>
                }
              />
              {/* modify */}
              <Route
                path="/incident/general/:incidentId/modify/"
                element={
                  <ProtectedRoute>
                    <ModifyGeneralIncident />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/incident/grievance/:incidentId/modify/"
                element={
                  <ProtectedRoute>
                    <ModifyGrievanceIncidentPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/incident/medication_error/:incidentId/modify/"
                element={
                  <ProtectedRoute>
                    <ModifyMedicalErrorIncidentPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/incident/drug-reaction/:incidentId/modify/"
                element={
                  <ProtectedRoute>
                    <ModifyMedicalAdverseDrugReactionIncidentPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/incident/lost_and_found/:incidentId/modify/"
                element={
                  <ProtectedRoute>
                    <ModifyLostFoundPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/incident/workplace_violence/:incidentId/modify/"
                element={
                  <ProtectedRoute>
                    <ModifyWorkplaceIncidentPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/incident/employee/investigation/:incidentId/modify/"
                element={
                  <ProtectedRoute>
                    <ModifyEmployeeInvestigationPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/incident/grievance/investigation/:incidentId/modify/"
                element={
                  <ProtectedRoute>
                    <ModifyGrievanceInvestigationFoundPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/incident/employee_incident/:incidentId/modify/"
                element={
                  <ProtectedRoute>
                    <ModifyEmployeeIncidentPage />
                  </ProtectedRoute>
                }
              />
              {/* end of modify */}
              {/* admin */}
              <Route path="/admin/" element={<ProtectedRoute><AdminPage /></ProtectedRoute>} />
              <Route path="/admin/users/" element={<ProtectedRoute><UsersListPage /></ProtectedRoute>} />
              {/* end of admin */}

              {/* department */}
              {/* end of department */}

              {/* facilities */}
              <Route path="/facilities/" element={<ProtectedRoute> <FacilitiesPage /> </ProtectedRoute>} />
              <Route path="/facilities/:facilityId/" element={<ProtectedRoute> <FacilityDetailsPage /> </ProtectedRoute>} />
              <Route path="/facilities/:facilityId/departments/" element={<ProtectedRoute> <DepartmentsPage /> </ProtectedRoute>} />
              <Route path="/facilities/:facilityId/departments/:departmentId/" element={<ProtectedRoute><DepartmentDetailsPage /></ProtectedRoute>} />
              {/* end of facilities */}
              {/* <Route path="/" element={<OverViewPage />} /> */}
              <Route path="/auth/get-started/" element={<SplashScreen />} />
              <Route path="*" element={<NotFoundPage />} />
              <Route path="/auth/restPassword" element={<RestPassword />} />
              <Route path="/auth/otpage" element={<OtpPage />} />
              <Route path="/403/" element={<ForbiddenPage />} />
              <Route path="/500/" element={<ServerErrorPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </Router>
          <Helmet>
            <link
              rel="stylesheet"
              href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
            ></link>
          </Helmet>
          {/* <SwitchTheme /> */}
          <Toaster position="bottom-right" reverseOrder={false} />
        </div>
      </PermissionsProvider>
    </MsalProvider>
  );
}



export default App;

