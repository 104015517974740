import React from 'react'

const SupportLink = () => {
    return (
        <div className='support-link'>
            <p>Need technical support on this page? <span>Click here to submit a ticket</span></p>
        </div>
    )
}

export default SupportLink
