import React from "react";
import FormatDateTime from '../../../../components/general/formatDateTime'

const GeneralIncidentDetailsContentTab = ({ data }) => {
    return (
        <div className="incident-details">
            <div className="number-mrn">
                <div className="phone-number">
                    <small>Phone number</small>
                    <h4>{data.phone_number || 'Not provided'}</h4>
                </div>
                <div className="mrn">
                    <small>Mrn</small>
                    <h4>MRN {data.medical_record_number || 'Not provided'}</h4>
                </div>
            </div>

            <div className="number-mrn">
                <div className="phone-number">
                    <small>Patient age</small>
                    <h4>{data.age || 'Not provided'}</h4>
                </div>
                <div className="mrn">
                    <small>Date of birth</small>
                    <h4> <FormatDateTime date={data.date_of_birth || 'Not provided'} />  </h4>
                </div>
            </div>

            <div className="address">
                <div className="street">
                    <small>Street</small>
                    <h4>{data.address || 'Not provided'}</h4>
                </div>
                <div className="state">
                    <small>State</small>
                    <h4>{data.state || 'Not provided'}</h4>
                </div>
                <div className="zip-code">
                    <small>Zip code</small>
                    <h4>{data.zip_code || 'Not provided'}</h4>
                </div>
            </div>

            <div className="location-contribution-diagnosis">
                <div className="location">
                    <small>Location</small>
                    <h4>{data.location || 'Not provided'}</h4>
                </div>
                <div className="contribution">
                    <small>Contributing Factors</small>
                    <h4>{data.consulting_diagnosis || 'Not provided'}</h4>
                </div>

            </div>

            {/* <div className="patient-status">
                <div className="status">
                    <small>Patient/visitor status prior to incident</small>
                    <h4>{data.patient_status_prior || 'Not provided'}</h4>
                </div>
            </div> */}

            <div className="patient-status">
                <div className="outcome">
                    <small>Outcome</small>
                    <h4>{data.outcome || 'Not provided'}</h4>
                </div>
            </div>

            <div className="actions-taken patient-status">
                <div className="outcome">
                    <small>Actions taken</small>
                    <h4>{data.immediate_action_taken || 'Not provided'}</h4>
                </div>
            </div>

        </div>
    )
}

export default GeneralIncidentDetailsContentTab
