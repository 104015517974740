import React, { useState } from "react";
import toast from "react-hot-toast";
import { Editor } from "@tinymce/tinymce-react";
import api, { API_URL, TINYEMCE_API_KEY } from "../../../api";
import { Loading02Icon } from "hugeicons-react";

const ReviewFrom = ({ incidentId, toggleReviewForm, incidentName }) => {
  const [content, setContent] = useState("");
  const [submittingReview, setSubmittingReview] = useState(false);
  const handleReviewContent = (content, editor) => {
    setContent(content);
  };

  const handleNewReview = async () => {
    setSubmittingReview(true);
    try {
      const response = await api.post(
        `${API_URL}/incidents/${incidentName}/${incidentId}/reviews/new/`,
        { content: content }
      );
      if (response.status === 201) {
        toast.success("Review added successfully");
        setContent("");
        setSubmittingReview(false);
        toggleReviewForm();
      }
    } catch (error) {
      if (error.response.data) {
        toast.error(error.response.data.message || "Error adding review");
      } else {
        toast.error("Failed to add review");
      }
      setSubmittingReview(false);
    }
  };
  return (
    <form action="" className="review-form">
      <Editor
        apiKey={TINYEMCE_API_KEY}
        onEditorChange={handleReviewContent}
        init={{
          height: 200,
          menubar: false,
          branding: false,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime  wordcount",
          ],
          toolbar:
            "undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent",
        }}
      />

      <div className="buttons">
        <button
          disabled={submittingReview}
          onClick={handleNewReview}
          type="button"
          className="primary-button"
        >
          {submittingReview ? (
            <Loading02Icon className="loading-icon" size={15} />
          ) : (
            "Add a new review"
          )}
        </button>
        <button
          onClick={toggleReviewForm}
          type="button"
          className="outline-button"
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default ReviewFrom;
