import React from 'react'

function NamesInitials({ fullName }) {
    const names = fullName.split(' ');
    const firstName = names[0].charAt(0).toUpperCase();
    const lastName = names[names.length - 1].charAt(0).toUpperCase();
    return (
        <span>{firstName} {lastName}</span>
    )
}

export default NamesInitials
