import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashBoardContainer from "../dashboardContainer";
import IncidentDetailsHeader from "./incidentDetails/incidentDetailsHeader";
import IncidentDetails from "./incidentDetails/incidentDetails";
import IncidentTabs from "./incidentDetails/incidentDetailsTabs";
import api, { API_URL } from "../../../api";
import "../../../assets/css/pages/incidents/general/generalIncidentDetailsPage.css";
import MedicationDetailsContentTab from "./medicationIncident/medication";
import MedicationGeneralInfo from "./medicationIncident/medicationGeneralInfo";
import MedicationOtherInformation from "./medicationIncident/medicationOtherInformation";
import EmployeeDetails from "./employeeincident/employeeSimpleDetails";
import EmployeeDetailsContentTab from "./employeeincident/employee";
import EmployeeGeneralInfo from "./employeeincident/employeeGeneralInfo";
import EmployeeOtherInformation from "./employeeincident/employeeOtherInformation";
import IncidentsTab from "../../../components/incidents/employeeTab";
import EmployeeDocumentHistory from "./employeeincident/employeeDocumentHistory";
import EmployeeReviews from "./employeeincident/employeeReviews";
import EmployeeInvestigationInfo from "./employeeincident/employeeInvestigationInfo";
import toast from "react-hot-toast";
import FilesList from "../../../components/documents/filesList";
import { ArrowRight01Icon } from "hugeicons-react";
import { Link } from "react-router-dom";

const EmployeeDetailsContent = () => {
  // const { medicationId } = useParams();

  const { incidentId } = useParams();
  const [isFetching, setIsFetching] = useState(true);
  const [incidentDetails, setIncidentDetails] = useState({});
  const [incidentStatus, setIncidentStatus] = useState({});
  const [investigationInfo, setInvestigationInfo] = useState({});

  useEffect(() => {
    const fetchIncidentDetails = async () => {
      setIsFetching(true);
      try {
        const response = await api.get(
          `${API_URL}/incidents/employee_incident/${incidentId}/`
        );
        if (response.status === 200) {
          setIncidentDetails(response.data.incident);
          setInvestigationInfo(response.data.investigation);
          setIsFetching(false);
        }
      } catch (error) {
        console.log(error);
        setIsFetching(false);
      }
    };

    fetchIncidentDetails();
  }, []);

  useEffect(() => {
    const getIncidentReviews = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/employee_incident/${incidentId}/reviews/`
        );
        if (response.status === 200) {
          localStorage.setItem("incidentReviewsCount", response.data.length);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          toast.error("Authentication error");
          window.location.reload();
        } else {
          toast.error("Failed to fetch incident reviews");
          console.error(error);
        }
      }
    };
    getIncidentReviews();
  }, []);
  useEffect(() => {
    const getDocumentHistory = async () => {
      try {
        const response = await api.get(
          `${API_URL}/activities/list/${incidentId}/`
        );
        if (response.status === 200) {
          localStorage.setItem("documentHistoryCount", response.data.length);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          toast.error("Authentication error");
          window.location.reload();
        } else {
          toast.error("Failed to fetch document History");
          console.error(error);
        }
      }
    };
    getDocumentHistory();
  }, []);
  return (
    <div className="incident-details-page">
      {isFetching ? (
        <div className="fetching-data">Loading data</div>
      ) : (
        <div className="incident-details">
          <IncidentDetailsHeader
            data={incidentDetails}
            incidentId={incidentId}
            apiLink={"employee_incident"}
            sendTo={"send-to-department"}
          />
          <div className="details">
            <EmployeeDetails
              data={incidentDetails}
              fullName={incidentDetails.name}
              location={incidentDetails.location}
              IncidentDate={incidentDetails.date_of_injury_or_near_miss}
              incidentTime={incidentDetails.time_of_injury_or_near_miss}
              status={incidentDetails.status}
              incidentDetails={
                <EmployeeDetailsContentTab data={incidentDetails} />
              }
            />
            <IncidentTabs
              data={incidentDetails}
              statuses={incidentStatus}
              generalInformation={
                <EmployeeGeneralInfo
                  data={incidentDetails}
                  incidentStatuses={incidentStatus}
                />
              }
              otherInformation={
                <EmployeeOtherInformation data={incidentDetails} />
              }
              documentHistory={
                <EmployeeDocumentHistory incidentId={incidentId} />
              }
              reviews={<EmployeeReviews incidentId={incidentId} />}
              documents={<IncidentDocuments incidentId={incidentId} />}
              investigation={
                <EmployeeInvestigationInfo
                  data={investigationInfo}
                />
              }
              showInvestigationTab={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const IncidentDocuments = ({ incidentId, apiLink }) => {
  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await api.get(
          `/incidents/employee_incident/${incidentId}/documents/`
        );
        if (response.status === 200) {
          setDocuments(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchDocuments();
  }, []);
  return <FilesList documents={documents} showDownload={true} />;
};

// export default EmployeeDetailsContent;

const BreadCrumbs = () => {
  const { incidentId } = useParams();
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />
      <Link to={"/incidents/"}>Incidents</Link> <ArrowRight01Icon />
      <Link to={"/incident/employee/"}>Staff Incident Report</Link>{" "}
      <ArrowRight01Icon />
      <Link className="current-page"> #{incidentId}</Link>
    </div>
  );
};

const EmployeeIncidentDetails = () => {
  return (
    <div>
      <DashBoardContainer
        content={<EmployeeDetailsContent />}
        breadCrumbs={<BreadCrumbs />}
      />
    </div>
  );
};

export default EmployeeIncidentDetails;
