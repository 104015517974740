// ReusableDatePicker.js
import { Calendar01Icon, Calendar03Icon } from "hugeicons-react";
import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { FaCalendarAlt } from 'react-icons/fa';
import { format } from "date-fns";

const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <div className="custom-input" onClick={onClick} ref={ref}>
    <input type="text" value={value} readOnly placeholder="YYYY-MM-DD" />
    <Calendar03Icon className="icon" />
  </div>
));

const CustomDatePicker = ({ selectedDate, setSelectedDate }) => {
  // const handleStartDateChange = (date) => {
  //   if (date !== "") {
  //     setSelectedDate(format(date, "yyyy-MM-dd"));
  //   }
  // };
  const today = new Date().toISOString().split('T')[0];
  return (

    <input type="date" name="" id="" onChange={(e) => setSelectedDate(e.target.value)} value={selectedDate} max={today} />
    // <DatePicker
    //   selected={selectedDate ? new Date(selectedDate) : null}
    //   onChange={handleStartDateChange}
    //   customInput={<CustomInput />}
    //   placeholderText="Click to select date"
    //   dateFormat="yyyy-MM-dd"
    //   showYearDropdown
    //   scrollableYearDropdown
    //   yearDropdownItemNumber={30}
    //   showMonthDropdown
    //   dropdownMode="select"
    //   maxDate={new Date()}
    // />
  );
};

export default CustomDatePicker;
