import React, { useEffect, useState } from "react";
import SideBar from "../../../../components/dashaboard/sideBar";
import FormChoicesPopup from "../../../../components/incidents/forms/incidentFormsChoices";
import PopUp from "../../../../components/general/popup";
import DrugReactionForm from "../../../../components/incidents/forms/drugReactionForm";
import WorkplaceViolenceIncidentForm from "../../../../components/incidents/forms/workPlaceViolenceForm";
import HealthIncidentInvestigationForm from "../../../../components/incidents/forms/healthIncidentForm";
import VerbalComplaintForm from "../../../../components/incidents/forms/verbalComplaintForm";
import GrievanceInvestigationForm from "../../../../components/incidents/forms/grivanceInvestigationForm";
import GrievanceForm from "../../../../components/incidents/forms/grievanceForm";
import GeneralIncidentForm from "../../../../components/incidents/forms/generalIncidentForm";
import LostAndFoundForm from "../../../../components/incidents/forms/lostAndFound";
import EmployeeIncidentForm from "../../../../components/incidents/forms/employeeIncidentForm";
import MedicationErrorForm from "../../../../components/incidents/forms/medicationError";
import { usePermission } from "../../../../contexts/permissionsContext";
import HealthInvestigationTab from "../../../../components/incidents/employeeHealthInvestigationTab";
import NewContentFullContainer from "../../../../components/general/newContentFullContainer";
import { ArrowRight01Icon } from "hugeicons-react";
import { Link, useParams } from "react-router-dom";

const BreadCrumbs = () => {
  const { incidentId } = useParams();
  return (
    <div className="breadcrumbs">
      <Link to={'/'}>Overview</Link> <ArrowRight01Icon />
      <Link to={'/incidents/'}>Incidents</Link> <ArrowRight01Icon />
      <Link to={'/incident/employee/investigation/'}>Employee Investigation</Link>  <ArrowRight01Icon />
      <Link className='current-page'> #{incidentId}</Link>
    </div>
  )
};

const IncidentsPage = ({ content }) => {
  const permissions = usePermission();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isFormChoicesOpen, setIsFormCHoicesOpen] = useState(false);
  const [SelectedForm, setSelectedForm] = useState(false);

  const toggleFormChoicesOpen = () => {
    setIsFormCHoicesOpen(!isFormChoicesOpen);
  };

  const tootlePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  return (
    <div className="dashboard-container">
      <SideBar isMobileMenuOpen={isMobileMenuOpen} />

      <div className="dashboard-content">
        <div className="dash-header">
          <div
            onClick={toggleMobileMenu}
            className={
              isMobileMenuOpen
                ? "mobile-menu-button open"
                : "mobile-menu-button"
            }
          >
            <div className="bar first"></div>
            <div className="bar middle"></div>
            <div className="bar last"></div>
          </div>
          <h4 className="page-title">Incident Tracking</h4>
          <div className="search-input">
            <i className="fa-solid fa-magnifying-glass"></i>
            <input
              type="search"
              name="systemSearch"
              id="systemSearch"
              placeholder="Search the system"
            />
          </div>
          <div className="header-actions">
            <button
              onClick={toggleFormChoicesOpen}
              className="new-action-button primary-button"
            >
              <i className="fa-solid fa-plus"></i>
              <span>Add new</span>

              {isFormChoicesOpen ? (
                <FormChoicesPopup
                  tootlePopup={tootlePopup}
                  setSelectedForm={setSelectedForm}
                />
              ) : (
                ""
              )}
            </button>

            <div className="notification">
              <div className="dot"></div>
              <i className="fa-regular fa-bell"></i>
            </div>

            <div className="profile">
              <img
                src={require("../../../../assets/img/branding/logo.png")}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="page-content">
          {isPopupOpen ? (
            <PopUp
              tootlePopup={tootlePopup}
              isPopupOpen={isPopupOpen}
              popupContent={
                SelectedForm === "general" ? (
                  <GeneralIncidentForm />
                ) : SelectedForm === "lostAndFound" ? (
                  <LostAndFoundForm />
                ) : SelectedForm === "employee" ? (
                  <EmployeeIncidentForm />
                ) : SelectedForm === "medicationError" ? (
                  <MedicationErrorForm />
                ) : SelectedForm === "grievance" ? (
                  <GrievanceForm />
                ) : SelectedForm === "reactionReport" ? (
                  <DrugReactionForm />
                ) : SelectedForm === "workPlaceViolence" ? (
                  <WorkplaceViolenceIncidentForm />
                ) : SelectedForm === "healthIncident" ? (
                  <HealthIncidentInvestigationForm />
                ) : SelectedForm === "verbalComplaint" ? (
                  <VerbalComplaintForm />
                ) : SelectedForm === "grievanceInvestigation" ? (
                  <GrievanceInvestigationForm />
                ) : (
                  ""
                )
              }
            />
          ) : (
            ""
          )}

          {permissions.includes("managers") ? (
            <div className="manger-content">
              <BreadCrumbs />
              <div className="tabs-content">
                <HealthInvestigationTab />
              </div>
            </div>
          ) : (
            <NewContentFullContainer action={toggleFormChoicesOpen} />
          )}
        </div>
      </div>
    </div>
  );
};

export default IncidentsPage;
