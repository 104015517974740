import axios from "axios";
import { useEffect, useState } from "react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
export const MAX_FILE_SIZE = 12 * 1024 * 1024; // 12 MB in bytes

export const API_URL = process.env.REACT_APP_API_URL;
export const AuthClientId = process.env.REACT_APP_MS_CLIENT_ID
export const Auth_CompStaffing_Tenant = process.env.REACT_APP_MS_COMPSTAFIFNG_TENANT
export const Auth_Ms_redirectUri = process.env.REACT_APP_MS_REDIRECT_URL
export const TINYEMCE_API_KEY = process.env.REACT_APP_TINYEMCE_API_KEY
const api = axios.create({
    baseURL: API_URL
})

api.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem("access");
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`
        } return config
    }, (error) => {
        return Promise.reject(error)
    }
)

export default api

export const cleanedData = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
        if (value !== null && value !== undefined && value !== '') {
            acc[key] = value;
        }
        return acc;
    }, {});
};


export const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    const age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}