import React, { useEffect, useState } from 'react'
import DashBoardContainer from '../dashboardContainer'
import { Link, useParams } from 'react-router-dom'
import { ArrowRight01Icon, LayersLogoIcon, ListViewIcon, Note02Icon, Sad01Icon, UserMultiple02Icon } from 'hugeicons-react'
import api from '../../../api'
import ModifyPageLoader from '../incidents/generalIncident/modify/loader'
import DateFormatter from '../incidents/dateFormatter'
import { ComplainDetails } from '../../../components/profile/profileComplaints'


const FacilityDetailsPageContent = () => {
    const { facilityId } = useParams()
    const [facility, setFacility] = useState({})
    const [departments, setDepartments] = useState(true)
    const [complaints, setComplaints] = useState([])
    const [staff, setStaff] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState("")

    const [activeTab, setActiveTab] = useState("reports")

    const [generalIncidents, setGeneralIncidents] = useState([])
    const [grievances, setGrievances] = useState([])
    const [grievanceInvestigations, setGrievancesInvestigation] = useState([])
    const [lostAndFoundIncidents, setLostAndFoundIncidents] = useState([])
    const [employeeIncidents, setEmployeeIncidents] = useState([])
    const [employeeHealthInvestigations, setEmployeeHealthInvestigations] = useState([])
    const [workplaceViolenceIncidents, setWorkplaceViolenceIncidents] = useState([])
    const [adverseDrugReaction, setAdverseDrugReaction] = useState([])
    const [medicationError, setMedicationError] = useState([])

    useEffect(() => {
        const fetchFacility = async () => {
            try {
                const response = await api.get(`/facilities/${facilityId}/`)
                if (response.status === 200) {
                    setFacility(response.data)
                    console.log(response.data)
                    localStorage.setItem('facilityName', response.data.name)
                    localStorage.setItem('facilityId', response.data.name)
                    setIsLoading(false)
                }
            } catch (error) {
                if (error.response) {
                    setErrorMessage(error.response.data.message || error.response.data.error || 'Error fetching facility')
                } else {
                    setErrorMessage('Unknown error fetching facility')
                }
            }
        }
        fetchFacility()
    }, [])

    useEffect(() => {
        const fetchDepartments = async () => {
            try {
                setIsLoading(true)
                const response = await api.get(`/facilities/${facilityId}/departments/`)
                if (response.status === 200) {
                    setDepartments(response.data.departments)
                }
            } catch (error) {
                if (error.response) {
                    setErrorMessage(error.response.data.message || error.response.data.error || 'Error fetching departments')
                } else {
                    setErrorMessage('Unknown error fetching departments')
                }
                setIsLoading(false)
            }
        }
        fetchDepartments()
    }, [])

    useEffect(() => {
        // Fetch drafts data

        const fetchReports = async () => {
            // API call to fetch drafts data
            try {
                const response = await api.get(`facilities/${facilityId}/incidents/overview/`)
                if (response.status === 200) {
                    // se(response.data)
                    setGrievancesInvestigation(response.data.grievance_investigation)
                    setGrievances(response.data.grievance_incident)
                    setGeneralIncidents(response.data.general_incident)
                    setLostAndFoundIncidents(response.data.lost_and_found)
                    setEmployeeIncidents(response.data.employee_incident)
                    setEmployeeHealthInvestigations(response.data.health_investigation)
                    setWorkplaceViolenceIncidents(response.data.workplace_violence)
                    setAdverseDrugReaction(response.data.adverse_drug_reaction)
                    setMedicationError(response.data.medical_error)
                    setIsLoading(false)
                }
            } catch (error) {
                if (error.response) {
                    setErrorMessage(error.response.data.message || error.response.error || 'Error fetching drafts data, try again later')
                } else {
                    setErrorMessage('Unknown fetching incidents, try again later')
                }
                setIsLoading(false)
                console.error(error)
            }
        }

        fetchReports()
    }, [])
    useEffect(() => {
        const fetchComplaint = async () => {
            try {
                setIsLoading(true)
                const response = await api.get(`/facilities/${facilityId}/complaints/`)
                if (response.status === 200) {
                    setComplaints(response.data.complaints)
                    setIsLoading(false)
                }
            } catch (error) {
                if (error.response) {
                    setErrorMessage(error.response.data.message || error.response.data.error || 'Error fetching complaints data')
                } else {
                    setErrorMessage('Unknown error fetching complaints data')
                }
                setIsLoading(false)
                console.error(error)
            }
        }
        fetchComplaint()
    }, [])

    useEffect(() => {
        const fetchStaffs = async () => {
            try {
                const response = await api.get(`/facilities/${facilityId}/staff`)
                if (response.status === 200) {
                    setStaff(response.data.staff)
                    console.log(response.data.staff)
                }
            } catch (error) {
                if (error.response) {
                    setErrorMessage(error.response.data.message || error.response.data.error || 'Error fetching staff data')
                } else {
                    setErrorMessage('Unknown error fetching staff data')
                }
            }
        }
        fetchStaffs()
    }, [])
    return isLoading ? <ModifyPageLoader /> : (
        <div className='dashboard-page-content'>
            <h2>{facility.name}</h2>
            <div className="tabs-list">
                <div onClick={() => setActiveTab('reports')} className={`tab ${activeTab === 'reports' ? 'active' : ''}`}><ListViewIcon size={20} />Incidents reports</div>
                <div onClick={() => setActiveTab('departments')} className={`tab ${activeTab === 'departments' ? 'active' : ''}`}> <LayersLogoIcon size={20} /> Departments</div>
                <div onClick={() => setActiveTab('complaints')} className={`tab ${activeTab === 'complaints' ? 'active' : ''}`}><Sad01Icon size={20} /> Complaints</div>
                {/* <div onClick={() => setActiveTab('documents')} className={`tab ${activeTab === 'documents' ? 'active' : ''}`}>Documents</div> */}
                <div onClick={() => setActiveTab('staff')} className={`tab ${activeTab === 'staff' ? 'active' : ''}`}><UserMultiple02Icon size={20} /> Staff</div>
            </div>
            {
                errorMessage && <div className="error-message">{errorMessage}</div>
            }
            {
                activeTab === 'departments' && <div className="departments-list-container">
                    <h3>Departments</h3>
                    <div className="departments-list">
                        {
                            departments && departments.length > 0 ? departments.map((department, index) => (
                                <Link to={`/facilities/${facilityId}/departments/${department.id}/`} key={index}>
                                    <div className="department-item">
                                        <div className="icon">
                                            <Note02Icon />
                                        </div>
                                        <div className="title-content">
                                            <h3>{department.name}</h3>
                                            <small>Members: {department.members}</small>
                                        </div>
                                    </div>
                                </Link>
                            ))
                                : 'No department found'
                        }
                    </div>
                </div>
            }
            {
                activeTab === 'reports' && <div>
                    {/* <h3>Reports</h3> */}
                    <FacilitiesReports
                        generalIncidents={generalIncidents}
                        lostAndFoundIncidents={lostAndFoundIncidents}
                        grievances={grievances}
                        adverseDrugReaction={adverseDrugReaction}
                        medicationError={medicationError}
                        workplaceViolenceIncidents={workplaceViolenceIncidents} />
                </div>
            }
            {
                activeTab === 'complaints' && <div>
                    <FacilityComplaints complaints={complaints} />
                </div>
            }
            {
                activeTab === 'documents' && <div>
                    <h3>Documents</h3>
                </div>
            }
            {
                activeTab === 'staff' && <div>
                    <FacilitiesStaff staff={staff} />
                </div>
            }
        </div >
    )
}

const FacilitiesStaff = ({ staff }) => {
    return (
        <div className="staff-list">
            <div className="content-card">
                <div className="card-header">
                    <h3>Staff</h3>
                </div>

                <div className="table">
                    <table>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Departments</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                staff && staff.map((staff, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{staff.id}</td>
                                        <td>{staff.first_name} {staff.last_name}</td>
                                        <td>{staff.email}</td>
                                        <td>
                                            {staff.department?.slice(0, 2).map((dep, index) => (
                                                <span key={index}>{dep.name}, </span>
                                            ))}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

const FacilityComplaints = ({ complaints }) => {
    const [showComplaintDetails, setShowComplaintDetailsWithComplaints] = useState(false)
    const [selectedComplaint, setSelectedComplaint] = useState()
    const handleShowComplainDetails = (complaint) => {
        setSelectedComplaint(complaint)
        setShowComplaintDetailsWithComplaints(!showComplaintDetails)
    }
    return (
        <div className="complaints-lists">
            {
                showComplaintDetails &&
                <ComplainDetails complaint={selectedComplaint} handleShowComplainDetails={handleShowComplainDetails} />
            }
            <div className="content-card">
                <div className="card-header">
                    <h3>Complaints</h3>
                </div>
            </div>

            <div className="table">
                <table>
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>Claim ID</th>
                            <th>Patient name</th>
                            <th>MRN</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            complaints && complaints.length > 0 ? complaints.map((complaint, index) => (
                                <tr onClick={() => handleShowComplainDetails(complaint)} key={index}>
                                    <td>{index + 1}</td>
                                    <td>{complaint.id}</td>
                                    <td>{complaint.patient_name}</td>
                                    <td>{complaint.medical_record_number}</td>
                                    <td>{<DateFormatter dateString={complaint.created_at} />}</td>
                                </tr>
                            ))
                                : 'No complaints found'
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const FacilitiesReports = ({ generalIncidents, grievances, adverseDrugReaction, lostAndFoundIncidents, workplaceViolenceIncidents, medicationError, employeeIncidents }) => {
    console.log(grievances)
    return (
        <div className="reports-list">
            {

                <div className="reports-categories">
                    {
                        generalIncidents && generalIncidents.length > 0 ?
                            <ContentCard incident={generalIncidents} title={"General incident reports"} apiLink={'incident/general'} />
                            : ''
                    }
                    {
                        grievances && grievances.length > 0 ?
                            <ContentCard incident={grievances} title={'Grievance reports'} apiLink={'incident/grievance'} />
                            : ''
                    }
                    {
                        adverseDrugReaction && adverseDrugReaction.length > 0 ?
                            <ContentCard incident={adverseDrugReaction} title={"Adverse drug reaction reports"} apiLink={'incident/drug-reaction'} />
                            : ''
                    }

                    {
                        lostAndFoundIncidents && lostAndFoundIncidents.length > 0 ?
                            <ContentCard incident={lostAndFoundIncidents} title={"Lost & Found property report reports"} apiLink={'incident/lost_and_found'} />
                            : ''
                    }
                    {
                        workplaceViolenceIncidents && workplaceViolenceIncidents.length > 0 ?
                            <ContentCard incident={workplaceViolenceIncidents} title={'Workplace violence reports'} apiLink={'incident/workplace_violence'} />
                            : ''
                    }
                    {
                        medicationError && medicationError.length > 0 ?
                            <ContentCard incident={medicationError} title={"Medication error reports"} apiLink={'incident/medication_error'} />
                            : ''
                    }
                    {
                        employeeIncidents && employeeIncidents.length > 0 ?
                            <ContentCard incident={employeeIncidents} title={'Staff Incident reports'} apiLink={'incident/employee_incident'} />
                            : ''
                    },

                </div>
            }
        </div>
    )
}

const ContentCard = ({ incident, title }) => {
    return (
        <div className="reports-card">
            <div className="card-header">
                <h3>{title}</h3>
            </div>
            <div className="table">
                <table>
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>Report ID</th>
                            <th>Status</th>
                            <th>Date</th>
                            <th>Current step</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            incident && incident.map((report, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{report.id}</td>
                                    <td>{report.status}</td>
                                    <td>{<DateFormatter dateString={report.created_at} />}</td>
                                    <td>{report.current_step}</td>

                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
const BreadCrumbs = () => {
    useEffect(() => {
        const facilityName = localStorage.getItem('facilityName')
        const facilityId = localStorage.getItem('facilityId')
    })
    return (
        <div className="breadcrumbs">
            <Link to={'/'}>Overview</Link>
            <ArrowRight01Icon />
            <Link to={"/facilities/"}>Facilities</Link>
            <ArrowRight01Icon />
            <Link className='current-page'>{localStorage.getItem("facilityName")}</Link>
        </div>
    )
}
const FacilityDetailsPage = () => {
    return (
        <DashBoardContainer breadCrumbs={<BreadCrumbs />} content={<FacilityDetailsPageContent />} />
    )
}

export default FacilityDetailsPage
