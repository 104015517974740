import { File02Icon } from "hugeicons-react";
import "../../../../assets/css/pages/drugReactionPage/drugReactionPage.css";
import { Link } from "react-router-dom";
import DateFormatter from "../dateFormatter";
import FormatDate from "../../../../components/general/formatDateTime";
import { format, parseISO } from "date-fns";

function GrievanceInvestigationOtherInfo({ data }) {
  return (
    <div className="flex-column">
      <div className="flex-row">
        <div className="flex-column">
          <h3>Interviews</h3>
          <small>{data.interviews_findings || "Not Provided"}</small>
        </div>
      </div>

      <div className="flex-row">
        <div className="flex-column">
          <h3>Medical Record Findings</h3>
          <small>{data.medical_record_findings || "Not Provided"}</small>
        </div>
      </div>
    </div>
  );
}

export default GrievanceInvestigationOtherInfo;
