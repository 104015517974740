import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import DashBoardContainer from "../../../dashboardContainer";
import api from "../../../../../api";
import ModifyWorkplaceIncident from "../../../../../components/incidents/forms/modiy/workplaceIncident";
import { ArrowLeft01Icon, ArrowTurnBackwardIcon } from "hugeicons-react";
import BackToPage from "../../../../../components/incidents/backToPage";
import { ArrowRight01Icon } from "hugeicons-react";


const ModifyWorkplaceIncidentPageContent = () => {
  const [error, setError] = useState();
  const [incident, setIncident] = useState({});
  const { incidentId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchIncidentData = async () => {
      try {
        const response = await api.get(
          `/incidents/workplace_violence/${incidentId}/`
        );

        if (response.status === 200) {
          console.log(response.data);
          setIncident(response.data);
          setIsLoading(false);
        }
      } catch (error) {
        setError("Error getting incident data");
      }
    };
    fetchIncidentData();
  }, []);

  return (
    <div>
      {isLoading
        ? "Loading"
        : incident && (
          <ModifyWorkplaceIncident data={incident} incidentId={incidentId} />
        )}
    </div>
  );
};
const BreadCrumbs = () => {
  const { incidentId } = useParams();
  return (
    <div className="breadcrumbs">
      <Link to={'/'}>Overview</Link> <ArrowRight01Icon />
      <Link to={'/incidents/'}>Incidents</Link> <ArrowRight01Icon />
      <Link to={'/incident/workplace-violence/'}>Work Place Violence List</Link>  <ArrowRight01Icon />
      <Link to={`/incident/workplace_violence/${incidentId}/`}>#{incidentId}</Link> <ArrowRight01Icon />
      <Link className='current-page'> Modify</Link>
    </div>
  )
};
const ModifyWorkplaceIncidentPage = () => {
  return (
    <DashBoardContainer content={<ModifyWorkplaceIncidentPageContent />} breadCrumbs={<BreadCrumbs />} />
  );
};

export default ModifyWorkplaceIncidentPage;
