import React from "react";
import DashboardContainer from "../../components/dashaboard/dahdboardContainer";
import {ArrowRight01Icon} from "hugeicons-react";
import { Link } from "react-router-dom";

const BreadCrumbs = () => {
  return (
      <div className="breadcrumbs">
          <Link to={'/'}>Overview</Link> <ArrowRight01Icon /> 
          <Link to={'/incidents/'}>Incidents</Link> <ArrowRight01Icon /> 
          <Link className='current-page'> Reports</Link>
      </div>
  )
}
const ReportsPage = () => {
    return (
        <div className="dashboard-content">
            <DashboardContainer  breadCrumbs={<BreadCrumbs />} />
        </div>
    )
}

export default ReportsPage