function GrievanceInvestationContentTab({ data }) {
  return (
    <div className="incident-details">
      <div className="number-mrn">
        <div className="phone-number">
          <small>Investigation conducted by</small>
          <h4>{data.conducted_by || "Not provided"}</h4>
        </div>
      </div>

      <div className="address">
        <div className="street">
          <small>Start Date</small>
          <h4>{data.start_date || "Not provided"}</h4>
        </div>
        <div className="state">
          <small>End Date</small>
          <h4>{data.end_date || "Not provided"}</h4>
        </div>
      </div>

      <div className="location-contribution-diagnosis">
        <div className="location">
          <small>Feedback to patient and family</small>
          <h4>{data.feedback || "Not provided"}</h4>
        </div>
        <div className="contribution">
          <small>Date of feedback</small>
          <h4>{data.date_of_feedback || "Not provided"}</h4>
        </div>
      </div>
    </div>
  );
}

export default GrievanceInvestationContentTab;
