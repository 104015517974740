import React, { useEffect, useState } from "react";
import "../../assets/css/main/main.css";
import api, { API_URL } from "../../api";
import { usePermission } from "../../contexts/permissionsContext";
import {
  MoreHorizontalSquare01Icon,
  FilterHorizontalIcon,
  CancelSquareIcon,
  PencilEdit01Icon,
  PencilEdit02Icon,
  EyeIcon,
} from "hugeicons-react";
import { useNavigate } from "react-router-dom";
import TableActionsPopup from "../general/popups/tableActionPopup";
import toast from "react-hot-toast";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

import NoAccessPage from "../../pages/errorPages/401";
import CustomSelectInput from "../../components/incidents/forms/inputs/customSelect";
import DateFormatter from "../../pages/dashboard/incidents/dateFormatter";
import ModifyPageLoader from "../../pages/dashboard/incidents/generalIncident/modify/loader";

const handleSearch = (items, searchString) => {
  if (searchString.length > 2) {
    const results = items.filter((item) => {
      return (
        (item.patient_visitor_name &&
          item.patient_visitor_name
            .toLowerCase()
            .includes(searchString.toLowerCase())) ||
        (item.incident_type &&
          item.incident_type
            .toLowerCase()
            .includes(searchString.toLowerCase())) ||
        (item.follow_up &&
          item.follow_up.toLowerCase().includes(searchString.toLowerCase()))
      );
    });
    return results;
  }

  return [];
};

const handleFilter = (
  items,
  searchType,
  searchStatus,
  searchCareLevel,
  startDate,
  endDate
) => {
  const results = items.filter((item) => {
    const matchesStatus =
      !searchStatus ||
      (item.status &&
        item.status.toLowerCase().includes(searchStatus.toLowerCase()));
    const matchesType =
      !searchType ||
      (item.incident_type &&
        item.incident_type.toLowerCase().includes(searchType.toLowerCase()));
    const matchesCareLevel =
      !searchCareLevel ||
      (item.category &&
        item.category.toLowerCase().includes(searchCareLevel.toLowerCase()));
    const incidentDate = new Date(item.incident_date);
    const matchesDateRange =
      (!startDate || incidentDate >= startDate) &&
      (!endDate || incidentDate <= endDate);

    return matchesStatus && matchesType && matchesCareLevel && matchesDateRange;
  });

  return results;
};

function formatDate(dateString) {
  const date = new Date(dateString);
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = String(date.getFullYear()).slice(0);
  return `${year}-${month}-${day}`;
}

const IncidentsTab = () => {
  const permission = usePermission();
  const [errorFetching, setErrorFetching] = useState("");
  const [isFetching, setIsFetching] = useState(true);
  const [incidentData, setIncidentData] = useState([]);
  const [searchResults, setSearchResults] = useState("");
  const [resultsFound, setResultsFound] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const [openAction, setOpenAction] = useState(false);
  const [openActionIndex, setOpenActionIndex] = useState("");

  // filters
  const [filterByType, setFilterByType] = useState("");
  const [filterByStatus, setFilterByStatus] = useState("");
  const [filterByCareLevel, setFilterByCareLevel] = useState("");
  const [clearFilters, setClearFilters] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [filterByDate, setFilterByDate] = useState();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // row click
  const handleRowClick = (incidentId) => {
    navigate(`/incident/general/${incidentId}`);
  };
  const navigateToModify = (incidentId) => {
    navigate(`/incident/general/${incidentId}/modify/`);
  };

  // allow actions column to be not clickable
  const handleNonClickableColumnClick = (event) => {
    event.stopPropagation();
  };
  const toggleOpenFilters = () => {
    setOpenFilters(!openFilters);
  };
  const toggleAction = (index) => {
    setOpenActionIndex(index);
    setOpenAction(!openAction);
  };

  const search = (string) => {
    setIsSearching(true);
    if (Object.keys(string).length < 2) {
      setIsSearching(false);
    }
    if (Object.keys(string).length > 2) {
      const results = handleSearch(incidentData, string);
      setSearchResults(results);
    }
  };

  const handleFilterByDate = (value) => {
    if (value !== "") {
      setFilterByDate(format(value, "yyyy-MM-dd"));
    } else {
      setFilterByDate("");
    }
    setClearFilters(false);
  };

  const handleFilterByType = (value) => {
    setFilterByType(value);
    setClearFilters(false);
  };
  const handleFilterByStatus = (value) => {
    setFilterByStatus(value);
    setClearFilters(false);
  };
  const handleFilterByCareLevel = (value) => {
    setFilterByCareLevel(value);
    setClearFilters(false);
  };

  const handleFilters = () => {
    setClearFilters(true);
    setIsSearching(true);
    setOpenFilters(false);
    const results = handleFilter(
      incidentData,
      filterByType,
      filterByStatus,
      filterByCareLevel,
      startDate,
      endDate
    );
    setSearchResults(results);
  };

  const handleClearFilters = () => {
    setOpenFilters(false);
    setIsSearching(false);
    setClearFilters(false);
    setFilterByType("");
    setFilterByCareLevel("");
    setFilterByStatus("");
    setSearchResults(incidentData);
    setStartDate(null);
    setEndDate(null);
  };

  useEffect(() => {
    const fetchIncidentData = async () => {
      try {
        const response = await api.get(`${API_URL}/incidents/general/`);
        if (response && response.status === 200 && response.data) {
          const formattedData = response.data.incidents.map((item) => ({
            ...item,
            incident_date: formatDate(item.incident_date),
          }));
          setIncidentData(formattedData);
          setIsFetching(false);
          console.log(response.data);
        } else {
          setErrorFetching("Unexpected response format.");
          setIsFetching(false);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          setErrorFetching(error.response.data.error);
        } else {
          setErrorFetching("An error occurred while fetching incident data.");
        }
        setIsFetching(false);
      }
    };
    fetchIncidentData();
  }, []);

  return isFetching ? <ModifyPageLoader /> : (
    <>
      {permission.includes("Quality - Risk Manager") ||
        permission.includes("managers") ? (
        <div>
          {errorFetching ? (
            <div className="error-message">
              <p>{errorFetching}</p>
            </div>
          ) : (
            <div className="tab-container incidents-tab">
              <div className="tab-header">
                <div className="title-container-action">
                  <div className="title-container">
                    <h2 className="title">Incident Tracking List</h2>
                    <p>15 incident available </p>
                  </div>
                </div>

                {/* <div className="filters">
                  {openFilters ? (
                    <div className="filters_popup">
                      <div onClick={toggleOpenFilters} className="close-icon">
                        <CancelSquareIcon size={24} variant={"stroke"} />
                      </div>

                      <h3>Filter incident data</h3>
                      <div className="filter-buttons">
                        <CustomSelectInput
                          options={[
                            "Fall related",
                            "Equipment Malfunction/Defect",
                            "Unresolved",
                            "Other",
                          ]}
                          placeholder={"Filter by type of incident"}
                          selected={filterByType}
                          setSelected={setFilterByType}
                          name="incidentType"
                          id="incidentType"
                        />
                        <CustomSelectInput
                          options={["Draft", "Completed", "Closed"]}
                          placeholder={"Filter by status"}
                          selected={filterByStatus}
                          setSelected={setFilterByStatus}
                          name="status"
                          id="status"
                        />
                        <CustomSelectInput
                          options={["Inpatient", "Outpatient", "ED", "Visitor"]}
                          placeholder={"Filter by care Level"}
                          selected={filterByCareLevel}
                          setSelected={setFilterByCareLevel}
                          name="careLevel"
                          id="careLevel"
                        />

                        <div className="filter-range">
                          <span>Start date</span>
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            placeholderText="Select a start date"
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>
                        <div className="filter-range">
                          <span>End date</span>
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            placeholderText="Select an end date"
                            dateFormat="yyyy-MM-dd"
                            minDate={startDate}
                          />
                        </div>

                        <div className="pop-up-buttons">
                          {clearFilters ? (
                            <button
                              onClick={handleClearFilters}
                              className="outline-button"
                            >
                              <CancelSquareIcon size={20} variant={"stroke"} />
                              Close & Clear
                            </button>
                          ) : (
                            <button
                              onClick={handleFilters}
                              className="secondary-button"
                            >
                              <div className="icon">
                                <FilterHorizontalIcon
                                  size={20}
                                  variant={"stroke"}
                                />
                              </div>
                              <span>Filter</span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="search-input incident-search">
                    <i className="fa-solid fa-magnifying-glass"></i>
                    <input
                      onChange={(e) => {
                        search(e.target.value);
                      }}
                      // value={searchString}
                      type="search"
                      name="systemSearch"
                      id="systemSearch"
                      placeholder="Search the system"
                    />
                  </div>
                  <button
                    onClick={toggleOpenFilters}
                    className="date-filter-button"
                  >
                    <div className="icon">
                      <FilterHorizontalIcon size={24} variant={"stroke"} />
                    </div>
                    <span>Filter</span>
                  </button>
                </div> */}
              </div>

              <div className="incident-list">
                <table>
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>ID</th>
                      <th>Facility</th>
                      <th>Name</th>
                      <th>Type of incident</th>
                      <th>Date & Time</th>
                      <th>Severity</th>
                      <th>Care level</th>
                      <th>Status</th>
                      <th className="action-col">Action</th>
                    </tr>
                  </thead>
                  {isSearching ? (
                    <tbody>
                      {searchResults.length > 0 ? (
                        searchResults.map((incident, index) => (
                          <tr
                            onClick={() => handleRowClick(incident.id)}
                            key={index}
                          >
                            <td>{index + 1}</td>
                            <td>{incident.id}</td>
                            <td>
                              {incident.report_facility || "Not provided"}
                            </td>
                            <td>
                              {incident.patient_visitor_name || "Not provided"}
                            </td>
                            <td>{incident.incident_type || "Not provided"}</td>
                            <td>
                              {(
                                <div>
                                  <DateFormatter
                                    dateString={incident.incident_date}
                                  />
                                  ,&nbsp; {incident.incident_time}
                                </div>
                              ) || "-"}
                            </td>
                            <td>
                              {incident.severity_rating || "Not provided"}
                            </td>
                            <td>{incident.care_level || "Not provided"}</td>
                            <td>
                              {" "}
                              <p
                                className={`follow-up ${index < 2 ? "in-progress" : ""
                                  }`}
                              >
                                {incident.status || "Not specified"}
                              </p>
                            </td>
                            <td
                              onClick={(event) =>
                                handleNonClickableColumnClick(event)
                              }
                              className="action-col"
                            >
                              <div className="table-actions">
                                {
                                  !incident.is_resolved &&
                                  <PencilEdit02Icon
                                    size={20}
                                    onClick={() => navigateToModify(incident.id)}
                                  />
                                }
                                <EyeIcon
                                  size={20}
                                  onClick={() => handleRowClick(incident.id)}
                                />
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <p>Nothing found</p>
                      )}
                    </tbody>
                  ) : (
                    <tbody>
                      {incidentData.map((incident, index) => (
                        <tr
                          onClick={() => handleRowClick(incident.id)}
                          key={index}
                        >
                          <td>{index + 1}</td>
                          <td>{incident.id}</td>
                          <td>
                            {incident.report_facility?.name || "Not provided"}
                          </td>
                          <td>
                            {incident.patient_visitor_name || "Not provided"}
                          </td>
                          <td>{incident.incident_type || "Not provided"}</td>
                          <td>
                            {(
                              <div>
                                <DateFormatter
                                  dateString={incident.incident_date}
                                />
                                ,&nbsp; {incident.incident_time}
                              </div>
                            ) || "-"}
                          </td>
                          <td>{incident.severity_rating || "Not provided"}</td>
                          <td>{incident.care_level || "Not provided"}</td>
                          <td>
                            {" "}
                            <p
                              className={`follow-up ${index < 2 ? "in-progress" : ""
                                }`}
                            >
                              {incident.status || "Not specified"}
                            </p>
                          </td>
                          <td
                            onClick={(event) =>
                              handleNonClickableColumnClick(event)
                            }
                            className="action-col"
                          >
                            <div className="table-actions">
                              {
                                !incident.is_resolved &&
                                <PencilEdit02Icon
                                  size={20}
                                  onClick={() => navigateToModify(incident.id)}
                                />
                              }
                              <EyeIcon
                                size={20}
                                onClick={() => handleRowClick(incident.id)}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          )}
        </div>
      ) : (
        <NoAccessPage />
      )}
    </>
  );
};

export default IncidentsTab;
