import { File02Icon } from "hugeicons-react";
import React from "react";
import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";

const GrievanceInvestigationDocument = ({ data }) => {
  const formattedDate = (dateString) => {
    if (dateString) {
      const date = parseISO(dateString); // Convert the string to a Date object
      return format(date, "MMM d, yyyy"); // Format the date
    } else {
      return "Not provided";
    }
  };
  return (
    <div className="flex-column">
      {data.extension_letter_copy ? (
        <Link
          className="document-link"
          to={data.extension_letter_copy}
          target="_blank"
          download
        >
          <div className="row">
            <File02Icon className="document-icon" />
            <div className="col">
              <h4>Certified Receipt Received</h4>
              <span>
                Added on {formattedDate(data.date_extension_letter_sent)}{" "}
              </span>
            </div>
          </div>
        </Link>
      ) : (
        <small>No Extension letter copy</small>
      )}

      <br />

      {data.response_letter_copy ? (
        <Link
          className="document-link"
          to={data.response_letter_copy}
          target="_blank"
          download
        >
          <div className="row">
            <File02Icon className="document-icon" />
            <div className="col">
              <h4>Certified Receipt Received</h4>
              <span>
                Added on {formattedDate(data.date_response_letter_sent)}{" "}
              </span>
            </div>
          </div>
        </Link>
      ) : (
        <small>No response letter copy</small>
      )}
    </div>
  );
};

export default GrievanceInvestigationDocument;
