import React from "react";

const MedicationOtherInformation = ({ data, incidentStatuses }) => {
  return (
    <div className="incident-details">

      <div className="number-mrn">
        <div className="location">
          <small>Notification</small>
          <h4>{data.notification || "Not provided"}</h4>
        </div>
        <div className="location">
          <small>Immediate supervisor</small>
          <h4>{data.immediate_supervisor || "Not provided"}</h4>
        </div>
      </div>

      <div className="number-mrn">
        <div className="location">
          <small>Name of supervisor</small>
          <h4>{data.name_of_supervisor || "Not provided"}</h4>
        </div>
        <div className="location">
          <small>Title of supervisor</small>
          <h4>{data.title_of_supervisor || "Not provided"}</h4>
        </div>
      </div>

      <div className="number-mrn">
        <div className="location">
          <small>Date notified</small>
          <h4>{data.date_notified || "Not provided"}</h4>
        </div>
        <div className="location">
          <small>Time notified</small>
          <h4>{data.time_notified || "Not provided"}</h4>
        </div>
      </div>

      <div className="number-mrn">
        <div className="location">
          <small>Action taken</small>
          <h4>{data.action_taken || "Not provided"}</h4>
        </div>
        <div className="location">
          <small>Prevention suggestion</small>
          <h4>{data.prevention_suggestion || "Not provided"}</h4>
        </div>
      </div>


      <div className="number-mrn">
        <div className="location">
          <small>Reported by</small>
          <h4>{data.reported_by || "Not provided"}</h4>
        </div>
        <div className="location">
          <small>Title reported by</small>
          <h4>{data.reported_by_title || "Not provided"}</h4>
        </div>
      </div>


      <div className="number-mrn">
        <div className="location">
          <small>Date reported</small>
          <h4>{data.date_reported || "Not provided"}</h4>
        </div>
        <div className="location">
          <small>Time reported</small>
          <h4>{data.time_reported || "Not provided"}</h4>
        </div>
      </div>

    </div>
  );
};

export default MedicationOtherInformation;
