import { File02Icon } from "hugeicons-react";
import "../../../../assets/css/pages/drugReactionPage/drugReactionPage.css";
import { Link } from "react-router-dom";
import DateFormatter from "../dateFormatter";
import FormatDate from "../../../../components/general/formatDateTime";
import { format, parseISO } from "date-fns";

function GrievanceInvestigationGeneraInfo({ data }) {
  const formattedDate = (dateString) => {
    if (dateString) {
      const date = parseISO(dateString); // Convert the string to a Date object
      return format(date, "MMM d, yyyy"); // Format the date
    } else {
      return "Not provided";
    }
  };
  return (
    <div className="flex-column">
      <div className="flex-row">
        <div className="flex-column">
          <h3>Date extension letter sent to patient and/or family</h3>
          <small>{data.date_extension_letter_sent || "Not Provided"}</small>
        </div>
      </div>
      <div className="flex-row">
        <div className="flex-column">
          <h3>Copy of letter and certified receipt received</h3>

          {data.extension_letter_copy ? (
            <Link
              className="document-link"
              to={data.extension_letter_copy}
              target="_blank"
              download
            >
              <div className="row">
                <File02Icon className="document-icon" />
                <div className="col">
                  <h4>Certified Receipt Received</h4>
                  <span>
                    Added on {formattedDate(data.date_extension_letter_sent)}{" "}
                  </span>
                </div>
              </div>
            </Link>
          ) : (
            <small>Not provided</small>
          )}
        </div>
      </div>
      <div className="flex-row">
        <div className="flex-column">
          <h3>Date written response letter sent to patient and/or family</h3>
          <small>{data.date_response_letter_sent || "Not Provided"}</small>
        </div>
      </div>
      <div className="flex-column">
        <h3>Copy and certified receipt received</h3>

        {data.response_letter_copy ? (
          <Link
            className="document-link"
            to={data.response_letter_copy}
            target="_blank"
            download
          >
            <div className="row">
              <File02Icon className="document-icon" />
              <div className="col">
                <h4>Certified Receipt Received</h4>
                <span>
                  Added on {formattedDate(data.date_response_letter_sent)}{" "}
                </span>
              </div>
            </div>
          </Link>
        ) : (
          <small>Not provided</small>
        )}
      </div>
      <div className="flex-row">
        <div className="flex-column">
          <h3>Date when matter closed</h3>
          <small>{data.date_matter_closed || "Not Provided"}</small>
        </div>
      </div>
    </div>
  );
}

export default GrievanceInvestigationGeneraInfo;
