import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const NoAccessPage = ({ title, message }) => {

    return (
        <div className="page dark light not-found-page error-page">
            <Helmet>
                <title>Access Denied | Quality Control</title>
            </Helmet>
            <div className="container">
                <div className="branding">
                    <Link><img src={require("../../assets/img/branding/logo.png")} alt="" className="logo" /></Link>
                    <h1 className="title site-title">Quality Control</h1>
                </div>
                <div className="content">
                    <div className="error-title">
                        <h3 className="error-code">401</h3>
                        <div className="divider">

                        </div>
                        <h1 className="title">Access Denied</h1>
                    </div>

                    <div className="error-details">
                        {message}
                        <div className="useful-links">
                            <Link className="useful-link" to={"/"}>Overview</Link>
                            <Link className="useful-link" to={"/incidents/"}>Incidents</Link>
                            <Link className="useful-link" to={"/profile/"}>Profile</Link>
                            <Link className="useful-link" to={"/logout/"}>Logout</Link>
                            <Link className="useful-link" to={"/login/"}>Login</Link>
                        </div>
                    </div>
                </div>

                <div className="report-error-link">
                    <p>Something is off? <Link to={"/error-reporting/"} className="error-link">Report the issue</Link></p>
                </div>
            </div>
        </div>
    )
}

export default NoAccessPage