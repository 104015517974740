import React, { createContext, useState, useEffect, useContext } from "react";
import api, { API_URL } from "../api";
import toast from "react-hot-toast";

const fetchPermissions = async () => {
  try {
    const response = await api.get(`${API_URL}/accounts/permissions`);
    if (response.status === 200) {
      return response.data.permissions;
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
    toast.error("You are logout");
    return [];
  }
};

const PermissionsContext = createContext();

export const PermissionsProvider = ({ children }) => {
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const fetchAndSetPermissions = async () => {
      const permissionsPromise = fetchPermissions();

      const permissions = await permissionsPromise;

      setPermissions(permissions);
    };

    fetchAndSetPermissions();
  }, []);

  return (
    <PermissionsContext.Provider value={permissions}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermission = () => {
  return useContext(PermissionsContext);
};
