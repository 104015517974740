import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashBoardContainer from "../dashboardContainer";
import IncidentDetailsHeader from "./incidentDetails/incidentDetailsHeader";
import IncidentDetails from "./incidentDetails/incidentDetails";
import IncidentTabs from "./incidentDetails/incidentDetailsTabs";
import GeneralIncidentDetailsContentTab from "./generalIncident/incidentDetails";
import api, { API_URL } from "../../../api";

import "../../../assets/css/pages/incidents/general/generalIncidentDetailsPage.css";

import GrievanceDetailsContentTab from "./grievanceIncident/grivance";
// import GrievanceGeneralInformation from "./generalIncident/generalInformation";
import GrievanceIncidentGeneralInfo from "./grievanceIncident/grievanceGeneralInfo";
import GrivanceDocumentHistory from "./grievanceIncident/grivanceDocumentHistory";
import GrievanceReview from "./grievanceIncident/grievanceReviews";
import toast from "react-hot-toast";
import { ArrowRight01Icon } from "hugeicons-react";
import { Link } from "react-router-dom";
import GrievanceInvestigationInfo from "./grievanceIncident/grievanceInvestigationInfo";
import FilesList from "../../../components/documents/filesList";

const GrievanceDetailsContent = () => {
  const { grievanceId } = useParams();
  const [isFetching, setIsFetching] = useState(true);
  const [incidentDetails, setIncidentDetails] = useState({});
  const [investigationDetails, setInvestigationDetails] = useState({});
  //   const [incidentStatus, setIncidentStatus] = useState({});

  useEffect(() => {
    const fetchIncidentDetails = async () => {
      setIsFetching(true);
      try {
        const response = await api.get(
          `${API_URL}/incidents/grievance/${grievanceId}/`
        );
        if (response.status === 200) {
          //   setIncidentStatus(response.data.statuses);
          console.log(response.data);
          setIncidentDetails(response.data.grievance);
          setInvestigationDetails(response.data.investigation);
          setIsFetching(false);
        }
        console.log(incidentDetails);
      } catch (error) {
        console.log(error);
        setIsFetching(false);
      }
    };

    fetchIncidentDetails();
  }, []);
  useEffect(() => {
    const getIncidentReviews = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/grievance-investigation/${grievanceId}/reviews/`
        );
        if (response.status === 200) {
          localStorage.setItem("incidentReviewsCount", response.data.length);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          toast.error("Authentication error");
          window.location.reload();
        } else {
          // toast.error("Failed to fetch incident reviews");
          console.error(error);
        }
      }
    };
    getIncidentReviews();
  }, []);
  useEffect(() => {
    const getDocumentHistory = async () => {
      try {
        const response = await api.get(
          `${API_URL}/activities/list/${grievanceId}/`
        );
        if (response.status === 200) {
          localStorage.setItem("documentHistoryCount", response.data.length);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          toast.error("Authentication error");
          window.location.reload();
        } else {
          toast.error("Failed to fetch document History");
          console.error(error);
        }
      }
    };
    getDocumentHistory();
  }, []);
  return (
    <div className="incident-details-page">
      {isFetching ? (
        <div className="fetching-data">Loading data</div>
      ) : (
        <div className="incident-details">
          <IncidentDetailsHeader
            data={incidentDetails}
            incidentId={grievanceId}
            apiLink={"grievance"}
            sendTo={"send-to-department"}
          />
          <div className="details">
            <IncidentDetails
              data={incidentDetails}
              fullName={incidentDetails.form_initiated_by}
              sex={incidentDetails.sex}
              age={incidentDetails.age}
              dateBirth={incidentDetails.date_of_birth}
              IncidentDate={incidentDetails.date}
              incidentTime={incidentDetails.incident_time}
              incidentCategory={incidentDetails.category}
              incidentDetails={
                <GrievanceDetailsContentTab data={incidentDetails} />
              }
            />
            <IncidentTabs
              data={incidentDetails}
              //   statuses={incidentStatus}
              generalInformation={
                <GrievanceIncidentGeneralInfo
                  data={incidentDetails}
                //   incidentStatuses={incidentStatus}
                />
              }
              otherInformation={
                // <GeneralIncidentOtherInformation data={incidentDetails} />
                "No other information"
              }
              documentHistory={
                <GrivanceDocumentHistory incidentId={grievanceId} />
              }
              reviews={<GrievanceReview incidentId={grievanceId} />}
              documents={<IncidentDocuments incidentId={grievanceId} />}
              investigation={
                <GrievanceInvestigationInfo data={investigationDetails} />
              }
              showInvestigationTab={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const IncidentDocuments = ({ incidentId, apiLink }) => {
  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await api.get(
          `/incidents/grievance/${incidentId}/documents/`
        );
        if (response.status === 200) {
          setDocuments(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchDocuments();
  }, []);
  return <FilesList documents={documents} showDownload={true} />;
};
const BreadCrumbs = () => {
  const { grievanceId } = useParams();
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />
      <Link to={"/incidents/"}>Incidents</Link> <ArrowRight01Icon />
      <Link to={"/incident/grievance/"}>Grievance List</Link>{" "}
      <ArrowRight01Icon />
      <Link className="current-page"> #{grievanceId}</Link>
    </div>
  );
};

const GrievanceDetails = () => {
  return (
    <div>
      <DashBoardContainer
        content={<GrievanceDetailsContent />}
        breadCrumbs={<BreadCrumbs />}
      />
    </div>
  );
};

export default GrievanceDetails;
