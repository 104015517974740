import React from 'react';
import { format, parseISO } from 'date-fns';

const FormatDate = ({ date }) => {
    let parsedDate;

    if (date) {
        try {
            // TO Check if the date includes a time component 
            if (date.includes('T')) {
                parsedDate = parseISO(date); 
            } else {
                parsedDate = new Date(date); 
            }

            if (!isNaN(parsedDate.getTime())) {
                const formattedDate = format(parsedDate, 'yyyy-MM-dd');
                return <span>{formattedDate}</span>;
            } else {
                return <span>Not provided</span>;
            }
        } catch (error) {
            console.error("Date formatting error:", error);
            return <span>Not provided</span>;
        }
    }

    return <span>Not provided</span>;
};

export default FormatDate;
