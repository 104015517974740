import React from "react";

const EmployeeInvestigationContentTab = ({ data }) => {
  return (
    <div className="incident-details">
      <div className="number-mrn">
        <div className="phone-number">
          <small>Marital status</small>
          <h4>{data.marital_status || "Not provided"}</h4>
        </div>
        <div className="mrn">
          <small>Date of hire</small>
          <h4>{data.date_of_hire || "Not provided"}</h4>
        </div>
      </div>

      <div className="address">
        <div className="street">
          <small>Date of birth</small>

          <h4>{data.date_of_birth || "Not provided"}</h4>
        </div>
      </div>

      <div className="address">
        <div className="street">
          <small>Address</small>
          <h4>{data.address || "Not provided"}</h4>
        </div>
      </div>
    </div>
  );
};

export default EmployeeInvestigationContentTab;
