import React, { useEffect, useState } from "react";
import "../../assets/css/main/main.css";
import api, { API_URL } from "../../api";

import TableActionsPopup from "../general/popups/tableActionPopup";
import { usePermission } from "../../contexts/permissionsContext";
import NoAccessPage from "../../pages/errorPages/401";
import {
  MoreHorizontalSquare01Icon,
  FilterHorizontalIcon,
  CancelSquareIcon,
  PencilEdit01Icon,
  PencilEdit02Icon,
  EyeIcon,
} from "hugeicons-react";
import { useNavigate } from "react-router-dom";
const handleSearch = (items, searchString) => {
  if (searchString.length > 2) {
    const results = items.filter((item) => {
      return (
        item.patient_name.toLowerCase().includes(searchString.toLowerCase()) ||
        item.form_initiated_by
          .toLowerCase()
          .includes(searchString.toLowerCase())
      );
    });
    return results;
  }

  return [];
};

function formatDate(dateString) {
  const date = new Date(dateString);
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = String(date.getFullYear()).slice(0);
  return `${month}-${day}-${year}`;
}

const MedicationTab = () => {
  const permission = usePermission();
  const [errorFetching, setErrorFetching] = useState("");
  const [isFetching, setIsFetching] = useState(true);
  const [medicationData, setMedicationData] = useState([]);
  const [searchResults, setSearchResults] = useState("");
  const [resultsFound, setResultsFound] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [filterByDate, setFilterByDate] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [openActionIndex, setOpenActionIndex] = useState("");

  const navigate = useNavigate();

  const handleRowClick = (incidentId) => {
    navigate(`/incident/medication_error/${incidentId}`);
  };
  const navigateToModify = (incidentId) => {
    navigate(`/incident/medication_error/${incidentId}/modify/`);
  };

  const handleNonClickableColumnClick = (event) => {
    event.stopPropagation();
  };
  const toggleAction = (index) => {
    setOpenActionIndex(index);
    setOpenAction(!openAction);
  };

  const toggleFilterByDate = () => {
    setFilterByDate(!filterByDate);
  };

  const search = (string) => {
    setIsSearching(true);
    if (Object.keys(string).length < 2) {
      setIsSearching(false);
    }
    if (Object.keys(string).length > 2) {
      const results = handleSearch(medicationData, string);
      setSearchResults(results);
    }
  };

  useEffect(() => {
    const fetchMedicationData = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/medication_error/`
        );
        if (response.status === 200) {
          // Format the dates before setting the state
          const formattedData = response.data.map((item) => ({
            ...item,
            date_of_error: formatDate(item.date_of_error),
          }));
          setMedicationData(formattedData);
          setIsFetching(false);
        }
      } catch (error) {
        if (error.response?.data?.error) {
          setErrorFetching(error.response.data.error);
        } else {
          setErrorFetching("An error occurred while fetching incident data.");
        }
        setIsFetching(false);
      }
    };
    fetchMedicationData();
  }, []);

  return isFetching ? (
    <>
      {permission.includes("Quality - Risk Manager") ||
      permission.includes("managers") ? (
        <div className="getting-data">
          <p>Getting data..</p>
        </div>
      ) : (
        <NoAccessPage />
      )}
    </>
  ) : (
    <>
      {permission.includes("Quality - Risk Manager") ||
      permission.includes("managers") ? (
        <div>
          {errorFetching ? (
            <div className="error-message">
              <p>{errorFetching}</p>
            </div>
          ) : (
            <div className="tab-container incidents-tab">
              <div className="tab-header">
                <div className="title-container-action">
                  <div className="title-container">
                    <h2 className="title">Medication Error List</h2>
                    <p>{medicationData.length} incidents available</p>
                  </div>
                </div>

                {/* <div className="filters">
                  <div className="search-input">
                    <i className="fa-solid fa-magnifying-glass"></i>
                    <input
                      onChange={(e) => {
                        search(e.target.value);
                      }}
                      type="search"
                      name="systemSearch"
                      id="systemSearch"
                      placeholder="Search the system"
                    />
                  </div>

                  <div className="filter-buttons">
                    <select name="employee" id="status">
                      <option value="employee">Employee</option>
                      <option value="patient">Patient</option>
                      <option value="general">General</option>
                    </select>

                    <select name="status" id="status">
                      <option value="resolved">Resolved</option>
                      <option value="unresolved">Unresolved</option>
                    </select>

                    <div className="date-filter">
                      <button
                        onClick={toggleFilterByDate}
                        className="date-filter-button"
                      >
                        <i className="fa-solid fa-calendar-days"></i>
                        <p> Date </p>
                      </button>

                      {filterByDate ? (
                        <div className="calendars-container">
                          <h3>Filter by dates</h3>

                          <div className="calendars">
                            <div className="calendar">
                              <p>Since</p>
                              <input type="date" />
                            </div>

                            <div className="calendar">
                              <p>Until</p>
                              <input type="date" />
                            </div>
                          </div>

                          <div className="buttons">
                            <button
                              onClick={toggleFilterByDate}
                              className="secondary-button"
                            >
                              Cancel
                            </button>
                            <button className="primary-button">
                              <i className="fa-solid fa-filter"></i>
                              Filter
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="incident-list">
                <table>
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>ID</th>
                      <th>Facility</th>
                      <th>MRN</th>
                      <th>Patient Name</th>
                      {/* <th>Description of variance</th> */}
                      <th>Category</th>
                      <th>Med classification</th>
                      <th>Nurse patient ratio</th>
                      <th>Follow up</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {isSearching ? (
                    <tbody>
                      {searchResults.length > 0 ? (
                        searchResults.map((medication, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{medication.id}</td>
                            <td>
                              {medication.report_facility?.name || "Not found"}
                            </td>
                            <td>{medication.mrn || "-"} </td>
                            <td>{medication.patient_name || "-"}</td>
                            <td>{medication.description_of_error || "-"}</td>
                            {/* <td>{medication.error_category ?
                            (typeof medication.error_category === "string" ?
                              JSON.parse(medication.error_category).category :
                              medication.error_category.category)
                            : "Not specified"}
                          </td> */}
                            <td>{medication.drug_given || "Not specified"}</td>
                            <td>
                              {medication.provider_name || "Not specified"}
                            </td>
                            {/* header add nurse to client ratio */}
                            <td>{medication.follow_up || "Not specified"}</td>

                            <td
                              onClick={(event) =>
                                handleNonClickableColumnClick(event)
                              }
                              className="action-col"
                            >
                              <div className="table-actions">
                                {!medication.is_resolved && (
                                  <PencilEdit02Icon
                                    size={20}
                                    onClick={() =>
                                      navigateToModify(medication.id)
                                    }
                                  />
                                )}

                                <EyeIcon
                                  size={20}
                                  onClick={() => handleRowClick(medication.id)}
                                />
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <p>Nothing found</p>
                      )}
                    </tbody>
                  ) : (
                    <tbody>
                      {medicationData.map((medication, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{medication.id}</td>
                          <td>
                            {medication.report_facility?.name || "Not found"}
                          </td>
                          <td>{medication.mrn || "-"} </td>
                          <td>{medication.patient_name || "-"}</td>
                          <td>{medication.description_of_error || "-"}</td>
                          {/* <td>{medication.error_category ?
                            (typeof medication.error_category === "string" ?
                              JSON.parse(medication.error_category).category :
                              medication.error_category.category)
                            : "Not specified"}
                          </td> */}
                          <td>{medication.drug_given || "Not specified"}</td>
                          <td>{medication.provider_name || "Not specified"}</td>
                          {/* header add nurse to client ratio */}
                          <td>{medication.follow_up || "Not specified"}</td>

                          <td
                            onClick={(event) =>
                              handleNonClickableColumnClick(event)
                            }
                            className="action-col"
                          >
                            <div className="table-actions">
                              {!medication.is_resolved && (
                                <PencilEdit02Icon
                                  size={20}
                                  onClick={() =>
                                    navigateToModify(medication.id)
                                  }
                                />
                              )}

                              <EyeIcon
                                size={20}
                                onClick={() => handleRowClick(medication.id)}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          )}
        </div>
      ) : (
        <NoAccessPage />
      )}
    </>
  );
};

export default MedicationTab;
