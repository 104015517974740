import React from "react";
import "../../../../assets/css/pages/incidents/medication/medication_details.css";
const EmployeeInvestigationGeneralInfo = ({ data, incidentStatuses }) => {
  console.log(data);
  return (
    <div className="incident-details">
      <div className="number-mrn">
        <div className="phone-number">
          <small>Part of the body That was injured</small>
          <h4>{data.part_of_body_injured || "Not provided"}</h4>
        </div>
      </div>

      <div className="number-mrn">
        <div className="phone-number">
          <small>Nature of the injury</small>
          <h4>{data.nature_of_injury || "Not provided"}</h4>
        </div>
      </div>

      <div className="location-contribution-diagnosis">
        <div className="location">
          <small>How the accident happened</small>
          <h4>{data.cause_of_event || "Not provided"}</h4>
        </div>
      </div>
      <div className="location-contribution-diagnosis">
        <div className="location">
          <small>What staff were doing prior to the event</small>
          <h4>{data.employee_prior_activity || "Not provided"}</h4>
        </div>
      </div>
      <div className="location-contribution-diagnosis">
        <div className="location">
          <small>Equipment or tools that were being used</small>
          <h4>{data.equipment_or_tools || "Not provided"}</h4>
        </div>
      </div>
    </div>
  );
};

export default EmployeeInvestigationGeneralInfo;
