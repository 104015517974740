import React, { useEffect, useState } from "react";
import "../../assets/css/main/main.css";
import api, { API_URL } from "../../api";

import TableActionsPopup from "../general/popups/tableActionPopup";
import {
  MoreHorizontalSquare01Icon,
  FilterHorizontalIcon,
  CancelSquareIcon,
  PencilEdit01Icon,
  PencilEdit02Icon,
  EyeIcon,
} from "hugeicons-react";
import { useNavigate } from "react-router-dom";
import SliceText from "../general/sliceText";
import DateFormatter from "../../pages/dashboard/incidents/dateFormatter";
const handleSearch = (items, searchString) => {
  if (searchString.length > 2) {
    const results = items.filter((item) => {
      return (
        (item.patient_visitor_name &&
          item.patient_visitor_name
            .toLowerCase()
            .includes(searchString.toLowerCase())) ||
        (item.incident_type &&
          item.incident_type
            .toLowerCase()
            .includes(searchString.toLowerCase())) ||
        (item.follow_up &&
          item.follow_up.toLowerCase().includes(searchString.toLowerCase()))
      );
    });
    return results;
  }

  return [];
};

const IncidentsTab = () => {
  const [errorFetching, setErrorFetching] = useState("");
  const [isFetching, setIsFetching] = useState(true);
  const [incidentData, setIncidentData] = useState([]);
  const [searchResults, setSearchResults] = useState("");
  const [resultsFound, setResultsFound] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [filterByDate, setFilterByDate] = useState(false);

  const [openAction, setOpenAction] = useState(false);
  const [openActionIndex, setOpenActionIndex] = useState("");

  const navigate = useNavigate();

  const handleRowClick = (incidentId) => {
    navigate(`/incident/workplace_violence/${incidentId}`);
  };
  const navigateToModify = (incidentId) => {
    navigate(`/incident/workplace_violence/${incidentId}/modify/`);
  };

  const handleNonClickableColumnClick = (event) => {
    event.stopPropagation();
  };

  const toggleAction = (index) => {
    setOpenActionIndex(index);
    setOpenAction(!openAction);
  };
  const toggleFilterByDate = () => {
    setFilterByDate(!filterByDate);
  };

  const search = (string) => {
    setIsSearching(true);
    if (Object.keys(string).length < 2) {
      setIsSearching(false);
    }
    if (Object.keys(string).length > 2) {
      const results = handleSearch(incidentData, string);
      setSearchResults(results);
    }
  };

  useEffect(() => {
    const fetchIncidentData = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/workplace_violence/`
        );
        if (response.status === 200) {
          setIncidentData(response.data.incidents);
          setIsFetching(false);
          console.log(response.data.incidents);
        }
      } catch (error) {
        if (error.response.data.error) {
          setErrorFetching(error.response.data.error);
          setIsFetching(false);
        } else {
          setErrorFetching("An error occurred while fetching incident data.");
          setIsFetching(false);
        }
      }
    };
    fetchIncidentData();
    setIsFetching(false);
  }, []);

  return isFetching ? (
    <div className="getting-data">
      <p>Getting data..</p>
    </div>
  ) : (
    <div>
      {errorFetching ? (
        <div className="error-message">
          <p>{errorFetching}</p>
        </div>
      ) : (
        <div className="tab-container incidents-tab">
          <div className="tab-header">
            <div className="title-container-action">
              <div className="title-container">
                <h2 className="title">Work Place Violence List</h2>
                <p>15 incident available </p>
              </div>
            </div>

            {/* <div className="filters">
              <div className="search-input">
                <i className="fa-solid fa-magnifying-glass"></i>
                <input
                  onChange={(e) => {
                    search(e.target.value);
                  }}
                  // value={searchString}
                  type="search"
                  name="systemSearch"
                  id="systemSearch"
                  placeholder="Search the system"
                />
              </div>

              <div className="filter-buttons">
                <select name="employee" id="status">
                  <option value="employee">Employee</option>
                  <option value="patient">Patient</option>
                  <option value="general">General</option>
                </select>

                <select name="status" id="status">
                  <option value="resolved">Resolved</option>
                  <option value="unresolved">Unresolved</option>
                </select>

                <div className="date-filter">
                  <button
                    onClick={toggleFilterByDate}
                    className="date-filter-button"
                  >
                    <i className="fa-solid fa-calendar-days"></i>
                    <p> Date </p>
                  </button>

                  {filterByDate ? (
                    <div className="calendars-container">
                      <h3>Filter by dates</h3>

                      <div className="calendars">
                        <div className="calendar">
                          <p>Since</p>
                          <input type="date" />
                        </div>

                        <div className="calendar">
                          <p>Until</p>
                          <input type="date" />
                        </div>
                      </div>

                      <div className="buttons">
                        <button
                          onClick={toggleFilterByDate}
                          className="secondary-button"
                        >
                          Cancel
                        </button>
                        <button className="primary-button">
                          <i className="fa-solid fa-filter"></i>
                          Filter
                        </button>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div> */}
          </div>

          <div className="incident-list">
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>ID</th>
                  <th>Facility</th>
                  <th>Type of incident</th>
                  <th>
                    Physical injury <br /> description
                  </th>
                  <th>Incident Date and Time</th>
                  <th>Care level</th>
                  <th>Severity</th>
                  <th>Follow up</th>
                  <th className="action-col">Action</th>
                </tr>
              </thead>
              {isSearching ? (
                <tbody>
                  {searchResults.length > 0 ? (
                    searchResults.map((incident, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{incident.id}</td>
                        <td>
                          {incident.report_facility?.name || "Not provided"}
                        </td>
                        <td>{incident.type_of_incident || "Not provided"}</td>
                        <td>
                          <SliceText
                            text={
                              incident.physical_injury_description ||
                              "Not provided"
                            }
                            maxLength={20}
                          />
                        </td>
                        <td>
                          {(
                            <div>
                              <DateFormatter
                                dateString={incident.date_of_incident}
                              />
                              ,&nbsp; {incident.time_of_incident}
                            </div>
                          ) || "-"}
                        </td>
                        <td>{incident.care_level || "Not Provided"}</td>
                        <td>{incident.severity_level || "Not Provided"}</td>
                        <td className="follow-up">
                          {incident.follow_up || "Not provided"}
                        </td>
                        <td
                          onClick={(event) =>
                            handleNonClickableColumnClick(event)
                          }
                          className="action-col"
                        >
                          <div className="table-actions">
                            {!incident.is_resolved && (
                              <PencilEdit02Icon
                                size={20}
                                onClick={() => navigateToModify(incident.id)}
                              />
                            )}
                            <EyeIcon
                              size={20}
                              onClick={() => handleRowClick(incident.id)}
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <p>Nothing found</p>
                  )}
                </tbody>
              ) : (
                <tbody>
                  {incidentData.map((incident, index) => {
                    const parseIfNeeded = (value) => {
                      if (typeof value === "string") {
                        try {
                          return JSON.parse(value);
                        } catch {
                          return value;
                        }
                      }
                      return value;
                    };

                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{incident.id}</td>
                        <td>
                          {incident.report_facility?.name || "Not provided"}
                        </td>
                        <td>
                          {(() => {
                            const parsedValue = parseIfNeeded(
                              <SliceText
                                text={incident.type_of_incident}
                                maxLength={20}
                              />
                            );
                            console.log("parsed value is: ", parsedValue);
                            // return Array.isArray(parsedValue)
                            //   ? parsedValue.join("/ ") || "-"
                            //   : parsedValue || "-";

                            if (typeof parsedValue === "string") {
                              return parsedValue;
                            } else if (typeof parsedValue === "array") {
                              if (parsedValue.length > 0) {
                                return parsedValue.join("/ ");
                              } else {
                                return parsedValue[0];
                              }
                            } else if (typeof parsedValue === "object") {
                              const objParsedValue = parseIfNeeded(
                                parsedValue.incidents
                              );

                              if (objParsedValue) {
                                console.log(
                                  "This is the real object parsed value: ",
                                  objParsedValue
                                );

                                if (objParsedValue.length > 0) {
                                  return objParsedValue.join("/ ");
                                } else {
                                  return objParsedValue[0];
                                }
                              } else {
                                return "Not Provided";
                              }
                            }
                            return "Type";
                          })()}
                        </td>
                        <td>
                          <td>
                            <SliceText
                              text={
                                incident.physical_injury_description ||
                                "Not provided"
                              }
                              maxLength={20}
                            />
                          </td>
                        </td>
                        <td>
                          {(
                            <div>
                              <DateFormatter
                                dateString={incident.date_of_incident}
                              />
                              ,&nbsp; {incident.time_of_incident}
                            </div>
                          ) || "-"}
                        </td>
                        <td>{incident.care_level || "Not Provided"}</td>

                        <td>{incident.severity_level || "Not Provided"}</td>
                        {/* header add care level & severity  */}
                        <td>
                          <p
                            className={`follow-up ${
                              index < 2 ? "in-progress" : ""
                            }`}
                          >
                            {incident.follow_up || "Not specified"}
                          </p>
                        </td>
                        <td
                          onClick={(event) =>
                            handleNonClickableColumnClick(event)
                          }
                          className="action-col"
                        >
                          <div className="table-actions">
                            {!incident.is_resolved && (
                              <PencilEdit02Icon
                                size={20}
                                onClick={() => navigateToModify(incident.id)}
                              />
                            )}
                            <EyeIcon
                              size={20}
                              onClick={() => handleRowClick(incident.id)}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default IncidentsTab;
