import React, { useEffect, useState } from "react";
import {
  CancelCircleIcon,
  ArrowRight02Icon,
  CheckmarkCircle02Icon,
  CircleIcon,
  Loading02Icon,
} from "hugeicons-react";
import { TINYEMCE_API_KEY } from "../../../api";
import { Editor } from "@tinymce/tinymce-react";
import toast, { LoaderIcon } from "react-hot-toast";
import api, { API_URL } from "../../../api";
import mediaAPI from "../../../mediaApi";
import FormatDate from "../../general/formatDateTime";
const SendToDepartmentForm = ({ incidentId, apiLink, closeForm, sendTo, complaint, hasDocuments }) => {
  console.log(incidentId, apiLink);
  const [isLoading, setIsLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [departmentMembers, setDepartmentMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedDepartmentIndex, setSelectedDepartmentIndex] = useState("");
  const [comment, setComment] = useState("");
  const [departmentNotified, setDepartmentNotified] = useState("");
  const [uploadingDocuments, setUploadingDocuments] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  // const [file, setFile] = useState("")
  const [gettingDepartments, setGettingDepartments] = useState(false);
  const [gettingDepartmentMembers, setGettingDepartmentMembers] =
    useState(false);
  const [headOfDepartment, setHeadOfDepartment] = useState([]);

  const fetchDepartmentMembers = async (departmentId) => {
    try {
      const response = await api.get(
        `${API_URL}/departments/${departmentId}/members/`
      );
      console.log(response.data.members);
      if (response.status === 200) {
        console.log("List of departments Members ", response.data.members);
        setDepartmentMembers(
          response.data.members.filter((user) => user.is_head === false)
        );

        setHeadOfDepartment(
          response.data.members.filter((user) => user.is_head === true)
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGettingDepartmentMembers(false);
    }
  };

  const handleSelectDepartment = (department, departmentId, index) => {
    setSelectedDepartment(department);
    setSelectedDepartmentIndex(index);
    fetchDepartmentMembers(departmentId);
    setGettingDepartmentMembers(true);
  };
  const handleSelectedMembers = (user_id) => {
    setSelectedMembers((prevSelected) => {
      // Check if the user_id is already in the selected array
      const isAlreadySelected = prevSelected.some(
        (user) => user.user_id === user_id
      );

      console.log(isAlreadySelected);

      if (isAlreadySelected) {
        // If it's already selected, remove it
        console.log("Element Filtered");
        return prevSelected.filter((user) => user.user_id !== user_id);
      } else {
        // Otherwise, add the new user object
        console.log("Previous Selected ", prevSelected);

        console.log([...prevSelected, { user_id }]);
        return [...prevSelected, { user_id }];
      }
    });
  };

  const handleSendToDepartment = async () => {
    setIsLoading(true);

    const departmentHead = [
      {
        user_id: headOfDepartment[0].id,
      },
    ];

    if (!comment && !complaint) {
      toast.error("Please enter a comment");
      setIsLoading(false);
      return;
    }

    try {
      const postData = {
        department: selectedDepartment,
        assignees:
          selectedMembers.length > 0
            ? [...selectedMembers, departmentHead[0]]
            : departmentHead,
        comment: comment,
      };

      console.log(headOfDepartment);

      console.log("Sending to department...");
      const endPoint = complaint ? apiLink : `incidents/${apiLink}/${incidentId}/${sendTo}`
      const sendToDepartmentResponse = await api.put(
        `${API_URL}/${endPoint}/`,
        postData
      );
      console.log("Send to department response:", sendToDepartmentResponse);

      if (sendToDepartmentResponse.status === 200) {
        toast.success("Sent to department");
        if (complaint) {
          closeForm()
          return
        }
        const saveReviewResponse = await api.post(
          `${API_URL}/incidents/${apiLink}/${incidentId}/reviews/new/`,
          { content: comment }
        );

        if (saveReviewResponse.status === 201) {
          toast.success(selectedDepartment + " is notified");
          setDepartmentNotified(true);
          setTimeout(() => {
            closeForm();
          }, 2000);
        } else {
          toast.error(
            "Error saving review. Go to the reviews tab and add your comment",
            saveReviewResponse
          );
        }
      } else {
        toast.error("Error sending to department");
        return;
      }
    } catch (error) {
      console.error("Error in handleSendToDepartment:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleFileChange = async (event) => {
    const formData = new FormData();
    const files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    try {
      setUploadingDocuments(true);
      console.log([...formData]);

      const response = await mediaAPI.post(
        `/incidents/${apiLink}/${incidentId}/documents/new/`,
        formData
      );

      if (response.status === 200) {
        console.log(response.data.files);
        setUploadingDocuments(false);
        toast.success("Files uploaded successfully");
        setUploadedFiles(response.data.files);
        // setIncident(response.data.incident);
      }
    } catch (error) {
      toast.error("Error uploading documents");
      setUploadingDocuments(false);
      console.log(error);
    }
  };
  useEffect(() => {
    console.log(selectedMembers);
    const fetchDepartments = async () => {
      setGettingDepartments(true);
      try {
        const response = await api.get(`${API_URL}/departments/`);
        console.log(response);
        if (response.status === 200) {
          setDepartments(response.data.departments);
          setGettingDepartments(false);
          console.log("List of departments ", response.data.departments);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setGettingDepartments(false);
      }
    };
    fetchDepartments();
  }, []);
  return (
    <div className="incident-popup">
      <div className="popup-content send-to-department">
        <div className="popup-header">
          <h3>Send to department</h3>
          <div onClick={closeForm} className="icon">
            <CancelCircleIcon size={24} variant={"stroke"} />
          </div>
        </div>
        {selectedDepartment === "" ? (
          <>
            <p>Select department</p>
            {/* <div className="search-field">
                                <input type="search" name="searchDepartment" id="" placeholder='Search department' />
                            </div> */}
            {/* <div className="available-departments">
                                <p>Available departments</p>
                                <small>16</small>
                            </div> */}
          </>
        ) : (
          ""
        )}

        {gettingDepartments ? (
          <div className="loading">
            <Loading02Icon
              className="loading-icon"
              size={20}
              variant={"stroke"}
            />
            <p>Loading departments...</p>
          </div>
        ) : (
          <div className="departments">
            {departments &&
              departments.map((department, index) => (
                <div
                  key={index}
                  onClick={() =>
                    handleSelectDepartment(
                      department.name,
                      department.id,
                      index
                    )
                  }
                >
                  <div
                    className={`department ${selectedDepartmentIndex === index ? "selected" : ""
                      }`}
                  >
                    <div className="icon">
                      {selectedDepartmentIndex === index ? (
                        <CheckmarkCircle02Icon size={18} variant={"stroke"} />
                      ) : (
                        <CircleIcon size={18} variant={"stroke"} />
                      )}
                    </div>

                    <p>{department.name}</p>
                  </div>
                </div>
              ))}
          </div>
        )}

        {gettingDepartmentMembers ? (
          <div className="loading">
            <Loading02Icon
              className="loading-icon"
              size={20}
              variant={"stroke"}
            />
            <p>Loading department members...</p>
          </div>
        ) : (
          <div
            className="departments"
            style={{ height: "100%", maxHeight: "100px", overflow: "auto" }}
          >
            {departmentMembers && departmentMembers.length > 0 ? (
              departmentMembers.map((departmentMember, index) => (
                <div
                  key={index}
                  onClick={() => handleSelectedMembers(departmentMember.id)}
                  style={{
                    padding: "10px",
                    margin: "5px",
                    border: "1px solid black",
                    cursor: "pointer",
                    color: selectedMembers.some(
                      (selectedMember) =>
                        selectedMember.user_id === departmentMember.id
                    )
                      ? "white"
                      : "black",
                    backgroundColor: selectedMembers.some(
                      (selectedMember) =>
                        selectedMember.user_id === departmentMember.id
                    )
                      ? "#F87C47"
                      : "white",
                    transition: "none",
                  }}
                >
                  <div>
                    <p>
                      {departmentMember.first_name} {departmentMember.last_name}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <div style={{ color: "red", fontWeight: "bold" }}>
                No department members, please select a department to display
                their members <br /> <br />{" "}
                <div style={{ color: "#F87C47", fontWeight: "bold" }}>
                  {" "}
                  Note: If you select a department and there is no department
                  members, it means that the department doesn't have any member
                  at the moment
                </div>
              </div>
            )}
          </div>
        )}
        {
          !complaint && <>
            <div className="comment">
              <p>Add a comment</p>
              <textarea
                onChange={(e) => setComment(e.target.value)}
                value={comment}
                name=""
                id=""
                rows="4"
                placeholder="Type your content here"
                required
              ></textarea>
            </div>
            <div className="files-list small">
              {uploadedFiles.map((file, index) => (
                <div className="file-container">
                  {/* <div className="icon">
                <img
                  src="/images/file_types/pdf2-svgrepo-com 1.svg"
                  alt=""
                />
              </div> */}
                  <div className="image-content">
                    <p className="file-title">{file.name}</p>
                    <small>{<FormatDate date={file.created_at} />}</small>
                  </div>
                </div>
              ))}
            </div>
            <div className="file">
              <p>If you have any file, attach it here</p>
              {uploadingDocuments ? (
                "Uploading ..."
              ) : (
                <input
                  type="file"
                  onChange={handleFileChange}
                  name="files"
                  id="files"
                  multiple
                />
              )}
            </div>

          </>
        }

        <div className="buttons">
          <button onClick={closeForm} type="button" className="outline-button">
            Cancel
          </button>
          <button
            onClick={handleSendToDepartment}
            type="button"
            className="primary-button"
          >
            {isLoading ? (
              <>
                <Loading02Icon
                  className="loading-icon"
                  size={20}
                  variant={"stroke"}
                />
                Sending
              </>
            ) : departmentNotified ? (
              <>
                <CheckmarkCircle02Icon size={20} variant={"stroke"} />
              </>
            ) : (
              <>
                <span>Send now</span>
                <ArrowRight02Icon size={20} variant={"stroke"} />
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SendToDepartmentForm;
