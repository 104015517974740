import {
  ArrowLeft01Icon,
  CheckmarkBadge01Icon,
  Tick01Icon,
} from "hugeicons-react";
import React from "react";

const FormCompleteMessage = ({ title, message }) => {
  const handlePageRefresh = () => {
    window.location.reload();
  };
  return (
    <div className="form-complete-message">
      <div className="message-container">
        <div className="icon">
          <Tick01Icon size={42} />
        </div>
        <h3 className="title">{title || "Form submission complete"}</h3>
        <p className="message">
          {message ||
            "All data has been saved, and people in charge have been notified"}
        </p>
      </div>
      <button onClick={handlePageRefresh} className="primary-button">
        <ArrowLeft01Icon />
        <span>Close</span>
      </button>
    </div>
  );
};

export default FormCompleteMessage;
