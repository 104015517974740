import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import DashBoardContainer from "../dashboardContainer";
import IncidentDetailsHeader from "./incidentDetails/incidentDetailsHeader";
import IncidentDetails from "./incidentDetails/incidentDetails";
import IncidentTabs from "./incidentDetails/incidentDetailsTabs";
import api, { API_URL } from "../../../api";
import LostFoundDetailsContentTab from "./lostandfound/lostandfound";
import LostFoundDetailsOtherInformation from "./lostandfound/lostOtherInformation";
import LostFoundGeneralInfo from "./lostandfound/lostandfoundGeneralInfo";
import LostDetails from "./lostandfound/lostincidentDetails";
import toast from "react-hot-toast";
import { ArrowRight01Icon } from "hugeicons-react";

import "../../../assets/css/pages/incidents/general/generalIncidentDetailsPage.css";
import LostAndfoundDocumentHistory from "./lostandfound/lostAndFoundDocumentHistory";
import LostAndFoundReviews from "./lostandfound/lostAndFoundReviews";
import FilesList from "../../../components/documents/filesList";

const LostFoundDetailsContent = () => {
  const { lostAndFoundId } = useParams();
  const [isFetching, setIsFetching] = useState(true);
  const [incidentDetails, setIncidentDetails] = useState({});
  const [incidentStatus, setIncidentStatus] = useState({});

  useEffect(() => {
    const fetchIncidentDetails = async () => {
      setIsFetching(true);
      try {
        const response = await api.get(
          `${API_URL}/incidents/lost_and_found/${lostAndFoundId}/`
        );
        if (response.status === 200) {
          setIncidentStatus(response.data.statuses);
          setIncidentDetails(response.data);
          console.log(response.data);
          setIsFetching(false);
        }
      } catch (error) {
        console.log(error);
        setIsFetching(false);
      }
    };

    fetchIncidentDetails();
  }, []);
  useEffect(() => {
    const getIncidentReviews = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/lost_and_found/${lostAndFoundId}/reviews/`
        );
        if (response.status === 200) {
          localStorage.setItem("incidentReviewsCount", response.data.length);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          toast.error("Authentication error");
          window.location.reload();
        } else {
          toast.error("Failed to fetch incident reviews");
          console.error(error);
        }
      }
    };
    getIncidentReviews();
  }, []);
  useEffect(() => {
    const getDocumentHistory = async () => {
      try {
        const response = await api.get(
          `${API_URL}/activities/list/${lostAndFoundId}/`
        );
        if (response.status === 200) {
          localStorage.setItem("documentHistoryCount", response.data.length);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          toast.error("Authentication error");
          window.location.reload();
        } else {
          toast.error("Failed to fetch document History");
          console.error(error);
        }
      }
    };
    getDocumentHistory();
  }, []);
  return (
    <div className="incident-details-page">
      {isFetching ? (
        <div className="fetching-data">Loading data</div>
      ) : (
        <div className="incident-details">
          <IncidentDetailsHeader
            data={incidentDetails.data}
            incidentId={lostAndFoundId}
            apiLink={"lost_and_found"}
            sendTo={"send-to-department"}
          />
          <div className="details">
            <LostDetails
              data={incidentDetails}
              //   location={incidentDetails.item_description}
              //   ActionTaken={incidentDetails.action_taken}
              //   PersonTakingReport={incidentDetails.person_taking_report || '-'}
              //   Relationship={incidentDetails.relation_to_patient}
              //   LocationFound={incidentDetails.data.location_found}
              incidentDetails={
                <LostFoundDetailsContentTab data={incidentDetails} />
              }
            />
            <IncidentTabs
              data={incidentDetails}
              statuses={incidentStatus}
              generalInformation={
                <LostFoundGeneralInfo
                  data={incidentDetails}
                  incidentStatuses={incidentStatus}
                />
              }
              otherInformation={
                <LostFoundDetailsOtherInformation data={incidentDetails} />
              }
              documentHistory={
                <LostAndfoundDocumentHistory incidentId={lostAndFoundId} />
              }
              reviews={<LostAndFoundReviews incidentId={lostAndFoundId} />}
              documents={<IncidentDocuments incidentId={lostAndFoundId} />}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const IncidentDocuments = ({ incidentId, apiLink }) => {
  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await api.get(
          `/incidents/lost_and_found/${incidentId}/documents/`
        );
        if (response.status === 200) {
          setDocuments(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchDocuments();
  }, []);
  return <FilesList documents={documents} showDownload={true} />;
};

const BreadCrumbs = () => {
  const { lostAndFoundId } = useParams();
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />
      <Link to={"/incidents/"}>Incidents</Link> <ArrowRight01Icon />
      <Link to={"/incident/lost-and-found/"}>
        Lost & Found Property report
      </Link>{" "}
      <ArrowRight01Icon />
      <Link className="current-page"> #{lostAndFoundId}</Link>
    </div>
  );
};

const LostFoundDetails = () => {
  return (
    <div>
      <DashBoardContainer
        content={<LostFoundDetailsContent />}
        breadCrumbs={<BreadCrumbs />}
      />
    </div>
  );
};

export default LostFoundDetails;

// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import DashBoardContainer from "../dashboardContainer";
// import IncidentDetailsHeader from "./incidentDetails/incidentDetailsHeader";
// import IncidentDetails from "./incidentDetails/incidentDetails";
// import IncidentTabs from "./incidentDetails/incidentDetailsTabs";
// import api, { API_URL } from "../../../api";
// import LostFoundDetailsContentTab from "./lostandfound/lostandfound";
// import LostFoundDetailsOtherInformation from "./lostandfound/lostOtherInformation";
// import LostFoundGeneralInfo from "./lostandfound/lostandfoundGeneralInfo";
// import LostDetails from "./lostandfound/lostincidentDetails";

// import "../../../assets/css/pages/incidents/general/generalIncidentDetailsPage.css";

// const LostFoundDetailsContent = () => {
//   const { lostAndFoundId } = useParams();
//   const [isFetching, setIsFetching] = useState(true);
//   const [incidentDetails, setIncidentDetails] = useState({});
//   const [incidentStatus, setIncidentStatus] = useState({});

//   useEffect(() => {
//     const fetchIncidentDetails = async () => {
//       setIsFetching(true);
//       try {
//         const response = await api.get(`${API_URL}/incidents/lost_and_found/${lostAndFoundId}/`);
//         if (response.status === 200) {
//           setIncidentStatus(response.data.statuses);
//           setIncidentDetails(response.data);
//           console.log(response.data);
//         }
//       } catch (error) {
//         console.log(error);
//       } finally {
//         setIsFetching(false);
//       }
//     };

//     fetchIncidentDetails();
//   }, [lostAndFoundId]); // Added lostAndFoundId to dependency array to ensure the effect runs when this ID changes

//   return (
//     <div className="incident-details-page">
//       {isFetching ? (
//         <div className="fetching-data">Loading data</div>
//       ) : (
//         <div className="incident-details">
//           <IncidentDetailsHeader data={incidentDetails} />
//           <div className="details">
//             <LostDetails
//               data={incidentDetails}
//               // If these props are required, make sure to uncomment and provide the correct paths
//               // fullName={incidentDetails.patient_name}
//               // sex={incidentDetails.sex}
//               // IncidentDate={incidentDetails.date_of_error}
//               // incidentTime={incidentDetails.incident_time}
//               // incidentCategory={incidentDetails.category}
//               incidentDetails={
//                 <LostFoundDetailsContentTab data={incidentDetails} />
//               }
//             />
//             <IncidentTabs
//               data={incidentDetails}
//               statuses={incidentStatus}
//               generalInformation={
//                 <LostFoundGeneralInfo
//                   data={incidentDetails}
//                   incidentStatuses={incidentStatus}
//                 />
//               }
//               otherInformation={
//                 <LostFoundDetailsOtherInformation data={incidentDetails} />
//               }
//             />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// const LostFoundDetails = () => {
//   return (
//     <div>
//       <DashBoardContainer content={<LostFoundDetailsContent />} />
//     </div>
//   );
// };

// export default LostFoundDetails;
