import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashBoardContainer from "../dashboardContainer";
import IncidentDetailsHeader from "./incidentDetails/incidentDetailsHeader";
import IncidentDetails from "./incidentDetails/incidentDetails";
import IncidentTabs from "./incidentDetails/incidentDetailsTabs";
import api, { API_URL } from "../../../api";

import "../../../assets/css/pages/incidents/general/generalIncidentDetailsPage.css";

import DrugReactionContentTab from "./drugReactionIncident/DrugReactionContentTab";
import DrugReactionGeneraInfo from "./drugReactionIncident/DrugReactionGeneraInfo";
import DrugReactionOtherInformation from "./drugReactionIncident/DrugReactionOtherInformation";
import DrugReactionDocumentHistory from "./drugReactionIncident/DrugReactionDocumentHistory";
import toast from "react-hot-toast";
import DrugReactionReviews from "./drugReactionIncident/DrugReactionReviews";
import FilesList from "../../../components/documents/filesList";
import { ArrowRight01Icon } from "hugeicons-react";
import { Link } from "react-router-dom";

const BreadCrumbs = () => {
  const { drugReactionId } = useParams();
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />
      <Link to={"/incidents/"}>Incidents</Link> <ArrowRight01Icon />
      <Link to={"/incident/drug-reaction/"}>
        Anaphylaxis/Adverse Drug Reaction List
      </Link>{" "}
      <ArrowRight01Icon />
      <Link className="current-page"> #{drugReactionId}</Link>
    </div>
  );
};

function DrugReactionDetailsContent() {
  const { drugReactionId } = useParams();
  const [isFetching, setIsFetching] = useState(true);
  const [incidentDetails, setIncidentDetails] = useState({});
  //   const [incidentStatus, setIncidentStatus] = useState({});

  useEffect(() => {
    const fetchIncidentDetails = async () => {
      setIsFetching(true);
      try {
        const response = await api.get(
          `${API_URL}/incidents/adverse_drug_reaction/${drugReactionId}/`
        );
        if (response.status === 200) {
          //   setIncidentStatus(response.data.statuses);
          console.log(response.data);
          setIncidentDetails(response.data.data);
          setIsFetching(false);
        }
        console.log(incidentDetails);
      } catch (error) {
        console.log(error);
        setIsFetching(false);
      }
    };

    fetchIncidentDetails();
  }, []);
  useEffect(() => {
    const getIncidentReviews = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/adverse_drug_reaction/${drugReactionId}/reviews/`
        );
        if (response.status === 200) {
          localStorage.setItem("incidentReviewsCount", response.data.length);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          toast.error("Authentication error");
          window.location.reload();
        } else {
          toast.error("Failed to fetch incident reviews");
          console.error(error);
        }
      }
    };
    getIncidentReviews();
  }, []);
  useEffect(() => {
    const getDocumentHistory = async () => {
      try {
        const response = await api.get(
          `${API_URL}/activities/list/${drugReactionId}/`
        );
        if (response.status === 200) {
          localStorage.setItem("documentHistoryCount", response.data.length);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          toast.error("Authentication error");
          window.location.reload();
        } else {
          toast.error("Failed to fetch document History");
          console.error(error);
        }
      }
    };
    getDocumentHistory();
  }, []);
  return (
    <div className="incident-details-page">
      {isFetching ? (
        <div className="fetching-data">Loading data</div>
      ) : (
        <div className="incident-details">
          <IncidentDetailsHeader
            data={incidentDetails}
            incidentId={drugReactionId}
            apiLink={"adverse_drug_reaction"}
            sendTo={"send-to-department"}
          />
          <div className="details">
            <IncidentDetails
              data={incidentDetails}
              fullName={incidentDetails.patient_name}
              sex={incidentDetails.sex}
              IncidentDate={incidentDetails.incident_date}
              incidentTime={incidentDetails.incident_time}
              incidentCategory={incidentDetails.patient_type}
              incidentDetails={
                <DrugReactionContentTab data={incidentDetails} />
              }
            />
            <IncidentTabs
              data={incidentDetails}
              //   statuses={incidentStatus}
              generalInformation={
                <DrugReactionGeneraInfo
                  data={incidentDetails}
                  //   incidentStatuses={incidentStatus}
                />
              }
              otherInformation={
                <DrugReactionOtherInformation data={incidentDetails} />
              }
              documentHistory={
                <DrugReactionDocumentHistory incidentId={drugReactionId} />
              }
              reviews={<DrugReactionReviews incidentId={drugReactionId} />}
              documents={<IncidentDocuments incidentId={drugReactionId} />} //Add a form to upload a document on this incident
            />
          </div>
        </div>
      )}
    </div>
  );
}
const IncidentDocuments = ({ incidentId, apiLink }) => {
  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await api.get(
          `/incidents/adverse_drug_reaction/${incidentId}/documents/`
        );
        if (response.status === 200) {
          setDocuments(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchDocuments();
  }, []);
  return <FilesList documents={documents} showDownload={true} />;
};
function DrugReactionDetails() {
  return (
    <div>
      <DashBoardContainer
        content={<DrugReactionDetailsContent />}
        breadCrumbs={<BreadCrumbs />}
      />
    </div>
  );
}

export default DrugReactionDetails;
