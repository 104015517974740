import { Tick01Icon, Loading02Icon, Cancel01Icon } from "hugeicons-react";
import React, { useState } from "react";
import api, { API_URL } from "../../../api";
import toast from "react-hot-toast";
import postDocumentHistory from "./documentHistory/postDocumentHistory";

function MarkResolvedForm({ incidentId, apiLink, isResolved }) {
  console.log("Is resolved: ", isResolved);
  const [isLoading, setIsLoading] = useState(false);
  const [resolved, setResolved] = useState(false);

  const markResolved = async () => {
    setIsLoading(true);
    try {
      const response = await api.put(
        `/incidents/${apiLink}/${incidentId}/resolve/`
      );
      if (response.status === 200) {
        setResolved(true);
        setIsLoading(false);
        postDocumentHistory(incidentId, "resolved this incident", "resolve");
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 403) {
        toast.error(error.response.data.message);
      } else if (error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Unknown error while resolving incident");
      }
      console.log(error);
    }
  };
  const closeForm = () => {
    window.location.reload();
  };
  return (
    <div className="incident-popup">
      <div className="popup-content">
        {resolved ? <h1>Resolved!</h1> : <h3>Mark Resolved</h3>}

        {resolved ? (
          <p>
            This incident is marked resolved. If you want to undo the action,
            please contact your manager
          </p>
        ) : (
          <p>Are you sure you want to mark this incident as resolved?</p>
        )}
        <div className="buttons">
          {resolved ? (
            ""
          ) : (
            <button
              onClick={closeForm}
              type="button"
              className="outline-button"
            >
              Cancel
            </button>
          )}
          {resolved ? (
            <button
              onClick={closeForm}
              type="button"
              className="secondary-color"
            >
              <Cancel01Icon size={20} />
              <span>Close</span>
            </button>
          ) : (
            <button
              onClick={() => {
                // Mark resolved logic here
                markResolved();
              }}
              type="button"
              className="primary-button"
            >
              {isLoading ? (
                <>
                  <Loading02Icon size={16} className="loading-icon" />
                  <span>Saving</span>
                </>
              ) : (
                <>
                  <Tick01Icon size={16} />
                  <span> Mark Resolved </span>
                </>
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default MarkResolvedForm;
