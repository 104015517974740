import { ArrowLeft01Icon } from 'hugeicons-react'
import React from 'react'
import { Link } from 'react-router-dom'

const BackToPage = ({ link, pageName }) => {
    return (
        <Link to={link} className="back-link">
            <div className="icon">
                <ArrowLeft01Icon />
            </div>
            <p>Back to {pageName}</p>
        </Link>
    )
}

export default BackToPage
