import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import api, { API_URL } from "../../api";
import { Navigate } from "react-router-dom";
import { useAuthentication } from "../../contexts/authContext";
import DashboardContainer from "./dashboardContainer";


import {
    GoogleDocIcon,
    DocumentAttachmentIcon,
    UserAccountIcon,
    Settings05Icon,
    JobLinkIcon,
    MedicineSyrupIcon,
    Loading03Icon
} from "hugeicons-react"

import "../../assets/css/pages/overview/overview.css"
import { ArrowRight01Icon } from "hugeicons-react";
import { Link, useParams } from "react-router-dom";
import ModifyPageLoader from "./incidents/generalIncident/modify/loader";

const OverViewPageContent = () => {
    const [overviewData, setOverviewData] = useState([])
    const [failedFetchingOverviewData, setFailedFetchingOverviewData] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const fetchOverviewData = async () => {
            try {
                const response = await api.get(`${API_URL}/incidents/overview/`);
                if (response.status === 200) {
                    console.log(response.data.overview);
                    setOverviewData(response.data.overview);
                    setIsLoading(false);
                }
            } catch (error) {
                setFailedFetchingOverviewData(true);
                setIsLoading(false);
                console.error(error);
                if (error.response.data) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error("Failed to fetch incident reports");
                }
            }
        }
        fetchOverviewData()
    }, [])
    return isLoading ?
        <ModifyPageLoader />
        : failedFetchingOverviewData
            ? "Error fetching the data" : (
                <div className="overview-content">
                    {
                        overviewData.numbers && <div className="numbers">
                            <div className="number general">
                                <div className="icon">
                                    <GoogleDocIcon size={24} variant={"stroke"} />
                                </div>
                                <div className="content">
                                    <h3 className="number-title">
                                        General Incidents
                                    </h3>
                                    <div className="count">{overviewData.numbers.general}   </div>
                                </div>
                            </div>
                            <div className="number grievance">
                                <div className="icon">
                                    <DocumentAttachmentIcon size={24} variant={"stroke"} />
                                </div>
                                <div className="content">
                                    <h3 className="number-title">
                                        Grievance Incidents
                                    </h3>
                                    <div className="count">{overviewData.numbers.grievances}   </div>
                                </div>
                            </div>

                            <div className="number employee-incident">
                                <div className="icon">
                                    <GoogleDocIcon size={24} variant={"stroke"} />
                                </div>
                                <div className="content">
                                    <h3 className="number-title">
                                        Staff Incidents
                                    </h3>
                                    <div className="count">{overviewData.numbers.employee_incidents}   </div>
                                </div>
                            </div>

                            <div className="number lost-and-found">
                                <div className="icon">
                                    <Settings05Icon size={24} variant={"stroke"} />
                                </div>
                                <div className="content">
                                    <h3 className="number-title">
                                        Lost & Found Property report
                                    </h3>
                                    <div className="count">{overviewData.numbers.lost_and_founds}   </div>
                                </div>
                            </div>
                            <div className="number drug-adverse-reaction">
                                <div className="icon">

                                </div>
                                <div className="content">
                                    <h3 className="number-title">
                                        Anaphylaxis/Adverse Drug Reaction
                                    </h3>
                                    <div className="count">{overviewData.numbers.adverse_drug_reactions}   </div>
                                </div>
                            </div>

                            <div className="number workplace-violence">
                                <div className="icon">
                                    <JobLinkIcon size={24} variant={"stroke"} />
                                </div>
                                <div className="content">
                                    <h3 className="number-title">
                                        Workplace Violence Incidence Report
                                    </h3>
                                    <div className="count">{overviewData.numbers.lost_and_founds}   </div>
                                </div>
                            </div>
                            <div className="number medical-error">
                                <div className="icon">
                                    <MedicineSyrupIcon size={24} variant={"stroke"} />
                                </div>
                                <div className="content">
                                    <h3 className="number-title">
                                        Medical Error/NearMiss
                                    </h3>
                                    <div className="count">{overviewData.numbers.medical_errors}   </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            );
};
const BreadCrumbs = () => {
    const { incidentId } = useParams();
    return (
        <div className="breadcrumbs">
            <ArrowRight01Icon /> <Link className='current-page'> Overview</Link>
        </div>
    )
};
const OverViewPage = () => {
    const { loading, isAuth } = useAuthentication()
    console.log("is auth", isAuth)

    if (loading) {
        // You can return a loading indicator here
        return <div>Loading...</div>;
    }
    return (
        <DashboardContainer content={<OverViewPageContent />} breadCrumbs={<BreadCrumbs />} />
    )

};

export default OverViewPage;
