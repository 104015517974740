import React from 'react'

const ModifyPageLoader = () => {
    return (
        <div className='incident-modify-loader'>
            <div className="loader title"></div>
            <div className="tabs">
                <div className="loader tab"></div>
                <div className="loader tab"></div>
                <div className="loader tab"></div>
            </div>

            <div className="content">
                <div className="loader form-field"></div>
                <div className="loader form-field"></div>
                <div className="loader form-field"></div>
                <div className="loader form-field"></div>
                <div className="loader form-field"></div>
                <div className="loader form-field"></div>
                <div className="loader form-field"></div>
                <div className="loader form-field"></div>
                <div className="loader form-field"></div>
                <div className="loader form-field"></div>
                <div className="loader form-field"></div>
                <div className="loader form-field"></div>
                <div className="loader form-field"></div>
                <div className="loader form-field"></div>
                <div className="button-loader">
                    <div className="loader button"></div>
                </div>
            </div>
        </div>
    )
}

export default ModifyPageLoader
