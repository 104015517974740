import React, { useState, useRef, useEffect } from 'react'
import DashBoardContainer from '../../dashboardContainer'
import CustomDatePicker from '../../../../components/incidents/forms/inputs/datePicker';
import CustomSelectInput from '../../../../components/incidents/forms/inputs/customSelect';
import { AddCircleIcon, CheckmarkSquare01Icon, FloppyDiskIcon, Loading03Icon, RemoveCircleIcon, SquareIcon } from 'hugeicons-react';
import RichTexField from '../../../../components/incidents/forms/inputs/richTexField';
import { incident_agreement, outComeData } from '../../../../constants';
import BackToPage from '../../../../components/incidents/backToPage';
import api from '../../../../api';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import ModifyAdverseDruReactionForm from '../../../../components/incidents/forms/modiy/adverseDruReaction';
import {ArrowRight01Icon} from "hugeicons-react";
import { Link } from "react-router-dom";


const ModifyMedicalAdverseDrugReactionIncidentPageContent = () => {
    const [incident, setIncident] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const { incidentId } = useParams()
    useEffect(() => {
        const fetchIncident = async () => {
            try {
                setIsLoading(true)
                const response = await api.get(`/incidents/adverse_drug_reaction/${incidentId}/`)
                if (response.status === 200) {
                    console.log("Data: ", response.data.data)
                    setIncident(response.data.data)
                    setIsLoading(false)
                }
            } catch (error) {
                toast.error("Error fetching incident")
                setIsLoading(false)
            }
        }
        fetchIncident()
    }, [])
    return isLoading ? 'Loading...' : (
        <ModifyAdverseDruReactionForm data={incident} />
    )
};

const BreadCrumbs = () => {
    const { incidentId } = useParams()
    return (
        <div className="breadcrumbs">
            <Link to={'/'}>Overview</Link> <ArrowRight01Icon />
            <Link to={'/incidents/'}>Incidents</Link> <ArrowRight01Icon />
            <Link to={'/incident/drug-reaction/'}>Anaphylaxis/Adverse Drug Reaction List</Link>  <ArrowRight01Icon />
            <Link to={`/incident/drug-reaction/${incidentId}/`}>#{incidentId}</Link> <ArrowRight01Icon />
            <Link className='current-page'> Modify</Link>
        </div>
    )
};

const ModifyMedicalAdverseDrugReactionIncidentPage = () => {
    const [incident, setIncident] = useState({});
    const [isLoading, setIsLoading] = useState(false)
    const { incidentId } = useParams()
    useEffect(() => {
        const fetchIncident = async () => {
            try {
                setIsLoading(true)
                const response = await api.get(`/incidents/adverse_drug_reaction/${incidentId}/`)
                if (response.status === 200) {
                    console.log("Data: ", response.data.data)
                    setIncident(response.data.data)
                    setIsLoading(false)
                }
            } catch (error) {
                toast.error("Error fetching incident")
                setIsLoading(false)
            }
        }
        fetchIncident()
    }, [])
    return isLoading ? '...' : (
        <DashBoardContainer content={<ModifyMedicalAdverseDrugReactionIncidentPageContent data={incident}  />}   breadCrumbs={<BreadCrumbs />}  />
    )
}

export default ModifyMedicalAdverseDrugReactionIncidentPage
