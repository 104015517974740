import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const ForbiddenPage = () => {
    return (
        <div className="page dark light not-found-page error-page error-page">
            <Helmet>
                <title>Forbidden | Quality Control</title>
            </Helmet>
            <div className="container">
                <div className="branding">
                    <Link><img src={require("../../assets/img/branding/logo.png")} alt="" className="logo" /></Link>
                    <h1 className="title site-title">Quality Control</h1>
                </div>
                <div className="content">
                    <div className="error-title">
                        <h3 className="error-code">404</h3>
                        <div className="divider">

                        </div>
                        <h1 className="title">Forbidden</h1>
                    </div>

                    <div className="error-details">
                        You many not have enough permissions to access this resource.  Here are some useful links:
                        <div className="useful-links">
                            <Link className="useful-link" to={"/"}>Contact Admins</Link>
                            <Link className="useful-link" to={"/incidents/"}>Clear browser data</Link>
                            <Link className="useful-link" to={"/profile/"}>Logout</Link>
                            <Link className="useful-link" to={"/logout/"}>Login</Link>
                        </div>
                    </div>
                </div>

                <div className="report-error-link">
                    <p>Something is off? <Link to={"/error-reporting/"} className="error-link">Report the issue</Link></p>
                </div>
            </div>
        </div>
    )
}

export default ForbiddenPage